<template>
    <div class="info-icons">
        <LastSeen class="m-r-8" :timestamp="user.last_sign_in_at" />
        <MarryPlan class="m-r-8" :user="user" />
        <div class="days-wrapper">
            <IntroDay
                class="m-r-4"
                v-for="idx in [1, 2, 3, 4, 5]"
                :key="idx"
                :introducingDay="idx"
                :selected="idx === introducingDay || idx === introducingDay - 7"
                :canClick="true"
                @clickDay="changeIntroDay(idx)"
                v-show="startDate"
            />
            <div
                class="week-text"
                v-show="user.introducing_day"
                v-html="
                    user.introducing_day - weekdays > 0 && user.introducing_day - weekdays < 8
                        ? ' : 전달하는 주'
                        : ' : 쉬는 주'
                "
            />
            <input
                v-show="
                    userRatePlanRecent && (!userRatePlanRecent.startTypeOrigin || !userRatePlanRecent.expireTypeOrigin)
                "
                class="date-text"
                name="date"
                :placeholder="'예시 : 15/01/02'"
                v-model="date"
                @keydown.enter="inputIntroDay"
            />
            <button
                v-show="
                    userRatePlanRecent && (!userRatePlanRecent.startTypeOrigin || !userRatePlanRecent.expireTypeOrigin)
                "
                class="submit-btn"
                @click="inputIntroDay"
            >
                확인
            </button>
        </div>
    </div>
</template>

<script>
import userService from '@/services/user'

export default {
    name: 'InfoIcons',
    props: ['user', 'hideBookmark'],
    data: () => ({
        date: '',
    }),
    computed: {
        weekdays() {
            return this.$store.getters.weekdays
        },
        startDate() {
            return this.$store.getters.chat.user.service_start_date || true
        },
        introducingDay() {
            return this.$store.getters.chat.user.introducing_day
        },
        userRatePlanRecent() {
            return this.$store.getters.userRatePlanRecent
        },
    },
    watch: {
        user: function () {
            this.date = ''
        },
    },
    methods: {
        async changeIntroDay(day) {
            const res = await userService.updateIntroducingDay(this.user.id, { day: day })
            this.$set(this.user, 'introducing_day', res.day)
            this.$set(this.$store.getters.chat.user, 'introducing_day', res.day)
        },
        async inputIntroDay() {
            if (!this.date) return

            try {
                const res = await userService.updateIntroducingDay(this.user.id, { date: `20${this.date}` })
                const { introducing_day, service_start_date, products } = await userService.detail(this.user.id)
                this.$set(this.$store.getters.chatUser, 'introducing_day', introducing_day)
                this.$set(this.$store.getters.chatUser, 'service_start_date', service_start_date)
                this.$set(this.$store.getters.chatUser, 'products', products)
                this.date = ''
                this.$toast.success(`${res.msg} : ${res.day}`)
            } catch (e) {
                // console.log(e)
            }
        },
    },
}
</script>

<style scoped lang="scss">
.info-icons {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .days-wrapper {
        display: flex;
        align-items: center;

        .week-text {
            @include f-bold;
            width: fit-content;
        }
        .date-text {
            margin-left: 8px;
            max-width: 100px;
        }
        .submit-btn {
            border-radius: 8px;
            // height: 23px;
            color: white;
            border: none;
            background-color: #8a74ff;
        }
    }
}
</style>
