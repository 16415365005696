<template>
    <div class="my-ranking-card">
        <div class="title" v-html="$translate(title)" />
        <div class="flex-row items-center">
            <div class="value flex-wrap" v-html="value" />
            <div class="badge flex-wrap m-l-8" :class="adaptiveColor" v-html="`상위 ${rank}%`" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'TotalRankingCard',
    props: ['title', 'value', 'rank'],
    computed: {
        adaptiveColor() {
            if (this.rank < 30) return 'green'
            if (this.rank < 50) return 'orange'
            return 'gray'
        },
    },
}
</script>
