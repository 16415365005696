<template>
    <div class="my-page">
        <div
            v-if="!$store.getters.isMobile"
            class="flex-row h-100 p-20">
            <div class="flex-wrap my-page-panel">
                <MyRating class="m-b-16" />
                <RefundInfo class="m-b-16" />
                <BridgeHistory/>
            </div>
            <div class="flex-wrap my-page-panel m-l-20">
                <MyRanking class="m-b-16" />
                <WeeklyStat class="m-b-16" />
                <BridgeInfo class="m-b-16" />
            </div>
            <div class="flex-wrap my-page-panel m-l-20">
                <Refunds />
            </div>
        </div>
        <div
            v-else
            class="flex h-100 ver-scroll">
<!--            <Tabs class="flex-wrap" :tabs="tabs.slice(0, $isTester() ? 2 : 4)" @click="onClickTab"/>-->
            <div class="tab-content flex-fill">
                <MyRating class="m-b-16" />
                <RefundInfo class="m-b-16" />
                <BridgeHistory class="m-b-16" />
                <MyRanking class="m-b-16" />
                <BridgeInfo class="m-b-16" />
                <WeeklyStat class="m-b-16" />
                <Refunds />
            </div>
        </div>
    </div>
</template>

<script>
import BridgeHistory from './BridgeHistory'
import BridgeInfo from './BridgeInfo'
import MyRanking from './MyRanking'
import MyRating from './MyRating'
import Refunds from './Refunds'
import RefundInfo from './RefundInfo'
import WeeklyStat from './WeeklyStat'

export default {
    name: 'MyPage',
    components: {
        Refunds,
        RefundInfo,
        MyRanking,
        MyRating,
        WeeklyStat,
        BridgeHistory,
        BridgeInfo,
    },
    data: () => ({
        tabs: [],
    }),
    computed: {
        selectedTab() {
            return this.tabs.find(tab => tab.selected) || {}
        },
        me() {
            return this.$store.getters.agent || {}
        },
    },
    mounted() {
        this.init()
    },
    methods: {
        async init() {

            this.tabs = [
                {
                    //     title: 'RECEIVED_REQUESTS_NORMAL',
                    //     count: this.normal.length,
                    //     selected: true,
                    // }, {
                    //     title: 'RECEIVED_REQUESTS_INSTANT',
                    //     count: this.instant.reduce((total, request) => total += (request.list || []).length, 0),
                    //     selected: false,
                    // }, {
                    //     title: 'MANAGE_FRIENDS',
                    //     selected: false,
                    // }, {
                    title: 'REFUNDS',
                    count: (this.$store.getters.refunds || []).length,
                    selected: false,
                },
            ]
        },
        onClickTab(selectedTab) {
            // eslint-disable-next-line no-return-assign
            this.tabs.forEach(tab => (tab.selected = tab.title === selectedTab.key))
        },
    },
}
</script>
