<template>
    <transition name="slide-up">
        <div v-if="!hide" class="scroller" @click="scroll(true)" ref="me">
            <i class="zmdi" :class="icon" />
        </div>
    </transition>
</template>

<script>
export default {
    name: 'Scroller',
    props: ['dom', 'hideAtEdge', 'direction', 'limit', 'showAtFirst'],
    data: () => ({
        hide: true,
    }),
    computed: {
        icon() {
            return `zmdi-chevron-${this.direction}`
        },
    },
    methods: {
        scroll(animate) {
            if (!this.dom) return

            if (!this.direction) {
                console.error('Missing prop: direction')
                return
            }

            setTimeout(_ => {
                this.$scroll[this.direction](this.dom, animate, this.limit)
                this.$animate.react(this.$refs.me, 200, 0.5)
            })
        },
        onScroll() {
            if (!this.hideAtEdge) return

            if (this.direction === 'up') this.hide = this.dom.scrollTop === 0
            if (this.direction === 'down')
                this.hide = this.dom.scrollTop + this.dom.clientHeight >= this.dom.scrollHeight
            if (this.direction === 'left') this.hide = this.dom.scrollLeft === 0
            if (this.direction === 'right')
                this.hide = this.dom.scrollLeft + this.dom.clientWidth >= this.dom.scrollWidth
        },
    },
    watch: {
        dom(val) {
            if (val) {
                // So that it always bind one event at a time.
                val.removeEventListener('scroll', this.onScroll)
                val.addEventListener('scroll', this.onScroll)
            }
        },
    },
    mounted() {
        this.hide = !this.showAtFirst
    },
}
</script>
