<template>
    <div class="signup intro">
        <Loading :loading="loading" :animation="'dot-falling'" />
        <div class="page-header">
            <div class="dots m-b-16">
                <div v-for="i in [1, 2, 3]" :key="i" class="dot" :class="{ selected: i === 3 }"></div>
            </div>
            <div class="title">주선자 한마디</div>
        </div>
        <div class="body">
            <div class="item-desc flex-row">
                <div class="tips flex-wrap badge-brd">tips</div>
                <div class="desc m-l-8">
                    인삿말, 주선 스타일 등을 통해 본인을 소개하고 소개해줄 수 있는 친구에 대해 어필해보세요!
                </div>
            </div>
            <div class="each-gender">
                <div class="top">
                    <div class="title" v-html="$translate(`MALE_INTRO`)" />
                </div>
                <TextareaWithAutoTexts
                    :placeholder="`PLACEHOLDER_MALE_INTRO`"
                    :hideAutotexts="true"
                    v-model="male_intro"
                />
            </div>
            <div class="each-gender">
                <div class="top">
                    <div class="title" v-html="$translate(`FEMALE_INTRO`)" />
                </div>
                <TextareaWithAutoTexts
                    :placeholder="`PLACEHOLDER_FEMALE_INTRO`"
                    :hideAutotexts="true"
                    v-model="female_intro"
                />
            </div>
        </div>
        <button class="btn btn-primary" @click="next" v-html="$translate('NEXT')"></button>
    </div>
</template>

<script>
import axios from '@/modules/axios'
import userService from '@/services/user'

export default {
    name: 'IntroPage',
    data: () => ({
        male_intro: '',
        female_intro: '',
        loading: false,
    }),
    mounted() {
        this.male_intro = this.$store.getters.payloads.signupData.male_intro
        this.female_intro = this.$store.getters.payloads.signupData.female_intro
    },
    computed: {},
    methods: {
        next() {
            this.$store.commit('setPayloads', {
                signupData: {
                    male_intro: this.male_intro,
                    female_intro: this.female_intro,
                },
            })
            this.createUser(this.$store.getters.payloads.signupData)
        },
        async createUser(signupData) {
            try {
                this.loading = true
                const payload = this.preparedPayload(signupData)
                axios.setHeader({ 'X-User-Role': 'agent' })
                await userService.create(payload)
                await this.$store.dispatch('signIn', {
                    email: signupData.email,
                    password: signupData.password,
                })
            } catch (e) {
                if (e.data.key === 'auth_validation_expired') {
                    this.$store.commit('setNiceData', null)
                }
                this.$toast.error(e.data)
            } finally {
                this.loading = false
            }
        },
        preparedPayload(signupData) {
            const formData = new FormData()
            Object.entries(signupData).forEach(([key, value]) => {
                if (key === 'photos') {
                    value.forEach((p, i) => {
                        formData.append(`photo${i + 1}`, p.blob, p.name)
                    })
                } else if (key === 'gender') {
                    formData.append(key, value.name === 'MALE' ? '0' : '1')
                } else if (['state', 'station', 'region'].indexOf(key) !== -1) {
                    // 커스텀 인풋 허용 안되는 값들은 id만 넘김
                    formData.append(`${this.$case.toSnake(key)}_id`, value.id)
                } else if (['male_keywords', 'female_keywords'].indexOf(key) !== -1) {
                    formData.append(key, JSON.stringify(value))
                } else {
                    formData.append(key, value)
                }
            })
            return formData
        },
    },
}
</script>
