<template>
    <div class="report-user">
        <header class="header m-b-20">
            <div class="m-b-20" @click="onClickBack">
                <i class="material-icons">{{ buttonIcon }}</i>
            </div>
            <h1 class="page-title" v-html="$translate('REPORT_USER')" />
        </header>

        <main class="main" :class="[`step-${step}`, showSkipButton ? '' : 'hide-skip']">
            <ul v-if="reasons.length > 0 && step === 0">
                <li class="reason" v-for="reason in reasons" :key="reason.id" @click="onClickReason(reason)">
                    <div class="content">
                        <p class="name f-medium" v-html="reason.name" />
                        <p class="detail" v-html="reason.detail" />
                    </div>
                    <i class="material-icons">chevron_right</i>
                </li>
            </ul>

            <template v-if="step === 1">
                <p class="m-b-12">신고사유:<span class="c-primary f-bold">프로필에 연락처 기재</span> (선택)</p>
                <div class="textarea-wrapper m-b-32">
                    <textarea
                        class="flex-fill"
                        :value="detailReason"
                        placeholder="신고사유를 작성해주세요"
                        @input="onInput"
                        @focus="hideButton = true"
                        @blur="hideButton = false"
                    />
                    <div v-show="detailReason.length > 0" class="delete-reason flex-wrap" @click="detailReason = ''">
                        <i class="material-icons">cancel</i>
                    </div>
                </div>
                <p class="m-b-12">사진 첨부 (선택)</p>
                <div class="photos">
                    <div
                        class="photo-wrapper center"
                        v-for="(photo, idx) in photos"
                        :key="photo.id"
                        @click="onClickPhoto(idx)"
                    >
                        <img v-if="photo.url" :src="photo.url" />
                        <i v-if="!photo.url" class="material-icons">add</i>
                    </div>
                </div>
            </template>
            <input
                ref="imageUploader"
                type="file"
                class="image display-none"
                @change="onChangeImage($event)"
                accept="image/*"
            />
        </main>

        <footer v-if="step === 1 && !hideButton" class="footer">
            <button class="btn btn-report" :disabled="!buttonEnabled" @click="onClickSubmit">신고하기</button>
            <button v-if="showSkipButton" class="btn btn-skip" @click="onClickSubmit">건너뛰고 신고하기</button>
        </footer>
    </div>
</template>

<script>
import commonService from '@/services/common'
import userService from '@/services/user'

export default {
    name: 'ReportUserPage',
    data: () => ({
        reasons: [],
        step: 0,
        detailReason: '',
        photos: [],
        selectedPhotoIndex: null,
        selectedReason: null,
        reportUser: null,
        hideButton: false,
    }),
    computed: {
        user() {
            return this.$route.params.user || this.$store.getters.chat.user
        },
        buttonIcon() {
            if (this.step === 0) return 'close'
            if (this.step === 1) return 'chevron_left'

            return ''
        },
        buttonEnabled() {
            return this.detailReason.length > 0 || this.photos.some(p => p.url)
        },
        canSkip() {
            if (!this.selectedReason) return false

            return this.selectedReason.name !== '기타'
        },
        showSkipButton() {
            return this.canSkip && !this.buttonEnabled
        },
    },
    methods: {
        async initReasons() {
            try {
                const { reasons } = await commonService.reportReasons('user')
                this.reasons = [...reasons]
            } catch (e) {
                console.error(e)
            }
        },
        initPhotos() {
            return Array(2)
                .fill({})
                .map((photo, idx) => {
                    return {
                        id: idx + 1,
                        url: '',
                        blob: null,
                        name: '',
                    }
                })
        },
        async onClickReason(reason) {
            this.selectedReason = reason

            try {
                this.$loading(true)
                const { report_user: ru } = await userService.reportUser.create(this.user.id, { reason_id: reason.id })
                this.reportUser = ru
                this.step = 1
            } catch (e) {
                console.error(e)
            } finally {
                this.$loading(false)
            }
        },
        onClickBack() {
            if (this.step === 1) this.step = 0
            else this.$router.go(-1)
        },
        onInput(e) {
            this.detailReason = e.target.value
        },
        onClickPhoto(index) {
            if (this.photos[index].url) {
                this.$actionSheet({
                    buttons: [
                        {
                            label: '지우기',
                            class: 'c-red',
                            handler: () => this.removePhoto(index),
                        },
                    ],
                })

                return
            }

            this.selectedPhotoIndex = index
            this.$refs.imageUploader.click()
        },
        onChangeImage(event) {
            const file = event.target.files[0]

            const selectedPhoto = this.photos[this.selectedPhotoIndex]
            selectedPhoto.name = file.name
            selectedPhoto.blob = file

            const reader = new FileReader()
            reader.onload = () => {
                selectedPhoto.url = reader.result
            }

            reader.readAsDataURL(file)

            this.$refs.imageUploader.value = ''
            this.selectedPhotoIndex = null
        },
        removePhoto(index) {
            const emptyPhoto = {
                key: index + 1,
                url: '',
                blob: null,
                name: '',
            }

            this.photos.splice(index, 1, emptyPhoto)
        },
        async onClickSubmit() {
            if (this.buttonEnabled) {
                try {
                    this.$loading(true)
                    const payload = this.populatePayload()
                    const { msg } = await userService.reportUser.update(this.user.id, this.reportUser.id, payload)
                    this.$toast.success(msg)
                    this.$router.go(-1)
                } catch (e) {
                    console.error(e)
                } finally {
                    this.$loading(false)
                }
            } else {
                this.$toast.success('제출 완료')
                this.$router.go(-1)
            }
        },
        populatePayload() {
            const formData = new FormData()

            if (this.photos.length > 0) {
                this.photos.forEach((photo, idx) => {
                    formData.append(`photo_${idx + 1}`, photo.blob)
                })
            }

            if (this.detailReason.length > 0) {
                formData.append('detail', this.detailReason)
            }

            return formData
        },
    },
    mounted() {
        this.initReasons()
        this.photos = this.initPhotos()
    },
}
</script>

<style lang="scss" scoped>
.report-user {
    p,
    h1 {
        padding: 0;
        margin: 0;
        line-height: normal;
    }

    .header {
        padding: 20px 16px;
    }

    .page-title {
        color: black;
        font-size: 24px;
    }

    .main {
        padding: 0 16px 16px;
        overflow-y: scroll;

        &.step-0 {
            height: calc(100vh - 142px);
        }

        &.step-1 {
            height: calc(100vh - (142px + 142px));

            &.hide-skip {
                height: calc(100vh - (142px + 86px));
            }
        }

        .reason {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 14px 16px;
            border-radius: 12px;
            background-color: $grey-01;

            &:not(:last-child) {
                margin-bottom: 12px;
            }

            .name {
                font-size: 18px;
                color: black;
                font-weight: 500;
                margin-bottom: 8px;
            }

            .detail {
                font-size: 13px;
                color: $grey-07;
            }
        }

        .textarea-wrapper {
            padding: 16px;
            display: flex;
            height: 200px;

            textarea {
                border: 0;
            }

            .delete-reason {
                width: 24px;
                display: flex;
                justify-content: flex-end;
            }

            .material-icons {
                font-size: 16px;
            }
        }

        .photos {
            display: flex;
        }

        .photo-wrapper {
            width: 76px;
            height: 76px;
            background-color: $grey-01;
            border-radius: 8px;
            border: solid 1px $grey-02;
            overflow: hidden;

            &:not(:last-child) {
                margin-right: 8px;
            }

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .footer {
        position: absolute;
        bottom: 0;
        padding: 16px 16px 20px;
        display: flex;
        flex-direction: column;
        border-top: solid 1px $grey-02;
        width: 100%;
        background-color: white;

        .btn {
            height: 48px;
            font-family: NotoSans-Medium;
            font-size: 15px;

            &-report {
                color: white;
                background-color: $purple-primary;

                &[disabled] {
                    background-color: $grey-02;
                    color: $grey-05;
                    font-family: NotoSans-Regular;
                }
            }

            &-skip {
                color: $grey-08;
                border: solid 1px $grey-03;
                margin-top: 10px;
            }
        }
    }
}
</style>
