<template>
    <div class="chat-pump" />
</template>
<script>
import ChatConst from '@/assets/constants/chat'

export default {
    name: 'ChatPump',
    computed: {
        authToken() {
            const storedHeader = window.localStorage.getItem('header')
            const header = this.$mustParse(storedHeader)
            if (!header) return

            return header['X-User-Token']
        },
        chats() {
            return this.$store.getters.chats || []
        },
        chat() {
            return this.$store.getters.chat || {}
        },
        connected: {
            get() {
                return this.$store.getters.connected
            },
            set(val) {
                this.$store.commit('connected', val)
            },
        },
        identifier() {
            return JSON.stringify({
                channel: ChatConst.CHANNEL_USER,
                role: 'agent',
                from: 'browser',
            })
        },
        solvedQuiz() {
            // If 'notices' isn't populated, just say the user solved the quiz.
            if (!this.$store.getters.notices) return true

            return (this.$store.getters.agent || {}).$$solvedQuiz
        },
        notices() {
            return this.$store.getters.notices
        },
    },
    data: () => ({
        socket: null,
        pingInterv: null,
        lastPing: null,
    }),
    watch: {
        $route(cur, prev) {
            this.askSolvingQuiz()
        },
        solvedQuiz(solved) {
            if (solved) {
                this.init()
            }
        },
        notices(notices) {
            const me = this.$store.getters.agent
            if (!me) return

            if (!me.$$solvedQuiz) this.askSolvingQuiz()
        },
    },
    mounted() {
        this.$bus.$on('onRequestReconnect', this.openPump)
        this.$bus.$on('onRequestDisconnect', this.closePump)
        this.$bus.$on('onSendTextMessage', this.sendTextMessage)
        this.$bus.$on('updateUnread', this.updateUnread)
        this.init()
    },
    beforeDestroy() {
        this.closePump()
        this.$bus.$off('onRequestReconnect', this.openPump)
        this.$bus.$off('onRequestDisconnect', this.closePump)
        this.$bus.$off('onSendTextMessage', this.sendTextMessage)
        this.$bus.$off('updateUnread', this.updateUnread)

        clearInterval(this.pingInterv)
    },
    methods: {
        init() {
            this.openPump()

            if (!this.pingInterv) {
                this.pingInterv = setInterval(_ => {
                    const diff = this.$moment().diff(this.lastPing, 'seconds')

                    if (diff > 10 && !this.connected) {
                        this.closePump()
                    }
                }, 3000)
            }
        },
        subscribe() {
            if (!this.socket || !this.connected) return

            try {
                this.socket.send(
                    JSON.stringify({
                        command: ChatConst.COMMAND_SUBSCRIBE,
                        identifier: this.identifier,
                    })
                )
            } catch (e) {
                this.closePump()
            }
        },
        async closePump() {
            if (!this.socket) return

            this.socket.close()
            this.connected = false
            this.socket = null
            this.$loading(false)
        },
        async openPump() {
            if (this.connected) return

            if (!this.solvedQuiz) {
                this.$toast.error('SOLVE_QUIZ_FIRST_BODY')
                return
            }

            if (this.socket) {
                this.socket.close()
            }
            this.socket = null

            this.$loading(true)
            const websocketEndpoint = process.env.VUE_APP_CABLE_URL
            const url =
                websocketEndpoint +
                `?token=${this.authToken}&from=${this.$store.getters.isMobile ? 'mobile' : 'pc'}&role=agent`
            this.socket = new WebSocket(url)

            this.socket.onopen = event => {
                this.connected = true
                this.$loading(false)
            }
            this.socket.onerror = event => this.closePump()
            this.socket.onclose = event => this.closePump()
            this.socket.onmessage = event => {
                if (!event.data) return

                this.handleSocketMessage(this.$mustParse(event.data))
            }
        },
        handleSocketMessage(message) {
            if (message.type === ChatConst.MESSAGE_TYPE_WELCOME) {
                this.subscribe()
            }

            if (message.type === ChatConst.MESSAGE_TYPE_CONFIRM_SUBSCRIPTION) {
                this.$mustParse(message.identifier)
            }

            if (message.type === ChatConst.MESSAGE_TYPE_PING) {
                this.lastPing = this.$moment()
                return
            }

            if (message.message && message.message.mtype) {
                this.$store.dispatch('handleNewChat', message.message)
            }

            if (ChatConst.SKIP_TYPE.indexOf(message.type) !== -1) return
            this.handleMessage(message)
        },
        sendTextMessage({ text, is_template, chat }) {
            const chatId = chat ? chat.id : this.chat.id
            const message = {
                command: ChatConst.COMMAND_MESSAGE,
                identifier: this.identifier,
                data: JSON.stringify({
                    content: text,
                    chat_id: chatId,
                    action: ChatConst.MESSAGE_ACTION_MESSAGE,
                    sender_role: this.$store.getters.isMobile ? 'agent' : 'agent_pc',
                    is_template,
                }),
            }

            if (!this.isWSOpened()) {
                setTimeout(_ => {
                    this.closePump()
                }, 500)
                return
            }

            this.$store.commit('saveTextareaInCurrentChat', undefined)
            this.socket.send(JSON.stringify(message))
        },
        isWSOpened() {
            if (!this.socket) return

            return this.socket.readyState === this.socket.OPEN
        },
        handleMessage(message) {
            if (!message) {
                return
            }

            const noti = message.message
            if (noti.action === ChatConst.MESSAGE_ACTION_ALERT) {
                this.$modal.basic({
                    title: noti.title,
                    body: noti.content,
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
                return
            }

            if (noti.action === ChatConst.MESSAGE_ACTION_MESSAGE) {
                this.$store.dispatch('addMessage', message)
                const dom = document.getElementsByClassName('messages')[0]
                if (dom) {
                    const isMatchMessageAndChatRoom = message => {
                        const newMessageChatId = message.message.chat_id
                        const chatRoomChatId = this.chat.id

                        return newMessageChatId === chatRoomChatId
                    }
                    if (isMatchMessageAndChatRoom(message)) this.$scroll.down(dom, true)
                }
                if (message.message.chat_id === this.chat.id && this.$router.currentRoute.name.includes('Chat')) {
                    this.updateUnread()
                }
                // 내가 보낸 메시지 아닌 경우에 언리드 카운트 업데이트
                if (message.message.user_id !== this.$store.getters.agent.user_id && this.$store.getters.isMobile)
                    this.$updateAppIconBadgeCount()
                return
            }

            if (noti.action === ChatConst.MESSAGE_ACTION_UPDATE_MESSAGE) {
                const content = noti.content
                const chatId = noti.chat_id
                const messageId = noti.id
                const chat = (this.$store.getters.chats || []).find(chat => chat.id === chatId)
                if (!chat || !chat.$$messages) return

                const message = (chat.$$messages || []).find(message => message.id === messageId)
                if (!message) return

                message.content = content
            }
        },
        updateUnread() {
            const message = {
                command: ChatConst.COMMAND_MESSAGE,
                identifier: this.identifier,
                data: JSON.stringify({
                    chat_id: this.chat.id,
                    action: ChatConst.MESSAGE_ACTION_UNREAD,
                    sender_role: this.$store.getters.isMobile ? 'agent' : 'agent_pc',
                }),
            }
            this.chat.unread = 0

            try {
                this.socket.send(JSON.stringify(message))
            } catch (e) {
                this.closePump()
            }
        },
        askSolvingQuiz() {
            if (!this.solvedQuiz && ['HomePage'].indexOf(this.$route.name) === -1) {
                this.closePump()
                this.$modal
                    .basic({
                        title: 'SOLVE_QUIZ_FIRST',
                        titleClass: 'c-danger',
                        body: this.$translate('SOLVE_QUIZ_FIRST_BODY'),
                        buttons: [
                            {
                                label: 'GO_TO_SOLVE',
                                class: 'btn-danger',
                            },
                        ],
                    })
                    .then(idx => {
                        if (idx === 0) {
                            this.$router.push({ name: 'HomePage' }).then(() => {
                                if (
                                    (this.$store.getters.pinned || []).some(notice =>
                                        (notice.quizzes || []).some(quiz => !quiz.is_solved)
                                    )
                                ) {
                                    this.$bus.$emit('openPinnedNotice')
                                }
                            })
                        }
                    })
            }
        },
    },
}
</script>
