<template>
    <div class="signup state">
        <div class="page-header">
            <div class="title">지역</div>
        </div>
        <div class="body">
            <ul class="result flex-fill">
                <li
                    @click="next(item)"
                    class="item flex-row items-center flex-between"
                    :key="item.id"
                    v-for="item in states"
                >
                    <div v-html="$translate(item.name)" />
                    <i class="zmdi zmdi-chevron-right" />
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'StatePage',
    mounted() {},
    computed: {
        states() {
            return this.$store.getters.states || []
        },
    },
    methods: {
        next(state) {
            this.$store.commit('setPayloads', {
                signupData: {
                    state: state,
                },
            })
            const next = state.is_station ? 'StationPage' : 'RegionPage'
            this.$router.push({ name: next })
        },
    },
}
</script>
