<template>
    <div class="signup friend-type">
        <div class="page-header">
            <div class="dots m-b-16">
                <div v-for="i in [1, 2, 3]" :key="i" class="dot" :class="{ selected: i === 1 }"></div>
            </div>
            <div class="title m-b-10">친구 성별 선택</div>
            <div class="item-desc">소개할 친구의 <span class="f-bold c-primary">성별</span>을 선택해주세요</div>
        </div>
        <div class="body">
            <div class="ver-scroll">
                <div class="types">
                    <div @click="setFriendType('both')" class="type both" :class="{ selected: friendType === 'both' }">
                        남녀 모두
                    </div>
                    <div @click="setFriendType('male')" class="type male" :class="{ selected: friendType === 'male' }">
                        남성 친구
                    </div>
                    <div
                        @click="setFriendType('female')"
                        class="type female"
                        :class="{ selected: friendType === 'female' }"
                    >
                        여성 친구
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FriendTypePage',
    data: () => ({
        friendType: null,
    }),
    mounted() {
        this.friendType = this.$store.getters.payloads.signupData.friend_type
    },
    methods: {
        setFriendType(type) {
            this.$store.commit('setPayloads', {
                signupData: {
                    friend_type: type,
                },
            })
            this.$router.push({ name: 'KeywordsPage' })
        },
    },
}
</script>
