<template>
    <div class="user-photos-selector">
        <div class="photos-top">
            <button class="btn btn-brd primary select-used p-10 m-6" @click="toggleChoosing">사진 고르기</button>
            <div class="photos-info f-bold f-20" v-if="ImageCount > 0">처리 안한 사진 {{ ImageCount }}장</div>
        </div>
        <div class="grid-col" :class="{ 'toggle-on': toggle, 'toggle-off': !toggle }" v-if="images">
            <div
                class="img-profile animate-size p-relative cursor-pointer"
                :key="img.id"
                v-lazy:background-image="img.photo_url"
                v-for="img in images"
                @click="onClickImage(img)"
            >
                <button v-if="choosing" @click.stop="setActuallyUsed(img, true)">O</button>
                <button v-if="choosing" @click.stop="setActuallyUsed(img, false)">X</button>
                <span class="icon-o" v-if="img.actuallyUsed === 1">O</span>
                <span class="icon-x" v-if="img.actuallyUsed === 2">X</span>
                <Checkbox class="white" v-model="img.checked" />
                <div v-show="img.checked" class="check-number">
                    {{ selectedImages.findIndex(el => el.photo_url === img.photo_url) + 1 }}
                </div>
                <div v-if="isPrivacy" class="privacy-status flex-row">
                    <i class="material-icons flex-wrap security">security</i>
                    <div class="privacy-remain" v-html="privacyRemain" />
                </div>
            </div>
        </div>
        <div class="toggle" @click.stop="onClickToggle">
            <i class="material-icons">{{ toggle ? 'expand_less' : 'expand_more' }}</i>
        </div>
    </div>
</template>

<script>
import agentService from '@/services/agent'

export default {
    name: 'UserPhotosSelector',
    props: ['user', 'sendNum'],
    watch: {
        user() {
            this.initImages()
            this.selectedImages = []
            this.$emit('onPhotos', this.selectedImages)
        },
        sendNum() {
            this.initImages()
            this.selectedImages = []
            this.$emit('onPhotos', this.selectedImages)
        },
        ImageCount(newValue) {
            this.$emit('updateImageCount', newValue)
        },
    },
    computed: {
        isPrivacy() {
            return this.user.$$privacy
        },
        privacyRemain() {
            if (this.user.$$privacy) {
                const privacy = this.$options.filters.hasProduct(this.user, 'privacy')
                return `${this.$moment(privacy.valid_until).diff(this.$moment(), 'days')}일 남음`
            } else {
                return null
            }
        },
    },
    data: () => ({
        images: [],
        selectedImages: [],
        choosing: false,
        toggle: false,
        ImageCount: 0,
    }),
    mounted() {
        this.initImages()
        this.$bus.$on('onPhotosSent', this.onPhotosSent)
        this.$bus.$on('onRequireSelectAllPhotos', this.onRequireSelectAllPhotos)
        this.$bus.$on('onRequireActuallyUsedALLPhotos', this.onRequireActuallyUsedALLPhotos)
        this.$bus.$on('updateImageCount', count => {
            this.ImageCount = count
        })
        const isToggle = sessionStorage.getItem('toggle')
        this.toggle = JSON.parse(isToggle)
    },
    beforeDestroy() {
        this.$bus.$off('onPhotosSent', this.onPhotosSent)
        this.$bus.$off('onRequireSelectAllPhotos', this.onRequireSelectAllPhotos)
        this.$bus.$off('onRequireActuallyUsedALLPhotos', this.onRequireActuallyUsedALLPhotos)
    },
    methods: {
        initImages() {
            this.images = ((this.user || {}).photos || []).map(p => ({
                id: p.id,
                photo_url: p.url,
                checked: false,
                actuallyUsed: p.actually_used,
            }))
            this.images.sort((a, b) => {
                if (a.actuallyUsed === 1 && b.actuallyUsed !== 1) {
                    return -1
                }
                if (a.actuallyUsed !== 1 && b.actuallyUsed === 1) {
                    return 1
                }
                return 0
            })
            this.ImageCount = this.images.filter(img => img.actuallyUsed === 0).length
        },
        toggleActuallyUsed(photo) {
            const updatedPhoto = { ...photo, actuallyUsed: !photo.actuallyUsed }
            this.$emit(
                'updatePhotos',
                this.photos.map(p => (p.id === updatedPhoto.id ? updatedPhoto : p))
            )
        },
        toggleChoosing() {
            this.choosing = !this.choosing
        },
        setActuallyUsed(img, use) {
            const usedImagesCount = this.images.filter(image => image.actuallyUsed === 1).length

            if (usedImagesCount >= 6 && use) {
                this.$toast.error('사진은 최대 6장만 사용할 수 있습니다.')
                return
            }
            const status = use ? 1 : 2
            img.actuallyUsed = status
            this.updatePhotoUseStatus(img.id, { actually_used: status })
            console.log(img.id, status)

            this.images.sort((a, b) => {
                if (a.actuallyUsed === 1 && b.actuallyUsed !== 1) {
                    return -1
                }
                if (a.actuallyUsed !== 1 && b.actuallyUsed === 1) {
                    return 1
                }
                return 0
            })
            this.ImageCount = this.images.filter(img => img.actuallyUsed === 0).length
        },
        async updatePhotoUseStatus(photoId, payload) {
            try {
                const data = await agentService.actuallyUsedPhoto(photoId, payload)
                this.$toast.success(data.msg)

                const imageToUpdate = this.images.find(img => img.id === photoId)
                if (imageToUpdate) {
                    imageToUpdate.actuallyUsed = payload.actually_used
                }
            } catch (e) {
                this.$toast.error('Error updating photo status')
            }
        },
        onClickImage(img) {
            img.checked = !img.checked
            if (this.selectedImages.findIndex(el => el.photo_url === img.photo_url) === -1) {
                const selectedArr = [...this.selectedImages, img]
                this.selectedImages = selectedArr
            } else {
                const selectedArr = this.selectedImages.filter(el => el.photo_url !== img.photo_url)
                this.selectedImages = selectedArr
            }
            if (this.selectedImages.length >= 7) {
                this.$modal.basic({
                    body: '사진은 최대 6장만 선택할 수 있습니다.',
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
                img.checked = false
                const selectedArr = this.selectedImages.filter(el => el.photo_url !== img.photo_url)
                this.selectedImages = selectedArr
            }
            this.$emit('onPhotos', this.selectedImages)
        },
        onPhotosSent(photos) {
            this.images
                .filter(img => photos.some(photoUrl => photoUrl === img.photo_url))
                .forEach(img => {
                    img.checked = false
                })
        },
        onRequireSelectAllPhotos() {
            this.images
                .filter(img => img.actuallyUsed === 1)
                .forEach(img => {
                    img.checked = true
                })
            this.$emit('onPhotos', this.images)
        },

        onRequireActuallyUsedALLPhotos() {
            this.images
                .filter(img => img.actuallyUsed === 1)
                .forEach(img => {
                    img.checked = true
                })
            if (this.images.every(img => img.actuallyUsed !== 1)) {
                this.images.forEach(img => {
                    img.checked = true
                })
            }
            console.log('actuallyUsed all', this.images)
            this.$emit('onPhotos', this.images)
        },

        onClickToggle() {
            this.toggle = !this.toggle
            sessionStorage.setItem('toggle', this.toggle)
        },
    },
}
</script>

<style lang="scss" scoped>
.user-photos-selector {
    width: fit-content;
    overflow-x: hidden;

    .toggle {
        position: absolute;
        top: 10px;
        right: 20px;
        background-color: $purple-primary;
        color: white;
        border-radius: 200px;
        padding: 10px;
        opacity: 0.8;
        z-index: 2;
        i {
            color: white;
        }
    }
}
.photos-top {
    display: flex;
    flex-direction: row;
    align-items: center;

    .photos-info {
        color: $red-chai;
    }
}
.grid-col {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 12px;
    grid-column-gap: 8px;
    // padding: 0 16px 16px;
    overflow-y: auto;
    overflow-x: hidden;
    height: fit-content;
    &.toggle-on {
        max-height: 90%;
        width: fit-content;
    }
    &.toggle-off {
        max-height: 640px;
        width: fit-content;
    }
}
.check-number {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: $purple-primary;
    margin: 10px;
    color: #fff;
    text-align: center;
    font-weight: 600;
}
.select-used {
    box-shadow: none;
    padding: 10px;
    font-weight: 700;
    font-size: 18px;
}
.icon-o {
    color: white;
    background-color: green;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin: 10px;
    text-align: center;
    font-weight: 600;
    font-size: 15px;
    padding: 5px;
}
.icon-x {
    color: white;
    background-color: red;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin: 10px;
    text-align: center;
    font-weight: 600;
    font-size: 15px;
    padding: 5px;
}
.privacy-status {
    width: 80px;
    height: 22px;
    border-radius: 14px;
    background-color: $blue-facebook;
    margin-top: 6px;
    margin-left: 6px;
    padding: 3px 12px 4px 8px;
    align-items: center;

    .security {
        font-size: 14px;
        margin-right: 4px;
        color: white;
    }
    .privacy-remain {
        color: white;
        font-size: 10px;
        @include f-bold;
    }
}
</style>
