<template>
    <div class="bridge-history-detail">
        <div class="body p-relative">
            <div class="thead">
                <div class="flex-row tr">
                    <div
                        :key="column.title"
                        v-for="column in columns"
                        class="th">
                        {{ column.title | translate }}
                    </div>
                </div>
            </div>
            <div class="tbody">
                <div
                    :key="rowIdx"
                    v-for="(row, rowIdx) in (options.rewards || [])"
                    class="flex-row tr">
                    <div>{{ row.created_at | formatDate('YY.MM.DD') }}</div>
                    <div>{{ (row.dating || {}).user_name }}</div>
                    <div>{{ (row.dating || {}).target_name }}</div>
                    <div>{{ row.point | currency }}원</div>
                    <div>{{ row.status_name }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BridgeHistoryDetail',
    props: ['options'],
    computed: {
        columns() {
            return [{
                title: 'TIMESTAMP',
            }, {
                title: 'PAYER',
            }, {
                title: 'FREE_RIDER',
            }, {
                title: 'POINT',
            }, {
                title: 'STATUS',
            }]
        },
    },
}
</script>