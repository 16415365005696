<template>
    <div class="edit-photos">
        <div class="photos">
            <div class="grid-col">
                <div
                    @click="onClickPhoto(idx)"
                    class="photo"
                    :class="{ border: photo.url }"
                    :key="photo.id"
                    v-for="(photo, idx) in photos"
                >
                    <div v-if="photo.url && !photo.$$deleted" class="img-cover" v-img-cover="photo.url" />
                    <i v-else class="zmdi zmdi-plus" />
                    <div v-if="idx === 0" class="badge-main" v-html="$translate('MAIN')" />
                </div>
            </div>
            <input
                ref="imageUploader"
                type="file"
                class="image-input display-none"
                @change="onChangePhoto"
                accept="image/*"
            />
        </div>
        <div class="photos-desc">
            <span class="badge-required" v-html="$translate('REQUIRED')" />
            <span class="content" v-html="$translate('PHOTOS_DESC')" />
        </div>
    </div>
</template>

<script>
import profileService from '@/services/agent'

export default {
    name: 'EditPhotos',
    props: ['value'],
    data: () => ({
        photos: [],
        curPhoto: null,
        deletePhotoIds: [],
    }),
    computed: {
        editable() {
            if (this.curPhoto.position === 0) {
                this.photoErrorHandler('메인 사진은 삭제할 수 없습니다')

                return false
            }

            if (this.photos.filter(p => p.id).length <= 2) {
                this.photoErrorHandler('최소 2장의 유효한 인물 사진이 필요합니다')

                return false
            }

            return true
        },
        me() {
            return this.$store.getters.agent || {}
        },
    },
    mounted() {
        this.init()
    },
    methods: {
        photoErrorHandler(msg) {
            this.$modal
                .basic({
                    title: 'MODAL_DEFAULT_TITLE',
                    body: msg,
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
                .then(idx => {
                    if (idx === 1) {
                        this.$unregisterBackHandler()
                        this.$router.go(-1)
                    }
                })
        },
        init() {
            this.photos = []
            this.$numArray(6).forEach(idx => this.photos.push(this.value[idx] || { url: null }))
        },
        onClickPhoto(index) {
            this.curPhoto = this.photos[index]
            if (!this.curPhoto.id && !this.curPhoto.url) {
                this.$refs.imageUploader.click()
                return
            }

            this.$actionSheet({
                buttons: [
                    {
                        label: '사진 수정하기',
                        handler: () => {
                            if (!this.curPhoto.id) {
                                this.$refs.imageUploader.click()
                                return
                            }

                            if (this.editable) this.$refs.imageUploader.click()
                        },
                    },
                    {
                        label: '사진 삭제',
                        handler: () => {
                            if (!this.curPhoto.id) {
                                this.removePhoto()
                                return
                            }

                            if (this.editable) {
                                this.removePhoto()
                            }
                        },
                    },
                    {
                        label: '메인 사진으로 지정',
                        handler: () => this.setMain(),
                    },
                ],
            })
        },
        onChangePhoto(event) {
            if (this.curPhoto.id) this.deletePhotoIds.push(this.curPhoto.id)

            const file = event.target.files[0]
            this.$refs.imageUploader.value = ''
            this.curPhoto.name = file.name

            this.$modal
                .custom({
                    component: 'ModalCropper',
                    options: {
                        imgFile: file,
                    },
                })
                .then(croppedFile => {
                    if (!croppedFile) return

                    const fReader = new FileReader()
                    fReader.onload = e => {
                        this.curPhoto.url = e.target.result
                        this.curPhoto.blob = croppedFile
                        this.curPhoto.$$deleted = false
                        this.emit()
                    }
                    fReader.readAsDataURL(croppedFile)
                })
        },
        removePhoto() {
            if (this.curPhoto.id) this.deletePhotoIds.push(this.curPhoto.id)

            this.$set(this.curPhoto, 'id', null)
            this.$set(this.curPhoto, 'url', null)
            this.$set(this.curPhoto, '$$deleted', true)
            this.$set(this.curPhoto, 'blob', null)
            this.$set(this.curPhoto, 'name', null)
            this.emit()
        },
        async setMain() {
            if (this.curPhoto.position === 0) return

            if (!this.curPhoto.id) {
                this.$modal
                    .basic({
                        title: 'MODAL_DEFAULT_TITLE',
                        body: '심사가 통과되지 않은 사진은 메인으로 지정할 수 없습니다',
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    .then(idx => {
                        if (idx === 1) {
                            this.$unregisterBackHandler()
                            this.$router.go(-1)
                        }
                    })
                // this.$alert('심사가 통과되지 않은 사진은 메인으로 지정할 수 없습니다')
                return
            }

            try {
                this.$loading(true)
                const data = await profileService.updateMainPhoto(this.curPhoto.id)
                this.$toast.success(data.msg)
                await this.$store.dispatch('loadAgentMe')
                const { photos } = await profileService.v2me()
                const recentPhotos = this.photos
                this.photos = []
                this.$numArray(6).forEach(idx => {
                    const photo = photos[idx]
                    let isExist = false

                    if (photo) {
                        recentPhotos.map(p => {
                            if (p.id === photo.id) isExist = true
                        })
                    }
                    if (isExist) this.photos.push(photo || { url: null })
                })

                this.$numArray(6).forEach(idx => {
                    const photo = recentPhotos[idx]
                    if (photo.blob && photo.blob != null) {
                        this.photos.push(photo || { url: null })
                    }
                })

                this.$numArray(6).forEach(idx => {
                    if (!this.photos[idx]) {
                        this.photos.push({ url: null })
                    }
                })
            } catch (e) {
                this.$modal
                    .basic({
                        title: 'MODAL_DEFAULT_TITLE',
                        body: e.msg,
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    .then(idx => {
                        if (idx === 1) {
                            this.$unregisterBackHandler()
                            this.$router.go(-1)
                        }
                    })
                // this.$alert(e.msg)
            }
            this.$loading(false)
        },
        emit() {
            this.$emit('input', this.photos)
            this.$emit('onChangeDeletePhotoIds', this.deletePhotoIds)
        },
    },
}
</script>
