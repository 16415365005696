<template>
    <div class="my-rating card p-relative">
        <Loading :loading="loading" />
        <div class="top">
            <div class="flex-row items-center">
                <div class="title flex-wrap" v-html="$translate('MY_RATING')" />
            </div>
        </div>
        <div class="cards">
            <div class="flex-row ratings">
                <StarRating
                    v-if="showStar"
                    :default="myRating"
                    :fixed="true"/>
                <div class="avg-rating">{{myRatingDesc}}</div>
            </div>
            <div class="description">최근 받은 15개의 평점 중에서, 가장 낮은 점수 2개를 제외한 13개 평점의 평균이며, 보너스에 적용됩니다.</div>
        </div>
    </div>
</template>

<script>
import StarRating from '@/components/common/StarRating'

export default {
    name: 'MyRating',
    components: { StarRating },
    data: () => ({
        loading: false,
    }),
    computed: {
        myRating() {
            return (this.$store.getters.agent || {}).avg_rating || -1
        },
        showStar() {
            return this.myRating > 0
        },
        myRatingDesc() {
            if(this.myRating > 0)
                return this.myRating
            else
                return '아직 15개의 평가를 받지 못했습니다.'
        }
    },
    async mounted() {

    },
    methods: {
    },
}
</script>
