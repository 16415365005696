<template>
    <div class="banner p-relative" v-if="banner">
        <img :src="banner.image_url">
    </div>
</template>

<script>
import dashboardService from '@/services/dashboard'

export default {
    name: 'Banner',
    data: () => ({
        banner: null,
    }),
    mounted() {
        this.loadBanner()
    },
    methods: {
        async loadBanner() {
            try {
                const data = await dashboardService.banners('notices')
                if (data && data.length > 0) {
                    this.banner = data[0]
                }
            } catch (e) {}
        },
    },
}
</script>