<template>
    <div class="native-bridge"></div>
</template>

<script>
export default {
    name: 'NativeBridge',
    mounted() {
        document.addEventListener('message', this.onNativeMessage) // Android
        window.addEventListener('message', this.onNativeMessage) // iOS
    },
    beforeDestroy() {
        document.removeEventListener('message', this.onNativeMessage) // Android
        window.addEventListener('message', this.onNativeMessage) // iOS
    },
    methods: {
        onNativeMessage(event) {
            const data = this.$mustParse(event.data)

            if (!data || !data.action) return

            switch (data.action) {
                case 'registration':
                    window.deviceToken = {
                        role: 'agent',
                        platform: data.platform,
                        registration_id: data.registration_id,
                    }
                    break
                case 'niceData':
                    this.$store.commit('setNiceData', data.params)
                    break
                case 'toast':
                    this.$toast[data.value.type](data.value.message)
                    break
                case 'setDevice': {
                    const storedDevice = this.$store.getters.device
                    if (data.device && !storedDevice) {
                        this.$store.commit('setDevice', data.device)
                    }
                    break
                }
                case 'changeAppState': {
                    if (data.value === 'foreground') {
                        setTimeout(() => {
                            this.$bus.$emit('onRequestReconnect')
                        }, 200)
                    } else if (data.value === 'background') {
                        this.$bus.$emit('onRequestDisconnect')
                    }
                    this.$updateAppIconBadgeCount()
                    break
                }
                default:
            }
        },
    },
}
</script>
