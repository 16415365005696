<template>
    <div class="super-filter">
        <div class="section-filters" :class="{ 'flex-row': !$store.getters.isMobile }">
            <div class="section-prior-filters flex-fill">
                <div class="slider filter-item" :key="key" v-for="key in ['age', 'height']">
                    <div class="header flex-row items-center flex-between">
                        <div class="title flex-wrap m-r-16" v-html="$translate(key.toUpperCase())" />
                        <div>
                            <span class="range flex-wrap" v-html="$translate(rangeStr(key))" />
                            <span class="unit" v-if="rangeStr(key) !== 'ALL'" v-html="key === 'age' ? '세' : 'cm'" />
                        </div>
                    </div>
                    <VueSlider v-if="key === 'age'" v-model="ageValue" v-bind="sliderOptions(key)" />
                    <VueSlider v-if="key === 'height'" v-model="heightValue" v-bind="sliderOptions(key)" />
                    <div class="min-max flex-row flex-between">
                        <span>{{ sliderOptions(key).min }}</span>
                        <span>{{ sliderOptions(key).max }}</span>
                    </div>
                </div>
                <div class="hr" />
                <div v-if="filters.searchByDistance" class="slider filter-item">
                    <div class="header flex-row items-center flex-between">
                        <div class="title flex-wrap m-r-16" v-html="$translate('DISTANCE')" />
                        <div>
                            <span class="range flex-wrap" v-html="$translate(rangeStr('distance'))" />
                            <span class="unit" v-if="distanceValue === 100 ? 'km' : 'km 이내'" />
                        </div>
                    </div>
                    <VueSlider v-model="distanceValue" v-bind="sliderOptions('distance')" />
                    <div class="min-max flex-row flex-between">
                        <span>0</span>
                        <span>100+km</span>
                    </div>
                </div>
                <div v-if="!filters.searchByDistance" class="flex-fill">
                    <FilterItem
                        @onChange="onFilterItem('province', $event)"
                        :title="$case.toSnake('province').toUpperCase()"
                        :items="filters['province']"
                    />
                </div>
                <div class="filter-switch-wrapper flex-row cursor-pointer">
                    <img class="m-r-8 flex-wrap" src="@/assets/images/filter_switch.svg" />
                    <button
                        class="filter-switch-button flex-wrap cursor-pointer f-medium"
                        @click="filters.searchByDistance = !filters.searchByDistance"
                    >
                        {{ searchDistanceString }}로 검색하기
                    </button>
                </div>
                <div class="hr" />
                <div class="filter-priorities">
                    <FilterItem
                        @onChange="onFilterItem(filterKey, $event)"
                        :title="$case.toSnake(filterKey).toUpperCase()"
                        :items="filters[filterKey]"
                        :hideAll="
                            ['preferences', 'neverIntroduced', 'lastSign', 'no_ons_campaign'].indexOf(filterKey) !== -1
                        "
                        :preventMultiSelect="['preferences', 'lastSign', 'no_ons_campaign'].indexOf(filterKey) !== -1"
                        :user="['neverIntroduced'].indexOf(filterKey) !== -1 ? chat.user : {}"
                        :key="filterKey"
                        v-for="filterKey in rightFirstFilterKeys"
                    />
                </div>
                <div class="hr" />
                <div class="filter-priorities">
                    <FilterItem
                        @onChange="onFilterItem(filterKey, $event)"
                        :title="$case.toSnake(filterKey).toUpperCase()"
                        :items="filters[filterKey]"
                        :key="filterKey"
                        v-for="filterKey in leftFirstFilterKeys"
                    />
                </div>
                <div class="hr" />
                <div class="filter-profiles" :key="filterKey" v-for="filterKey in kindShapeFilterKeys">
                    <FilterItem
                        @onChange="onFilterItem(filterKey, $event)"
                        :title="$case.toSnake(filterKey).toUpperCase()"
                        :items="filters[filterKey]"
                    />
                </div>
                <div class="hr" />
                <div class="flex-fill" :key="filterKey" v-for="filterKey in detailedFilterKeys">
                    <FilterItem
                        @onChange="onFilterItem(filterKey, $event)"
                        :title="$case.toSnake(filterKey).toUpperCase()"
                        :items="filters[filterKey]"
                    />
                </div>
                <div class="hr" />
                <div class="filter-belongings" :key="filterKey" v-for="filterKey in belongingFilterKeys">
                    <FilterItem
                        @onChange="onFilterItem(filterKey, $event)"
                        :title="$case.toSnake(filterKey).toUpperCase()"
                        :items="filters[filterKey]"
                    />
                </div>
                <div class="hr" />
                <div class="filter-priorities">
                    <FilterItem
                        @onChange="onFilterItem(filterKey, $event)"
                        :title="$case.toSnake(filterKey).toUpperCase()"
                        :items="filters[filterKey]"
                        :key="filterKey"
                        v-for="filterKey in styleFilterKeys"
                    />
                </div>
            </div>
            <div class="bottom-button-wrapper">
                <button class="bottom-button" @click="superSearch">필터 적용하기</button>
            </div>
        </div>
    </div>
</template>

<script>
import FilterItem from '@/components/common/FilterItem'
import * as helpers from '@/store/data/helpers'

export default {
    name: 'SuperFilter',
    props: ['filters', 'filterChanged'],
    data: () => ({
        titleFocused: false,
    }),
    components: { FilterItem },
    watch: {
        filters: {
            handler(newVal, oldVal) {
                helpers.updateFiltersInLocalStorage(this.$store.getters.chats)
                this.$emit('update:filterChanged', true)
            },
            deep: true,
        },
    },
    computed: {
        searchDistanceString() {
            return this.filters.searchByDistance ? '지역으' : '거리'
        },
        ageValue: {
            get() {
                return [this.filters.minAge, this.filters.maxAge]
            },
            set(newVal) {
                this.filters.minAge = newVal[0]
                this.filters.maxAge = newVal[1]
            },
        },
        heightValue: {
            get() {
                return [this.filters.minHeight, this.filters.maxHeight]
            },
            set(newVal) {
                this.filters.minHeight = newVal[0]
                this.filters.maxHeight = newVal[1]
            },
        },
        distanceValue: {
            get() {
                return this.filters.distance
            },
            set(newVal) {
                this.filters.distance = newVal
            },
        },
        defaultParams() {
            return {
                minAge: 20,
                maxAge: 49,
                minHeight: 140,
                maxHeight: 199,
                smoking: [
                    {
                        name: '흡연',
                        value: true,
                        selected: true,
                    },
                    {
                        name: '비흡연',
                        value: false,
                        selected: true,
                    },
                ],
            }
        },
        detailedFilterKeys() {
            return ['isStudent', 'jobCategory', 'schoolType']
        },
        leftFirstFilterKeys() {
            const arr = ['smoking', 'religion']
            return arr
        },
        rightFirstFilterKeys() {
            const arr = ['lookScoreAdmin', 'lastSign', 'neverIntroduced', 'preferences', 'no_ons_campaign']
            return arr
        },
        kindShapeFilterKeys() {
            const arr = ['shape', 'kind', 'mbti']
            return arr
        },
        belongingFilterKeys() {
            const arr = ['car', 'pet']
            return arr
        },
        styleFilterKeys() {
            const arr = ['targetFriendType', 'targetSpecType', 'targetSmokingType']
            return arr
        },
        chat() {
            return this.$store.getters.chat || {}
        },
    },
    methods: {
        rangeStr(type) {
            if (type === 'distance') {
                const val = this.distanceValue
                if (val === 100) return 'ALL'
                else return this.distanceValue
            } else {
                const min = this[`${type}Value`][0]
                const max = this[`${type}Value`][1]
                if (min === this.sliderOptions(type).min && max === this.sliderOptions(type).max) return 'ALL'

                return `${min} ~ ${max}`
            }
        },
        sliderOptions(type) {
            if (type === 'distance') {
                return {
                    min: 0,
                    max: 100,
                    tooltip: 'none',
                }
            } else {
                return {
                    min: this.defaultParams[`min${this.$case.toCapital(type)}`],
                    max: this.defaultParams[`max${this.$case.toCapital(type)}`],
                    tooltip: 'none',
                }
            }
        },
        closeFilter() {
            this.$store.commit('setShowFriendsFilter', false)
        },
        onFilterItem(filterKey, filter) {
            if (!filterKey || !filter) return

            if (filterKey === 'preferences' && filter.item) {
                this.toggleAutoFilter(filter.item.value)
            }

            this.filters[filterKey] = this.$copy(filter.items)
        },
        toggleAutoFilter(on) {
            if (on) {
                helpers.setAgeHeightFiltersByStyles(this.chat, this.filters)
            } else {
                Object.keys(this.defaultParams).forEach(key => {
                    this.filters[key] = this.$copy(this.defaultParams[key])
                })
            }
        },
        superSearch() {
            this.filters.nickname = ''
            this.$hooks.superSearch(this.filters)
            this.$emit('onClickSuperSearch')
            this.$emit('update:filterChanged', false)
        },
    },
}
</script>
