<template>
    <div class="chat-input" v-show="chat.user">
        <transition name="slide-up">
            <div class="functions" v-if="showFunctions">
                <div
                    @click="foo.action"
                    class="item flex-row items-center"
                    :key="foo.title"
                    :class="{ inactive: foo.active != null && !foo.active }"
                    v-for="foo in functions"
                    v-html="$translate(foo.title)"
                />
            </div>
        </transition>

        <transition name="slide-up">
            <AutoTexts
                v-if="showAutoTexts"
                :shortcut="shortcut"
                @close="showAutoTexts = false"
                @onAutoText="onAutoText"
            />
        </transition>

        <transition name="slide-up">
            <Emoji v-if="showEmoji" @onClickEmoji="onClickEmoji" @close="showEmoji = false" />
        </transition>

        <div class="textarea-wrapper">
            <!-- <Disconnected /> -->
            <div ref="height-limiter" class="flex-row items-center height-limiter">
                <textarea
                    :disabled="isAlimi"
                    @paste="sendPhotoWhenClipboardContainsImage"
                    ref="chat-input-textarea"
                    class="chat-input-textarea flex-fill"
                    @keypress.exact.enter.prevent="onEnter"
                    :placeholder="$translate(isAlimi ? 'DISABLED_CHAT_ALIMI' : 'INPUT_MESSAGE')"
                    :maxlength="maxlength"
                    @input="text = $event.target.value"
                    :value="text"
                />
            </div>
            <input
                ref="imageUploader"
                type="file"
                class="image display-none"
                @change="onChangeImage"
                accept="image/*"
            />
            <div class="flex-row flex-between items-center" v-if="!isAlimi">
                <div class="flex-row function-icons">
                    <img
                        v-for="icon in chatInputPcIcons"
                        :key="icon.key"
                        @click="onClickIcon(icon.key)"
                        class="chat-input-function-icon"
                        @mouseover="icon.mouseOver(true)"
                        @mouseleave="icon.mouseOver(false)"
                        v-show="icon.show"
                        :src="icon.src"
                    />
                    <div
                        v-if="isPremiumAgent && myPremiumUser"
                        class="sales-template-button"
                        v-html="$translate('SALES_TEMPLATE')"
                        @click="onClickSalesTemplate"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import chatService from '@/services/chat'
import userService from '@/services/user'
// import Disconnected from '@/components/common/Disconnected'
import suggestService from '@/services/suggest'

export default {
    name: 'ChatInput',
    // components: { Disconnected },
    computed: {
        contacts() {
            return this.chat.$$contacts
        },
        introducingUser() {
            return this.chat.$$introducingUser
        },
        chat() {
            return this.$store.getters.chat || {}
        },
        agent() {
            return this.$store.getters.agent || {}
        },
        functions() {
            const items = [
                { title: 'CHAT_FUNCTION_SEND_SMS', action: this.sendJoinSMS, active: true },
                {
                    title: 'CHAT_FUNCTION_REQUEST_CONTACT',
                    action: this.requestContact,
                    active: this.enableDating,
                },
                {
                    title: 'CHAT_FUNCTION_SEND_CHECK',
                    action: this.requestDating,
                    // active: this.enableDating
                    active: true,
                },
                {
                    title: 'CHAT_USER_DORMANT',
                    action: this.dormantUser,
                    active: true,
                },
                {
                    title: 'CHAT_USER_DROPOUT',
                    action: this.dropoutUser,
                    active: true,
                },
            ]

            return items
        },
        showIcon() {
            return manager => {
                const showManager = {
                    matching: [1, 2, 4, 5, 6, 7, 8, 12, 13, 14, 15, 16, 17, 18, 19],
                    costomercenter: [3, 9],
                    judging: [3, 9],
                }
                return manager.some(mng => {
                    return showManager[mng].indexOf(this.agent.id) > -1
                })
            }
        },
        chatInputPcIcons() {
            const icons = [
                {
                    key: 'functions',
                    mouseOver: over => {
                        this.overFunctions = over
                    },
                    src: this.functionSrc,
                    show: true,
                },
                {
                    key: 'profile',
                    mouseOver: over => {
                        this.overProfile = over
                    },
                    src: this.profileSrc,
                    show: this.showIcon(['judging']),
                },
                {
                    key: 'schedule',
                    mouseOver: over => {
                        this.overSchedule = over
                    },
                    src: this.scheduleSrc,
                    show: this.showIcon(['matching', 'costomercenter']),
                },
                {
                    key: 'feedback',
                    mouseOver: over => {
                        this.overFeedback = over
                    },
                    src: this.feedbackSrc,
                    show: this.showIcon(['matching', 'costomercenter']),
                },
                {
                    key: 'divider',
                    mouseOver: () => {},
                    src: require(`@/assets/images/divider.svg`),
                    show: true,
                },
                {
                    key: 'emoji',
                    mouseOver: over => {
                        this.overEmoji = over
                    },
                    src: this.emojiSrc,
                    show: true,
                },
                {
                    key: 'auto_texts',
                    mouseOver: over => {
                        this.overAutoTexts = over
                    },
                    src: this.autoTextSrc,
                    show: true,
                },
                {
                    key: 'gallery',
                    mouseOver: over => {
                        this.overGallery = over
                    },
                    src: this.gallerySrc,
                    show: true,
                },
            ]
            if (this.agent.fulltime) {
                icons.push({
                    key: 'referral',
                    mouseOver: () => {},
                    src: require(`@/assets/images/referral.svg`),
                    show: true,
                })
            }
            return icons
        },

        connected() {
            return this.$store.getters.connected
        },
        textLength() {
            if (!this.text) return

            return `(${this.text.length}/${this.maxlength})`
        },
        maxlength() {
            return 1024
        },
        dom() {
            return this.$refs['chat-input-textarea']
        },
        isAlimi() {
            return (this.chat.user || {}).id === 0
        },

        emojiSrc() {
            return this.iconSrc('emoji', this.showEmoji, this.overEmoji)
        },
        functionSrc() {
            return this.iconSrc('functions', this.showFunctions, this.overFunctions)
        },
        profileSrc() {
            return this.iconSrc('profile', this.showProfile, this.overProfile)
        },
        autoTextSrc() {
            return this.iconSrc('auto_texts', this.showAutoTexts, this.overAutoTexts)
        },
        gallerySrc() {
            return this.iconSrc('gallery', false, this.overGallery)
        },
        scheduleSrc() {
            return this.iconSrc('schedule', false, this.overSchedule)
        },
        feedbackSrc() {
            return this.iconSrc('feedback', this.showFeedback, this.overFeedback)
        },
        isInstantChat() {
            return this.chat && this.chat.expire_at
        },
        isPremiumAgent() {
            return this.agent.is_premium
        },
        myPremiumUser() {
            if ((this.chat.user || {}).$$premium) {
                const premium = this.$options.filters.hasProduct(this.chat.user, 'premium')
                return this.$store.getters.agent.id === premium.agent_id
            }
            return false
        },
    },
    data: () => ({
        text: '',
        lastChanged: true,
        showFunctions: false,
        overFunctions: false,
        showAutoTexts: false,
        overAutoTexts: false,
        shortcut: null,
        templateUsed: false, // Denoted if user clicked autotext in current chatroom.
        showEmoji: false,
        overEmoji: false,
        overGallery: false,
        overSchedule: false,
        overFeedback: false,
        overProfile: false,
        enableSpreadAgenting: false,
        enableIntroduceAgenting: false,
        enableDating: true,
        showFeedback: false,
        showProfile: false,
    }),
    watch: {
        chat(newVal, oldVal) {
            if (newVal && oldVal && newVal.id === oldVal.id) return

            this.text = this.chat.$$text
            this.templateUsed = false

            this.noticeRestriction()
        },
        connected(newVal) {
            if (this.dom) {
                this.dom.blur()
            }
        },
        text(newVal, oldVal) {
            if (!newVal) {
                this.showAutoTexts = false
                return
            }

            if (
                oldVal &&
                oldVal.indexOf('/') === -1 &&
                newVal.indexOf('/') === -1 &&
                this.showAutoTexts &&
                this.lastChanged
            ) {
                // template 에서 마지막글자 남는것 처리 위한 야매
                this.text = this.text.substring(0, this.text.length - 1)
                this.lastChanged = false
                return
            }

            if (!newVal) {
                this.shortcut = null
                this.showAutoTexts = false
                return
            }

            const splitted = (newVal || '').split('/')
            if (splitted.length > 1) {
                this.lastChanged = true
                this.shortcut = splitted[1]
                this.showAutoTexts = true
            } else {
                setTimeout(_ => {
                    this.showAutoTexts = false
                }, 200)
            }
        },
    },
    methods: {
        async noticeRestriction() {
            await this.agentingEnabled()
            if (!this.enableDating) {
                this.$modal.basic({
                    title: 'NOTICE',
                    body: this.$translate('NOTICE_CONTACT_RESTRICTION'),
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
            }
        },
        iconSrc(key, show, over) {
            if (show) {
                return require(`@/assets/images/${key}_active.svg`)
            } else if (over) {
                return require(`@/assets/images/${key}_hover.svg`)
            } else {
                return require(`@/assets/images/${key}_inactive.svg`)
            }
        },
        onKeydown() {
            setTimeout(() => {
                this.text = this.dom.value
            })
        },
        onEnter() {
            this.send(this.text)
        },
        send(text) {
            if (!this.chat.user || !this.text || this.text.trim().length === 0) return

            if (this.showAutoTexts) return

            this.$bus.$emit('onSendTextMessage', {
                text,
                is_template: this.templateUsed,
            })
            this.text = ''
            this.templateUsed = false
            this.dom.focus()
        },
        // This is not uploading any image, but just sending imageURLs as messages.
        async sendLinkedPhotoMessage(imageUrls, source, isMichagam, sentByWho) {
            const updateChatHigh = () => {
                // const chat = (this.$store.getters.chats || []).find(c => c.id === this.chat.id)
                // if (!chat) return
            }

            const acceptSuggest = async () => {
                const suggest = (this.chat.$$acceptedUser || []).filter(s => s.source_id === source.id)[0]
                if (suggest && suggest.status === 'user_accept') {
                    suggestService.acceptRequest(suggest.id)
                }
            }

            const payload = {
                source_id: source.id,
                photo_url_path: imageUrls,
                is_premium: this.isPremiumAgent && this.myPremiumUser,
                is_michagam: isMichagam,
                sent_by_who: sentByWho,
            }

            try {
                acceptSuggest()
                this.$loading(true)
                const resp = await chatService.sendLinkedPhotoMessage(this.chat.id, payload)
                this.$store.dispatch('loadBadges')
                this.$loading(false)
                if (resp.msg) this.$toast.success(resp.msg)
                this.$bus.$emit('onPhotosSent', imageUrls)
                updateChatHigh()
                this.$store.commit('markAsIntroduced', [this.introducingUser.id])
            } catch (e) {
                if (e.data && e.data !== undefined) this.$toast.error(e.data)
            }
        },
        onSendPictureRequested({ selectedImageUrls, source, isMichagam, sentByWho }) {
            this.$store.dispatch('checkAlreadyIntroduced', {
                source,
                callbacks: {
                    sendLinkedPhotoMessage: () =>
                        this.sendLinkedPhotoMessage(selectedImageUrls, source, isMichagam, sentByWho),
                    deleteChatFloatingInterest: () => {
                        if (!this.chat || this.chat.expire_at) return

                        const floating = (this.chat.$$floatingsInterest || []).find(f => f.user.id === source.id)
                        if (floating) {
                            this.$store.dispatch('deleteChatFloatingInterest', {
                                chat: this.chat,
                                floating: floating,
                                reject: false,
                            })
                        }
                    },
                },
            })
        },
        async sendJoinSMS() {
            this.showFunctions = false
            const idx = this.$modal.basic({
                body: '가입 유도 SMS를 보내시겠습니까?',
                buttons: [
                    {
                        label: 'CANCEL',
                        class: 'btn-default',
                    },
                    {
                        label: 'CONFIRM',
                        class: 'btn-primary',
                    },
                ],
            })
            if (idx) {
                await chatService.onboardSms(this.chat.id)
            }
        },
        async requestDating() {
            await this.$store.dispatch('loadContacts')
            this.showFunctions = false
            this.$modal
                .custom({
                    component: 'ModalSendCheck',
                    options: this.contacts,
                })
                .then(async selectedContact => {
                    if (!selectedContact) return

                    try {
                        const data = await chatService.requestDating(this.chat.id, {
                            shared_contact_id: selectedContact.id,
                        })
                        this.$toast.success(data.msg)
                    } catch (e) {
                        this.$toast.error(e.data)
                    }
                })
        },
        async dropoutUser() {
            this.showFunctions = false
            this.$modal
                .basic({
                    body: '[탈퇴]시키시겠습니까?',
                    buttons: [
                        {
                            label: 'CANCEL',
                            class: 'btn-default',
                        },
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
                .then(async selectedBtnIdx => {
                    if (selectedBtnIdx === 1) {
                        try {
                            const data = await userService.dropoutUser(this.chat.user.id)
                            this.$toast.success(data.msg)
                        } catch (e) {
                            this.$toast.error(e.data)
                        }
                    }
                })
        },
        async dormantUser() {
            this.showFunctions = false
            this.$modal
                .basic({
                    body: '[휴면]시키시겠습니까?',
                    buttons: [
                        {
                            label: 'CANCEL',
                            class: 'btn-default',
                        },
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
                .then(async selectedBtnIdx => {
                    if (selectedBtnIdx === 1) {
                        try {
                            const data = await userService.dormantUser(this.chat.user.id)
                            this.$toast.success(data.msg)
                        } catch (e) {
                            this.$toast.error(e.data)
                        }
                    }
                })
        },
        requestContact() {
            if (!this.isInstantChat && !this.enableDating) {
                this.toastError('CANNOT_CONTACT_THIS_USER')
                return
            }
            this.showFunctions = false
            this.$modal
                .basic({
                    body: this.$translate('REQUEST_CONTACT_TITLE').replace(/%s/g, this.$nameOrNick(this.chat.user)),
                    buttons: [
                        {
                            label: 'CANCEL',
                            class: 'btn-default',
                        },
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
                .then(selectedBtnIdx => {
                    if (selectedBtnIdx === 1) {
                        chatService.requestContact(this.chat.id)
                    }
                })
        },
        async onClickIcon(foo) {
            if (foo === 'functions') {
                this.showFunctions = !this.showFunctions
                this.showAutoTexts = false
                this.showEmoji = false
            }

            if (foo === 'auto_texts') {
                this.shortcut = null
                this.showAutoTexts = !this.showAutoTexts
                this.showFunctions = false
                this.showEmoji = false
            }

            if (foo === 'gallery') {
                this.$refs.imageUploader.click()
            }

            if (foo === 'emoji') {
                this.showEmoji = !this.showEmoji
                this.showAutoTexts = false
                this.showFunctions = false
            }

            if (foo === 'schedule') {
                await this.$modal.custom({
                    component: 'ModalCreateSchedule',
                    options: {
                        chat: this.chat,
                    },
                })
                // console.log(res)
            }
            if (foo === 'feedback') {
                this.showFeedback = !this.showFeedback
                await this.$modal.custom({
                    component: 'ModalSendFeedback',
                    options: {
                        chat: this.chat,
                    },
                })
                this.showFeedback = !this.showFeedback

                // console.log(res)
            }
            if (foo === 'profile') {
                this.showProfile = !this.showProfile
                const res = await this.$modal.basic({
                    body: '이 회원의 프로필을 보내시겠습니까?',
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
                // console.log(res)
                if (res !== undefined) {
                    chatService.sendSelfProfile(this.chat.id)
                }

                this.showProfile = !this.showProfile
            }

            if (foo === 'referral') {
                this.$modal
                    .basic({
                        body: '친구초대를 요청합니다',
                        buttons: [
                            {
                                label: 'CANCEL',
                                class: 'btn-default',
                            },
                            {
                                label: 'SEND',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    .then(idx => {
                        if (idx === 1) {
                            chatService.referral(this.chat.id)
                        }
                    })
            }
        },
        onChangeImage(event) {
            const onConfirm = croppedFile => {
                const data = new FormData()
                data.append('attachment', croppedFile)
                this.$refs.imageUploader.value = ''
                chatService.sendPhotoMessage(this.chat.id, data)
            }

            const size = event.target.files[0].size
            if (size > 1000 * 1000 * 3) {
                this.$toast.error('IMAGE_TOO_BIG')
                return
            }
            onConfirm(event.target.files[0])
        },
        shouldHideMenu(event) {
            const l = event.target.classList
            if (
                !l.contains('chat-input-function-icon') &&
                !l.contains('btn-chat-function') &&
                !l.contains('item') &&
                !l.contains('textarea') &&
                !l.contains('mobile-function') &&
                !l.contains('zmdi-plus')
            ) {
                this.showFunctions = false
            }
        },
        sendPhotoWhenClipboardContainsImage(event) {
            const clipboardDataType = (event.clipboardData.items[0] || {}).type
            if (!(clipboardDataType || '').includes('image')) return

            const file = event.clipboardData.items[0].getAsFile()
            if (!file) return

            const onConfirm = () => {
                const data = new FormData()
                data.append('attachment', file)
                chatService.sendPhotoMessage(this.chat.id, data)
            }

            this.$modal
                .basic({
                    body: `<img class="btn-brd w-100" src=${URL.createObjectURL(file)}>`,
                    buttons: [
                        {
                            label: 'CANCEL',
                            class: 'btn-default',
                        },
                        {
                            label: 'SEND',
                            class: 'btn-primary',
                        },
                    ],
                })
                .then(idx => {
                    if (idx === 1) {
                        onConfirm()
                    }
                })
        },
        onAutoText(autoText) {
            this.templateUsed = true
            if (this.text) {
                if (this.text.includes('/')) {
                    this.text = this.text.replace(`/${this.shortcut}`, autoText.content)
                } else {
                    this.text += autoText.content
                }
                return
            }
            this.text = autoText.content

            if (this.dom) this.dom.focus()
        },
        onClickEmoji(value) {
            this.showEmoji = false
            if (!this.text) this.text = ''
            this.text += value

            if (this.dom) this.dom.focus()
        },
        async agentingEnabled() {
            if (!this.isInstantChat && this.chat.id) {
                const agentingEnable = await chatService.agentingEnabled(this.chat.id)
                this.enableSpreadAgenting = agentingEnable.spread_enabled
                this.enableIntroduceAgenting = agentingEnable.ask_enabled
                this.enableDating = agentingEnable.dating_enabled
            }

            if (this.isInstantChat) this.enableDating = true
        },
        toastError(type) {
            this.$toast.error(this.$translate(type))
        },
        async onClickSalesTemplate() {
            const userDetail = await this.$store.dispatch('loadUserDetail', { userId: this.chat.user.id })
            userDetail.$$premium = true

            this.$modal.custom({
                component: 'ModalSalesTemplate',
                options: {
                    user: userDetail,
                    // user: this.chat.user,
                    type: 'FRIENDS',
                },
            })
        },
    },
    mounted() {
        this.$bus.$on('onClickSendPicture', this.onSendPictureRequested)
        document.addEventListener('click', this.shouldHideMenu)
        // this.agentingEnabled()
        this.noticeRestriction()
    },
    beforeDestroy() {
        this.$bus.$off('onClickSendPicture', this.onSendPictureRequested)
        document.removeEventListener('click', this.shouldHideMenu)
    },
}
</script>

<style lang="scss" scoped>
.sales-template-button {
    background-color: $yellow-premium;
    border-radius: 6px;
    position: absolute;
    right: 16px;
    padding: 2px 10px 3px;
    font-size: 11px;
    color: white;
    @include f-medium;
}
</style>
