<template>
    <div class="agent-profile">
        <div class="agent-container">
            <div class="section">
                <div class="profile-title" v-html="$translate(`AGENT_PROFILE`)" />
                <div class="profile-title-content" v-html="$translate(`AGENT_PROFILE_CONTENT`)" />
            </div>
            <div class="step keywords">
                <div class="flex-row">
                    <div class="section comment p-relative">
                        <div class="profile-title with-btn">
                            <span>주선자 한마디</span>
                            <button
                                @click="update"
                                :class="{ disabled: !profileChanged }"
                                :disabled="!profileChanged"
                                v-html="$translate('SAVE')"
                            />
                        </div>
                        <div class="each-gender flex-fill" :key="gender" v-for="gender in selectedGenders">
                            <div class="top">
                                <div class="intro" v-html="$translate(`${gender.toUpperCase()}_INTRO`)" />
                            </div>
                            <TextareaWithAutoTexts
                                @changedText="profileChanged = true"
                                :placeholder="`PLACEHOLDER_${gender.toUpperCase()}_INTRO`"
                                :hideAutotexts="false"
                                v-model="payload[`${gender}Intro`]"
                            />
                        </div>
                    </div>
                    <EditTrustPhotos @onSubmitPhotos="update" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import agentService from '@/services/agent'
import EditTrustPhotos from '@/routes/settings/EditTrustPhotos'

export default {
    name: 'EditProfilePc',
    watch: {
        agent(newVal) {
            // if (!newVal) return
            this.initPayload()
        },
    },
    components: {
        EditTrustPhotos,
    },
    data: () => ({
        profileChanged: false,
        payload: {
            maleIntro: null,
            femaleIntro: null,
        },
    }),
    computed: {
        agent() {
            return this.$store.getters.agent || {}
        },
        selectedGenders() {
            return ['female', 'male']
        },
    },
    mounted() {
        this.initPayload()

        this.$bus.$on('onClickUpdateAgentProfile', this.update)
    },
    beforeDestroy() {
        this.$bus.$off('onClickUpdateAgentProfile', this.update)
    },
    methods: {
        async update(photos, deletePhotoIds) {
            if (photos.length) this.profileChanged = true

            if (!this.profileChanged) return

            const payload = {}
            payload.male_intro = this.payload.maleIntro
            payload.female_intro = this.payload.femaleIntro

            try {
                if (photos.length) {
                    await agentService.updateProfile(this.preparedPayload(photos, deletePhotoIds))
                    this.$toast.success('UPDATE_SUCCESS_AGENT_PHOTO')
                } else {
                    await agentService.updateProfile(payload)
                    this.$toast.success('UPDATE_SUCCESS_AGENT_PROFILE')
                }

                this.$store.dispatch('loadAgentMe')

                this.profileChanged = false
            } catch (e) {
                // console.log(e)
                this.$toast.error(e.data)
            }
        },
        preparedPayload(photos, deletePhotoIds) {
            const formData = new FormData()
            if (photos.length) {
                photos.forEach((photo, i) => {
                    if (photo.blob) {
                        formData.append(`agent_photo_${i + 1}`, photo.blob, photo.name)
                        formData.append(`agent_photo_desc_${i + 1}`, photo.desc)
                    } else if (photo.desc) {
                        formData.append(`agent_photo_desc_${i + 1}`, photo.desc ? photo.desc : '')
                    }
                })
                if (deletePhotoIds.length) {
                    formData.append('delete_agent_photo_ids', JSON.stringify(deletePhotoIds))
                }
            }
            return formData
        },
        initPayload() {
            if (!this.agent) return
            this.$set(this.payload, 'maleIntro', this.agent.male_intro)
            this.$set(this.payload, 'femaleIntro', this.agent.female_intro)
        },
    },
}
</script>
<style scoped lang="scss">
//::v-deep textarea {
//    height: 25vh;
//}
</style>
