<template>
    <div class="signup consent">
        <div class="page-header">
            <div class="title">약관에 동의해주세요</div>
        </div>
        <div class="body">
            <div v-if="consentItems.length > 0" class="consent-items">
                <div class="consent-item flex-row align-center all" @click="toggleItems.handler">
                    <Checkbox class="flex-wrap no-touch" v-model="toggleItems.checked" />
                    <span v-html="toggleItems.title"></span>
                </div>
                <div
                    v-for="item in consentItems"
                    class="consent-item flex-row align-center"
                    :class="item.key"
                    :key="item.key"
                >
                    <Checkbox class="flex-wrap" v-model="item.checked" />
                    <span v-html="item.title" @click="item.handler"></span>
                </div>
            </div>
        </div>
        <button
            class="btn btn-primary"
            :class="{ disabled: isDisabled }"
            @click="next()"
            v-html="$translate('NEXT')"
        ></button>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'ConsentPage',
    data: () => ({
        consentItems: [],
        toggleItems: null,
        disabled: true,
    }),
    created() {
        this.initConsentItems()
    },
    watch: {
        consentItems: {
            handler() {
                this.toggleItems.checked = this.allSelected
            },
            deep: true,
        },
    },
    computed: {
        isDisabled() {
            if ((this.consentItems || []).length === 0) return true
            return !this.consentItems.filter(i => i.required).every(i => i.checked)
        },
        allSelected() {
            return this.consentItems.filter(item => item.required).every(item => item.checked)
        },
    },
    methods: {
        initConsentItems() {
            this.toggleItems = {
                title: '바닐라브릿지 필수 항목 모두 동의',
                key: 'all',
                handler: () => this.toggleRequiredItems(),
                required: false,
                checked: true,
            }
            this.consentItems = [
                {
                    title: '<span class="text-underline">서비스 이용약관 동의</span>',
                    key: 'service-terms',
                    handler: () => this.openConsent('service'),
                    required: true,
                    checked: true,
                },
                {
                    title: '<span class="text-underline">개인정보 처리방침 및 수집이용 동의</span>',
                    key: 'privacy-collect',
                    handler: () => this.openConsent('privacy'),
                    required: true,
                    checked: true,
                },
                {
                    title: '<span class="text-underline">개인정보 제 3자 제공 및 활용 동의</span>',
                    key: 'privacy-use',
                    handler: () => this.openConsent('privacy_third'),
                    required: true,
                    checked: true,
                },
                {
                    title: '(선택)<span class="m-l-4 text-underline">서비스 및 마케팅 정보 활용 동의</span>',
                    key: 'marketing',
                    handler: () => this.openConsent('optional'),
                    required: false,
                    checked: this.$store.getters.payloads.signupData.optional_consent === 0 ? false : true,
                },
            ]
        },
        openConsent(type) {
            this.$nativeBridge.postMessage({
                action: 'openInAppBrowser',
                value: `${axios.defaults.baseURL}users/terms?term_type=${type}`,
            })
        },
        next() {
            if (this.isDisabled) return

            this.$store.commit('setPayloads', {
                signupData: {
                    optional_consent: this.consentItems.find(i => !i.required).checked ? 1 : 0,
                },
            })
            this.$router.push({ name: 'EmailPage' })
        },
        back() {
            this.$router.go(-1)
        },
        toggleRequiredItems() {
            this.consentItems.forEach(item => {
                if (item.required) item.checked = !this.toggleItems.checked
            })
        },
    },
}
</script>
