import * as $http from 'axios'

export default {
    all: payload => $http.get('v2/friends', payload),
    requested: () => $http.get('v2/friends/requested'),
    requesting: () => $http.get('v2/friends/requesting'),
    rejectRequest: friendId => $http.put(`v2/friends/${friendId}/reject`),
    cancelRequest: friendId => $http.put(`v2/friends/${friendId}/cancel`),
    pendingRequest: friendId => $http.put(`v2/friends/${friendId}/pending`),
    extendFriend: friendId => $http.put(`v2/friends/${friendId}/extend`),
    checkFriend: userId => $http.get(`v2/agents/me/check_friend?user_id=${userId}`),
    disconnectFriends: friendIds => $http.put('v2/friends/disconnect_list', { friend_ids: friendIds }),
    // payload EX: { type: 'new', message: 'hi', gender: 'male', num: 5, is_direct: false }
    autoFriendRequests: payload => $http.post('v2/friends/auto_friend_request', payload),
    request: payload => $http.post('v2/friends', payload),
    requestMulti: payload => $http.post('v2/friends/multi', payload),
    update: friend => $http.put(`v2/friends/${friend.id}`, friend),
    aiTestSpread: payload => $http.post('v2/friends/ai_test_spread', payload),
}
