<template>
    <div class="feedback-item">
        <MeetingInfo :user="feedbackItem.user" :meet="feedbackItem.meet" />
        <div class="feedback-section">
            <div class="feedback-wrap" v-for="(fb, idx) in feedbackItem.feedback" :key="idx">
                <div class="question">
                    {{ fb.q }}
                </div>
                <div class="answer">
                    <span v-for="(ans, idx) in fb.a" :key="idx">
                        {{ ans }}
                    </span>
                    <span v-if="!fb.a.length"> - </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MeetingInfo from '@/routes/chat/meeting-feedback/MeetingInfo'

export default {
    name: 'FeedbackItem',
    props: {
        feedbackItem: {
            type: Object,
        },
    },
    components: {
        MeetingInfo,
    },
    computed: {
        getMeetDate() {
            return (
                this.$moment(this.feedbackItem.meet.date).format('YY/MM/DD') +
                ' ' +
                ['일', '월', '화', '수', '목', '금', '토'][this.$moment(this.feedbackItem.meet.date).day()]
            )
        },
        getMeetTime() {
            return (
                (this.$moment(this.feedbackItem.meet.date).format('A') === 'AM' ? '오전' : '오후') +
                ' ' +
                this.$moment(this.feedbackItem.meet.date).format('h')
            )
        },
    },
}
</script>

<style scoped lang="scss">
.feedback-item {
    margin: 0 16px;
    margin-bottom: 32px;

    background-color: #fff;
    .feedback-section {
        .feedback-wrap {
            display: flex;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #111111;
            .question {
                min-width: 100px;
                margin-right: 16px;
            }
            .answer {
                font-weight: 400;
                span:not(:last-child)::after {
                    content: ', ';
                }
            }
        }
    }
}
</style>
