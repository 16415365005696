/* If you want to add new api store, write new file and import here. */
import Chat from './chat'
import Common from './common'
import Dashboard from './dashboard'
import Friend from './friend'
import User from './user'
import Product from './product'

const merged = {
    state: {},
    getters: {},
    mutations: {},
    actions: {},
}

const objToMerge = [Chat, Common, Dashboard, Friend, User, Product]

objToMerge.forEach(store => {
    ;['state', 'getters', 'mutations', 'actions'].forEach(storeKey => {
        Object.keys(store[storeKey]).forEach(key => {
            merged[storeKey][key] = store[storeKey][key]
        })
    })
})

merged.mutations.initAppData = state => {
    objToMerge.forEach(store => {
        Object.assign(state, store.defaultState())
    })
}

export default merged
