<template>
    <transition name="slide-down">
        <div class="toast-wrapper" v-if="toast.show">
            <div class="toast flex-row items-center" :class="toast.type">
                <div class="flex-fill" v-html="html" />
                <div
                    class="flex-wrap"
                    v-if="toast.type === 'custom' && shouldShowButton"
                    v-html="$translate(toast.options.text)"
                    :class="toast.options.class"
                    @click="onClickToastAction"
                />
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    methods: {
        async onClickToastAction() {
            this.removeToast(0)
            if (!this.toast.options.action) return

            this.toast.options.action()
        },
        removeToast(timeout) {
            this.toastTimeout = setTimeout(() => {
                this.$store.commit('setToast', {
                    message: null,
                    type: null,
                    show: false,
                })
            }, timeout || 2000)
        },
    },
    computed: {
        shouldShowButton() {
            const should = this.$route.name === 'MyPage'
            if (!should) this.removeToast(2000)
            return should
        },
        showToast() {
            return this.toast.show
        },
        toast() {
            return this.$store.getters.toast
        },
        html() {
            if (this.toast.message) {
                return this.$translate(this.toast.message).replace(/\n/g, '<br>')
            }

            return ''
        },
    },
    watch: {
        showToast() {
            if (this.toast.show) {
                if (this.toastTimeout) {
                    clearTimeout(this.toastTimeout)
                }
                switch (this.toast.type) {
                    case 'custom':
                        break
                    default:
                        this.removeToast((this.toast.options || {}).duration || 2000)
                }
            }
        },
    },
}
</script>
