<template>
    <div class="gift-page">
        <div class="wrapper">
            <section class="received-gifts section">
                <div class="section-head">
                    <div class="title-with-count">
                        <h2 class="title">받은 선물함</h2>
                        <span class="count"
                            >발송 미신청 <em>{{ receivedGiftsCount }}개</em></span
                        >
                    </div>
                    <p class="desc">
                        <strong>친절하게 주선해주셨군요!</strong> 회원님들에게 설렘과 만족을 선물해주셔서 감사해요 💜
                    </p>
                </div>
                <div class="items" v-if="receivedGiftsCount">
                    <GiftItem v-for="gift in receivedGifts" :key="gift.id" :gift="gift" />
                </div>
                <button
                    class="btn btn-primary m-t-20"
                    v-if="receivedGiftsCount"
                    :disabled="disabled"
                    @click="onClickRequest"
                >
                    기프티콘 발송 신청하기
                </button>
                <p class="desc-black" v-else>받은 선물이 없습니다</p>
            </section>
            <hr />
            <section class="requested-gifts section">
                <div class="section-head">
                    <div class="title-with-count">
                        <h2 class="title">발송 신청 완료</h2>
                        <span class="count"
                            ><em>{{ requestedGiftsCount }}개</em></span
                        >
                    </div>
                    <p class="desc">매주 화요일에 일괄 발송됩니다! 감사합니다 😊</p>
                    <p class="detail">(화요일이 휴일인 경우 1영업일 이전, 혹은 1영업일 이후에 발송될 수 있습니다)</p>
                </div>
                <div class="items" v-if="requestedGiftsCount">
                    <GiftItem v-for="gift in requestedGifts" :key="gift.id" :gift="gift" />
                </div>
                <p class="desc-black" v-if="!requestedGiftsCount">발송 신청 완료한 선물이 없습니다</p>
            </section>
            <hr />
            <section class="confirmed-gifts section">
                <div class="section-head">
                    <div class="title-with-count">
                        <h2 class="title">발송 완료</h2>
                        <span class="count"
                            ><em>{{ confirmedGiftsCount }}개</em></span
                        >
                    </div>
                </div>
                <div class="items" v-if="confirmedGiftsCount">
                    <GiftItem v-for="gift in confirmedGifts" :key="gift.id" :gift="gift" />
                </div>
                <p class="desc-black" v-if="!confirmedGiftsCount">발송 완료한 선물이 없습니다</p>
            </section>
        </div>
    </div>
</template>

<script>
import GiftItem from './GiftItem'
import agentService from '@/services/agent'

export default {
    name: 'GiftPage',
    components: { GiftItem },
    data: () => ({
        receivedGifts: [],
        requestedGifts: [],
        confirmedGifts: [],
    }),
    computed: {
        receivedGiftsCount() {
            return this.receivedGifts.length
        },
        requestedGiftsCount() {
            return this.requestedGifts.length
        },
        confirmedGiftsCount() {
            return this.confirmedGifts.length
        },
        disabled() {
            return this.receivedGifts.every(gift => !gift.checked)
        },
    },
    mounted() {
        this.init()
    },
    methods: {
        async init() {
            try {
                const {
                    confirmed_gift: confirmedGifts,
                    received_gift: receivedGifts,
                    requested_gift: requestedGifts,
                } = await this.$store.dispatch('loadGifts')
                this.receivedGifts = receivedGifts
                this.requestedGifts = requestedGifts
                this.confirmedGifts = confirmedGifts

                this.receivedGifts.forEach(gift => {
                    this.$set(gift, 'checked', false)
                })
            } catch (e) {
                this.$toast.error(e.data)
            }
        },
        onClickRequest() {
            if (this.disabled || this.receivedGifts.length === 0) return

            const checkedGifts = this.receivedGifts.filter(gift => gift.checked)
            if (checkedGifts.length === 0) return

            const body =
                checkedGifts.length > 1
                    ? this.$translate('REQUEST_MULTIPLE_GIFTICON_BODY')
                          .replace(/%s/, `'${checkedGifts[0].product.name}'`)
                          .replace(/%d/, checkedGifts.length - 1)
                    : this.$translate('REQUEST_SINGLE_GIFTICON_BODY').replace(/%s/, `'${checkedGifts[0].product.name}'`)

            const request = async () => {
                try {
                    this.$loading(true)
                    const { msg } = await agentService.gifts.request(checkedGifts.map(gift => gift.id))
                    this.$toast.success(msg)
                    await this.$store.dispatch('loadGifts')
                    await this.init()
                } catch (e) {
                    this.$toast.error(e.data)
                } finally {
                    this.$loading(false)
                }
            }

            this.$modal
                .basic({
                    title: 'REQUEST_GIFTICON_TITLE',
                    body,
                    buttons: [
                        {
                            label: 'CANCEL',
                            class: 'btn-default',
                        },
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
                .then(idx => {
                    if (idx === 1) request()
                })
        },
    },
}
</script>
