<template>
    <div class="meeting-feedback">
        <div class="meeting-feedback-wrapper">
            <div class="meeting-feedback-title">약속 피드백</div>
            <div class="meeting-feedback-side-info">
                <div>{{ badFeedbackRatio }}% ({{ feedbackCounts(3) }}/{{ feedbackTotal }}명)</div>
                <img
                    @click="expand"
                    :src="require(`@/assets/images/icons/${expand_icon}.png`)"
                    alt=""
                    width="24px"
                    height="24px"
                />
            </div>
        </div>
        <div class="meeting-feedback-detail" v-if="expanded && feedbacks.length">
            <div v-for="(f, idx) in feedbackItems" :key="idx">
                <div v-if="f.count" class="meeting-feedback-items">
                    <img
                        class="meeting-feedback-items-detail"
                        src="@/assets/images/icons/personal.png"
                        alt=""
                        width="16px"
                        height="16px"
                    />
                    <span :class="{ most: mostFeedback === f.count }" class="meeting-feedback-items-detail">{{
                        f.count
                    }}</span>
                    <span :class="{ most: mostFeedback === f.count }" class="meeting-feedback-items-detail">{{
                        f.name
                    }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MeetingFeedback',
    props: {
        chatUser: {
            type: Object,
        },
    },
    data: () => ({
        expanded: false,
    }),
    computed: {
        expand_icon() {
            if (this.expanded) {
                return 'expand_less'
            } else {
                return 'expand_more'
            }
        },
        feedbacks() {
            return this.chatUser.user_manner_feedbacks
        },
        feedbackTotal() {
            if (!this.feedbacks) return 0
            return this.feedbacks.filter(f => f.step_id === 1).length
        },
        feedbackCounts() {
            return id => {
                if (!this.feedbacks) return 0
                return this.feedbacks.filter(f => f.answer_id === id).length
            }
        },
        feedbackItems() {
            return this.$store.getters.mannerFeedbacks
                .filter(f => f.step === 4)
                .map(f => {
                    return {
                        id: f.id,
                        name: f.name,
                        count: this.feedbackCounts(f.id),
                    }
                })
        },
        badFeedbackRatio() {
            if (!this.feedbacks || !this.feedbackTotal) return 0
            return Math.round((this.feedbackCounts(3) / this.feedbackTotal) * 100) || 0
        },
        mostFeedback() {
            return this.feedbackItems.reduce((max, cur) => {
                return Math.max(max, cur.count)
            }, -1)
        },
    },
    methods: {
        expand() {
            this.expanded = !this.expanded
        },
    },
}
</script>

<style scoped lang="scss">
.meeting-feedback {
    position: relative;
    z-index: 3;
    .meeting-feedback-wrapper {
        background-color: #ff3d6b;
        border-radius: 8px;
        height: 49px;
        margin-bottom: 24px;
        font-weight: bold;
        align-items: center;
        font-size: 17px;
        line-height: 25px;
        color: #ffffff;
        padding: 12px 16px;

        display: flex;
        justify-content: space-between;
        position: relative;
        .meeting-feedback-side-info {
            display: flex;
            align-items: center;
        }
    }
    .meeting-feedback-detail {
        position: absolute;
        top: 4em;
        max-height: 224px;
        width: 100%;
        border: 1px solid #ff3d6b;
        box-sizing: border-box;
        box-shadow: 0px 2px 4px rgba(255, 61, 107, 0.25);
        border-radius: 8px;
        background-color: #ffffff;
        z-index: 3;
        padding: 16px;
        font-size: 15px;
        line-height: 22px;
        .meeting-feedback-items {
            display: flex;
            align-items: center;
            .meeting-feedback-items-detail:not(last-child) {
                margin-right: 5px;
            }
            .most {
                font-weight: bold;
            }
        }
    }
}
</style>
