<template>
    <div class="ai-btn-section">
        <div class="ai-title">
            <i class="material-icons">psychology</i>
            <span>AI 성능 테스트</span>
        </div>
        <div class="ai-btn-wrap">
            <button
                class="ai-btn"
                :class="{ disable: isAnswered }"
                v-for="(btn, idx) in aiMatchResponse"
                :key="idx"
                :id="`ai-button-${btn.id}`"
                @click="onSelectResponse(btn)"
            >
                <i class="material-icons-outlined">{{ btn.icon }}</i>
                <span v-html="btn.label"></span>
            </button>
        </div>
        <div class="reject-options-wrap" v-if="isOptionOpen" :style="{ top: optionTop, left: optionLeft }">
            <div
                class="reject-option"
                @click="onSelectOptions(opt)"
                :class="{ selected: selectedOptions.indexOf(opt.id) > -1 }"
                v-for="(opt, idx) in rejectOptions"
                :key="idx"
            >
                {{ opt.label }}
            </div>
            <button class="reject-submit ai-btn" @click="onSubmitAnswer">제출</button>
        </div>
    </div>
</template>

<script>
import chatService from '@/services/chat'

export default {
    name: 'AITestResponse',
    data: () => ({
        selectedOptions: [],
        selectedAnswer: null,
        isOptionOpen: false,
        // isAnswered: false,
    }),

    computed: {
        optionTop() {
            const button3 = document.getElementById(`ai-button-3`)
            return `${button3.offsetHeight + button3.offsetTop + 8}px`
        },
        optionLeft() {
            const button3 = document.getElementById(`ai-button-3`)
            return `${button3.offsetLeft * 0.96}px`
        },
        aiMatchResponse() {
            return [
                {
                    label: '한쪽만<br/> 좋아함',
                    icon: 'circle',
                    id: 1,
                },
                {
                    label: '양쪽이<br/>좋아함',
                    icon: 'favorite',
                    id: 2,
                },
                {
                    label: '절대 <br/>보내지 않음',
                    icon: 'close',
                    needOption: true,
                    id: 3,
                },
                {
                    label: '해당사항<br/>없음',
                    icon: 'change_history',
                    id: 4,
                },
            ]
        },
        rejectOptions() {
            return [
                {
                    id: 1,
                    label: '종교',
                },
                {
                    id: 2,
                    label: '학력',
                },
                {
                    id: 3,
                    label: '직업',
                },
                {
                    id: 4,
                    label: '나이',
                },
                {
                    id: 5,
                    label: '외모/키',
                },
                {
                    id: 9,
                    label: '연봉/자산',
                },
                {
                    id: 6,
                    label: '안 좋아할 것 같음',
                },
                {
                    id: 7,
                    label: '거리',
                },
                {
                    id: 8,
                    label: '기타',
                },
            ]
        },
        chat() {
            return this.$store.getters.chat || {}
        },
        user() {
            return this.$store.getters.chat.$$introducingUser
        },
        isAnswered() {
            if (this.user.$$AIRecommendUser.result) {
                return true
            } else {
                return false
            }
        },
    },
    mounted() {
        // mounted 됐을때, 이미 응답한 유저인지 판단하고 이미 응답했다면 inactive 처리하기
        // if ((this.chat.$$introducingUser.$$AIRecommendUser|| {}).result) {
        //     this.isAnswered = true
        // }
    },
    methods: {
        onSelectResponse(btn) {
            this.selectedAnswer = btn.id
            if (btn.needOption) {
                this.isOptionOpen = !this.isOptionOpen
                // this.selectedAnswer = null
            } else {
                this.onSubmitAnswer(btn)
                this.isOptionOpen = false
            }
        },
        async onSubmitAnswer() {
            // api 보내기
            const currentManager = localStorage.getItem('currentManager')

            if (!parseInt(currentManager) || parseInt(currentManager) === 4) {
                this.$toast.error('1패널에서 매니저 선택 후 평가를 진행해주세요')
                return
            }

            const payload = {
                user_id: this.chat.user.id,
                target_id: this.user.$$AIRecommendUser.target_id,
                judging: this.selectedAnswer,
                why_reject: JSON.stringify(this.selectedOptions),
                manager_id: parseInt(currentManager),
            }

            const res = await chatService.aiJudgingResults(payload)
            this.$store.dispatch('loadChat', {
                userId: this.chat.user.id,
                introducingUserId: this.user.$$AIRecommendUser.target_id,
            })
            this.$store.dispatch('loadChats', { force: true })
            this.$set(this.$store.getters.chat.$$introducingUser.$$AIRecommendUser, 'result', res.data)
            this.isOptionOpen = false
        },
        onSelectOptions(opt) {
            if (this.selectedOptions.indexOf(opt.id) > -1) {
                this.selectedOptions.splice(this.selectedOptions.indexOf(opt.id), 1)
            } else {
                this.selectedOptions.push(opt.id)
            }
            // console.log(this.selectedOptions)
        },
    },
}
</script>

<style lang="scss" scoped>
.ai-btn-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    background-color: #edf2fb;
    border-radius: 8px;
    padding: 12px;
    color: #343a40;
    position: relative;
    .ai-title {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 17px;
        line-height: 27px;
        margin-bottom: 6px;
    }
    .ai-btn-wrap {
        display: flex;
    }
    .ai-btn {
        border-radius: 8px;
        background-color: #4361ee;
        color: white;
        border: none;
        padding: 12px 16px;
        display: block;
        width: 100%;
        &:hover {
            cursor: pointer;
        }
        .material-icons,
        .material-icons-outlined {
            color: white;
            display: block;
            margin-bottom: 3px;
        }
        &:not(:last-child) {
            margin-right: 4px;
        }
        &.disable {
            opacity: 0.3;
        }
    }
    .reject-options-wrap {
        border: 1px solid #4361ee;
        position: absolute;
        width: 120px;
        z-index: 2;
        border-radius: 8px;
        padding: 12px;
        background: #fff;
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        .reject-option:hover {
            background-color: #e2eafc;
            color: #012a4a;
            cursor: pointer;
        }
        .reject-option {
            margin: 6px 0;
            padding: 0 12px;
            display: inline-block;
            border-radius: 12px;

            &.selected {
                color: #fff;
                background-color: #0466c8;
            }
        }
    }
}
</style>
