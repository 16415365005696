import Vue from 'vue'
import userService from '@/services/user'
import router from '@/router'

// initial state
const defaultState = () => ({
    toast: {
        message: null,
        type: null,
        show: false,
    },
    modals: [],
    loading: {
        global: false,
        chats: false,
        friends: false,
        requested: false,
        superSearch: false,
        datings: false,
    },
    buildNumberFrontend: null,
    showNotifications: false,
    isMobile: false,
    niceData: null,
})

const state = defaultState()

// getters
const getters = {
    toast: state => state.toast,
    modals: state => state.modals,
    loading: state => state.loading,
    global: state => state.global,
    buildNumberFrontend: state => state.buildNumberFrontend,
    showNotifications: state => state.showNotifications,
    isMobile: state => state.isMobile,
    niceData: state => state.niceData,
}

// actions
const actions = {
    setToast({ commit }, payload) {
        payload.show = true
        commit('setToast', payload)
    },
    detectMobile({ state, commit }) {
        commit(
            'setIsMobile',
            ['Android', 'webOS', 'iPhone', 'iPad', 'iPod', 'BlackBerry', 'Windows Phone'].some(key =>
                navigator.userAgent.includes(key)
            )
        )

        // MOBILE ONLY
        if (
            !state.isMobile &&
            ['ChatroomPage', 'FriendsPage', 'UserDetailPage', 'Notices'].indexOf(router.currentRoute.name) !== -1
        ) {
            router.push({ name: 'ChatPage' })
        }
    },
    async afterAuthCallbacks({ state, dispatch }) {
        dispatch('loadChatList')
        // dispatch('loadActionPointAmount')
        try {
            await dispatch('loadAgentMe')
            dispatch('loadProducts')
        } catch (e) {
            return Promise.reject(e)
        }
        dispatch('loadBadges')
        // dispatch('loadNotices')
        dispatch('loadChats')
        dispatch('loadFriends')
        // dispatch('loadGifts')
        // api 호출해서 id, recommenduserid, pending, status 이 들어있는 리스트를 load 해오기

        let numTries = 0
        // eslint-disable-next-line prefer-const
        let interv
        const postToken = async () => {
            if (numTries >= 10) {
                clearInterval(interv)
                return
            }

            numTries++
            try {
                await userService.registerDevice(window.deviceToken)
            } catch (e) {}
            clearInterval(interv)
        }

        interv = setInterval(() => (window.deviceToken ? postToken() : null), 1000)

        if (!getters.isMobile) {
            Vue.prototype.$toast.success(Vue.prototype.$translate('HELLO_AGENT').replace(/%s/, state.agent.name))
        } else {
            dispatch('loadFriends')
        }
    },
}

// mutations
const mutations = {
    setToast(state, payload) {
        if (!payload) {
            state = {
                toast: {
                    message: null,
                    type: null,
                    show: false,
                },
            }
            return
        }

        if (payload.type !== undefined) {
            state.toast.type = payload.type
        }

        if (payload.message !== undefined) {
            if (typeof payload.message === 'string') {
                state.toast.message = payload.message
            } else if (payload.message) {
                // We decided that error will be response.data.msg
                state.toast.message = payload.message.msg
            } else {
                state.toast.message = null
            }
        }

        if (payload.show !== undefined) {
            state.toast.show = payload.show
        }

        if (payload.options !== undefined) {
            state.toast.options = payload.options
        }
    },
    setLoading(state, payload) {
        // If we use this.$loading(boolean), set global loader.
        if (typeof payload === 'boolean') {
            state.loading.global = payload
            return
        }

        // Otherwise, use given payload.
        Object.keys(payload).forEach(key => {
            if (typeof payload[key] === 'boolean') state.loading[key] = payload[key]
        })
    },
    setGlobal(state, payload) {
        const globalVariables = ['selectingChat', 'selectingFriend']
        globalVariables.forEach(globalVariable => {
            if (payload[globalVariable] !== undefined) {
                state.global[globalVariable] = payload[globalVariable]
            }
        })
    },
    setVersionInfo(state, buildNumberFrontend) {
        state.buildNumberFrontend = buildNumberFrontend
    },
    setIsMobile(state, isMobile) {
        state.isMobile = isMobile
    },
    setShowNotifications(state, showNotifications) {
        state.showNotifications = showNotifications
    },
    addModal(state, modal) {
        state.modals.push(modal)
    },
    popModal(state) {
        state.modals.pop()
    },
    setNiceData(state, niceData) {
        state.niceData = niceData
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
