<template>
    <div class="context-menu">
        <div
            @click.stop="$emit('input', null)"
            @contextmenu.stop.prevent="$emit('input', null)"
            v-show="show"
            class="wrapper"
        >
            <transition name="slide-up">
                <ul v-show="prepared" ref="menu" class="menu">
                    <li
                        @click="item.handler && item.handler()"
                        class="flex-row items-center"
                        :key="idx"
                        v-for="(item, idx) in menuItems"
                    >
                        <div class="title" v-html="$translate(item.title)" />
                    </li>
                </ul>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContextMenu',
    props: ['value', 'menuItems'],
    data: () => ({
        show: false,
        prepared: false,
    }),
    watch: {
        value(newVal) {
            this.show = newVal ? true : false
            if (!this.show) this.prepared = false

            this.initUlPosition()
        },
    },
    methods: {
        initUlPosition() {
            const event = this.value
            if (!event) return

            const menu = this.$refs.menu
            menu.style.left = `${event.clientX + 2}px`
            menu.style.top = `${event.clientY + 2}px`
            this.prepared = true
        },
    },
}
</script>
