<template>
    <div v-if="targetChat" class="edit-memo" :class="{ opened: opened }" @click.stop="closeOnClickOverlay">
        <transition name="slide-down">
            <div v-show="opened" class="container">
                <div class="textarea-wrapper">
                    <textarea
                        v-model="targetChat.memo"
                        @keydown="onKeydown"
                        :placeholder="$translate('PLACEHOLDER_FRIEND_MEMO')"
                    />
                </div>
                <div class="bottom flex-row">
                    <div class="bg-white flex-fill" />
                    <div class="rectangle flex-wrap" />
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import chatService from '@/services/chat'
import debounce from '@/modules/debounce'

export default {
    name: 'EditMemo',
    data: () => ({
        opened: false,
        user: null,
    }),
    watch: {
        '$route.name'() {
            this.opened = false
            this.user = null
        },
    },
    computed: {
        chat() {
            return this.$store.getters.chat
        },
        targetChat() {
            if (this.user) {
                return (this.$store.getters.chatList || []).find(chat => chat.user_id === this.user.id)
            } else {
                return this.$store.getters.chat
            }
        },
    },
    mounted() {
        this.$bus.$on('toggleMemo', this.toggleMemo)
    },
    beforeDestroy() {
        this.$bus.$off('toggleMemo', this.toggleMemo)
    },
    methods: {
        onKeydown: debounce(async function (event) {
            if (!this.targetChat) {
                return
            }
            const memo = event.target.value || ''

            try {
                await chatService.update(this.targetChat.id, { memo })
            } catch (e) {}
        }, 200),
        toggleMemo(user) {
            this.user = user
            this.opened = !this.opened
        },
        closeOnClickOverlay(e) {
            if (e.target.classList.contains('opened')) this.opened = false
        },
    },
}
</script>
