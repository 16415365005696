<template>
    <div class="interest-page">
        <div class="header">
            <h1 class="page-title f-medium">{{ $nameOrNick(chat.user) }}님에게 호감있는 회원</h1>
            <span class="m-l-5 m-t-5 f-medium">{{ floatings.length }}</span>
        </div>
        <div class="grid" v-if="floatings.length > 0">
            <FloatingV2
                v-for="floating in floatings"
                :key="floating.id"
                :floating="floating"
                :chatId="floating.chat_id"
                @clickFloating="onClickFloating(floating)"
            />
        </div>
        <div v-else class="empty">호감을 표현한 회원이 없어요 😥</div>
    </div>
</template>

<script>
import FloatingV2 from '@/routes/chat/friends/FloatingV2'
import chatFloatingService from '@/services/chat-floating'

export default {
    name: 'InterestPage',
    components: { FloatingV2 },
    mounted() {
        this.$bus.$on('deleteAll', this.deleteAll)
    },
    beforeDestroy() {
        this.$bus.off('deleteAll')
    },
    computed: {
        chat() {
            return this.$store.getters.chat
        },
        floatings() {
            if (!this.chat) return

            return this.$store.getters.chat.$$floatingsInterest || []
        },
        floatingIds() {
            return this.floatings.map(f => f.id)
        },
    },
    methods: {
        onClickFloating(floating) {
            this.$router.push({
                name: 'UserDetailPage',
                params: { userId: floating.user.id, from: 'InterestPage' },
            })
        },
        async deleteAll() {
            const idx = await this.$modal.basic({
                body: '호감박스를 제거하시겠습니까?',
                buttons: [
                    {
                        label: '취소',
                        class: 'btn-default',
                    },
                    {
                        label: '확인',
                        class: 'btn-primary',
                    },
                ],
            })
            if (idx) {
                try {
                    const { msg } = await chatFloatingService.deleteAll(this.chat.id, this.floatingIds)
                    if (msg) {
                        this.$loading(true)
                        const floatingsRemoved = { ...this.chat, $$floatingsInterest: [] }
                        this.$store.commit('setChat', floatingsRemoved)
                        this.$toast.success('호감박스가 제거되었습니다.')
                        this.$router.go(-1)
                    }
                } catch (e) {
                    this.$toast.error('호감박스 제거에 실패했어요')
                } finally {
                    this.$loading(false)
                }
            }
        },
    },
}
</script>

<style lang="scss" scoped>
$floating-size: calc((100vw - (16px * 2 + 12px)) / 2);

.interest-page {
    padding: 20px 16px;
    padding-top: 0;
    margin-top: 10px;
    overflow-y: auto;
    .header {
        h1 {
            margin: 0;
        }
        span {
            color: $blue-facebook;
        }
        display: flex;
        //align-items: center;
        margin-bottom: 20px;
    }

    .page-title {
        line-height: normal;
        color: black;
        font-weight: 500;
        font-size: 24px;
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 24px;
        grid-column-gap: 12px;
        overflow-y: auto;
    }

    /deep/ .floatingv2 {
        width: $floating-size;

        .img-profile {
            width: $floating-size;
            height: $floating-size;
        }

        .nickname-age {
            line-height: normal;
            margin: 8px 0px 2px 0px;
        }

        .nickname {
            font-size: 16px;
            max-width: 80px;
        }

        .age {
            margin-right: 6px;
            font-size: 16px;
        }

        .height-region {
            font-size: 12px;
            letter-spacing: -0.2px;

            .distance {
                margin-left: 4px;
                font-size: 11px;
            }
        }

        .badge-friend-type {
            font-size: 11px;
        }

        .badge-container {
            position: absolute;
            left: 8px;
            top: 8px;
        }

        .new-floating {
            width: 22px;
            height: 20px;
            border-radius: 8px;
            background-color: #4a90e2;
            font-size: 12px;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;

            @include f-bold;
        }
    }

    .empty {
        font-size: 15px;
        color: $grey-09;
    }
}
</style>
