<template>
    <div class="auto-texts flex">
        <div class="ver-scroll list">
            <div
                @click="onClickAutoText(at)"
                class="item flex-row"
                :class="{ selected: (selectedAutoText || {}).id === at.id }"
                :key="at.id"
                v-for="at in autoTextsList"
            >
                <div class="title flex-wrap m-r-16" v-if="at.title">/{{ at.title }}</div>
                <div class="content lines-1 flex-fill">{{ at.content }}</div>
                <div class="right-functions flex-row flex-wrap">
                    <i class="zmdi zmdi-edit m-r-12" @click="onClickEditTemplate(at, $event)" />
                    <i class="zmdi zmdi-delete" @click="deleteAutoText(at, $event)" />
                </div>
            </div>
        </div>
        <div class="add-template flex-row items-center flex-between">
            <div class="desc" v-if="!autoTexts">{{ 'ADD_TEMPLATE_DESC' | translate }}</div>
            <div class="add flex-row items-center" @click="onClickAddTemplate">
                <i class="zmdi zmdi-plus flex-wrap" />
                <div class="add-template-button">{{ 'ADD_TEMPLATE' | translate }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import autoTextService from '@/services/auto-text'

export default {
    name: 'AutoTexts',
    props: ['shortcut'],
    mounted() {
        // this.$store.dispatch('loadAutoTexts')
        this.$store.dispatch('loadAutoTextsAllCategory')
        document.addEventListener('keydown', this.onKeydown)
        document.addEventListener('click', this.checkOutsideClicked)
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.onKeydown)
        document.removeEventListener('click', this.checkOutsideClicked)
    },
    computed: {
        autoTexts() {
            return (this.$store.getters.autoTextsAllCategory || []).custom
        },
        autoTextsList() {
            let filtered = this.autoTexts || []
            if (this.shortcut) {
                filtered = (this.autoTexts || []).filter(at => at.title.includes(this.shortcut))
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.selectedAutoText = filtered[0]
            return filtered
        },
    },
    data: () => ({
        selectedAutoText: null,
    }),
    methods: {
        onKeydown(event) {
            const followAndFocus = () =>
                setTimeout(() => {
                    const autoTexts = document.getElementsByClassName('auto-texts')[0]
                    if (!autoTexts) return

                    const selected = autoTexts.getElementsByClassName('item selected')[0]
                    if (!selected) return

                    selected.scrollIntoView({ block: 'nearest' })
                })

            if (event.key === 'Enter') {
                this.onClickAutoText(this.selectedAutoText)
                return
            }

            if (event.key === 'ArrowDown' || event.key === 'ArrowRight') {
                const cur = this.autoTextsList.indexOf(this.selectedAutoText)
                if (cur === this.autoTextsList.length - 1) {
                    this.selectedAutoText = this.autoTextsList[0]
                } else {
                    this.selectedAutoText = this.autoTextsList[cur + 1]
                }
                followAndFocus()
                return
            }

            if (event.key === 'ArrowUp' || event.key === 'ArrowLeft') {
                const cur = this.autoTextsList.indexOf(this.selectedAutoText)
                if (cur <= 0) {
                    this.selectedAutoText = this.autoTextsList[this.autoTextsList.length - 1]
                } else {
                    this.selectedAutoText = this.autoTextsList[cur - 1]
                }
                followAndFocus()
            }
        },
        checkOutsideClicked(event) {
            const l = event.target.classList
            if (
                !l.contains('zmdi-comment-edit') &&
                !l.contains('add-template') &&
                !l.contains('chat-input-function-icon') &&
                !l.contains('item') &&
                !l.contains('mobile-function')
            )
                this.$emit('close')
        },
        onClickAutoText(autoText) {
            if (!autoText) return

            this.$emit('onAutoText', autoText)
            this.selectedAutoText = null
        },
        onClickAddTemplate() {
            this.$modal.custom({
                component: 'ModalAutoText',
            })
            this.$emit('close')
        },
        onClickEditTemplate(autoText, event) {
            event.stopPropagation()

            this.$modal.custom({
                component: 'ModalAutoText',
                options: {
                    autoText: this.$copy(autoText),
                },
            })
            this.$emit('close')
        },
        deleteAutoText(autoText, event) {
            event.stopPropagation()

            const onConfirm = async () => {
                try {
                    await autoTextService.delete(autoText.id)
                    this.$toast.success('삭제되었습니다')
                } catch (e) {
                    this.$toast.error(e.data)
                }
            }

            this.$modal
                .basic({
                    title: 'DELETE_AUTO_TEXT',
                    body: this.$translate('DELETE_AUTO_TEXT_BODY'),
                    buttons: [
                        {
                            label: 'CANCEL',
                            class: 'btn-default',
                        },
                        {
                            label: 'DELETE',
                            class: 'btn-primary',
                        },
                    ],
                })
                .then(idx => {
                    if (idx === 1) {
                        onConfirm()
                    }
                })
        },
    },
}
</script>
