ㅊ
<template>
    <transition name="modal">
        <div class="modal" @mousedown="closeOnMousedownMask">
            <div :class="modal.component === 'ModalBasic' ? 'modal-container-basic' : 'modal-container'">
                <component @close="onClose" :options="modal.options" :is="modal.component" />
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'Modal',
    props: ['modal'],
    components: {
        ModalBasic: () => import('@/components/modals/ModalBasic'),
        ModalCropper: () => import('@/components/modals/ModalCropper'),
        ModalImages: () => import('@/components/modals/ModalImages'),
        ModalInput: () => import('@/components/modals/ModalInput'),
        ModalSlot: () => import('@/components/modals/ModalSlot'),
        ModalAlert: () => import('@/components/modals/ModalAlert'),
        ModalActionSheet: () => import('@/components/modals/ModalActionSheet'),
        ModalSendCheck: () => import('@/components/modals/ModalSendCheck'),
        ModalAutoText: () => import('@/components/modals/ModalAutoText'),
        ModalConfirmPasteImage: () => import('@/components/modals/ModalConfirmPasteImage'),
        ModalFriendsFilter: () => import('@/components/modals/ModalFriendsFilter'),
        ModalRecommend: () => import('@/components/modals/ModalRecommend'),
        ModalRejectRefund: () => import('@/components/modals/ModalRejectRefund'),
        ModalNotice: () => import('@/components/modals/ModalNotice'),
        ModalPhotosSelector: () => import('@/components/modals/ModalPhotosSelector'),
        ModalSelectEnv: () => import('@/components/modals/ModalSelectEnv'),
        ModalQuiz: () => import('@/components/modals/ModalQuiz'),
        ModalHelp: () => import('@/components/modals/ModalHelp'),
        ModalSpreadProfile: () => import('@/components/modals/modal-spread-profile/ModalSpreadProfile'),
        ModalDatingStatusInfo: () => import('@/components/modals/ModalDatingStatusInfo'),
        ModalFaceRating: () => import('@/components/modals/ModalFaceRating'),
        ModalAcceptedHistory: () => import('@/components/modals/ModalAcceptedHistory'),
        ModalPrivacyAgreement: () => import('@/components/modals/ModalPrivacyAgreement'),
        ModalSalesTemplate: () => import('@/components/modals/ModalSalesTemplate'),
        ModalOnceAsk: () => import('@/components/modals/ModalOnceAsk'),
        ModalOnceReply: () => import('@/components/modals/ModalOnceReply'),
        ModalRecommendMob: () => import('@/components/modals/ModalRecommendMob'),
        ModalPremiumComment: () => import('@/components/modals/ModalPremiumComment'),
        ModalAiTest: () => import('@/components/modals/ModalAiTest'),
        ModalUserDetail: () => import('@/components/modals/ModalUserDetail'),
        ModalCreateSchedule: () => import('@/components/modals/ModalCreateSchedule'),
        ModalSendFeedback: () => import('@/components/modals/ModalSendFeedback'),
        ModalAddProduct: () => import('@/components/modals/ModalAddProduct'),
        ModalChooseReason: () => import('@/components/modals/ModalChooseReason'),
        ModalChooseManager: () => import('@/components/modals/ModalChooseManager'),
        ModalEditMessage: () => import('@/components/modals/ModalEditMessage'),
        ModalEditUserPersonalStory: () => import('@/components/modals/ModalEditUserPersonalStory'),
    },
    created() {
        document.addEventListener('keydown', this.onKeydown)
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.onKeydown)
    },
    methods: {
        onClose(event) {
            if (this.modal.resolve) {
                this.modal.resolve(event)
            }

            this.$store.commit('popModal')
        },
        closeOnMousedownMask(event) {
            if ((this.modal.options || {}).preventCloseOnMousedownMask) return

            if (event.target.classList.contains('modal')) {
                this.onClose()
            }
        },
        onKeydown(event) {
            if ((this.modal.options || {}).preventCloseOnEscape) return

            if (event.key === 'Escape') {
                this.onClose()
            }
        },
    },
}
</script>
