<template>
    <div class="simple-profile">
        <div class="basic flex-row">
            <div v-if="showName" class="name flex-wrap m-r-4" v-html="$nameOrNick(user)" />
            <div v-if="showName" class="flex-wrap" v-html="'('" />
            <div class="age flex-wrap">{{ user.profile.birthday | asAge }}세</div>
            <div class="divider flex-wrap" v-html="'ㅣ'" />
            <div class="height flex-wrap">{{ user.profile.height }}cm</div>
            <div v-if="showName" v-html="')'" />
        </div>
        <div class="region flex-row" v-html="user.profile.region_name" />
        <div class="job flex-row items-center">
            <i class="zmdi zmdi-case flex-wrap" />
            <div class="hor-separated flex-wrap">
                <span>{{ user.profile.job_name }}</span>
                <span v-if="company" v-html="company" />
            </div>
            <i v-if="user.profile.company_verified" class="badge badge-verified zmdi zmdi-check flex-wrap m-l-4" />
        </div>

        <div class="school flex-row items-center" v-if="!user.profile.is_student">
            <i class="zmdi zmdi-city flex-wrap" />
            <div class="flex-wrap">{{ user.profile | school }}</div>
            <i v-if="user.profile.university_verified" class="badge badge-verified zmdi zmdi-check flex-wrap m-l-4" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'SimpleProfile',
    props: ['user', 'showName'],
    computed: {
        company() {
            return this.$options.filters.company(this.user.profile)
        },
    },
}
</script>
