<template>
    <div class="signup state">
        <div class="page-header">
            <div class="title">지하철역</div>
        </div>
        <div class="body">
            <div class="location-input-wrapper flex-row items-center flex-wrap">
                <i class="zmdi zmdi-search" />
                <input
                    ref="searchValue"
                    v-model="searchValue"
                    @keydown="onKeydown"
                    :placeholder="$translate('PLACEHOLDER_SEARCH_STATION')"
                />
                <i v-if="searchValue" @click="searchValue = ''" class="zmdi zmdi-close-circle" />
            </div>
            <ul class="result flex-fill">
                <li
                    @click="next(item)"
                    class="item flex-row items-center flex-between"
                    :key="item.id"
                    v-for="item in stations"
                >
                    <div v-html="$translate(item.name)" />
                    <i class="zmdi zmdi-chevron-right" />
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import debounce from '@/modules/debounce'
import commonService from '@/services/common'

export default {
    name: 'StationPage',
    data: () => ({
        searchValue: '',
        stations: [],
    }),
    watch: {
        searchValue(newVal) {
            if (!newVal) this.search()
        },
    },
    mounted() {
        this.search()
    },
    computed: {
        state() {
            return this.$store.getters.payloads.signupData.state.name
        },
    },
    methods: {
        search: debounce(async function () {
            try {
                this.stations = await commonService.stations(this.state, this.searchValue)
            } catch (e) {
                this.$toast.error(e.data)
            }
        }, 200),
        onKeydown() {
            setTimeout(() => {
                this.searchValue = this.$refs.searchValue.value
                this.search(this.searchValue)
            })
        },
        next(station) {
            this.$store.commit('setPayloads', {
                signupData: {
                    station: station,
                },
            })
            this.$router.push({ name: 'UploadPhotoPage' })
        },
    },
}
</script>
