<template>
    <div class="bridge-history card">
        <div class="top flex-row flex-between items-center">
            <div class="title flex-wrap" v-html="$translate('BRIDGE_HISTORY')" />
            <div ref="info" @click="onClickInfo">
                <i class="zmdi zmdi-info-outline flex-wrap m-l-8" />
                <span class="info-desc" v-html="$translate('BRIDGE_METHOD')" />
            </div>
        </div>
        <div class="p-l-20 p-r-20 p-b-20">
            <div class="section-title week m-b-8" v-html="thisWeek" />
            <div class="desc" v-html="bridgeHistoryDesc" />
            <div class="desc-2" v-html="$translate('BRIDGE_HISTORY_DESC_2')" />
            <div class="hr" />
            <div class="section-title m-b-8" v-html="$translate('BRIDGE_HISTORY_SUMMARY')" />
            <div class="bridge-summary">
                <div class="flex-row">
                    <span class="flex-wrap" v-html="$translate('TOTAL_PROFITS')" />
                </div>
                <div class="price flex-row items-center m-t-16">
                    <span class="f-bold flex-wrap">{{ total }}</span
                    >원
                    <span class="c-text-dark f-medium f-14 m-l-4"
                        >({{ ((latestHistory || {}).rewards || []).length }}건)</span
                    >
                </div>
            </div>
        </div>
        <div class="flex-row" v-if="latestHistory">
            <div class="history" :key="history.key" v-for="history in convertedHistory(latestHistory)">
                <div class="title" v-html="$translate(history.key)" />
                <div class="item flex-row" :key="item.key" v-for="item in history.items">
                    <div class="key flex-wrap">{{ item.key | translate }}</div>
                    <div class="value">
                        <span class="f-bold">{{ item.value | currency }}</span
                        >원
                        <span class="c-text-dark f-13 m-l-4">({{ item.count }}건)</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="buttons">
            <button class="btn btn-brd" @click="openModal('BridgeHistoryDetail')">
                {{ 'BRIDGE_HISTORY_DETAIL' | translate }} <i class="zmdi zmdi-chevron-right" />
            </button>
            <button class="btn btn-brd" @click="openModal('BridgeHistories')">
                {{ 'BRIDGE_HISTORIES' | translate }} <i class="zmdi zmdi-chevron-right" />
            </button>
        </div>
    </div>
</template>

<script>
import agentService from '@/services/agent'

export default {
    name: 'BridgeHistory',
    data: () => ({
        bridgeHistories: null,
        latestHistory: null,
        pivot: null,
    }),
    computed: {
        keys() {
            return ['male_worker_amt', 'other_amt', 'refund_amt', 'premium_amt']
        },
        total() {
            if (!this.latestHistory) return 0

            let total = 0

            this.keys.forEach(key => {
                total += this.latestHistory[key]
            })
            return total
        },
        thisWeek() {
            if (!this.pivot) return

            return `이번 정산일<span class="divider">ㅣ</span>${this.$moment(this.pivot).format(
                'M월 D일'
            )} (${this.koreanDay(this.pivot)})`
        },
        bridgeHistoryDesc() {
            if (!this.pivot) return

            const format = 'M월 D일'
            const end = this.$moment(this.pivot).add(-15, 'days')
            const start = this.$moment(this.pivot).add(-21, 'days')
            return this.$translate('BRIDGE_HISTORY_DESC')
                .replace(/%s/, `${start.format(format)} (${this.koreanDay(start)})`)
                .replace(/%s/, `${end.format(format)} (${this.koreanDay(end)})`)
        },
        weeklyStat() {
            return this.$store.getters.weeklyStat
        },
        bridgeHistoryHelp() {
            return `
            <h3>1. 소개팅권 (대표 아이템)</h3>

<b>▶ 주선자 인센티브</b>

- <b>주선 1회 성공 당 금액</b>

- 직장인 남자가 결제할 경우 : 11,000원

- 직장인 남자 외 직장인 여자/대학생 남자, 여자가 결제할 경우 : 8,000원

- <b>환불된 1건 당 3,500원 지급</b>

- 환불이 들어올 경우에도 1건당 3,500원을 지급해드립니다.

<b>▶ 정산 요일 : 매주 화요일</b>

- 회원의 환급 신청 기간  : 연락처 교환 후 14일 이내

- 매칭 성공건의 정산 기준 : 성공 후 14일 지난 건정산

- 적용 시점 : 전전전주 화요일 ~ 전전주 월요일 사이 매칭건 정산

(ex. 정산일 21년05월04일 >  : 04월13일~04월19일까지 매칭된 건)`
        },
    },
    methods: {
        async init() {
            try {
                this.bridgeHistories = await agentService.bridgeHistory.all()
                if (!this.bridgeHistories[0]) return

                const latestHistory = await agentService.bridgeHistory.detail(this.bridgeHistories[0].id)
                if (this.$moment().diff(this.$moment(latestHistory.created_at), 'days') >= 8) return

                this.latestHistory = latestHistory
                this.pivot = this.latestHistory.created_at
            } catch (e) {
                this.$toast.error(e.data)
            }
        },
        convertedHistory(o) {
            if (!this.latestHistory || !this.latestHistory.rewards) return

            const counts = {}
            this.keys.forEach(key => {
                counts[key] = 0
            })
            this.latestHistory.rewards.forEach(reward => {
                if (reward.status === 40) counts.refund_amt += 1
                else if (reward.status === 131) counts.premium_amt += 1
                else if ([30, 50, 60].indexOf(reward.status) !== -1) counts.male_worker_amt += 1
            })

            return [
                {
                    key: 'TICKET',
                    items: [
                        { key: '만남성공 보상', value: o.male_worker_amt + o.other_amt, count: counts.male_worker_amt },
                        { key: '환급 보상', value: o.refund_amt, count: counts.refund_amt },
                    ],
                },
                {
                    key: 'PREMIUM',
                    items: [{ key: '완료 보상', value: o.premium_amt, count: counts.premium_amt }],
                },
                // {
                //     key: 'ETC',
                //     items: [
                //         { key: '평점 보너스', value: o.rating_bonus, count: o.success_count },
                //         { key: '환급률 보너스', value: o.refund_bonus, count: o.success_count },
                //     ],
                // },
            ]
        },
        openModal(component) {
            this.$modal.slot({
                component,
                title: this.$case.toConst(component),
                options: {
                    rewards:
                        component === 'BridgeHistoryDetail' ? (this.latestHistory || {}).rewards : this.bridgeHistories,
                },
            })
        },
        onClickInfo() {
            const info = this.$refs.info
            info.getBoundingClientRect()
            const options = {
                title: 'BRIDGE_METHOD',
                titleDesc: 'BRIDGE_HISTORY_HELP',
                footer: this.bridgeHistoryHelp,
                footerClass: 'c-text-dark',
                style: {
                    'max-width': 'initial',
                },
            }

            this.$modal.custom({
                component: 'ModalHelp',
                options,
            })
        },
        koreanDay(momentDate) {
            return ['일', '월', '화', '수', '목', '금', '토'][this.$moment(momentDate).day()]
        },
    },
    mounted() {
        this.init()
    },
}
</script>
