<template>
    <div class="chat-input-mobile" v-show="chat.user">
        <transition name="slide-up">
            <div class="functions" v-if="showFunctions">
                <div
                    @click="foo.action"
                    class="item flex-row items-center"
                    :key="foo.title"
                    :class="{ inactive: foo.active != null && !foo.active }"
                    v-for="foo in functions"
                    v-html="$translate(foo.title)"
                />
            </div>
        </transition>

        <transition name="slide-up">
            <AutoTexts
                v-if="showAutoTexts"
                :shortcut="shortcut"
                @close="showAutoTexts = false"
                @onAutoText="onAutoText"
            />
        </transition>

        <transition name="slide-up">
            <Emoji v-if="showEmoji" @onClickEmoji="onClickEmoji" @close="showEmoji = false" />
        </transition>

        <transition name="slide-up">
            <div class="functions spread-profiles" v-if="showSpreadProfiles">
                <div
                    @click="foo.action"
                    :key="foo.title"
                    class="container"
                    :class="{ inactive: !foo.active }"
                    v-for="foo in spreadProfilesTestAgent"
                >
                    <div class="item">
                        <img v-if="foo.icon" :src="foo.icon" />
                        <span class="text m-l-8" v-html="$translate(foo.title)"></span>

                        <!-- {{ foo.title | translate }} -->
                    </div>
                    <hr />
                </div>
            </div>
        </transition>

        <div class="textarea-wrapper">
            <!-- <Disconnected /> -->
            <div class="mobile-functions flex-row">
                <div
                    @click="onClickIcon('spread-profiles')"
                    class="mobile-function flex-fill"
                    :class="{ inactive: isInstantChat, active: showSpreadProfiles }"
                    v-html="$translate('AUTO_CONNECT')"
                />

                <div class="divider flex-wrap" :class="{ inactive: isInstantChat }">ㅣ</div>
                <div
                    @click="goToFriendsRoute"
                    class="mobile-function flex-fill"
                    :class="{ inactive: isInstantChat }"
                    v-html="$translate('SEARCH_USERS')"
                />
                <div class="divider flex-wrap" :class="{ inactive: isInstantChat }">ㅣ</div>
                <div
                    @click="onClickIcon('auto_texts')"
                    class="mobile-function flex-fill"
                    v-html="$translate('AUTO_TEXTS')"
                />
            </div>
            <div ref="height-limiter" class="flex-row items-center height-limiter">
                <i class="zmdi zmdi-plus mobile-function flex-wrap" @click="onClickIcon('functions')" />
                <textarea
                    :disabled="isAlimi"
                    @paste="sendPhotoWhenClipboardContainsImage"
                    ref="chat-input-textarea"
                    class="chat-input-textarea flex-fill"
                    @keypress.exact.enter.prevent="onEnter"
                    :placeholder="$translate(isAlimi ? 'DISABLED_CHAT_ALIMI' : 'INPUT_MESSAGE')"
                    :maxlength="maxlength"
                    @input="text = $event.target.value"
                    :value="text"
                />
                <button
                    v-show="text && text.length > 0"
                    @click="send(text)"
                    class="btn btn-send flex-wrap"
                    v-html="$translate('SEND')"
                />
            </div>
            <input
                ref="imageUploader"
                type="file"
                class="image display-none"
                @change="onChangeImage"
                accept="image/*"
            />
        </div>
    </div>
</template>

<script>
import chatService from '@/services/chat'
// import Disconnected from '@/components/common/Disconnected'
import suggestService from '@/services/suggest'

export default {
    name: 'ChatInputMobile',
    // components: { Disconnected },
    computed: {
        contacts() {
            return this.chat.$$contacts
        },
        introducingUser() {
            return this.chat.$$introducingUser
        },
        chat() {
            return this.$store.getters.chat || {}
        },
        agent() {
            return this.$store.getters.agent || {}
        },
        myPremium() {
            if (this.chat.user.$$premium) {
                const premium = this.$options.filters.hasProduct(this.chat.user, 'premium')
                return this.$store.getters.agent.id === premium.agent_id
            }
            return false
        },
        isAbleRecommend() {
            if (!this.$store.getters.agent) return true
            if (!this.chat.user.$$premium && !this.chat.user.$$privacy) return true
            return this.$store.getters.agent.is_premium && this.chat.user.$$premium && this.myPremium
        },
        functions() {
            const items = [
                {
                    title: 'CHAT_FUNCTION_REQUEST_CONTACT',
                    action: this.requestContact,
                    active: this.enableDating,
                },
                {
                    title: 'CHAT_FUNCTION_SEND_CHECK',
                    action: this.requestDating,
                    // active: this.enableDating
                    active: true,
                },
                {
                    title: 'CHAT_FUNCTION_SEND_PHOTO',
                    action: () => this.onClickIcon('gallery'),
                },
            ]
            return items
        },
        chatInputPcIcons() {
            return [
                {
                    key: 'functions',
                    mouseOver: over => {
                        this.overFunctions = over
                    },
                    src: this.functionSrc,
                },
                {
                    key: 'emoji',
                    mouseOver: over => {
                        this.overEmoji = over
                    },
                    src: this.emojiSrc,
                },
                {
                    key: 'auto_texts',
                    mouseOver: over => {
                        this.overAutoTexts = over
                    },
                    src: this.autoTextSrc,
                },
                {
                    key: 'gallery',
                    mouseOver: over => {
                        this.overGallery = over
                    },
                    src: this.gallerySrc,
                },
            ]
        },
        spreadProfilesTestAgent() {
            return [
                {
                    title: 'INTRODUCE_FRIEND',
                    icon: require('@/assets/images/account_inner.svg'),
                    visible: true,
                    active:
                        this.enableIntroduceAgenting &&
                        this.enableDating &&
                        this.chat.user.gender === 0 &&
                        this.isAbleRecommend,
                },
                {
                    title: 'SPREAD_FRIEND',
                    icon: require('@/assets/images/account_outer.svg'),
                    visible: true,
                    active: true && this.isAbleRecommend,
                },
            ]
                .map(foo => {
                    foo.action = () => this.openSpreadProfileModal(foo.title)
                    return foo
                })
                .filter(foo => foo.visible)
        },

        connected() {
            return this.$store.getters.connected
        },
        textLength() {
            if (!this.text) return

            return `(${this.text.length}/${this.maxlength})`
        },
        maxlength() {
            return 1024
        },
        dom() {
            return this.$refs['chat-input-textarea']
        },
        isAlimi() {
            return (this.chat.user || {}).id === 0
        },
        emojiSrc() {
            return this.iconSrc('emoji', this.showEmoji, this.overEmoji)
        },
        functionSrc() {
            return this.iconSrc('functions', this.showFunctions, this.overFunctions)
        },
        autoTextSrc() {
            return this.iconSrc('auto_texts', this.showAutoTexts, this.overAutoTexts)
        },
        gallerySrc() {
            return this.iconSrc('gallery', false, this.overGallery)
        },
        isInstantChat() {
            return this.chat && this.chat.expire_at
        },
        isPremiumAgent() {
            return this.agent.is_premium
        },
        myPremiumUser() {
            if ((this.chat.user || {}).$$premium) {
                const premium = this.$options.filters.hasProduct(this.chat.user, 'premium')
                return this.$store.getters.agent.id === premium.agent_id
            }
            return false
        },
    },
    data: () => ({
        text: '',
        lastChanged: true,
        showFunctions: false,
        overFunctions: false,
        showSpreadProfiles: false,
        showAutoTexts: false,
        overAutoTexts: false,
        hoverSpreadProfiles: false,
        shortcut: null,
        templateUsed: false, // Denoted if user clicked autotext in current chatroom.
        showEmoji: false,
        overEmoji: false,
        overGallery: false,
        enableSpreadAgenting: false,
        enableIntroduceAgenting: false,
        enableDating: true,
    }),
    watch: {
        chat(newVal, oldVal) {
            if (newVal && oldVal && newVal.id === oldVal.id) return

            this.text = this.chat.$$text
            this.templateUsed = false

            this.noticeRestriction()
        },
        connected(newVal) {
            if (this.dom) {
                this.dom.blur()
            }
        },
        text(newVal, oldVal) {
            if (!newVal) {
                this.showAutoTexts = false
                return
            }

            if (
                oldVal &&
                oldVal.indexOf('/') === -1 &&
                newVal.indexOf('/') === -1 &&
                this.showAutoTexts &&
                this.lastChanged
            ) {
                // template 에서 마지막글자 남는것 처리 위한 야매
                this.text = this.text.substring(0, this.text.length - 1)
                this.lastChanged = false
                return
            }

            if (!newVal) {
                this.shortcut = null
                this.showAutoTexts = false
                return
            }

            const splitted = (newVal || '').split('/')
            if (splitted.length > 1) {
                this.lastChanged = true
                this.shortcut = splitted[1]
                this.showAutoTexts = true
            } else {
                setTimeout(_ => {
                    this.showAutoTexts = false
                }, 200)
            }
        },
    },
    methods: {
        async noticeRestriction() {
            await this.agentingEnabled()
            if (!this.enableDating) {
                this.$modal.basic({
                    title: 'NOTICE',
                    body: this.$translate('NOTICE_CONTACT_RESTRICTION'),
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
            }
        },
        iconSrc(key, show, over) {
            if (show) {
                return require(`@/assets/images/${key}_active.svg`)
            } else if (over) {
                return require(`@/assets/images/${key}_hover.svg`)
            } else {
                return require(`@/assets/images/${key}_inactive.svg`)
            }
        },
        onKeydown() {
            setTimeout(() => {
                this.text = this.dom.value
                this.resetTextareaHeight()
            })
        },
        resetTextareaHeight() {
            const dom = this.$refs['height-limiter']
            if (!this.text || this.dom.scrollHeight < 60) {
                dom.style.height = 'auto'
                dom.style.height = '40px'
            } else {
                dom.style.height = 'auto'
                dom.style.height = `${this.dom.scrollHeight}px`
            }
        },
        onEnter() {
            if ((this.text || '').length > 0) this.text += '\n'

            this.resetTextareaHeight()
        },
        send(text) {
            if (!this.chat.user || !this.text || this.text.trim().length === 0) return

            if (this.showAutoTexts) return

            this.$bus.$emit('onSendTextMessage', {
                text,
                is_template: this.templateUsed,
            })
            this.text = ''
            this.templateUsed = false
            this.resetTextareaHeight()
            this.dom.focus()
        },
        // This is not uploading any image, but just sending imageURLs as messages.
        async sendLinkedPhotoMessage(imageUrls, source, isMichagam, sentByWho) {
            const updateChatHigh = () => {
                // const chat = (this.$store.getters.chats || []).find(c => c.id === this.chat.id)
                // if (!chat) return
            }

            const acceptSuggest = async () => {
                const suggest = (this.chat.$$acceptedUser || []).filter(s => s.source_id === source.id)[0]
                if (suggest && suggest.status === 'user_accept') {
                    suggestService.acceptRequest(suggest.id)
                }
            }

            const payload = {
                source_id: source.id,
                photo_url_path: imageUrls,
                is_premium: this.isPremiumAgent && this.myPremiumUser,
                is_michagam: isMichagam,
                sent_by_who: sentByWho,
            }

            try {
                acceptSuggest()
                this.$loading(true)
                const resp = await chatService.sendLinkedPhotoMessage(this.chat.id, payload)
                this.$store.dispatch('loadBadges')
                this.$loading(false)
                if (resp.msg) this.$toast.success(resp.msg)
                this.$bus.$emit('onPhotosSent', imageUrls)
                updateChatHigh()
                this.$store.commit('markAsIntroduced', [this.introducingUser.id])
            } catch (e) {
                if (e.data && e.data !== undefined) this.$toast.error(e.data)
            }
        },
        onSendPictureRequested({ selectedImageUrls, source, isMichagam, sentByWho }) {
            this.$store.dispatch('checkAlreadyIntroduced', {
                source,
                callbacks: {
                    sendLinkedPhotoMessage: () =>
                        this.sendLinkedPhotoMessage(selectedImageUrls, source, isMichagam, sentByWho),
                    deleteChatFloatingInterest: () => {
                        if (!this.chat) return

                        const floating = (this.chat.$$floatingsInterest || []).find(f => f.user.id === source.id)
                        if (floating) {
                            this.$store.dispatch('deleteChatFloatingInterest', {
                                chat: this.chat,
                                floating: floating,
                                reject: false,
                            })
                        }
                    },
                },
            })
        },
        async requestDating() {
            await this.$store.dispatch('loadContacts')
            this.showFunctions = false
            this.$modal
                .custom({
                    component: 'ModalSendCheck',
                    options: this.contacts,
                })
                .then(async selectedContact => {
                    if (!selectedContact) return

                    try {
                        const data = await chatService.requestDating(this.chat.id, {
                            shared_contact_id: selectedContact.id,
                        })

                        this.$toast.success(data.msg)
                    } catch (e) {
                        this.$toast.error(e.data)
                    }
                })
        },
        requestContact() {
            if (!this.isInstantChat && !this.enableDating) {
                this.toastError('CANNOT_CONTACT_THIS_USER')
                return
            }

            this.showFunctions = false
            this.$modal
                .basic({
                    title: 'REQUEST_CONTACT_TITLE',
                    body: this.$translate('REQUEST_CONTACT_BODY').replace(/%s/g, this.$nameOrNick(this.chat.user)),
                    buttons: [
                        {
                            label: 'CANCEL',
                            class: 'btn-default',
                        },
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
                .then(selectedBtnIdx => {
                    if (selectedBtnIdx === 1) {
                        chatService.requestContact(this.chat.id)
                    }
                })
        },
        async onClickIcon(foo) {
            if (foo === 'functions') {
                this.showFunctions = !this.showFunctions
                this.showAutoTexts = false
                this.showSpreadProfiles = false
                this.showEmoji = false
            }

            if (foo === 'auto_texts') {
                this.shortcut = null
                this.showAutoTexts = !this.showAutoTexts
                this.showFunctions = false
                this.showSpreadProfiles = false
                this.showEmoji = false
            }

            if (foo === 'gallery') {
                this.$refs.imageUploader.click()
            }

            if (foo === 'spread-profiles') {
                if (this.isInstantChat) {
                    this.$toast.error(
                        this.$translate('CAN_INTRODUCE_ONLY_TARGET').replace(
                            /%s/,
                            this.$nameOrNick(this.introducingUser)
                        )
                    )
                } else {
                    this.showSpreadProfiles = !this.showSpreadProfiles
                    this.showFunctions = false
                    this.showAutoTexts = false
                    this.showEmoji = false
                }
            }

            if (foo === 'emoji') {
                this.showEmoji = !this.showEmoji
                this.showAutoTexts = false
                this.showFunctions = false
                this.showSpreadProfiles = false
            }
        },
        onChangeImage(event) {
            const onConfirm = croppedFile => {
                const data = new FormData()
                data.append('attachment', croppedFile)
                this.$refs.imageUploader.value = ''
                chatService.sendPhotoMessage(this.chat.id, data)
            }

            const size = event.target.files[0].size
            if (size > 1000 * 1000 * 3) {
                this.$toast.error('IMAGE_TOO_BIG')
                return
            }
            onConfirm(event.target.files[0])
        },
        shouldHideMenu(event) {
            const l = event.target.classList
            if (
                !l.contains('chat-input-function-icon') &&
                !l.contains('btn-chat-function') &&
                !l.contains('item') &&
                !l.contains('textarea') &&
                !l.contains('mobile-function') &&
                !l.contains('zmdi-plus')
            ) {
                this.showFunctions = false
                this.showSpreadProfiles = false
            }
        },
        sendPhotoWhenClipboardContainsImage(event) {
            const clipboardDataType = (event.clipboardData.items[0] || {}).type
            if (!(clipboardDataType || '').includes('image')) return

            const file = event.clipboardData.items[0].getAsFile()
            if (!file) return

            const onConfirm = () => {
                const data = new FormData()
                data.append('attachment', file)
                chatService.sendPhotoMessage(this.chat.id, data)
            }

            this.$modal
                .basic({
                    body: `<img class="btn-brd w-100" src=${URL.createObjectURL(file)}>`,
                    buttons: [
                        {
                            label: 'CANCEL',
                            class: 'btn-default',
                        },
                        {
                            label: 'SEND',
                            class: 'btn-primary',
                        },
                    ],
                })
                .then(idx => {
                    if (idx === 1) {
                        onConfirm()
                    }
                })
        },
        onAutoText(autoText) {
            this.templateUsed = true
            if (this.text) {
                if (this.text.includes('/')) {
                    this.text = this.text.replace(`/${this.shortcut}`, autoText.content)
                } else {
                    this.text += autoText.content
                }
                return
            }
            this.text = autoText.content

            if (this.dom) this.dom.focus()
        },
        onClickEmoji(value) {
            this.showEmoji = false
            if (!this.text) this.text = ''
            this.text += value

            if (this.dom) this.dom.focus()
        },
        async openSpreadProfileModal(type) {
            this.showSpreadProfiles = false
            // const agentingEnable = await chatService.agentingEnabled(this.chat.id)

            if (!this.isAbleRecommend) {
                this.isAbleRecommendAlert()
                return
            }

            if (type === 'SPREAD_FRIEND') {
                // if (!this.enableSpreadAgenting) {
                //     this.$toast.error(this.$translate('UNABLE_AUTO_AGENTING'))
                //     return
                // } else if (!this.enableDating) {
                //     // if (!this.enableDating) {
                //     this.$toast.error(this.$translate('UNABLE_AGENTING_USER'))
                //     return
                // }
                //
                // try {
                //     this.$loading(true)
                //     const data = await chatService.spreadSource(this.chat.id)
                //     this.$toast.success(data.msg)
                //     this.$emit('close')
                // } catch (e) {
                //     this.$toast.error(e.data)
                // } finally {
                //     this.$loading(false)
                // }
                const user = await this.$store.dispatch('loadUserDetail', { userId: this.chat.user.id })
                this.$modal.custom({
                    component: 'ModalSpreadProfile',
                    options: {
                        user: user,
                        type: 'SUPER_SEARCH',
                    },
                })
            } else if (type === 'INTRODUCE_FRIEND') {
                if (!this.enableIntroduceAgenting) {
                    this.$toast.error(this.$translate('UNABLE_AUTO_AGENTING'))
                    return
                } else if (!this.enableDating) {
                    // if (!this.enableDating) {
                    this.$toast.error(this.$translate('UNABLE_AGENTING_USER'))
                    return
                } else if (this.chat.user.gender !== 0) {
                    return
                }

                try {
                    this.$loading(true)
                    const data = await chatService.askSource(this.chat.id)
                    this.$toast.success(data.msg)
                } catch (e) {
                    this.$toast.error(e.data)
                } finally {
                    this.$loading(false)
                }
            }
        },
        goToFriendsRoute() {
            if (this.isInstantChat) {
                this.$toast.error(
                    this.$translate('CAN_INTRODUCE_ONLY_TARGET').replace(/%s/, this.$nameOrNick(this.introducingUser))
                )
            } else {
                const showModal = this.$store.getters.agent.user_privacy_terms_version !== 1

                if (showModal) {
                    this.$modal.basic({
                        body: this.$translate('MODAL_USER_PRIVACY_AGREEMENT_ALERT'),
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })

                    return
                }

                this.$router.push({ name: 'FriendsPage' })
            }
        },
        async agentingEnabled() {
            if (!this.isInstantChat && this.chat.id) {
                const agentingEnable = await chatService.agentingEnabled(this.chat.id)
                this.enableSpreadAgenting = agentingEnable.spread_enabled
                this.enableIntroduceAgenting = agentingEnable.ask_enabled
                this.enableDating = agentingEnable.dating_enabled
            }

            if (this.isInstantChat) this.enableDating = true
        },
        toastError(type) {
            this.$toast.error(this.$translate(type))
        },
        isAbleRecommendAlert() {
            if (this.chat.user.$$premium) {
                this.$modal.basic({
                    title: 'NOTICE',
                    body: this.$translate('CANNOT_RECOMMEND_PREMIUM_USER'),
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
                // this.$alert(this.$translate('CANNOT_RECOMMEND_PREMIUM_USER'))
                return
            }

            if (this.chat.user.$$privacy) {
                this.$modal.basic({
                    title: 'NOTICE',
                    body: this.$translate('CANNOT_RECOMMEND_PRIVACY_USER'),
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })

                // this.$alert(this.$translate('CANNOT_RECOMMEND_PREMIUM_USER'))
            }

            // this.$alert(this.$translate('CANNOT_RECOMMEND_PREMIUM_USER'))
        },
    },
    mounted() {
        this.$bus.$on('onClickSendPicture', this.onSendPictureRequested)
        document.addEventListener('click', this.shouldHideMenu)
        // this.agentingEnabled()
        this.noticeRestriction()
    },
    beforeDestroy() {
        this.$bus.$off('onClickSendPicture', this.onSendPictureRequested)
        document.removeEventListener('click', this.shouldHideMenu)
    },
}
</script>
