import common from './common.json'
import dashboard from './dashboard.json'
import messages from './messages.json'
import modals from './modals.json'
import spreadProfile from './spread_profile.json'
import superAgenting from './super_agenting.json'
import refund from './refund.json'
import toast from './toasts'

const result = {}
const translations = [common, dashboard, messages, modals, spreadProfile, superAgenting, refund, toast]
translations.forEach(json =>
    Object.keys(json).forEach(key => {
        result[key] = json[key]
    })
)

export default result
