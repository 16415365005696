<template>
    <div class="refunds flex">
        <div class="ongoing flex flex-fill" :class="{ card: !$store.getters.isMobile }">
            <div class="title flex-wrap" v-html="$translate('REFUND_ONGOING_TITLE')" />
            <div class="refund-items flex flex-fill" v-if="refunds && refunds.length > 0">
                <div class="refund flex-wrap" :key="refund.id" v-for="refund in refunds">
                    <div class="upper">
                        <div class="row">
                            <div class="key" v-html="$translate('MATCHING_DATE')" />
                            <div class="value" v-html="formattedRefundDate(refund)" />
                        </div>
                        <div
                            class="row flex-between"
                            :key="user.id"
                            v-for="user in [refund.user, refund.relative_user]"
                        >
                            <div class="profile flex-row items-center">
                                <div
                                    @click="onClickUserImage(user)"
                                    class="img-profile"
                                    :class="user.gender | gender"
                                    v-lazy:background-image="(user.photo || {}).url"
                                />
                                <div class="name m-r-3">{{ user.name ? user.name : user.nickname }}</div>
                                <div class="c-primary" v-html="formatWhatTodo(user.$$todo, refund.reason.id)" />
                            </div>
                            <div class="functions">
                                <div class="button bg-gray-border">
                                    <ChatButton :user="user" :class="user | friendType" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="description m-t-24">
                        <div class="row">
                            <div class="key" v-html="$translate('REFUND_REASON')" />
                            <div class="value" v-html="refund.reason.title" />
                        </div>
                        <div class="text" v-html="refund.description" />
                    </div>
                    <div class="images m-t-24">
                        <div class="row"><div class="key" v-html="$translate('REFUND_IMAGES')" /></div>
                        <div class="flex-row hor-scroll">
                            <div
                                @click="onClickRefundImage(image)"
                                class="img-contain flex-wrap"
                                v-img-cover="image"
                                :key="image"
                                v-for="image in refund.images"
                            />
                        </div>
                    </div>
                    <div class="hr w-100" />
                    <div class="what-to-do" v-if="refund.status === 0">
                        <div class="desc" v-html="formattedWhatToDo(refund)" />
                        <div>
                            <div
                                @click="option.action(refund)"
                                class="card"
                                :class="idx === 0 ? 'accept' : 'reject'"
                                :key="idx"
                                v-for="(option, idx) in options"
                            >
                                <div class="option-key" v-html="$translate(option.key)" />
                                <div
                                    v-if="[17, 18, 19].includes(refund.reason.id) && option.key === 'REFUND_ACCEPT'"
                                    class="option-value"
                                    v-html="$translate('REFUND_ACCEPT_RESULT_NO_SUBTRACT')"
                                />
                                <div v-else class="option-value" v-html="$translate(option.value)" />
                            </div>
                        </div>
                    </div>
                    <div class="rejected" v-if="refund.status === 2" v-html="$translate('REFUND_REJECTED')" />
                </div>
            </div>
            <div v-else class="empty" v-html="$translate('EMPTY_REFUNDS')" />
        </div>
    </div>
</template>

<script>
import refundService from '@/services/refund'

export default {
    name: 'Refunds',
    data: () => ({
        // datingInfo: null,
    }),
    mounted() {
        this.init()
    },
    watch: {
        '$store.getters.chatList': {
            handler(newVal) {
                if (newVal) this.$store.commit('populateRefundObservables')
            },
        },
    },
    computed: {
        refunds() {
            return this.$store.getters.refunds
        },
        options() {
            return [
                {
                    key: 'REFUND_ACCEPT',
                    value: 'REFUND_ACCEPT_RESULT',
                    action: refund => this.onAcceptRefund(refund.id),
                },
                {
                    key: 'REFUND_REJECT',
                    value: 'REFUND_REJECT_RESULT',
                    action: refund => {
                        const parentNode = event.target.parentNode.classList
                        parentNode.add('click')

                        setTimeout(async () => {
                            const idx = await this.$modal.custom({
                                component: 'ModalRejectRefund',
                                options: {
                                    refund,
                                },
                            })
                            // console.log(idx)
                            if (idx === 0) parentNode.remove('click')
                            if (idx === 1) this.init()
                        }, 400)
                    },
                },
            ]
        },
    },
    methods: {
        init() {
            this.$store.dispatch('loadRefunds')
        },
        formatWhatTodo(whatTodo, id) {
            const noSubtract = [17, 18, 19].includes(id)
            if (whatTodo === 'REFUND_USER_TODO' && noSubtract) {
                if (noSubtract) return `(${this.$translate(whatTodo)} - 차감 x)`
            }
            return `(${this.$translate(whatTodo)})`
        },
        async onAcceptRefund(refundId) {
            try {
                this.$loading(true)
                const resp = await refundService.accept(refundId)
                this.$modal.basic({
                    title: 'MODAL_DEFAULT_TITLE',
                    body: resp.msg,
                    buttons: [{ label: 'CONFIRM', class: 'btn-primary' }],
                })
            } catch (e) {
                this.$toast.error(e.data)
            }
            this.$loading(false)
            this.init()
        },
        formattedRefundDate(refund) {
            return `${this.$moment(refund.dating.created_at).format('YYYY.M.DㅣHH시mm분')}`.replace(
                'ㅣ',
                '<span class="c-text-very-light">ㅣ</span>'
            )
        },
        formattedWhatToDo(refund) {
            const nameOrNick = refund.relative_user.name ? refund.relative_user.name : refund.relative_user.nickname

            return this.$translate('REFUND_WHAT_TO_DO')
                .replace(/%s/, nameOrNick)
                .replace(/%s/, this.$moment(refund.expired).format('YYYY.M.DㅣHH시mm분'))
        },
        onClickRefundImage(image) {
            window.open(image, '_blank')
        },
        onClickUserImage(user) {
            if (!user.photo) return

            this.$modal.images({
                images: [user.photo.url],
            })
        },
    },
}
</script>
