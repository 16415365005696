import * as $http from 'axios'

export default {
    all: () => $http.get('v2/products'),
    update: productId => $http.put(`v2/products/product_extend`, { product_id: productId }),
    // getAgentIntro: payload => $http.get(`v2/products/get_agent_intro`, payload),
    updateAgentIntro: payload => $http.put(`v2/products/update_agent_intro_pc`, payload),
    updatePremiumEnd: payload => $http.put(`v2/products/update_premium_end`, payload),
    orderInfo: orderId => $http.get(`v2/orders/${orderId}`),
    addProduct: async (payload) =>  $http.post(`v2/products/add_product`, payload),
}
