<template>
    <div class="floatingsv2" v-if="floatings && floatings.length > 0">
        <div class="title">
            <div class="left">
                <span
                    v-html="$translate('USER_LIKE_TITLE').replace(/%s/, $nameOrNick($store.getters.chat.user))"
                ></span>
                <span class="count">{{ (floatings || []).length }}</span>
            </div>
            <div class="delete-all" @click="deleteAll">전체삭제</div>
        </div>
        <transition-group
            name="list"
            class="floatingv2-items flex-row"
            :class="{ 'toggle-on': toggle, 'toggle-off': !toggle }"
        >
            <FloatingV2
                class="flex-wrap list-item"
                :floating="floating"
                :chatId="floating.chat_id"
                :key="floating.id"
                v-for="floating in floatings"
            />
        </transition-group>
        <div class="toggle" @click.stop="onClickToggle">
            <i class="material-icons">{{ toggle ? 'expand_less' : 'expand_more' }}</i>
        </div>
    </div>
</template>

<script>
import FloatingV2 from './FloatingV2'
import chatFloatingService from '@/services/chat-floating'

export default {
    name: 'FloatingsV2',
    components: { FloatingV2 },
    computed: {
        floatings() {
            const chat = this.$store.getters.chat
            if (!chat) return

            return chat.$$floatingsInterest
        },
        floatingIds() {
            return this.floatings.map(f => f.id)
        },
        chat() {
            return this.$store.getters.chat
        },
    },
    data: () => ({
        toggle: false,
    }),
    mounted() {
        const isToggle = sessionStorage.getItem('toggle')
        this.toggle = JSON.parse(isToggle)
    },
    methods: {
        onClickToggle() {
            this.toggle = !this.toggle
            sessionStorage.setItem('toggle', this.toggle)
        },
        async deleteAll() {
            const idx = await this.$modal.basic({
                body: '호감박스를 제거하시겠습니까?',
                buttons: [
                    {
                        label: '취소',
                        class: 'btn-default',
                    },
                    {
                        label: '확인',
                        class: 'btn-primary',
                    },
                ],
            })
            if (idx) {
                try {
                    const { msg } = await chatFloatingService.deleteAll(this.chat.id, this.floatingIds)
                    if (msg) {
                        this.$loading(true)
                        const floatingsRemoved = { ...this.chat, $$floatingsInterest: [] }
                        this.$store.commit('setChat', floatingsRemoved)
                        this.$toast.success('호감박스가 제거되었습니다.')
                    }
                } catch (e) {
                    this.$toast.error('호감박스 제거에 실패했어요')
                } finally {
                    this.$loading(false)
                }
            }
        },
    },
}
</script>

<style lang="scss">
.floatingsv2 {
    border-top: 1px solid $grey-02;
    border-bottom: 1px solid $grey-02;
    $img-size: calc((#{$panel-width-friends} - 8px - 2 * 16px) / 2);
    position: relative;
    .toggle {
        position: absolute;
        top: 15px;
        left: 10px;
        background-color: $purple-primary;
        color: white;
        border-radius: 200px;
        padding: 10px;
        opacity: 0.8;
        z-index: 2;
        i {
            color: white;
        }
    }

    .floatingv2-items {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 12px;
        grid-column-gap: 8px;
        padding: 0 16px 16px;
        overflow-y: auto;

        height: fit-content;
        &.toggle-on {
            max-height: 90%;
        }
        &.toggle-off {
            max-height: $panel-width-friends;
        }
    }
}
</style>
