<template>
    <div class="daily-separator flex-row items-center">
        <div class="left"></div>
        <div class="timestamp flex-wrap">{{ message.created_at | formatDate("YYYY년 M월 D일")}}</div>
        <div class="right"></div>
    </div>
</template>
<script>
export default {
    props: ['message'],
}
</script>