import * as $http from 'axios'

export default {
    all: (userId, sourceId) => $http.get(`v2/suggests?user_id=${userId || ''}&source_id=${sourceId || ''}`),
    requested: () => $http.get('v2/friends/requested'),
    requesting: () => $http.get('v2/friends/requesting'),
    request: payload => $http.post('v2/suggests', payload),
    acceptRequest: id => $http.put(`v2/suggests/${id}/agent_accept`),
    userSuggests: userId => $http.get(`v2/suggests/user_suggests?user_id=${userId}`),
    makeChatRoom: payload => $http.post('v2/suggests/make_chat_room', payload),
}
