import { store as $store } from '@/store'
import Vue from 'vue'

const m = arg => Vue.prototype.$moment(arg)

export const translate = key => {
    const locale = $store.getters.translation.locale
    return ($store.getters.translation.texts[key] || {})[locale] || key
}

// formatDate: 'format' can be either
// any format, 'chatList', 'community' or, neglectetimestamp.
export const formatDate = (value, format) => {
    if (!value) return

    const timestamp = m(value)
    const now = m()
    const fallbackFormat = 'YYYY-MM-DD'
    const toKR = result => {
        result = result.replace(/AM/g, '오전')
        result = result.replace(/PM/g, '오후')
        result = result.replace(/am/g, '오전')
        result = result.replace(/pm/g, '오후')
        return result
    }

    /* Used for chat list, like KakaoTalk */
    if (format === 'chatList') {
        const diffDays = m(now.format('YYYY-MM-DD')).diff(m(timestamp.format('YYYY-MM-DD')), 'day')

        // Show time only if it's today.
        if (diffDays === 0) {
            return toKR(timestamp.format('A h:mm'))
        }
        // Show month and day only if it's this year.
        if (diffDays === 1) {
            return '어제'
        }
        // Otherwise, show year, month, day.
        return timestamp.format('M월 D일')
    }

    /* Used for community, like DC inside or Inven */
    if (format === 'community') {
        if (format) {
            return timestamp.format(format) // || 'YYYY-MM-DD HH:mm:ss')
        }
        // Show time only if it's today.
        if (timestamp.format('YYYY-MM-DD') === now.format('YYYY-MM-DD')) {
            return timestamp.format('HH:mm:ss')
        }
        // Show month and day only if it's this year.
        if (timestamp.format('YYYY') === now.format('YYYY')) {
            return timestamp.format('MM-DD HH:mm:ss')
        }
        // Otherwise, show year, month, day.
        return timestamp.format(fallbackFormat)
    }

    if (format) {
        return toKR(timestamp.format(format))
    }
    return toKR(timestamp.format(fallbackFormat))
}

export const humanizeSeconds = seconds => {
    if (seconds < 60) return translate('BEFORE_SECONDS').replace(/%s/, seconds)

    const minutes = Math.floor(seconds / 60)
    if (minutes < 60) return translate('BEFORE_MINUTES').replace(/%s/, minutes)

    const hours = Math.floor(seconds / 60 / 60)
    if (hours < 24) return translate('BEFORE_HOURS').replace(/%s/, hours)

    const days = Math.floor(seconds / 60 / 60 / 24)
    return translate('BEFORE_DAYS').replace(/%s/, days)
}

export const asAge = birthday => {
    const thisYear = m().format('YYYY')
    const birthYear = m(birthday).format('YYYY')
    return thisYear - birthYear + 1
}

export const noDetailRegion = region => {
    if (!region) return '-'

    if (region.includes('(')) return region.split('(')[0]
    return region
}

export const isNewbie = user => {
    return m().diff(m(user.created_at), 'seconds') < 60 * 60 * 24
}

export const gender = gender => (gender === 0 ? 'male' : 'female')

export const currency = (value, decimalCount) => {
    const digitsRegex = /(\d{3})(?=\d)/g
    value = parseFloat(value)
    if (!isFinite(value) || (!value && value !== 0)) return ''
    decimalCount = decimalCount || 0
    const valueStr = Math.abs(value).toFixed(decimalCount)
    const integer = decimalCount ? valueStr.slice(0, -1 - decimalCount) : valueStr
    const i = integer.length % 3
    const head = i > 0 ? integer.slice(0, i) + (integer.length > 3 ? ',' : '') : ''
    const decimals = decimalCount ? valueStr.slice(-1 - decimalCount) : ''
    const sign = value < 0 ? '-' : ''
    return sign + head + integer.slice(i).replace(digitsRegex, '$1,') + decimals
}

export const ipFront = ip => {
    if (!ip) {
        return
    }
    return ip.split('.').slice(0, 2).join('.')
}

export const alphaNumeric = s => {
    return s.split('').every(c => /^[a-zA-Z0-9가-힣]+$/.test(c))
}

export const school = profile => {
    const schoolTypes = $store.getters.schoolTypes || []

    if (profile.university_name && profile.university_name.trim()) {
        return profile.university_name
    }

    const schoolType = schoolTypes.find(s => s.id === profile.school_type_id)
    return (schoolType || {}).name
}

export const company = profile => {
    if (!profile) return

    if (profile.is_student) {
        if (!school(profile)) return

        return school(profile) + ' 재학'
    }

    const jobCategories = $store.getters.jobCategories || []

    if (profile.company_name && profile.company_name.trim()) {
        return profile.company_name
    }

    const jobCategory = jobCategories.find(j => j.id === profile.job_category_id)
    return (jobCategory || {}).name
}

export const noDetail = region => {
    if (!region) return

    const splitted = region.split('(')
    if (splitted.length >= 1) return splitted[0]
}

export const hasProduct = (user, ptype) => {
    if (!user || !user.products) return

    if (ptype === 'premium') {
        // const agent = $store.getters.agent || {}
        // return user.products.find(p => p.ptype === ptype && p.agent_id === agent.id)
        // enabled?
        return user.products.find(p => p.ptype === ptype)
    }

    return user.products.find(p => p.ptype === ptype)
}

// Don't give `user` type as obj if possible, since it's searching for whole friends, chats.
// If you give an obj which has .friend_type, this doesn't take much time.
export const friendType = (obj, reverse) => {
    if (!obj) return
    const chat = ($store.getters.chatList || []).find(c => c.user_id === obj.id)
    if ((chat || {}).expire_at) return reverse ? 3 : 'recommended'
    else return reverse ? 1 : 'myUser'

    // const agent = $store.getters.agent || {}

    // const friendTypes = {
    //     // 1: agent.$$testAgent ? 'myUser' : 'normal',
    //     1: 'myUser',
    //     2: 'daily',
    //     3: 'recommended',
    // }
    //
    // let found
    // if (obj.friend_type > 0) found = obj
    // if (!found) found = ($store.getters.friends || []).find(f => f.user.id === obj.id)
    // if (found && found.friend_type > 0) return reverse ? found.friend_type : friendTypes[found.friend_type]
}

export const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1)

// toPascal converts message.mtype to PascalCase.
// (ex: contact_request => ContactRequest)
export const toPascal = (str, delim, limit) => {
    let splitted = str.split(delim)
    if (limit) {
        splitted = splitted.slice(0, limit)
    }

    // eslint-disable-next-line no-return-assign
    return splitted.reduce((result, word) => (result += capitalize(word)), '')
}

export const $case = {
    toSnake: function (str, delim) {
        if (!str) return

        const upperChars = str.match(/([A-Z])/g)
        if (!upperChars) {
            return str
        }

        for (let i = 0, n = upperChars.length; i < n; i++) {
            str = str.replace(new RegExp(upperChars[i]), (delim || '_') + upperChars[i].toLowerCase())
        }

        if (str.slice(0, 1) === (delim || '_')) {
            str = str.slice(1)
        }

        return str
    },
    toConst: function (str) {
        if (!str) return

        return this.toSnake(str).toUpperCase()
    },
    toPascal: function (str, delim, limit) {
        let splitted = str.split(delim)
        if (limit) {
            splitted = splitted.slice(0, limit)
        }

        // eslint-disable-next-line no-return-assign
        return splitted.reduce((result, word) => (result += this.toCapital(word)), '')
    },
    toCamel: function (snake) {
        return snake.replace(/([-_][a-z])/gi, $1 => {
            return $1.toUpperCase().replace('-', '').replace('_', '')
        })
    },
    toCapital: function (str) {
        return str.charAt(0).toUpperCase() + str.slice(1)
    },
}
