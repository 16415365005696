<template>
    <div class="refund-info card p-relative">
        <div class="top flex-row">
            <div class="title" v-html="$translate('REFUND_RATE')" />
            <div class="divider">|</div>
            <div class="refund-rate">{{refundRatio}}</div>
        </div>
        <div class="cards grid-col">
            <div class="refund-info-card">
                <div class="title" v-html="'만남 성공'" />
                <div class="value" v-html="`${datingInfo.success}회`" />
            </div>
            <div class="refund-info-card">
                <div class="title" v-html="'만남 실패'" />
                <div class="value" v-html="`${datingInfo.refund}회`" />
            </div>
        </div>
        <div class="descriptions">
            <div>* 매칭 후 10일이 지나 만남 성공/환급 여부가 확정된 매칭을 대상으로 산출된 결과입니다.</div>
            <div>* 확정된 <span class="f-bold">최근 30개의 환급률</span>을 기준으로 보너스를 지급합니다.</div>
        </div>
    </div>
</template>

<script>
import agentService from '@/services/agent'
import refundService from '@/services/refund'

export default {
    name: 'RefundInfo',
    data: () => ({
        datingInfo: {},
    }),
    mounted() {
        this.init()
    },
    computed: {
        refundRatio() {
            if (!this.datingInfo || this.datingInfo.total === 0) return 0 + '%'

            return Math.round((100 * this.datingInfo.refund) / this.datingInfo.total) + '%'
        },
    },
    methods: {
        init() {
            agentService.datingInfo().then(data => {
                this.datingInfo = data
            })
        },
    },
}
</script>
