<template>
    <div class="bridge-histories">
        <div class="body p-relative">
            <div class="thead">
                <div class="flex-row tr">
                    <div :key="column.title" v-for="column in columns" class="th">
                        {{ column.title | translate }}
                    </div>
                </div>
            </div>
            <div class="tbody">
                <div :key="rowIdx" v-for="(row, rowIdx) in options.rewards || []" class="flex-row tr">
                    <div>{{ row.created_at | formatDate('YY.MM.DD') }}</div>
                    <div>{{ row.total_amt | currency }}</div>
                    <div>{{ row.created_at < '2021-01-06' ? '-' : ((row.male_worker_amt + row.other_amt) | currency) }}</div>
                    <div>{{ row.created_at < '2021-01-06' ? '-' : (row.refund_amt) | currency }}</div>
                    <div>{{ row.created_at < '2021-04-06' ? '-' : (row.premium_amt) | currency }}</div>
                    <div>{{ row.created_at < '2021-04-06' ? ((row.rating_bonus) | currency) : '-' }}</div>
                    <div>{{ row.created_at < '2021-04-06' ? ((row.refund_bonus) | currency) : '-' }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BridgeHistories',
    props: ['options'],
    computed: {
        columns() {
            return [
                {
                    title: 'TIMESTAMP',
                },
                {
                    title: 'TOTAL',
                },
                {
                    title: 'SUCCESS',
                },
                {
                    title: 'REFUNDS',
                },
                {
                    title: 'PREMIUM',
                },
                {
                    title: '평점 보너스',
                },
                {
                    title: '환급률 보너스',
                },
            ]
        },
        total(row) {
            if(row.created_at < '2021-01-05') return ''
            else return row.total_amt | currency
        },
    },
}
</script>
