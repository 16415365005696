<template>
    <div class="detail" :class="isInstantChat ? 'is-instant-chat' : ''">
        <h3 class="h3">
            <i class="material-icons">person</i>
            인적사항
        </h3>
        <div class="item" v-for="item in personalProfile" :key="item.key">
            <div class="key" v-html="item.key" />
            <div class="value">
                <span class="personal_value">{{ item.value }}</span>
                <span v-if="item.selective" class="badge-manager-only">매니저만 </span>
                <div v-if="item.description">{{ item.description }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Personal',
    props: ['profile'],
    data: () => ({
        isInstantChat: false,
    }),
    computed: {
        personalProfile() {
            if (!(this.profile && this.profile.privacy_setting)) return []

            const items = [
                {
                    id: 5,
                    key: 'MBTI',
                    value: (this.profile || {}).mbti,
                    selective: !this.profile.privacy_setting.show_mbti,
                },
                {
                    id: 2,
                    key: '운동빈도',
                    value: (this.profile || {}).exercise_freq,
                    selective: !this.profile.privacy_setting.show_exercise_freq,
                },
                {
                    id: 31,
                    key: '운동종류',
                    value: (this.profile || {}).exercise_type,
                    selective: !this.profile.privacy_setting.show_exercise_type,
                },
                {
                    id: 4,
                    key: '흡연여부',
                    value: (this.profile || {}).smoking ? '흡연' : '비흡연',
                    selective: !this.profile.privacy_setting.show_smoking,
                },
                {
                    id: 6,
                    key: '음주여부',
                    value: (this.profile || {}).drink_freq,
                    selective: !this.profile.privacy_setting.show_drink_freq,
                },
                {
                    id: 8,
                    key: '선호주종',
                    value: (this.profile || {}).drink_style,
                    selective: !this.profile.privacy_setting.show_drink_style,
                },
                {
                    id: 10,
                    key: '종교',
                    value: (this.profile || {}).religion_name || null,
                    selective: !this.profile.privacy_setting.show_religion_name,
                },
                {
                    id: 11,
                    key: '반려동물',
                    value: (this.profile || {}).pet,
                    selective: !this.profile.privacy_setting.show_pet,
                },
                {
                    id: 11,
                    key: '건강',
                    value: (this.profile || {}).health_status ? '좋지 않음' : '좋음',
                    description: (this.profile || {}).health_status ? this.profile.health_detail : null,
                    selective: !this.profile.privacy_setting.show_health_status,
                },
            ]

            return items.filter(item => item.value)
        },
    },
}
</script>

<style scoped lang="scss">
.h3 {
    color: #646468;
}
.key {
    color: #646468;
}
.instant-chat {
    .h3 {
        color: #fff;
    }
    .key {
        color: #fff;
    }
    .personal_value {
        color: #fff;
    }
}
.before-confirm {
    .h3 {
        color: #fff;
    }
}
</style>
