<template>
    <div class="introducing-user bg-white flex" v-if="user" :class="isInstantChat ? 'is-instant-chat' : ''">
        <div>
            <UserPhotosSelector
                @onPhotos="photos => (images = photos)"
                @updatePhotos="updatePhotos"
                :user="user"
                :sendNum="sendNum"
                @updateImageCount="ImageCount = $event"
            />
        </div>

        <div class="buttons flex-row items-center flex-between flex-wrap">
            <button
                @click="throttle(onClickSendPicture, 1000)"
                class="btn btn-primary send-selected flex-fill m-r-6"
                :style="{ height: '40px' }"
                :class="disabled"
            >
                {{ 'SEND_SELECTED_PICTURES' | translate }}
            </button>
            <button
                @click="throttle(onClickSendAllPictures, 1000)"
                class="btn btn-brd primary send-all flex-fill m-r-6"
                :style="{ height: '40px' }"
            >
                {{ 'SEND_USED_PICTURES' | translate }}
            </button>
            <button @click="onClickUserImage" class="btn img-photo" :style="{ height: '40px' }">
                <i class="material-icons">search</i>
            </button>
            <div v-if="from !== 'ModalSalesTemplate'" class="divider flex-wrap" v-html="'ㅣ'" />
            <div v-if="from !== 'ModalSalesTemplate'" class="functions flex-row">
                <div class="button">
                    <ChatButton :class="user | friendType" :user="user" :source_id="$store.getters.chat.user.id" />
                </div>
            </div>
        </div>
        <!-- <button @click="onClickSendPictureForFree" class="btn btn-free-ticket">미차감으로 보내기</button> -->
        <div class="product" v-for="plan in userRatePlans" :key="plan.id">
            <div class="product-name">
                {{ plan.name }}
            </div>
            <div class="product-info">
                {{ plan.amount }}회{{
                    plan.price ? `| ${(plan.price / plan.amount_original).toLocaleString()}원` : null
                }}
            </div>
        </div>
        <div v-if="unlimited" class="product">
            <div class="product-name">기간제</div>
            <div class="product-info">{{ unlimitedValidUntil }}까지</div>
        </div>
        <div class="basic-profile section flex-wrap">
            <MeetingFeedback :chatUser="user" />
            <AITestResponse v-if="chat.$$introducingUser.$$AIRecommendUser" />
            <div class="flex-row items-start">
                <UserProfile
                    :hideBookmark="true"
                    v-if="user.profile"
                    class="flex-fill"
                    :user="user"
                    :hide-memo="true"
                />
            </div>
            <div class="flex-row">
                <UserStyles v-if="user.style" class="fourline" :user="user" :tableOnly="true" />
            </div>
        </div>
        <Profiles :from="`introducingUser`" :chatUser="user" :profile="user.profile" />
    </div>
</template>

<script>
import Profiles from '@/routes/chat/chatroom/Profiles'
import UserStyles from '@/components/common/UserStyles'
import AITestResponse from '@/components/common/AITestResponse'
import { getPlanInfo, parsePidToPlanName } from '@/store/data/product'
import productService from '@/services/product'

export default {
    name: 'IntroducingUser',
    props: ['user', 'from'],
    components: {
        Profiles,
        UserStyles,
        AITestResponse,
    },
    data: () => ({
        images: null,
        timer: null,
        price: null,
        sendNum: 0,
        processingQueue: [],
        isProcessing: false,
        ImageCount: 0,
    }),
    computed: {
        products() {
            if (!this.user) return []
            return this.user.products
        },
        unlimited() {
            return this.products.find(p => [9, 10].includes(p.product_id))
        },
        unlimitedValidUntil() {
            if (this.unlimited) {
                return this.$moment(this.unlimited.valid_until).format('YY/MM/DD')
            } else {
                return false
            }
        },
        productsNowOnSale() {
            return this.$store.getters.productsNowOnSale
        },
        userRatePlans() {
            if (!this.products) return {}
            const allRatePlans = this.products.filter(
                item => item.ptype === 'rate_plan' || item.ptype === 'subscription_plan'
            )

            const res = allRatePlans.map(item => {
                const {
                    id,
                    enabled,
                    product_id: pid,
                    valid_from: start,
                    valid_until: expire,
                    created_at: created,
                    order_id,
                } = item
                const {
                    name,
                    description,
                    pre_price: prePrice,
                    price,
                    valid_date: validDate,
                    amount,
                    amount_original,
                    cycle,
                } = (this.productsNowOnSale || {}).all.find(item => item.id === pid)
                const m = this.$moment

                return {
                    id,
                    created,
                    pid,
                    name,
                    prePrice,
                    price,
                    validDate,
                    amount,
                    amount_original,
                    order_id,
                    cycle,
                    description: description ? description.split(',') : null,
                    enabled,
                    startTypeOrigin: start,
                    expireTypeOrigin: expire,
                    start: start ? m(start).format('YY.M.D') : '미정',
                    expire: !enabled
                        ? -1
                        : start
                        ? m(m(m(expire).format('YYYY-MM-DD'))).diff(m().format('YYYY-MM-DD'), 'day')
                        : validDate,
                    // expire: -2,
                }
            })

            return res.sort((a, b) => b.id - a.id)
        },
        userRatePlanRecent() {
            if (!this.products) return {}
            const m = this.$moment
            const tickets =
                this.user.tickets && this.user.tickets.length > 0 ? this.user.tickets.filter(item => item.enabled) : []
            const onGoing = this.userRatePlans.find(item => {
                const dateValid = item.startTypeOrigin && m().isBefore(m(item.expireTypeOrigin))
                const ticketsValid = tickets.filter(t => {
                    return item.id === t.related_user_product_id
                })
                const plan = parsePidToPlanName(item.pid)
                if (!dateValid) return false
                if (plan === 'EXTRA') {
                    return getPlanInfo(item.pid).amount ? ticketsValid.length : true
                } else {
                    return plan === 'UNLIMITED' ? true : ticketsValid.length
                }
            })
            const pending = this.userRatePlans.filter(item => item.start === '미정')
            const expired = this.userRatePlans.filter(item => {
                if (!item.enabled) return true

                const dateExpired = item.startTypeOrigin && m().isAfter(m(item.expireTypeOrigin))
                const ticketsValid = tickets.filter(t => {
                    return item.id === t.related_user_product_id
                })
                const plan = parsePidToPlanName(item.pid)
                if (dateExpired) return true
                if (plan === 'EXTRA') {
                    return getPlanInfo(item.pid).amount ? ticketsValid.length === 0 : true
                } else {
                    return plan === 'UNLIMITED' ? true : ticketsValid.length === 0
                }
            })

            if (onGoing) return { ...onGoing, status: 'on-going' }
            if (pending.length) return { ...pending[0], status: 'pending' }
            if (expired.length) return { ...expired[0], status: 'expired' }

            return { pid: 0, name: '현재 진행중인 요금제가 없습니다' }
        },
        chatUserRatePlan() {
            return this.$store.getters.userRatePlanRecent
        },
        userTickets() {
            if (!this.products) return {}
            const tickets = this.user.tickets
            if (this.userRatePlanRecent.pid === 0) {
                return {
                    all: tickets.length,
                    used: tickets.filter(ticket => !ticket.enabled).length,
                    manner: tickets.filter(ticket => ticket.enabeld && ticket.ticket_type === 'manner_ticket').length,
                    active: tickets.filter(ticket => ticket.enabled && ticket.ticket_type !== 'manner_ticket').length,
                }
            }

            // const validTickets = tickets.filter(
            //     t =>
            //         this.userRatePlanRecent.startTypeOrigin <= t.valid_until &&
            //         this.userRatePlanRecent.expireTypeOrigin >= t.valid_until
            // )
            // console.log('val', validTickets.length, validTickets)
            return {
                all: tickets.length,
                used: tickets.filter(item => !item.enabled).length,
                active: tickets.filter(item => item.enabled).length,
            }
        },
        friendTypeAsNumber() {
            return this.$options.filters.friendType(this.user, true)
        },
        disabled() {
            return (this.images || []).filter(img => img.checked).length === 0 ? 'disabled' : ''
        },
        chat() {
            return this.$store.getters.chat || {}
        },
        isInstantChat() {
            return this.$store.getters.chat && this.$store.getters.chat.expire_at
        },
        isPrivacy() {
            return this.user.$$privacy
        },
        myPremium() {
            if (this.chat.user.$$premium) {
                const premium = this.$options.filters.hasProduct(this.chat.user, 'premium')
                return this.$store.getters.agent.id === premium.agent_id
            }
            return false
        },
        profile() {
            if (!this.user) return

            // const p = this.user.profile
            // const badge = `<i class="zmdi zmdi-check"></i><span>${this.$translate('VERIFIED')}</span>`

            return [
                {
                    items: [
                        { key: 'income' },
                        { key: 'asset' },
                        { key: 'car_model' },
                        { key: 'finance' },
                        { key: 'hometown_shorten' },
                        { key: 'family_asset' },
                        { key: 'family_intro' },
                        { key: 'high_school_name' },
                    ],
                },
                {
                    items: [
                        { key: 'company_department' },
                        { key: 'company_task' },
                        { key: 'marry_plan' },
                        { key: 'child_plan' },
                        { key: 'career_plan' },
                    ],
                },
            ]
        },
        marry() {
            return this.user.profile.marry
        },
        likedBefore() {
            if (this.chat && this.chat.$$floatingsInterest && this.chat.$$floatingsInterest.length > 0) {
                const likedBefore = this.chat.$$floatingsInterest.find(f => f.user.id === this.user.id)
                return likedBefore
            }
            return false
        },
    },
    watch: {
        user() {
            this.images = null
            this.getProducts()
        },
        userRatePlanRecent() {
            this.getProducts()
        },
    },
    mounted() {
        this.getProducts()
        this.$bus.$on('onClickSendAllPictures', this.onClickSendAllPicturesHandler)
    },

    beforeDestroy() {
        this.$bus.$off('onClickSendAllPictures', this.onClickSendAllPicturesHandler)
    },
    methods: {
        throttle(handler, delay) {
            if (!this.timer) {
                this.timer = setTimeout(() => {
                    this.timer = null
                }, delay)

                handler()
            }
        },
        updatePhotos(updatedPhotos) {
            this.images = updatedPhotos
        },
        async getProducts() {
            if (this.userRatePlanRecent.order_id) {
                const res = await productService.orderInfo(this.userRatePlanRecent.order_id)
                if (res) this.price = res.order.price
            }
        },
        async onClickUserImage() {
            const photos = await this.$store.dispatch('loadUserImages', this.user.id)
            if (!photos) return

            this.$modal.images({
                images: photos.map(p => p.url),
            })
        },
        // async onClickSendPictureForFree() {
        //     // if (
        //     //     this.chatUserRatePlan.name.includes('기간제') ||
        //     //     this.chatUserRatePlan.name === '현재 진행중인 요금제가 없습니다'
        //     // ) {
        //     //     await this.$modal.basic({
        //     //         body:
        //     //             '(미차감 불가) 미차감으로 프로필을 보낼 수 없는 회원입니다. (기간제, 만남권 없음, 무료 티켓만 소지)',
        //     //         buttons: [
        //     //             {
        //     //                 label: 'CONFIRM',
        //     //                 class: 'btn-primary',
        //     //             },
        //     //         ],
        //     //     })
        //     //     return
        //     // } else {
        //     this.$modal
        //         .basic({
        //             body: '상대방에게 미차감으로 프로필을 보냅니다.',
        //             buttons: [
        //                 {
        //                     label: 'CANCEL',
        //                     class: 'btn-default',
        //                 },
        //                 {
        //                     label: 'SEND',
        //                     class: 'btn-primary',
        //                 },
        //             ],
        //         })
        //         .then(idx => {
        //             if (idx === 1) {
        //                 this.onClickSendPicture(true)
        //             }
        //         })
        //     // }
        // },
        async onClickSendPicture(isMichagam = false, sentByWho = null) {
            const photoUrls = (this.images || []).filter(img => img.checked).map(img => img.photo_url) || []
            this.$bus.$emit('updateImageCount', this.ImageCount)
            console.log('img_count: ', this.ImageCount)

            if (photoUrls.length === 0) {
                this.$toast.error('SELECT_PICTURES')
                return
            } else if (this.ImageCount > 0) {
                const idx = await this.$modal.basic({
                    body: '고르지 않은 사진이 있습니다. 그래도 보내시겠어요?',
                    buttons: [
                        {
                            label: 'CANCEL',
                            class: 'btn-basic',
                        },
                        {
                            label: 'SEND',
                            class: 'btn-primary',
                        },
                    ],
                })
                if (idx === 0) {
                    return
                }
            }

            this.sendNum++

            if (this.isPrivacy) {
                this.$modal
                    .basic({
                        body: this.$translate('PRIVACY_USER'),
                        buttons: [
                            {
                                label: 'CANCEL',
                                class: 'btn-default',
                            },
                            {
                                label: 'SEND',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    .then(idx => {
                        if (idx === 1) {
                            if (this.from === 'ModalSalesTemplate') {
                                this.$bus.$emit('importPicturesToModalSpreadProfile', photoUrls)
                                return
                            }

                            this.$bus.$emit('onClickSendPicture', {
                                selectedImageUrls: photoUrls,
                                source: this.user,
                                isMichagam: isMichagam,
                                sentByWho: sentByWho,
                            })

                            if (this.chat.likes_unsent > 0 && this.likedBefore) {
                                this.chat.likes_unsent -= 1
                            }
                        }
                    })
            } else if (this.marry) {
                this.$modal
                    .basic({
                        body: this.$translate('MARRIED_USER'),
                        buttons: [
                            {
                                label: 'CANCEL',
                                class: 'btn-default',
                            },
                            {
                                label: 'SEND',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    .then(idx => {
                        if (idx === 1) {
                            this.$bus.$emit('onClickSendPicture', {
                                selectedImageUrls: photoUrls,
                                source: this.user,
                                isMichagam: isMichagam,
                                sentByWho: sentByWho,
                            })

                            if (this.chat.likes_unsent > 0 && this.likedBefore) {
                                this.chat.likes_unsent -= 1
                            }
                        }
                    })
            } else if (
                this.$store.getters.agent.id !== 8 &&
                this.userRatePlans.filter(p => p.name.includes('Marry Fit Plus')).length > 0 &&
                this.likedBefore
            ) {
                this.$modal.basic({
                    body: 'MFP 회원의 선수락 프로필은 전송할 수 없습니다',
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
            } else {
                if (this.from === 'ModalSalesTemplate') {
                    this.$bus.$emit('importPicturesToModalSpreadProfile', photoUrls)
                    return
                }

                this.$bus.$emit('onClickSendPicture', {
                    selectedImageUrls: photoUrls,
                    source: this.user,
                    isMichagam: isMichagam,
                    sentByWho: sentByWho,
                })

                if (this.chat.likes_unsent > 0 && this.likedBefore) {
                    this.chat.likes_unsent -= 1
                }
            }
        },
        async updateUserInfo(userId) {
            await this.$store.dispatch('loadIntroducingUser', userId)
            this.user = this.$store.getters.chat.$$introducingUser
            console.log('Update user_info:', this.user)
        },
        async processQueue() {
            if (this.isProcessing || this.processingQueue.length === 0) return
            this.isProcessing = true

            const userId = this.processingQueue.shift()
            console.log('current_user:', userId)

            await this.updateUserInfo(userId)
            await this.onClickSendAllPictures(userId, 'regular_m_v2')

            this.isProcessing = false
            this.processQueue()
        },
        onClickSendAllPicturesHandler(userId) {
            this.processingQueue.push(userId)
            this.processQueue()
        },
        async onClickSendAllPictures(userId, sentByWho = null) {
            if (!this.user || !this.user.photos) {
                console.log('!this.user || !this.user.photos')
                return
            }

            this.images = ((this.user || {}).photos || []).map(p => ({
                id: p.id,
                photo_url: p.url,
                checked: p.actually_used === 1,
                actuallyUsed: p.actually_used,
            }))
            const selectedImageCount = this.images.filter(img => img.checked).length

            if (this.user.photos.length < 3) {
                await this.$modal.basic({
                    body: '사진이 3장 미만인 유저의 프로필은 보낼 수 없습니다.',
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
            } else if (selectedImageCount >= 7) {
                await this.$modal.basic({
                    body: '사진은 최대 6장만 보낼 수 있습니다.',
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
            } else {
                if (this.isPrivacy) {
                    await this.$modal
                        .basic({
                            body: this.$translate('PRIVACY_USER'),
                            buttons: [
                                {
                                    label: 'CANCEL',
                                    class: 'btn-default',
                                },
                                {
                                    label: 'SEND',
                                    class: 'btn-primary',
                                },
                            ],
                        })
                        .then(idx => {
                            if (idx === 1) {
                                this.$bus.$emit('onRequireActuallyUsedALLPhotos')
                                this.onClickSendPicture()
                            }
                        })
                } else if (this.marry) {
                    await this.$modal
                        .basic({
                            body: this.$translate('MARRIED_USER'),
                            buttons: [
                                {
                                    label: 'CANCEL',
                                    class: 'btn-default',
                                },
                                {
                                    label: 'SEND',
                                    class: 'btn-primary',
                                },
                            ],
                        })
                        .then(idx => {
                            if (idx === 1) {
                                this.$bus.$emit('onRequireActuallyUsedALLPhotos')
                                this.onClickSendPicture()
                            }
                        })
                } else {
                    this.$bus.$emit('onRequireActuallyUsedALLPhotos')
                    await this.onClickSendPicture(false, sentByWho)
                }
            }
        },
    },
}
</script>

<style lang="scss" scoped>
$introducing-basic-profile-height: 216px;
$sending-picture-size: calc((#{$panel-width-introducing-user} - 16px * 2 - 8px) / 2);

.introducing-user {
    $grid-gap: 8px;
    position: relative;
    height: 100%;
    overflow-y: auto;

    &.is-instant-chat {
        background-color: $grey-09;
        border: none !important;
    }

    .functions {
        .button {
            width: 40px;
            height: 40px;
            background: $grey-02;
        }
    }

    .basic-profile {
        padding: 4px 16px 0;
    }

    .buttons {
        border-top: 1px solid $grey-02;
        padding: 16px;

        .divider {
            color: $grey-03;
            margin: 4px;
            font-size: 20px;
        }

        // .send-selected {
        //     min-width: $sending-picture-size;
        // }

        .send-all {
            box-shadow: none;
            padding: 2px;
        }

        .img-photo {
            background: #f1f2f4;
            padding: 5px;

            i {
                color: #000000;
            }
        }
    }

    .btn-free-ticket {
        color: #fff;
        background-color: #e67e22;
        padding: 10px;
        margin: 16px;
        margin-top: 0px;
    }

    // .section {
    //     padding: 20px 16px 24px;
    // }

    .additional {
        margin-top: 24px;
        padding-top: 24px;
        padding-bottom: 40px;
        border-top: 1px solid $grey-02;
    }

    .detail {
        margin-left: 16px;
        margin-right: 16px;

        &:last-child {
            .hr {
                display: none;
            }
        }

        .job,
        .school {
            font-size: 11px;
            margin-bottom: 4px;
            // color: $grey-05;
        }

        // &:not(:last-child) {
        //     margin-bottom: 24px;
        // }

        .zmdi-case,
        .zmdi-account {
            font-size: $icon-size;
            display: block;
            text-align: left;
            margin-bottom: 12px;
        }

        .row {
            margin-bottom: 24px;
            display: flex;
            flex-direction: row;
            align-items: center;
            color: $black;

            .key {
                width: 100px;
                margin-right: 0px;
                color: $grey-05;
            }

            .badge {
                color: $purple-primary !important;

                .zmdi {
                    margin-right: 4px;
                }
            }
        }

        .job-description {
            width: 246px;
            height: fit-content;
            font-size: 14px;

            margin-top: -12px;
            margin-bottom: 24px;
            margin-left: 22vw;
            padding: 12px;
            color: $grey-09;
            line-height: 22px;
            border-radius: 12px;
            border: solid 1px $grey-02;

            .btn-more {
                text-align: end;
                text-decoration: underline;
            }
        }
    }
    .product {
        margin: 0 16px 10px;
        display: flex;
        justify-content: space-between;
        border: 1px solid #adacb0;
        border-radius: 8px;
        padding: 8px;
        align-items: center;
        .product-name {
            font-weight: 700;
            font-size: 18px;
            line-height: 20px;
            color: #000000;
        }
        .product-info {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #ff3d6b;
        }
    }
}
</style>
