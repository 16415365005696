<template>
    <div @click="$emit('click')" v-if="user.profile" class="user-profile">
        <div v-if="hideMemo !== true" class="flex-row m-b-8">
            <div class="btn user-detail-btn m-r-8" v-html="'심사 보이기'" @click="openUserProfileUpdateRequest" />
            <div
                v-if="!isJudgeManager"
                class="btn manner-feedback-btn"
                v-html="'만남 피드백'"
                @click="openMannerFeedback"
            />
        </div>
        <UserProducts v-if="!hideBookmark" :user="user" />
        <InfoIcons :user="user" :hideBookmark="hideBookmark" />
        <UserInfoBasic class="m-b-4" :user="user" :hideBookmark="hideBookmark" :hide-memo="hideMemo" />
        <ProfileUpdateRequests :user="user" />

        <div class="no-info" v-if="user.profile.height === null" v-html="'필수 프로필 미입력!'"></div>
        <UserVerificationBadges :user="user" />
        <ProfileUpdateRequests
            v-if="showPUR && hideMemo !== true"
            @hideRequest="showPUR = false"
            :userId="user.id"
            :profile="user.profile"
        />
    </div>
</template>

<script>
import UserInfoBasic from '@/components/common/UserInfoBasic'
import ProfileUpdateRequests from '@/components/common/ProfileUpdateRequests'
import InfoIcons from '@/routes/chat/chatroom/components/InfoIcons'
import UserVerificationBadges from '@/components/common/UserVerificationBadges'
import UserProducts from '@/components/common/UserProducts'

export default {
    name: 'UserProfile',
    props: ['user', 'chat', 'hideDistance', 'hideKey', 'hideKinds', 'hideBookmark', 'hideMemo', 'profile'],
    components: { UserInfoBasic, ProfileUpdateRequests, InfoIcons, UserVerificationBadges, UserProducts },
    data: () => ({
        showPUR: true,
    }),
    computed: {
        gender() {
            return this.$options.filters.gender(this.user.gender)
        },
        company() {
            return this.$options.filters.company(this.user.profile)
        },
        isMannerFeedbackOpen() {
            return this.$store.getters.isMannerFeedbackOpen || false
        },
        isJudgeManager() {
            return this.$store.getters.agent.id === 8
        },
    },
    watch: {
        user() {},
    },
    methods: {
        onClickPremiumComment() {
            this.$modal.custom({
                component: 'ModalPremiumComment',
                options: {
                    agent_intro: (this.user.$$premium || {}).premium_info.agent_intro,
                },
            })
        },
        openUserDetail() {
            this.$modal.custom({
                component: 'ModalUserDetail',
                options: {
                    userId: this.user.id,
                    from: 'UserProfile',
                },
            })
        },
        openUserProfileUpdateRequest() {
            this.showPUR = !this.showPUR
        },
        openMannerFeedback() {
            this.$store.commit('setMannerFeedbackOpen', !this.isMannerFeedbackOpen)
        },
    },
}
</script>

<style lang="scss" scoped>
.no-info {
    font-size: 40px;
    @include f-bold;
}
.kinds {
    margin-left: -150px;
    // margin-top: 40px;

    &.isPremium {
        margin-top: 10px;
    }
}
.school {
    height: 16px;
}
.user-detail-btn {
    width: 100%;
    height: 40px;
    background-color: #8a74ff;
    color: white;
    font-size: 12px;
    border-radius: 8px;
    cursor: pointer;
    border-color: transparent !important;
}
.manner-feedback-btn {
    @extend .user-detail-btn;
    background-color: #ff3d6b;
}
</style>
