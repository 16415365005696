import ChatroomPage from '@/routes/chat/chatroom/ChatroomPage'
import FriendsPage from '@/routes/chat/friends/FriendsPage'
import UserDetailPage from '@/routes/mobile/user-detail/UserDetailPage'
import Notices from '@/routes/home/Notices'
import DefaultFilterPage from '@/routes/settings/DefaultFilterPage'
import InterestPage from '@/routes/mobile/chat/interest/InterestPage'
import SettingsPageMobile from '@/routes/mobile/settings/SettingsPageMobile'
import ReportUserPage from '@/routes/mobile/chat/report-user/ReportUserPage'

export default [
    {
        path: '/chatroom',
        name: 'ChatroomPage',
        component: ChatroomPage,
    },
    {
        path: '/friends',
        name: 'FriendsPage',
        component: FriendsPage,
    },
    {
        path: '/user-detail',
        name: 'UserDetailPage',
        component: UserDetailPage,
    },
    {
        path: '/notices',
        name: 'Notices',
        component: Notices,
    },
    {
        path: '/default-filter',
        name: 'DefaultFilterPage',
        component: DefaultFilterPage,
    },
    {
        path: '/interest',
        name: 'InterestPage',
        component: InterestPage,
    },
    {
        path: '/settingsMobile',
        name: 'SettingsPageMobile',
        component: SettingsPageMobile,
    },
    {
        path: '/report-user',
        name: 'ReportUserPage',
        component: ReportUserPage,
    },
]
