<template>
    <div class="default-filter">
        <div class="section">
            <div class="profile-title" v-html="$translate(`DEFAULT_FILTER`)" />
            <div class="profile-title-content" v-html="$translate(`DEFAULT_FILTER_CONTENT`)" />
        </div>
        <div class="filter-container">
            <div class="title">기본 필터</div>
            <div class="content">중복 선택 가능</div>
            <div class="item-container item-3">
                <button
                    @click="applyFilterDetail(item, 'basic')"
                    class="items"
                    :class="{ primary: item.selected }"
                    :key="idx"
                    v-html="`${$translate(item.name)}`"
                    v-for="(item, idx) in filters['basic']"
                ></button>
            </div>
            <div class="title">접속 시간</div>
            <div class="item-container item-4">
                <button
                    @click="applyFilterDetail(item, 'lastSign')"
                    class="items"
                    :class="{ primary: item.selected }"
                    :key="idx"
                    v-html="`${$translate(item.name)}`"
                    v-for="(item, idx) in filters['lastSign']"
                ></button>
            </div>
            <hr />
            <button class="btn btn-primary m-20" @click="save">저장</button>
        </div>
    </div>
</template>

<script>
import * as helpers from '@/store/data/helpers'

export default {
    name: 'DefaultFilterPc',
    watch: {},
    data: () => ({
        filters: {},
    }),
    mounted() {
        this.filters = helpers.getSettingDefaultFilters(true, false)
        helpers.setVisitFilters()
    },
    methods: {
        save() {
            window.localStorage.setItem('defaultFilter', JSON.stringify(this.filters))
            this.$toast.success('필터 기본값이 저장되었습니다!')
        },
        applyFilterDetail(item, category) {
            if (category === 'lastSign') {
                // eslint-disable-next-line no-return-assign
                this.filters[category].forEach(i => (i.selected = i.name === item.name))
            } else {
                item.selected = !item.selected
            }
        },
    },
}
</script>
