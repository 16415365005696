<template>
    <div class="profile-update-requests" v-if="profileUpdateRequests">
        <div v-html="'프로필 수정'" />
        <div class="badge-request" :key="item.key" v-for="item in items">
            <span class="key">{{ humanizeKey(item) }}:</span>
            <div v-if="item.key === 'photo_ids'" class="imgs">
                <div class="flex-row" :key="photo.id" v-for="photo in item.value">
                    <img class="photo" :src="photo.url" />
                    <div class="btn pass m-l-8" @click="onClickPass(photo.id, item.key)" v-html="'통과'" />
                    <div class="btn reject m-l-8" @click="onClickReject(photo.id, item.key)" v-html="'반려'" />
                </div>
            </div>
            <!-- 직장 -->
            <div v-else-if="item.key === 'company'" style="width: 100%">
                <div v-for="(c, idx) in item.value" :key="idx" class="job-item">
                    <!-- <div>{{ c.employment_status ? '현직장' : '전직장' }}</div> -->
                    <div v-for="(val, key, j) of c" :key="j">
                        <div v-if="showingJobInfo(key)">
                            <span class="key">{{ $translate(key.toUpperCase()) }} : </span>
                            <span class="value">{{ getVal(key, val) }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 가족 -->
            <div v-else-if="item.key === 'family'" style="width: 100%">
                <div v-for="(f, idx) in item.value" :key="idx" class="job-item">
                    <div>{{ getFamilyType(f.family_type) }}</div>
                    <div v-for="(val, key, j) of f" :key="j">
                        <div v-if="showingFamilyInfo(key)">
                            <span class="key">{{ $translate(key.toUpperCase()) }} : </span>
                            <span class="value">{{ getVal(key, val) }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <span v-else class="value">{{ humanizeValue(item) }}</span>
            <div
                v-if="item.key !== 'photo_ids'"
                class="btn pass m-l-8"
                @click="onClickPass(item, item.key)"
                v-html="'통과'"
            />
            <div
                v-if="item.key !== 'photo_ids'"
                class="btn reject m-l-8"
                @click="onClickReject(item, item.key)"
                v-html="'반려'"
            />
        </div>
    </div>
</template>
<script>
import userService from '@/services/user'

export default {
    name: 'ProfileUpdateRequests',
    // props: ['userId', 'profileUpdateRequest'],
    props: ['userId', 'profile'],
    watch: {
        items() {},
        profileUpdateRequests() {},
        userId() {
            this.getProfileUpdateRequest()
        },
    },
    data: () => ({
        items: [],
        profileUpdateRequests: null,
        // show: true,
    }),
    computed: {
        showingJobInfo() {
            return key => {
                // const valid = [
                //     'company_name',
                //     'region_shorten',
                //     'job_category_name',
                //     'employment_type',
                //     'company_department',
                //     'company_rank',
                //     'company_task',
                // ]
                // return valid.indexOf(key) > -1 ? true : false
                return key.includes('show') || key.includes('id') || key.includes('state') || key.includes('station')
                    ? false
                    : true
            }
        },
        showingFamilyInfo() {
            return key => {
                // const valid = ['live_status', 'education_status', 'company_name', 'job_name']
                // return valid.indexOf(key) > -1 ? true : false
                return key.includes('show') || key.includes('id') ? false : true
            }
        },
        getFamilyType() {
            return val => {
                const type = Object.keys(this.$profile.familyType).find(key => this.$profile.familyType[key].id === val)
                return this.$translate(this.$profile.familyType[type].name.toUpperCase())
            }
        },
    },
    mounted() {
        // this.init()
        this.getProfileUpdateRequest()
    },
    methods: {
        // async setProfileUpdateRequest() {
        //     this.profileUpdateRequests = this.profileUpdateRequest
        // },
        async getProfileUpdateRequest() {
            const pur = await userService.getProfileUpdateRequest(this.userId)
            if (!pur || pur.msg === 'fail') {
                // this.show = false
                this.$emit('hideRequest')
                return
            }

            if (pur.res) {
                this.profileUpdateRequests = pur.res
            }
            if (!this.profileUpdateRequests) {
                this.items = []
                return
            }

            const items = [
                'photo_ids',
                // 'height',
                // 'company_name',
                'job_name',
                'job_type_name',
                // 'university_name',
                // 'major_name',
                'intro',
                // 'high_school_name',
                'car_brand',
                'car_model',
                'finance',
                // 'company_department',
                // 'company_task',
                // 지역관련
                // 'state_name',
                // 'region_name',
                // 'station_name',

                'school_name',
                'bachelor_u_name',
                'bachelor_c_name',
                'bachelor_m_name',
                'bachelor_status',
                'bachelor_start',
                'bachelor_end',
                'master_u_name',
                'master_c_name',
                'master_m_name',
                'master_status',
                'master_start',
                'master_end',
                'doctor_u_name',
                'doctor_c_name',
                'doctor_m_name',
                'doctor_status',
                'doctor_start',
                'doctor_end',
                // 'smoking', // 가공해야하는 값들
                'marry', // 가공해야하는 값들
                // 'marry_plan',
                'income',
                'asset',
                // 'hometown_state_name',
                'hometown_region_name',
                'hometown_station_name',
                'family_asset',
                'family_intro',
                'high_school_name',
                'high_school_status', // 가공해야하는 값들
                'high_school_start',
                'high_school_end',
                'health_status', // 가공해야하는 값들
                'health_detail',
                'house_owner', // 가공해야하는 값들
                'parent_status', // 가공해야하는 값들
                'brotherhood_status', // 가공해야하는 값들
                'brother_count',
                'sister_count',
                'order_of_children',
                'company', // 가공해야하는 값들
                'family', // 가공해야하는 값들
            ]
            const result = []
            items.forEach(key => {
                const value =
                    this.profileUpdateRequests[key] && typeof this.profileUpdateRequests[key] === 'object'
                        ? this.profileUpdateRequests[key].name
                        : this.profileUpdateRequests[key]
                if (value !== null) {
                    if (key === 'photo_ids') {
                        result.push({
                            key: key,
                            value: this.profileUpdateRequests.photos,
                            // value: JSON.parse(value),
                        })
                    } else if (key === 'family') {
                        result.push({
                            key: key,
                            value: JSON.parse(this.profileUpdateRequests[key]),
                        })
                    } else if (key === 'company') {
                        const purCompany = JSON.parse(this.profileUpdateRequests[key])[0]
                        const profileCompany = this.profile.company[0]
                        const changedCompany = {}
                        for (const prop in purCompany) {
                            if (
                                Object.prototype.hasOwnProperty.call(purCompany, prop) &&
                                this.isValueChanged(purCompany[prop], profileCompany[prop])
                            ) {
                                changedCompany[prop] = purCompany[prop]
                            }
                        }

                        if (Object.keys(changedCompany).length > 0) {
                            result.push({
                                key: key,
                                value: [changedCompany],
                            })
                        }
                    } else {
                        const purValue = this.profileUpdateRequests[key]
                        const profileValue = this.profile[key]
                        if (this.isValueChanged(purValue, profileValue)) {
                            const value = typeof purValue === 'object' ? purValue.name : purValue
                            if (value !== null) {
                                result.push({
                                    key: key,
                                    value: value,
                                })
                            }
                        }
                    }
                }
            })
            this.items = result
        },
        isValueChanged(purValue, profileValue) {
            return JSON.stringify(purValue) !== JSON.stringify(profileValue)
        },

        async init() {
            await this.setProfileUpdateRequest()
            if (!this.profileUpdateRequests) {
                this.items = []
                return
            }

            const items = [
                'photo_ids',
                // 'company_name',
                'job_name',
                'job_type_name',
                // 'university_name',
                // 'major_name',
                'intro',
                // 'high_school_name',
                'car_brand',
                'car_model',
                'finance',
                // 'company_department',
                // 'company_task',
                'bachelor_u_name',
                'bachelor_c_name',
                'bachelor_m_name',
                'master_u_name',
                'master_c_name',
                'master_m_name',
                'doctor_u_name',
                'doctor_c_name',
                'doctor_m_name',
                'smoking', // 가공해야하는 값들
                'marry', // 가공해야하는 값들
                'marry_plan',
                'income',
                'asset',
                'hometown_state_name',
                'hometown_region_name',
                'hometown_station_name',
                'family_asset',
                'family_intro',
                'high_school_name',
                'high_school_status', // 가공해야하는 값들
                'high_school_start',
                'high_school_end',
                'health_status', // 가공해야하는 값들
                'health_detail',
                'house_owner', // 가공해야하는 값들
                'parent_status', // 가공해야하는 값들
                'brotherhood_status', // 가공해야하는 값들
                'brother_count',
                'sister_count',
                'order_of_children',
                'company', // 가공해야하는 값들
                'family', // 가공해야하는 값들
            ]
            const result = []
            items.forEach(key => {
                const value =
                    this.profileUpdateRequests[key] && typeof this.profileUpdateRequests[key] === 'object'
                        ? this.profileUpdateRequests[key].name
                        : this.profileUpdateRequests[key]

                if (value) {
                    if (key === 'photo_ids') {
                        result.push({
                            key: key,
                            value: this.profileUpdateRequests.photos,
                            // value: JSON.parse(value),
                        })
                    } else {
                        result.push({
                            key: key,
                            value: value,
                        })
                    }
                }
            })

            this.items = result
        },
        humanizeKey(item) {
            return this.$translate(item.key.toUpperCase())
        },
        humanizeValue(item) {
            if (item.key === 'parent_status') {
                const status = Object.keys(this.$profile.parentStatus).find(
                    key => this.$profile.parentStatus[key].id === item.value
                )
                return this.$profile.parentStatus[status].title
            } else if (item.key.includes('asset') || item.key.includes('income')) {
                return this.$krwParsed(item.value)
            } else if (item.key === 'smoking') {
                return item.value ? '흡연' : '비흡연'
            } else if (item.key === 'health_status') {
                return item.value ? '좋지 않음' : '양호'
            } else if (item.key === 'house_owner') {
                const status = Object.keys(this.$profile.houseOwner).find(
                    key => this.$profile.houseOwner[key].id === item.value
                )
                return this.$profile.houseOwner[status].name
            } else if (item.key.includes('_status')) {
                const status = Object.keys(this.$profile.userEduStatus).find(
                    key => this.$profile.userEduStatus[key].id === item.value
                )
                return this.$profile.userEduStatus[status].title
            } else if (item.key === 'marry') {
                return item.value ? '재혼' : '초혼'
            }
            return item.value
            // if (item.key === 'car') {
            //     return item.value ? '보유' : '미보유'
            // }
            // if (item.key !== 'smoking') {
            //     return item.value || '-'
            // }

            // return item.value === '비흡연'
            //     ? this.hideKey
            //         ? 'NON_SMOKING'
            //         : '비흡연'
            //     : this.hideKey
            //     ? 'SMOKING'
            //     : '흡연'
        },
        getVal(key, val) {
            if (key === 'employment_type' && !['', null].includes(val)) {
                const status = Object.keys(this.$profile.employmentType).find(
                    key => this.$profile.employmentType[key].id === val
                )
                return this.$profile.employmentType[status].title
            } else if (key === 'education_status' && !['', null].includes(val)) {
                const status = Object.keys(this.$profile.educationStatus).find(
                    key => this.$profile.educationStatus[key].id === val
                )
                return this.$profile.educationStatus[status].title
            } else if (key === 'live_status' && !['', null].includes(val)) {
                return val ? '살아계심' : '작고'
            } else if (key === 'high_school_status' && !['', null].includes(val)) {
                const status = Object.keys(this.$profile.userEduStatus).find(
                    key => this.$profile.userEduStatus[key].id === val
                )
                return this.$profile.userEduStatus[status].title
            } else if (key === 'job_status' && !['', null].includes(val)) {
                return val ? '현직' : '전직'
            } else if (key === 'family_type' && !['', null].includes(val)) {
                const status = Object.keys(this.$profile.familyType).find(
                    key => this.$profile.familyType[key].id === val
                )
                return this.$translate(this.$profile.familyType[status].name.toUpperCase())
            } else if (key === 'employment_status') {
                return val ? '현직' : '전직'
            }
            return val
        },
        async onClickPass(item, type) {
            const payload = {
                status: 'accepted',
                // photo_ids: item.key === 'photo_ids' ? JSON.stringify(item.value.map(p => p.id)) : '[]',
                photo_ids: type === 'photo_ids' ? JSON.stringify([item]) : '[]',
                text: type === 'photo_ids' ? '' : this.getText(item.key, item.value),
                type: type === 'photo_ids' ? 'photo' : this.getType(item.key, item.value),
            }

            try {
                await userService.profileUpdateRequest(this.userId, payload)

                if (type === 'photo_ids') {
                    const idx = this.items[0].value.findIndex(p => p.id === item)
                    if (idx > -1) {
                        this.items[0].value.splice(idx, 1)
                    }
                } else {
                    const idx = this.items.findIndex(pur => pur.key === item.key)
                    if (idx > -1) {
                        this.items.splice(idx, 1)
                    }
                }
            } catch (e) {
                this.$toast.error(e)
            }
        },
        async onClickReject(item, type) {
            // const res = await userService.profileUpdateRequest(this.userId, payload)
            const payload = {
                status: 'rejected',
                // photo_ids: item.key === 'photo_ids' ? JSON.stringify(item.value.map(p => p.id)) : '[]',
                photo_ids: type === 'photo_ids' ? JSON.stringify([item]) : '[]',
                text: type === 'photo_ids' ? '' : this.getText(item.key, item.value),
                type: type === 'photo_ids' ? 'photo' : this.getType(item.key, item.value),
            }

            try {
                await userService.profileUpdateRequest(this.userId, payload)

                if (type === 'photo_ids') {
                    const idx = this.items[0].value.findIndex(p => p.id === item)
                    if (idx > -1) {
                        this.items[0].value.splice(idx, 1)
                    }
                } else {
                    const idx = this.items.findIndex(pur => pur.key === item.key)
                    if (idx > -1) {
                        this.items.splice(idx, 1)
                    }
                }
            } catch (e) {
                this.$toast.error(e)
            }
        },
        getText(key, val) {
            if (key === 'photo_ids') {
                return ''
            } else if (key === 'family') {
                return JSON.stringify(val)
            } else if (key === 'company') {
                return JSON.stringify(JSON.parse(this.profileUpdateRequests[key]))
            } else if (key === 'region_name' || key === 'station_name') {
                return `${this.profileUpdateRequests.state_id},${this.profileUpdateRequests.region_id},${this.profileUpdateRequests.station_id}`
            } else if (key === 'hometown_region_name' || key === 'hometown_station_name') {
                return `${this.profileUpdateRequests.hometown_state_id},${this.profileUpdateRequests.hometown_region_id},${this.profileUpdateRequests.hometown_station_id}`
            } else {
                return val
            }
        },
        getType(key, val) {
            if (key === 'photo_ids') {
                return 'photo'
            } else if (key === 'region_name' || key === 'station_name') {
                return 'state'
            } else if (key === 'hometown_region_name' || key === 'hometown_station_name') {
                return 'hometown_state'
            } else {
                return key
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.profile-update-requests {
    // max-height: 420px;
    overflow-y: auto;
    border: 1px solid $grey-05;
    border-radius: 8px;
    .badge-request {
        // max-height: 350px;
        overflow-y: auto;
        display: flex;
        .key {
            @include f-medium;
            font-family: NotoSans-Regular !important;
            color: $grey-05 !important;
            margin-left: 16px;
            vertical-align: middle;
            display: inline;
        }
        .value {
            margin-left: 6px;
            color: red;
            @include f-bold;
        }
        .btn {
            border-radius: 4px;
            border: 2px solid $grey-03 !important;
            color: red;
        }
        .job-item {
            padding: 10px;
        }
    }
}
</style>
