<template>
    <div class="settings-mobile bg-white flex-row">
        <div class="my-profile-container-mobile">
            <div class="my-profile flex-row items-center flex-wrap">
                <PhotoAndLastSeen class="flex-wrap" :profile="me.photo_url" :gender="me.gender" :userId="me.user_id" />
                <div>
                    <div class="names">
                        <span class="name">{{ me.name }}</span>
                        <span v-if="me.nickname" class="nickname">({{ me.nickname }})</span>
                        <div class="badge display-table" v-html="$translate('PRO')" />
                    </div>
                    <div class="buttons flex-row">
                        <button
                            @click="updateProfile"
                            class="btn btn-brd flex-fill"
                            v-html="$translate('EDIT_PROFILE')"
                        />
                    </div>
                </div>
            </div>
            <div class="stats flex-row m-12">
                <div class="item rectangle-settings m-8" :key="idx" v-for="(stat, idx) in stats">
                    <div class="value" v-html="$translate(stat.value)" />
                    <div class="key" v-html="$translate(stat.key)" />
                </div>
            </div>
            <div class="hr"></div>
            <ul class="flex-fill">
                <div class="section">
                    <div class="section-title" v-html="$translate('NOTIFICATIONS')" />
                    <li @click="toggle(key)" :key="key" v-for="key in ['notified', 'message_accepted']">
                        <label v-html="$translate(key.toUpperCase())" />
                        <Toggler v-model="agent[key]" class="no-touch" />
                    </li>
                </div>
                <div class="section">
                    <div class="section-title" v-html="$translate('FUNCTION')" />
                    <li @click="settingFunc(key)" :key="key" v-for="key in ['default_filter']">
                        <label v-html="$translate(key.toUpperCase())" />
                    </li>
                </div>
                <div class="section">
                    <div class="section-title" v-html="$translate('AUTO_MESSAGE')" />
                    <li
                        @click="toggle(key)"
                        :key="key"
                        v-for="key in [
                            'notify_recommend_request',
                            'notify_recommend_reject',
                            'notify_recommend_accept',
                            'notify_user_pass',
                        ]"
                    >
                        <label v-html="$translate(key.toUpperCase())" />
                        <Toggler v-model="agent[key]" class="no-touch" />
                    </li>
                </div>
                <div class="section">
                    <div class="section-title" v-html="$translate('ACCOUNT')" />
                    <li @click="toggleLocale">
                        <label v-html="$translate('LOCALE')" />
                        <span v-html="$store.getters.translation.locale" />
                    </li>
                    <li @click="askLogout">
                        <label v-html="$translate('LOGOUT')" />
                        <!-- <i class="zmdi zmdi-chevron-right"/> -->
                    </li>
                </div>
            </ul>
            <div class="app-version" v-html="'앱 버전 1.0.0'" />
        </div>
    </div>
</template>

<script>
import agentService from '@/services/agent'

export default {
    name: 'SettingsPageMobile',

    data: () => ({
        showFilter: false,
        // pages: [],
    }),
    mounted() {
        if (this.$route.params && this.$route.params.defaultShow === 'friendsFilter')
            this.$router.push({ name: 'DefaultFilterPage' })
    },
    beforeDestroy() {},

    computed: {
        agent() {
            return this.$store.getters.agent || {}
        },
        me() {
            return this.$store.getters.agent || {}
        },
        stats() {
            return [
                {
                    key: 'SUCCESS_DATINGS',
                    value: `${(this.agent.datings_stat || {}).total_count || 0}<span class="value-unit">회</span>`,
                },
                {
                    key: 'FRIENDS',
                    value: `${(this.agent.datings_stat || {}).friend_count || 0}<span class="value-unit">명</span>`,
                },
            ]
        },
    },
    methods: {
        askLogout() {
            this.$modal
                .basic({
                    title: 'LOGOUT',
                    body: this.$translate('LOGOUT_BODY'),
                    buttons: [
                        {
                            label: 'CANCEL',
                            class: 'btn-default',
                        },
                        {
                            label: 'LOGOUT',
                            class: 'btn-primary',
                        },
                    ],
                })
                .then(selectedBtnIdx => {
                    if (selectedBtnIdx === 1) {
                        this.$toast.success('BYEBYE!')
                        this.$store.dispatch('signOut')
                    }
                })
        },
        toggleLocale() {
            this.$store.dispatch('toggleLocale')
        },
        async updateProfile() {
            const checkUpdate = await agentService.checkUpdateEnable()
            if (checkUpdate.ret) {
                this.$router.push({ name: 'EditProfilePage' })
            } else {
                this.$toast.error(this.$translate('UNCHECKED_PROFILE'))
            }
        },
        updatePhoto() {
            this.$toast.error(this.$translate('DO_IT_ON_MOBILE'))
        },

        async updateAgent() {
            try {
                await agentService.update(this.agent)
                this.$toast.success('UPDATED')
            } catch (e) {
                this.$toast.error(e.data)
            }
        },
        toggle(key) {
            this.agent[key] = !this.agent[key]
            this.updateAgent()
        },
        settingFunc(key) {
            if (key === 'auto_message_management') {
                this.$toast.error(this.$translate('DO_IT_ON_MOBILE'))
            } else if (key === 'default_filter') {
                this.$router.push({ name: 'DefaultFilterPage' })
            }
        },
        setDefaultShow() {
            if (this.$route.params) {
                switch (this.$route.params.defaultShow) {
                    case 'friendsFilter':
                        this.showFilter = true
                        break
                    default:
                }
            }
        },
    },
}
</script>
