<template>
    <div class="styles" v-if="content">
        <div class="title">
            <i class="material-icons">chat_bubble</i>
            <span>매니저에게만 살짝</span>
        </div>
        <div class="style">
            <p class="value" v-html="contentParsed" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'OnlyToManager',
    props: ['content'],
    computed: {
        contentParsed() {
            if (!this.content) return false

            return this.content.split(/\n/).join('<br>')
        },
    },
}
</script>

<style lang="scss" scoped>
.styles {
    .title {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        padding: 0 8px;

        i {
            color: $purple-primary;
            margin-right: 8px;
        }
    }
    .style {
        font-size: 14px !important;
        display: flex;
        margin-bottom: 4px;
    }
    .key {
        width: 20%;
        font-size: 14px !important;
    }
    .value {
        @include f-bold;
        font-size: 14px !important;
        color: black;
    }
}
</style>
