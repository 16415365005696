<template>
    <div class="profile-section">
        <img class="img" v-if="user.photo" :src="user.photo" alt="" />
        <div class="img" v-if="!user.photo"></div>
        <div class="info-wrap">
            <div class="detail">
                <span>{{ user.name || ' - ' }}</span>
                <span>{{ $options.filters.asAge(user.birthday) || ' - ' }}</span>
                <span>{{ user.job || ' - ' }}</span>
            </div>
            <div class="detail" v-if="date">
                <span>{{ getMeetDate }}</span>
                <span>{{ getMeetTime }}시</span>
            </div>
            <div class="detail" v-if="place">
                <span>{{ place }}에서 {{ meetType }}예정</span>
                <span v-if="restaurant" @click="onClickRestaurant"> {{ restaurant.restaurant_name }} > </span>
            </div>
            <div class="detail red" v-if="food">
                <span>여성분이 꺼리는 음식 : {{ food }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ScheduleProfile',
    props: {
        user: {
            type: Object,
        },
        date: {
            type: String,
        },
        place: {
            type: String,
        },
        food: {
            type: String,
        },
        restaurant: {
            type: Object,
        },
        meetType: {
            type: String,
        },
    },
    computed: {
        getMeetDate() {
            return (
                this.$moment(this.date).format('YY/MM/DD') +
                ' ' +
                ['일', '월', '화', '수', '목', '금', '토'][this.$moment(this.date).day()]
            )
        },
        getMeetTime() {
            return (
                (this.$moment(this.date).format('A') === 'AM' ? '오전' : '오후') +
                ' ' +
                this.$moment(this.date).format('h')
            )
        },
    },
    methods: {
        onClickRestaurant() {
            // window.location.href = this.restaurant.restaurant_url
            window.open(this.restaurant.restaurant_url, '_blank')
        },
    },
}
</script>

<style scoped lang="scss">
.profile-section {
    padding: 4px;
    background: #f7f8f9;
    display: flex;
    margin: 16px 0px;
    width: 100%;
    .img {
        width: 56px;
        height: 56px;
        border: 1px solid #f1f2f4;
        border-radius: 8px;
        margin-right: 8px;
        background-color: #e9e9e9;
    }
    .info-wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .detail {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #111111;
            span:not(:last-child)::after {
                content: ', ';
            }
            &.red {
                font-weight: 700;
                line-height: 24px;
                color: #ff3d6b;
            }
        }
    }
}
</style>
