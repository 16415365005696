<template>
    <div class="detail" :class="isInstantChat ? 'is-instant-chat' : ''">
        <h3 class="h3">
            <i class="material-icons">school</i>
            학력정보
        </h3>

        <div class="item-container">
            <span class="title-badge-purple f-15">표시될 학교명 : {{ universityModified }}</span>
        </div>

        <div class="item-container m-t-10" v-if="profile.school">
            <span class="title-badge-green">최종학력 : {{ profile.school.name }}</span>
        </div>

        <!-- high school -->
        <div class="item-container" v-if="highSchoolItem.name">
            <span class="title-badge-yellow">고등학교</span>
            <div class="item">
                <div class="key">학교명</div>
                <div class="value">
                    <div class="educational_value">{{ highSchoolItem.name }}</div>
                    <span v-if="!highSchoolItem.show_high_school" class="badge-manager-only">매니저만</span>
                </div>
            </div>
            <div class="item">
                <div class="key">학력상태</div>
                <div class="value">
                    {{ getSchoolStatus(highSchoolItem.status) }}
                    <span v-if="!highSchoolItem.show_high_school" class="badge-manager-only">매니저만</span>
                </div>
            </div>
            <div class="item">
                <div class="key">입학/졸업년도</div>
                <div class="value">
                    {{ highSchoolItem.start }} - {{ highSchoolItem.end }}
                    <span v-if="!highSchoolItem.show_high_school" class="badge-manager-only">매니저만</span>
                </div>
            </div>
        </div>
        <!-- universities -->
        <div class="item-container" v-for="(item, idx) in univItems" :key="idx">
            <span class="title-badge-yellow">{{ getSchoolType(item.type) }}</span>
            <div class="item">
                <div class="key">학교명</div>
                <div class="educational_value">{{ item.name }}</div>
            </div>
            <div class="item">
                <div class="key">단과대/전공</div>
                <div class="value" v-if="item.college || item.major">
                    <span class="seperator-slash"
                        >{{ item.college }}
                        <span v-if="!item.show_college" class="badge-manager-only">매니저만</span>
                    </span>
                    <span class="seperator-slash"
                        >{{ item.major }}
                        <span v-if="!item.show_major" class="badge-manager-only">매니저만</span>
                    </span>
                </div>
            </div>
            <div class="item" v-if="getSchoolStatus(item.status)">
                <div class="key">학력상태</div>
                <div class="value">{{ getSchoolStatus(item.status) }}</div>
            </div>
            <div class="item" v-if="item.start || item.end">
                <div class="key">입학/졸업년도</div>
                <div class="value">{{ item.start }} - {{ item.end }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import userService from '@/services/user'

export default {
    name: 'Educational',
    props: ['profile'],
    data: () => ({
        university: null,
        universityModified: null,
        isInstantChat: false,
    }),
    watch: {
        profile: {
            deep: true,
            immediate: true,
            handler(newProfile) {
                this.initUniversities(newProfile.school.id)
            },
        },
    },
    computed: {
        userUniv() {
            return ['bachelor', 'master', 'doctor'].filter(type => this.profile[`${type}_u_name`])
        },
        univItems() {
            const items = []
            if (!this.profile.privacy_setting) return []

            this.userUniv.forEach(sType => {
                items.push({
                    type: sType,
                    name: this.profile[`${sType}_u_name`],
                    college: this.profile[`${sType}_c_name`],
                    show_college: this.profile.privacy_setting[`show_${sType}_c_name`],
                    major: this.profile[`${sType}_m_name`],
                    show_major: this.profile.privacy_setting[`show_${sType}_m_name`],
                    status: this.profile[`${sType}_status`],
                    start: this.profile[`${sType}_start`],
                    end: this.profile[`${sType}_end`],
                })
            })

            return items
        },
        highSchoolItem() {
            // if (this.profile.profile_setting.show_high_school_name) {
            if (!this.profile.privacy_setting) return {}

            return {
                type: 'high_school',
                name: this.profile.high_school_name,
                show_high_school: this.profile.privacy_setting.show_high_school_name,
                status: this.profile.high_school_status,
                start: this.profile.high_school_start,
                end: this.profile.high_school_end,
            }
        },
        onlySchool() {
            return !(this.univItems.length || this.profile.high_shcool_name)
        },
        getSchoolStatus() {
            return status => {
                let content = ''
                switch (status) {
                    case 0:
                        content = '졸업'
                        break
                    case 1:
                        content = '수료'
                        break
                    case 2:
                        content = '중퇴'
                        break
                    case 3:
                        content = '재학'
                        break
                    case 4:
                        content = '휴학'
                        break
                    case 5:
                        content = '편입재학'
                        break
                    case 6:
                        content = '편입졸업'
                        break
                    default:
                        content = false
                }
                return content
            }
        },
        getSchoolType() {
            return type => {
                let content = ''
                switch (type) {
                    case 'doctor':
                        content = '대학교(박사)'
                        break
                    case 'master':
                        content = '대학교(석사)'
                        break
                    case 'bachelor':
                        content = '대학교(학사)'
                        break
                }
                return content
            }
        },
    },
    methods: {
        async initUniversities(schoolId) {
            if (schoolId === 1) {
                this.university = await userService.universities({
                    schoolTypeId: null,
                    name: this.profile.doctor_u_name,
                })
            } else if (schoolId < 4) {
                this.university = await userService.universities({
                    schoolTypeId: null,
                    name: this.profile.master_u_name,
                })
            } else if ([4, 5, 7].includes(schoolId)) {
                this.university = await userService.universities({
                    schoolTypeId: null,
                    name: this.profile.bachelor_u_name,
                })
            }
            this.updateUniversityModified()
        },
        updateUniversityModified() {
            if (this.university && this.university.length > 0) {
                if (this.university[0].spec_grade >= 5) {
                    if (
                        [
                            '해외 명문대(세계 100위권)',
                            '해외명문대(세계 30위권)',
                            '해외명문대',
                            '아이비리그',
                            '해외대',
                        ].includes(this.university[0].utype)
                    ) {
                        this.universityModified = `${this.university[0].name} ${this.university[0].utype}) ${
                            this.profile.school.id === 1 ? '(박사)' : this.profile.school.id < 4 ? '(석사)' : ''
                        }`
                    } else {
                        this.universityModified = `${this.university[0].name} ${
                            this.profile.school.id === 1 ? '(박사)' : this.profile.school.id < 4 ? '(석사)' : ''
                        }`
                    }
                } else if (this.university[0].spec_grade === 4) {
                    this.universityModified = `${this.university[0].utype} ${
                        this.profile.school.id === 1 ? '(박사)' : this.profile.school.id < 4 ? '(석사)' : ''
                    }`
                } else {
                    if (this.university[0].utype === '해외대') {
                        this.universityModified = `${this.university[0].utype} ${
                            this.profile.school.id === 1 ? '(박사)' : this.profile.school.id < 4 ? '(석사)' : ''
                        }`
                    } else {
                        this.universityModified = null
                    }
                }
            }
        },
        showBadge(key) {
            if (!this.isMe) return false
            return !this.profile.privacy_setting[`show_${key}`]
        },
    },
}
</script>
<style scoped lang="scss">
.h3 {
    color: #646468;
}
.key {
    color: #646468;
}
.seperator-slash:not(:last-child)::after {
    content: '/';
    margin: 0 2px;
}
.instant-chat {
    .h3 {
        color: #fff;
    }
    .key {
        color: #fff;
    }
    .educational_value {
        color: #fff;
    }
    .seperator-slash {
        color: #fff;
    }
}
.before-confirm {
    .h3 {
        color: #fff;
    }
}
</style>
