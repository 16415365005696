import Vue from 'vue'
import productService from '@/services/product'
import { store as $store } from '@/store'

export const parsePidToPlanName = (pid, upper = true) => {
    const products = $store.getters.productsNowOnSale
    const result = products.prepaid.some(item => item.id === pid) ? 'prepaid' : 'unlimited'
    return upper ? result.toUpperCase() : result
}

export const getPlanInfo = (planName, pid) => {
    const plans = $store.getters.productsNowOnSale[planName.toLowerCase()]
    const res = plans.find(item => item.id === pid)

    return res || $store.getters.products.all.find(item => item.id === pid)
}

export const getPlanNameById = pid => {
    const plans = $store.getters.productsNowOnSale.all
    return plans.find(item => item.id === pid)
}

const defaultState = () => ({
    productsNowOnSale: null,
})

const state = defaultState()

const getters = {
    productsNowOnSale: state => state.productsNowOnSale,
    chatUser: state => state.chat.user,
    userProducts: (state, getters) => getters.chatUser.products || [],
    userRatePlans: (state, getters) => {
        const allRatePlans = getters.userProducts.filter(item => item.ptype === 'rate_plan' || item.ptype === 'subscription_plan')

        const res = allRatePlans.map(item => {
            const {
                id,
                enabled,
                product_id: pid,
                valid_from: start,
                valid_until: expire,
                created_at: created,
                order_id,
            } = item
            const {
                name,
                description,
                pre_price: prePrice,
                price,
                valid_date: validDate,
                amount,
                cycle,
                amount_original,
            } = (state.productsNowOnSale || {}).all.find(item => item.id === pid)
            const m = Vue.prototype.$moment

            return {
                id,
                created,
                pid,
                name,
                prePrice,
                price,
                validDate,
                amount,
                amount_original,
                order_id,
                cycle,
                description: description ? description.split(',') : null,
                enabled,
                startTypeOrigin: start,
                expireTypeOrigin: expire,
                start: start ? m(start).format('YY.M.D') : '미정',
                expire: !enabled
                    ? -1
                    : start
                    ? m(m(m(expire).format('YYYY-MM-DD'))).diff(m().format('YYYY-MM-DD'), 'day')
                    : validDate,
                // expire: -2,
            }
        })

        return res.sort((a, b) => b.id - a.id)
    },
    userRatePlanRecent: (state, getters) => {
        if (!getters.userRatePlans.length) return { pid: 0, name: '현재 진행중인 요금제가 없습니다' }

        const m = Vue.prototype.$moment
        const tickets = getters.userProducts.filter(item => item.ptype === 'ticket')
        const onGoing = getters.userRatePlans.find(item => {
            const dateValid = item.startTypeOrigin && m().isBefore(m(item.expireTypeOrigin))
            const ticketsValid = tickets.filter(t => {
                return item.id === t.related_user_product_id
            })
            const plan = parsePidToPlanName(item.pid)
            if (!dateValid) return false
            if (plan === 'EXTRA') {
                return getPlanInfo(item.pid).amount ? ticketsValid.length : true
            } else {
                return plan === 'UNLIMITED' ? true : ticketsValid.length
            }
        })
        const pending = getters.userRatePlans.filter(item => item.start === '미정')
        const expired = getters.userRatePlans.filter(item => {
            if (!item.enabled) return true

            const dateExpired = item.startTypeOrigin && m().isAfter(m(item.expireTypeOrigin))
            const ticketsValid = tickets.filter(t => {
                return item.id === t.related_user_product_id
            })
            const plan = parsePidToPlanName(item.pid)
            if (dateExpired) return true
            if (plan === 'EXTRA') {
                return getPlanInfo(item.pid).amount ? ticketsValid.length === 0 : true
            } else {
                return plan === 'UNLIMITED' ? true : ticketsValid.length === 0
            }
        })

        if (onGoing) return { ...onGoing, status: 'on-going' }
        if (pending.length) return { ...pending[0], status: 'pending' }
        if (expired.length) return { ...expired[0], status: 'expired' }

        return { pid: 0, name: '현재 진행중인 요금제가 없습니다' }
    },
    userTickets: (state, getters) => {},
}

// const actions = {
//     async loadProducts({ commit }) {
//         const { rate_plan } = await productService.all()
//         commit('setProductsNowOnSale', rate_plan)
//     },
// }
const actions = {
    async loadProducts({ commit }) {
        const allResults = await productService.all();
        const combinedProducts = [
            ...allResults.rate_plan,
            ...allResults.ticket_plan,
            ...allResults.subscription_plan,
            ...allResults.package_plan,
        ];
        commit('setProductsNowOnSale', combinedProducts);
    },
}

const mutations = {
    setProductsNowOnSale(state, value) {
        state.productsNowOnSale = {
            all: value,
            unlimited: value.filter(item => item.code.includes('unlimited_') || item.amount === 0),
            prepaid: value.filter(item => item.code.includes('prepaid_') || item.amount > 0),
            ticketPlan: value.filter(item => item.ptype == 'ticket_plan'),
            subscriptonPlan: value.filter(item => item.ptype == 'subscription_plan'),
            packagePlan: value.filter(item => item.ptype == 'package_plan'),
        }
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
    defaultState,
}
