<template>
    <div class="emoji" ref="ver-scroll">
        <Picker
            color="#8a74ff"
            :exclude="['flags', 'symbols', 'objects']"
            :perLine="10"
            :showSearch="false"
            :showPreview="false"
            :style="{ 'max-height': '296px' }"
            @select="selectEmoji"
        />
    </div>
</template>

<script>
import { Picker } from 'emoji-mart-vue'

export default {
    name: 'Emoji',
    components: { Picker },
    computed: {},
    mounted() {
        document.addEventListener('click', this.checkClicked)
    },
    beforeDestroy() {
        document.removeEventListener('click', this.checkClicked)
    },
    data: () => ({
        selectedAutoText: null,
    }),
    methods: {
        selectEmoji(emoji) {
            this.$emit('onClickEmoji', emoji.native)
        },
        checkClicked(event) {
            const l = event.target
            if (l.closest('.emoji-mart-bar')) {
                // 스크롤이 안내려가서 카테고리 선택이 안되서 강제로 좀 더 내려줌
                const dom = this.$refs['ver-scroll'].querySelector('.emoji-mart-scroll')
                if (dom) dom.scrollTop += 5
            } else if (
                !l.closest('.emoji-mart') &&
                !l.classList.contains('chat-input-function-icon') &&
                !l.classList.contains('auto-texts-function-icon')
            ) {
                this.$emit('close')
            }
        },
    },
}
</script>
