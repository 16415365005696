<template>
    <div
        v-if="chatUser"
        ref="chat-user"
        class="chat-user"
        :class="{ 'before-confirm': confirmedStatus !== 'confirmed' }"
    >
        <div class="t-box">
            <div class="basic grid-profile" :class="{ 'toggle-on': toggle, 'toggle-off': !toggle }">
                <div
                    @click="onClickChatUserImage"
                    class="img-profile animate-size cursor-pointer flex-wrap"
                    v-for="(photo, key) in chatUser.photos"
                    v-lazy:background-image="photo.url"
                    :key="key"
                >
                    <div v-if="isPrivacy" class="privacy-status flex-row">
                        <i class="material-icons flex-wrap security">security</i>
                        <div class="privacy-remain" v-html="privacyRemain" />
                    </div>
                </div>
            </div>
            <div class="toggle" @click.stop="onClickToggle">
                <i class="material-icons">{{ toggle ? 'expand_less' : 'expand_more' }}</i>
            </div>

            <div class="user-profile-box flex-fill">
                <div class="flex-row">
                    <UserProfile
                        :hideDistance="true"
                        :user="chatUser"
                        :chat="chat"
                        :hideBookmark="false"
                        :profile="chatUser.profile"
                    />
                </div>
                <div class="flex-row">
                    <UserStyles v-if="chatUser" class="fourline" :user="chatUser" :tableOnly="true" />
                </div>
            </div>
        </div>
        <Profiles v-if="chatUser" :from="`chatUser`" :chatUser="chatUser" :profile="chatUser.profile" />
        <div class="loading" v-else />
    </div>
</template>

<script>
import UserStyles from '@/components/common/UserStyles'
import productService from '@/services/product'
import Profiles from '@/routes/chat/chatroom/Profiles'

export default {
    name: 'ChatUser',
    components: {
        UserStyles,
        Profiles,
    },
    computed: {
        confirmedStatus() {
            return (this.chatUser.profile || {}).status
        },
        user() {
            return this.chat.user
        },
        chat() {
            return this.$store.getters.chat || {}
        },
        coverImg() {
            return this.user.photo_url
        },
        me() {
            return this.$store.getters.agent
        },
        myPremium() {
            if (this.user.$$premium) {
                const premium = this.$options.filters.hasProduct(this.user, 'premium')
                return this.$store.getters.agent.id === premium.agent_id
            }
            return false
        },
        agentId() {
            return this.me.id
        },
        premiumProduct() {
            if (this.user.$$premium) {
                const premium = this.$options.filters.hasProduct(this.user, 'premium')
                return premium
            }
            return null
        },
        isPrivacy() {
            return this.user.$$privacy
        },
        privacyRemain() {
            if (this.user.$$privacy) {
                const privacy = this.$options.filters.hasProduct(this.user, 'privacy')
                return `${this.$moment(privacy.valid_until).diff(this.$moment(), 'days')}일 남음`
            } else {
                return null
            }
        },
        profile() {
            if (!this.user) return

            // const p = this.user.profile
            // const badge = `<i class="zmdi zmdi-check"></i><span>${this.$translate('VERIFIED')}</span>`

            return [
                {
                    items: [
                        { key: 'income' },
                        { key: 'asset' },
                        { key: 'car_model' },
                        { key: 'finance' },
                        { key: 'hometown_shorten' },
                        { key: 'family_asset' },
                        { key: 'family_intro' },
                        { key: 'high_school_name' },
                    ],
                },
                {
                    items: [
                        { key: 'company_department' },
                        { key: 'company_task' },
                        { key: 'marry_plan' },
                        { key: 'child_plan' },
                        { key: 'career_plan' },
                    ],
                },
            ]
        },
        company() {
            console.log('82', this.chatUser.profile)
            return this.$options.filters.company(this.chatUser.profile)
        },
    },
    data: () => ({
        expanded: null,
        showSbExpand: false,
        chatUser: {},
        remainDay: null,
        remainTime: null,
        toggle: false,
    }),
    mounted() {
        const isToggle = sessionStorage.getItem('toggle')
        this.toggle = JSON.parse(isToggle)
    },
    watch: {
        user(newVal, oldVal) {
            if (newVal && oldVal && newVal.id !== oldVal.id) this.init()
        },
    },
    created() {
        this.init()
    },
    methods: {
        async init() {
            this.chatUser = await this.$store.dispatch('loadUserDetail', { userId: this.user.id })
            this.chatUser.user_personal_stories = this.user.user_personal_story
            this.premiumRemain()
        },
        // toggleExpand() {
        //     this.expanded = !this.expanded ? 'expanded' : null
        // },
        async onClickChatUserImage() {
            const photos = await this.$store.dispatch('loadUserImages', this.user.id)
            if (!photos) return

            this.$modal.images({
                images: photos.map(p => p.url),
            })
        },
        async closeChat(event) {
            event.stopPropagation()
            const onConfirm = async () => {
                try {
                    // const resp = await chatService.delete(this.chat.id)
                    const resp = await productService.updatePremiumEnd({
                        product_id: this.premiumProduct.id,
                        chat_id: this.chat.id,
                    })
                    this.$toast.success(resp.msg)
                    // this.$store.commit('removeChat', this.chat.id)
                } catch (e) {
                    this.$toast.error(e.data)
                }
            }

            this.$modal
                .basic({
                    body: '프리미엄 주선을 종료합니다.',
                    buttons: [
                        {
                            label: 'CANCEL',
                            class: 'btn-default',
                        },
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
                .then(idx => {
                    if (idx === 1) {
                        onConfirm()
                    }
                })
        },
        async premiumExtend() {
            event.stopPropagation()
            const premiumProductId = this.premiumProduct.id
            const onConfirm = async () => {
                try {
                    const resp = await productService.update(premiumProductId)
                    this.$toast.success(resp.msg)
                    this.remainDay += 1
                    // this.$store.commit('removeChat', this.chat.id)
                } catch (e) {
                    this.$toast.error(e.data)
                }
            }

            this.$modal
                .basic({
                    body: '프리미엄을 1일 연장하시겠습니까?',
                    buttons: [
                        {
                            label: 'CANCEL',
                            class: 'btn-default',
                        },
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
                .then(idx => {
                    if (idx === 1) {
                        onConfirm()
                    }
                })
        },
        premiumRemain() {
            if (this.user.$$premium) {
                const premium = this.$options.filters.hasProduct(this.user, 'premium')
                this.remainTime = this.$moment() - this.$moment(premium.valid_until)
                this.remainDay = this.$moment(premium.valid_until).diff(this.$moment(), 'days')
            }
        },
        isPremiumSales() {
            // 상담사 에이전트 아이디를 하드코딩으로 박아놓음. 아직은 프로퍼티 추가하기 그래서..
            // 나중에 프로퍼티 추가해서 만들수도
            // 유저쪽 상담사 워딩도 하드코딩임. 수정 필요

            const premiumSalesAgent = [17743, 17744, 17747, 17751]
            if (this.agentId && premiumSalesAgent.find(i => i === this.agentId)) {
                return true
            } else {
                return false
            }
        },
        onClickToggle() {
            this.toggle = !this.toggle
            sessionStorage.setItem('toggle', this.toggle)
        },
    },
}
</script>

<style lang="scss" scoped>
.chat-user {
    width: fit-content;

    .toggle {
        position: absolute;
        top: 15px;
        left: 10px;
        background-color: $purple-primary;
        color: white;
        border-radius: 200px;
        padding: 10px;
        opacity: 0.8;
        z-index: 2;
        i {
            color: white;
        }
    }
}
.basic {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 12px;
    grid-column-gap: 8px;
    padding: 0 16px 16px;
    overflow-y: auto;

    height: fit-content;
    &.toggle-on {
        max-height: 90%;
        width: fit-content;
    }
    &.toggle-off {
        max-height: 640px;
        width: fit-content;
    }
}
.hr {
    width: 100%;
    height: 1px;
    margin: 24px 0;
    background: $grey-02;
}
.privacy-status {
    width: 80px;
    height: 22px;
    border-radius: 14px;
    background-color: $blue-facebook;
    margin-top: 6px;
    margin-left: 6px;
    padding: 3px 12px 4px 8px;
    align-items: center;

    .security {
        font-size: 14px;
        margin-right: 4px;
        color: white;
    }
    .privacy-remain {
        color: white;
        font-size: 10px;
        @include f-bold;
    }
}
.detail {
    &:last-child {
        .hr {
            display: none;
        }
    }

    .job,
    .school {
        font-size: 11px;
        margin-bottom: 4px;
    }

    &:not(:last-child) {
        margin-bottom: 24px;
    }

    .zmdi-case,
    .zmdi-account {
        font-size: $icon-size;
        display: block;
        text-align: left;
        margin-bottom: 12px;
    }

    .row {
        margin-bottom: 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: $black;

        .key {
            width: 100px;
            margin-right: 0px;
            color: $grey-05;
        }

        .badge {
            color: $purple-primary !important;

            .zmdi {
                margin-right: 4px;
            }
        }
    }

    .job-description {
        width: 246px;
        height: fit-content;
        font-size: 14px;

        margin-top: -12px;
        margin-bottom: 24px;
        margin-left: 22vw;
        padding: 12px;
        color: $grey-09;
        line-height: 22px;
        border-radius: 12px;
        border: solid 1px $grey-02;

        .btn-more {
            text-align: end;
            text-decoration: underline;
        }
    }
}
</style>
