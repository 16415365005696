<template>
    <div class="detail" :class="isInstantChat ? 'is-instant-chat' : ''">
        <h3 class="h3">
            <i class="material-icons">favorite</i>
            결혼계획
        </h3>
        <div class="item" v-for="item in marriageProfile" :key="item.key">
            <div class="key" v-html="item.key" />
            <div class="marriage_value" v-html="item.value" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'Marriage',
    props: ['profile'],
    data: () => ({
        isInstantChat: false,
    }),
    computed: {
        marriageProfile() {
            const items = [
                {
                    id: 1,
                    key: '결혼계획',
                    value: this.profile.marry_plan,
                },
                {
                    id: 2,
                    key: '자녀계획',
                    value: this.profile.child_plan,
                },
                {
                    id: 3,
                    key: '결혼 후 커리어',
                    value: this.profile.career_plan,
                },
            ]

            return items.filter(item => item.value)
        },
    },
}
</script>

<style scoped lang="scss">
.h3 {
    color: #646468;
}
.key {
    color: #646468;
}
.instant-chat {
    .h3 {
        color: #fff;
    }
    .key {
        color: #fff;
    }
    .marriage_value {
        color: #fff;
    }
}
.before-confirm {
    .h3 {
        color: #fff;
    }
}
</style>
