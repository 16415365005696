<template>
    <div class="spread-message">
        <div class="spread-message-container">
            <div class="section">
                <div class="spread-message-title" v-html="$translate(`SPREAD_FRIEND`)" />
                <div class="spread-message-body" v-html="$translate(`SPREAD_SUGGESTION_MESSAGE_BODY`)" />
            </div>
            <div class="content">
                <div class="spread-message-container">
                    <div class="container-top">
                        <div class="container-message">
                            <span class="container-title" v-html="$translate(`SPREAD_SUGGESTION_MALE`)"> </span>
                            <span class="container-ex" v-html="$translate(`SPREAD_SUGGESTION_MALE_EX`)"> </span>
                        </div>
                        <button
                            @click="spreadMessageChangedM ? updateSpreadMessage('male') : null"
                            class="btn"
                            :class="spreadMessageChangedM ? 'save' : 'btn-block save-off'"
                            v-html="$translate('DO_SAVE')"
                        />
                    </div>
                    <TextareaWithAutoTexts
                        @changedText="spreadMessageChangedM = true"
                        :placeholder="`PLACEHOLDER_MESSAGE`"
                        :hideAutotexts="false"
                        v-model="spreadMessageTextM"
                    />
                </div>
                <div class="spread-message-container">
                    <div class="container-top">
                        <div class="container-message">
                            <span class="container-title" v-html="$translate(`SPREAD_SUGGESTION_FEMALE`)"> </span>
                            <span class="container-ex" v-html="$translate(`SPREAD_SUGGESTION_FEMALE_EX`)"> </span>
                        </div>
                        <button
                            @click="spreadMessageChangedF ? updateSpreadMessage('female') : null"
                            class="btn"
                            :class="spreadMessageChangedF ? 'save' : 'btn-block save-off'"
                            v-html="$translate('DO_SAVE')"
                        />
                    </div>
                    <TextareaWithAutoTexts
                        @changedText="spreadMessageChangedF = true"
                        :placeholder="`PLACEHOLDER_MESSAGE`"
                        :hideAutotexts="false"
                        v-model="spreadMessageTextF"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SpreadMessagePc',
    data: () => ({
        spreadMessageChangedM: false,
        spreadMessageChangedF: false,
        spreadMessageTextM: '',
        spreadMessageTextF: '',
        spreadMessageIdM: null,
        spreadMessageIdF: null,
    }),
    mounted() {
        this.initSpreadMessage()
    },
    computed: {
        spreadMessageM() {
            const autoTexts = this.$store.getters.autoTextsAllCategory
            if (autoTexts && autoTexts.spread_message_male !== undefined) {
                return autoTexts.spread_message_male[0]
            } else {
                return { id: null, content: '' }
            }
        },
        spreadMessageF() {
            const autoTexts = this.$store.getters.autoTextsAllCategory
            if (autoTexts && autoTexts.spread_message_female !== undefined) {
                return autoTexts.spread_message_female[0]
            } else {
                return { id: null, content: '' }
            }
        },
    },
    methods: {
        async initSpreadMessage() {
            await this.$store.dispatch('loadAutoTextsAllCategory')
            this.spreadMessageTextM = this.spreadMessageM.content
            this.spreadMessageIdM = this.spreadMessageM.id
            this.spreadMessageTextF = this.spreadMessageF.content
            this.spreadMessageIdF = this.spreadMessageF.id
        },

        async updateSpreadMessage(gender) {
            this.$store.dispatch('updateSpreadMessage', {
                id: gender === 'male' ? this.spreadMessageIdM : this.spreadMessageIdF,
                spreadMessage: gender === 'male' ? this.spreadMessageTextM : this.spreadMessageTextF,
                gender,
            })
        },
    },
}
</script>
