<template>
    <div class="badge marry-plan" :style="{ background: bgTextColor.color }">
        {{ bgTextColor.text }}
    </div>
</template>

<script>
export default {
    name: 'MarryPlan',
    props: ['user'],
    computed: {
        marryPlan() {
            return ((this.user || {}).profile || {}).marry_plan
        },
        bgTextColor() {
            const marryPlan = this.marryPlan
            if (marryPlan === this.$translate('MARRY_1')) {
                return { class: 1, color: '#ff3d6b', text: '가급적 빨리' }
            } else if (marryPlan === this.$translate('MARRY_2')) {
                return { class: 1, color: '#ffaa00', text: '1~2년내 결혼' }
            } else if (marryPlan === this.$translate('MARRY_3')) {
                return { class: 1, color: '#3da2ff', text: '3~4년내 결혼' }
            } else if (marryPlan === this.$translate('MARRY_4')) {
                return { class: 1, color: '#8a74ff', text: '언제든지' }
            } else {
                return { class: 1, color: '#737377', text: '아직미정' }
            }
        },
    },
}
</script>

<style scoped lang="scss">
.marry-plan {
}
</style>
