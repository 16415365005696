<template>
    <div class="signup verification">
        <div class="header-bar flex-row">
            <div @click="$router.go(-1)" class="left flex-row items-center flex-wrap">
                <i class="zmdi zmdi-chevron-left flex-wrap c-white" />
            </div>
        </div>
        <div class="page-header">
            <div class="title">안전한 실명제 소개팅 앱</div>
        </div>
        <div class="body">
            <div class="thick-divider"></div>
            <div class="desc">
                신뢰가는 소개팅을 위해<br />
                <span>주선자도 본인인증</span>이 필요합니다.<br />
            </div>
        </div>
        <div class="bottom">
            <button class="btn btn-primary" @click="next">인증하고 시작하기</button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VerificationPage',
    watch: {
        '$store.getters.niceData': {
            handler(newVal) {
                this.finish(newVal)
            },
            deep: true,
        },
    },
    mounted() {
        this.$nativeBridge.postMessage({
            action: 'setBackgroundColor',
            value: '$grey-09',
        })
    },
    beforeDestroy() {
        this.$nativeBridge.postMessage({
            action: 'setBackgroundColor',
            value: '#FFFFFF',
        })
    },
    methods: {
        next() {
            // 한 번 인증 받아서 인증정보가 스토어에 남아있으면 그냥 진행 다시시도 (사실 이런 경우 자체가 생기면 안되긴 함)
            if (this.$store.getters.niceData) return this.finish(this.$store.getters.niceData)

            this.$nativeBridge.postMessage({ action: 'verification' })
        },
        finish(niceData) {
            if (this.checkNiceData(niceData)) {
                this.$store.commit('setPayloads', { signupData: { response_id: niceData.response_id } })
                this.$router.push({ name: 'ConsentPage' })
            }
        },
        checkNiceData(niceData) {
            const checkStatus = status => {
                let errMsg = ''
                if (status === 'too_young')
                    errMsg = '바닐라브릿지에서는 미성년자를 위한 서비스를 제공하고 있지 않습니다. 양해 부탁드립니다.'
                if (status === 'too_old')
                    errMsg =
                        '바닐라브릿지는 현재 20~39세의 회원을 대상으로 서비스를 운영중이며, 향후 서비스 범위 확장을 계획중입니다. 감사합니다.'
                if (status === 'duplicated') errMsg = '이미 가입된 핸드폰 번호입니다.'
                if (status === 'fail') errMsg = '본인 인증을 다시 시도해주세요.'

                if (errMsg) {
                    this.$toast.error(errMsg)
                    return false
                }
                return true
            }

            return checkStatus(niceData.status)
        },
    },
}
</script>
