<template>
    <div
        class="chatroom"
        :class="[
            { 'instant-chat': $store.getters.chat && $store.getters.chat.expire_at },
            { 'instant-mobile': $store.getters.isMobile },
        ]"
    >
        <div class="chatbody-box">
            <FloatingMemo />
            <ChatBody v-if="!$store.getters.isMobile" class="flex-fill" />
            <ChatBodyMobile v-else class="flex-fill" />
            <ChatInput v-if="!$store.getters.isMobile" class="flex-wrap" />
            <ChatInputMobile v-else class="flex-wrap" />
        </div>

        <FloatingComponents />
    </div>
</template>
<script>
import ChatBody from './ChatBody'
import ChatInput from './ChatInput'
import ChatBodyMobile from '../../mobile/chat/chatroom/ChatBodyMobile'
import ChatInputMobile from '../../mobile/chat/ChatInputMobile'
import FloatingComponents from '@/components/mobile/FloatingComponents'
import FloatingMemo from '@/components/common/FloatingMemo'

export default {
    components: {
        ChatBody,
        ChatBodyMobile,
        ChatInput,
        ChatInputMobile,
        FloatingComponents,
        FloatingMemo,
    },
    name: 'ChatroomPage',
    computed: {
        chat() {
            return this.$store.getters.chat
        },
        chatUser() {
            return (this.chat || {}).user || {}
        },
        introducingUser() {
            return this.$store.getters.chat.$$introducingUser
        },
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            if (!this.$store.getters.isMobile) return

            if (!this.chat) {
                this.$router.go(-1)
                return
            }

            this.updateUnreadCount()

            const dom = document.getElementsByClassName('chat-input-textarea')[0]
            if (dom && !this.$store.getters.isMobile) dom.focus()
        },
        updateUnreadCount() {
            this.$bus.$emit('updateUnread')
        },
    },
}
</script>

<style lang="scss" scoped>
.chatroom {
    display: flex;
}
.chatbody-box {
    width: calc(100% - 436px);
    min-width: 208px;
    height: 100%;
    display: flex;
    flex-direction: column;
}
</style>
