<template>
    <div class="chat-item m-r-10" @click="onClickChatItem">
        <div class="content flex-row center">
            <PhotoAndLastSeen
                class="flex-wrap m-r-12"
                :class="[genderClass]"
                :chat="chat"
                :profile="chat.user.photo_url"
                :userId="chat.user.id"
                :showBookmark="true"
            />
            <div class="flex-row p-t-4">
                <div class="info">
                    <div class="flex-row">
                        <div class="name lines-1 flex-wrap">{{ $nameOrNick(chat.user) }}</div>
                        <div v-if="chat.user.id" class="status flex-row items-center flex-wrap">
                            <div v-if="instantChat" class="source" v-html="`${instantSourceCnt}명 소개 중`" />
                            <template v-else>
                                <div
                                    v-if="chat.user.profile.status === 'created'"
                                    class="badge badge-brd normal m-r-4"
                                    v-html="'0: 본인인증 완료'"
                                />
                                <div
                                    v-else-if="chat.user.profile.status === 'complete_profile'"
                                    class="badge badge-brd recommended m-r-4"
                                    v-html="'1: 프로필 작성 완료'"
                                />
                                <div
                                    v-else-if="chat.user.profile.status === 'ongoing_verification'"
                                    class="badge badge-brd orange m-r-4"
                                    v-html="'3: 서류인증 업로드중'"
                                />
                                <div
                                    v-else-if="chat.user.profile.status === 'judging'"
                                    class="badge badge-brd normal m-r-4"
                                    v-html="'5: 서류인증 완료'"
                                />
                                <div
                                    v-else-if="chat.user.profile.status === 'judged'"
                                    class="badge badge-brd accent m-r-4"
                                    v-html="'7: 가입심사 통과'"
                                />
                                <div
                                    v-else-if="
                                        chat.user.profile.status === 'waitlist_age' ||
                                        chat.user.profile.status === 'waitlist_state' ||
                                        chat.user.profile.status === 'waitlist_marry'
                                    "
                                    class="badge badge-brd green m-r-4"
                                    v-html="'대기'"
                                />
                                <div
                                    v-else-if="chat.user.profile.status === 'failed'"
                                    class="badge badge-brd red m-r-4"
                                    v-html="'탈락'"
                                />
                                <!-- <div v-else class="badge badge-brd orange m-r-4" v-html="'신규'" /> -->
                            </template>
                            <LastSeen :timestamp="(chat.user || {}).last_sign_in_at" />
                            <IntroDay
                                v-if="(chat.user || {}).introducing_day"
                                class="m-l-4"
                                :introducingDay="(chat.user || {}).introducing_day"
                                :selected="true"
                                :canClick="false"
                            />
                        </div>
                    </div>
                    <div v-if="chat.user.id" class="age-region hor-separated c-text-dark">
                        <span>({{ chat.user.profile.birthday | asAge }}세</span>
                        <span>{{ chat.user.profile.region_name | noDetail }})</span>
                        <span v-if="profileStatus !== 'judged' && profileStatus !== 'confirmed'" class="icon">
                            <img v-if="isPass === 'pass'" src="@/assets/images/blue-circle.svg" alt="O" />
                            <img
                                v-if="isPass === 'judgment_required' || isPass === 'before_judgment'"
                                src="@/assets/images/green-triangle.svg"
                                alt="세모"
                            />
                            <img v-if="isPass === 'fail'" src="@/assets/images/red-x.svg" alt="X" />
                        </span>
                    </div>
                    <div class="last-message" :class="isAlimi ? 'lines-2' : 'lines-1'" v-html="lastMessage" />
                </div>
                <div class="right">
                    <div class="time-unread">
                        <div class="timestamp flex-wrap" :class="{ 'o-0': lastMessageAt === 'NONE' }">
                            {{ lastMessageAt }}
                        </div>
                    </div>
                    <div v-if="!instantChat && chat.likes_unsent !== 0" class="badge-like-count">
                        <span :class="{ 'o-0': chat.likes_count === undefined || chat.likes_count === 0 }">
                            N호감
                        </span>
                        {{ chat.likes_unsent }}
                    </div>
                    <div
                        class="badge badge-count"
                        :class="{ 'o-0': chat.unread === 0, instant: instantChat, premium: isPremiumChat }"
                    >
                        {{ chat.unread }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['chat'],
    data: () => ({
        contextmenu: null,
    }),
    // watch: {
    //     chat: {
    //         handler(newChat, oldChat) {
    //             this.init(newChat)
    //         },
    //     },
    // },
    methods: {
        async onClickChatItem() {
            const dom = document.getElementsByClassName('chat-input-textarea')[0]
            if (dom && dom.value) {
                this.$store.commit('saveTextareaInCurrentChat', dom.value)
            }

            await this.$store.dispatch('loadChat', {
                userId: this.chat.user.id,
            })
            if (this.isPremiumChat) await this.$store.dispatch('loadAutoTextsAllCategory')

            this.chat.likes_count = 0
        },
        transformToJsObject(str) {
            let newStr = str.replace(/:(\s*)([a-z0-9_]+)(\s*)=>/gi, '"$2":')
            newStr = newStr.replace(/:(\s*)'([^']*)'/g, ': "$2"')
            newStr = newStr.replace(/nil/g, 'null')
            try {
                return JSON.parse(newStr)
            } catch (e) {
                console.error(e)
                return {}
            }
        },
    },
    computed: {
        isAlimi() {
            return !this.chat.user.id
        },
        genderClass() {
            if (this.isAlimi) return

            return this.$options.filters.gender(this.chat.user.gender)
        },
        // newbie() {
        //     return this.$options.filters.isNewbie(this.chat.user)
        // },
        lastMessage() {
            const lastMessage = this.chat.last_message
            if (!lastMessage) return
            // console.log(lastMessage)

            switch (lastMessage.mtype) {
                case 'text':
                case 'premium-counsel':
                    return lastMessage.content
                case 'photo':
                    return this.$translate('PHOTO')
                case 'gift-info': {
                    const user = (this.$mustParse(lastMessage.content) || {}).user
                    return this.$nameOrNick(user) + '님이 ' + (this.$mustParse(lastMessage.content) || {}).msg
                }
                case 'profile-completed':
                case 'verification-uploaded-all':
                case 'verification-completed-all':
                case 'induce-noti':
                    return lastMessage.content
                case 'schedule-agent-arrangeable': {
                    const content = this.$mustParse(lastMessage.content)
                    if (content.change_type === 'change') {
                        return '약속 변경을 요청헀습니다'
                    } else {
                        return '약속 취소를 요청했습니다'
                    }
                }
                case 'schedule-agent-unarrangeable': {
                    const content = this.$mustParse(lastMessage.content)
                    if (content.change_type === 'change') {
                        return '약속 변경을 위해 유저끼리 조율중입니다'
                    } else {
                        return '유저가 임의로 약속을 취소했습니다'
                    }
                }
                case 'verification-unavailable': {
                    const contentObj = this.transformToJsObject(lastMessage.content)
                    const verificationType = contentObj.verification_type
                    if (verificationType === 'company') {
                        return '직장인증 도움 요청'
                    } else {
                        return '학력인증 도움 요청'
                    }
                }

                default:
                    return (this.$mustParse(lastMessage.content) || {}).msg
            }
        },
        lastMessageAt() {
            const result =
                this.$options.filters.formatDate((this.chat.last_message || {}).created_at, 'chatList') || 'NONE'
            return result
        },
        instantChat() {
            return this.chat.expire_at
        },
        instantSourceCnt() {
            return this.chat.suggest_count || 0
        },
        isPremiumChat() {
            return this.chat.user.$$premium
        },
        isPass() {
            return this.chat.user.profile.is_pass.toString()
        },
        profileStatus() {
            return this.chat.user.profile.status
        },
    },
}
</script>
