import Vue from 'vue'
import { store as $store } from '@/store'
import { $case } from '@/filters'

export const getIntroducingUserIdFromLocalStorage = chat => {
    const storedIntroducingUsers = window.localStorage.getItem('introducingUsers')
    if (!storedIntroducingUsers) return

    const users = JSON.parse(storedIntroducingUsers)
    if (!users || users.length === 0) return

    const introducingUserId = (users.find(p => p.c === chat.id) || {}).i
    return introducingUserId
}

export const updateSortInLocalStorage = chats => {
    const $$sortToStore = JSON.stringify(
        chats
            .filter(c => c.$$sort)
            .map(c => ({
                c: c.id,
                s: c.$$sort,
            }))
    )
    window.localStorage.setItem('sort', $$sortToStore)
}

export const updateFiltersInLocalStorage = chats => {
    const $$filtersToStore = JSON.stringify(
        chats
            .filter(c => c.$$filters)
            .map(c => ({
                c: c.id,
                f: c.$$filters,
            }))
    )

    const setFilters = async () => {
        try {
            await window.localStorage.setItem('filters', $$filtersToStore)
        } catch (e) {
            // If $$filtersToStore is too big, this happens.
        }
    }
    setFilters()
}

export const updateIntroducingUsersInLocalStorage = chats => {
    let users = []
    const stored = window.localStorage.getItem('introducingUsers')
    if (stored) {
        users = JSON.parse(stored)
    }

    chats
        .filter(c => c.$$introducingUser)
        .forEach(c => {
            const found = users.find(u => u.c === c.id)
            if (found) {
                found.i = c.$$introducingUser.id
            } else {
                users.push({
                    c: c.id,
                    i: c.$$introducingUser.id,
                })
            }
        })

    window.localStorage.setItem('introducingUsers', JSON.stringify(users))
}

export const removeIntroducingUserInLocalStorage = chat => {
    let users = []
    const stored = window.localStorage.getItem('introducingUsers')
    if (stored) {
        users = JSON.parse(stored)
    }

    const toRemove = users.map(p => p.c).indexOf(chat.id)
    users.splice(toRemove, 1)
    window.localStorage.setItem('introducingUsers', JSON.stringify(users))
}

export const updatePremiumAcceptedUsersInLocalStorage = chats => {
    let users = []
    const stored = window.localStorage.getItem('premiumAcceptedUsers')
    if (stored) {
        users = JSON.parse(stored)
    }

    chats
        .filter(c => c.$$premiumAcceptedUser)
        .forEach(c => {
            const found = users.find(u => u.c === c.id)
            if (found) {
                found.i = c.$$premiumAcceptedUser.id
            } else {
                users.push({
                    c: c.id,
                    i: c.$$premiumAcceptedUser.id,
                })
            }
        })

    window.localStorage.setItem('premiumAcceptedUsers', JSON.stringify(users))
}

export const removePremiumAcceptedUserInLocalStorage = chat => {
    let users = []
    const stored = window.localStorage.getItem('premiumAcceptedUsers')
    if (stored) {
        users = JSON.parse(stored)
    }

    const toRemove = users.map(p => p.c).indexOf(chat.id)
    users.splice(toRemove, 1)
    window.localStorage.setItem('premiumAcceptedUsers', JSON.stringify(users))
}

export const loadStoredSort = chats => {
    let sort
    const storedSort = window.localStorage.getItem('sort')
    if (storedSort) {
        sort = JSON.parse(storedSort)
    }
    if (!sort || sort.length === 0) return
    ;(chats || []).forEach(c => {
        const found = sort.find(s => s.c === c.id)
        if (found) {
            c.$$sort = found.s
        }
    })
}

export const loadStoredFilters = chats => {
    const isFiltersLatestVersion = (filters, chat) => {
        // chat.user 안에 $$premium 이 있는데도 chat.user.$$premium == undefined가 뜬다 왜지.
        const defaultFilters = getDefaultFilters(chat.user.gender, chat.user.$$premium ? true : false)

        if (Object.keys(defaultFilters).length !== Object.keys(filters).length) return false

        if (Object.keys(defaultFilters).some(key => filters[key] === undefined)) return false

        if (
            Object.keys(defaultFilters).some(
                key =>
                    (Array.isArray(defaultFilters[key]) && !Array.isArray(filters[key])) ||
                    (!Array.isArray(defaultFilters[key]) && Array.isArray(filters[key])) ||
                    (Array.isArray(defaultFilters[key]) &&
                        Array.isArray(filters[key]) &&
                        defaultFilters[key].length !== filters[key].length)
            )
        ) {
            return false
        }

        return Object.keys(defaultFilters)
            .filter(key => Array.isArray(defaultFilters[key]))
            .every(key => defaultFilters[key].length === filters[key].length)
    }

    let filters
    const storedFilters = window.localStorage.getItem('filters')
    if (storedFilters) {
        filters = JSON.parse(storedFilters)
    }
    if (!filters || filters.length === 0) return
    ;(chats || []).forEach(c => {
        const found = (filters || []).find(f => f.c === c.id)
        if (found && isFiltersLatestVersion(found.f, c)) {
            Vue.set(c, '$$filters', found.f)
        }
    })
    updateFiltersInLocalStorage(chats)
}

export const canSkipApiCall = (state, data) => {
    if (!state.lastApiCall) {
        Vue.set(state, 'lastApiCall', {})
    }

    const initLastApiCallTime = () => Vue.set(state.lastApiCall, data, Vue.prototype.$moment())
    // If it's never called, can't skip API call.
    if (!state.lastApiCall[data]) {
        initLastApiCallTime()
        return false
    }

    const diff = Vue.prototype.$moment().diff(state.lastApiCall[data], 'seconds')
    if (diff <= 60 * 30) {
        return true
    }
    initLastApiCallTime()
    return false
}

export const setAgeHeightFiltersByStyles = (chat, filters) => {
    if (!chat || !filters) return

    const style = chat.user.style || {}
    // let minHeight = 140
    // let maxHeight = 199
    if (Object.keys(style).length === 0) return

    // if (chat.user.gender === 0) {
    //     if (style.height_type === 1) {
    //         minHeight = 163
    //     } else if (style.height_type === 2) {
    //         maxHeight = 162
    //     }
    // } else {
    //     if (style.height_type === 1) {
    //         minHeight = chat.user.profile.height
    //     } else if (style.height_type === 2) {
    //         minHeight = 172
    //     } else if (style.height_type === 3) {
    //         minHeight = 175
    //     }
    // }

    Vue.set(filters, 'minHeight', style.min_height)
    Vue.set(filters, 'maxHeight', style.max_height)
    Vue.set(filters, 'minAge', style.min_age)
    Vue.set(filters, 'maxAge', style.max_age)
    Vue.set(filters, 'houseDistance', style.house_distance)
    Vue.set(filters, 'jobDistance', style.job_distance)
    // const smoking = filters.smoking

    // if (style.smoking_type === 0) {
    //     smoking[0].selected = true
    //     smoking[1].selected = true
    // }
    // if (style.smoking_type === 1) {
    //     smoking[0].selected = false
    //     smoking[1].selected = true
    // }
    // if (style.smoking_type === 2) {
    //     smoking[0].selected = true
    //     smoking[1].selected = false
    // }

    if (style.religion_ids) {
        if (style.religion_ids === '[0]') {
            // 전부 괜찮아요 일떄
        } else {
            const religion = filters.religion
            religion.map(r => {
                r.selected = false
            })
            const religionIds = style.religion_ids.replace('[', '').replace(']', '').split(',')
            religionIds.map(r => {
                religion[Number(r) - 1].selected = true
            })
            // // console.log('239 : ' , filters, style, religionIds, religion)
        }
    }
    // if (style.university_grades) {
    //     if (style.university_grades === '[0]') {
    //         // 전부 괜찮아요 일떄
    //     } else {
    //         const universityGradeNumber = style.university_grades.replace('[', '').replace(']', '').split(',')
    //         const universityGrades = filters.universityGrades
    //         universityGrades.map(ug => {
    //             if (
    //                 universityGradeNumber.some(ugn => {
    //                     return Number(ugn) === ug.value
    //                 })
    //             ) {
    //                 ug.selected = true
    //             } else {
    //                 ug.selected = false
    //             }
    //             return ug
    //         })
    //     }
    // }
    if (style.job_detail_type) {
        if (style.job_detail_type === '[0]') {
            // 전부 괜찮아요 일떄
            return
        } else {
            const jobDetailTypeNumber = style.job_detail_type.replace('[', '').replace(']', '').split(',')
            const jobDetailType = filters.jobDetailType
            jobDetailType.map(j => {
                if (
                    jobDetailTypeNumber.some(jn => {
                        return Number(jn) === j.value
                    })
                ) {
                    j.selected = true
                } else {
                    j.selected = false
                }
                return j
            })
        }
    }
    // if (style.force_type) {
    //     const forceTypeIds = style.force_type.replace('[', '').replace(']', '').split(',')
    //     const forceType= filters.forceType
    //     console.log('forceType',forceType)
    //     console.log('forcetype', style.force_type)
    //     console.log('forceTypeIds',forceTypeIds)
    //     forceType.map(f => {
    //         if (
    //             forceTypeIds.some(fn => {
    //                 return Number(fn) === f.value
    //             })
    //         ) {
    //             f.selected = true
    //         } else {
    //             f.selected = false
    //         }
    //         return f
    //     })
    // }
    if (style.wealth_type) {
        const incomeType = filters.income
        const assetType = filters.asset
        const wealthTypeIds = style.wealth_type.replace('[', '').replace(']', '').split(',')
        incomeType.map(it => {
            if (
                wealthTypeIds.some(wn => {
                    return Number(wn) === it.value && Number(wn) < 10
                })
            ) {
                it.selected = true
            } else {
                it.selected = false
            }
            return it
        })
        assetType.map(at => {
            if (
                wealthTypeIds.some(wn => {
                    return Number(wn) === at.value && Number(wn) >= 10
                })
            ) {
                at.selected = true
            } else {
                at.selected = false
            }
            return at
        })
    }
    if (style.school_type) {
        const schoolType = filters.schoolType
        const schoolTypeIds = style.school_type.replace('[', '').replace(']', '').split(',')
        schoolType.map(s => {
            if (
                schoolTypeIds.some(sn => {
                    return Number(sn) === s.value
                })
            ) {
                s.selected = true
            } else {
                s.selected = false
            }
            return s
        })
    }
    if (style.marry_plan_type) {
        const marryPlanType = filters.marryPlanType
        const marryPlanTypeIds = style.marry_plan_type.replace('[', '').replace(']', '').split(',')
        marryPlanType.map(m => {
            if (
                marryPlanTypeIds.some(mn => {
                    return Number(mn) === m.value
                })
            ) {
                m.selected = true
            } else {
                m.selected = false
            }
            return m
        })
    }
    if (style.spec_grade_type) {
        const specGradeType = filters.specGradeType
        const specGradeTypeIds = style.spec_grade_type.replace('[', '').replace(']', '').split(',')
        specGradeType.map(m => {
            if (
                specGradeTypeIds.some(mn => {
                    return Number(mn) === m.value
                })
            ) {
                m.selected = true
            } else {
                m.selected = false
            }
            return m
        })
    }
    if (style.look_grade_type) {
        const lookGradeType = filters.lookGradeType
        const lookGradeTypeIds = style.look_grade_type.replace('[', '').replace(']', '').split(',')
        lookGradeType.map(m => {
            if (
                lookGradeTypeIds.some(mn => {
                    return Number(mn) === m.value
                })
            ) {
                m.selected = true
            } else {
                m.selected = false
            }
            return m
        })
    }
    if (style.total_grade_type) {
        const totalGradeType = filters.totalGradeType
        const totalGradeTypeIds = style.total_grade_type.replace('[', '').replace(']', '').split(',')
        totalGradeType.map(m => {
            if (
                totalGradeTypeIds.some(mn => {
                    return Number(mn) === m.value
                })
            ) {
                m.selected = true
            } else {
                m.selected = false
            }
            return m
        })
    }

    // const suggestionMethodType = filters.suggestionMethodType

    // if (style.suggestion_method_type === 0) {
    //     suggestionMethodType[0].selected = true
    //     suggestionMethodType[1].selected = false
    //     suggestionMethodType[2].selected = false
    // }
    // if (style.suggestion_method_type === 1) {
    //     suggestionMethodType[0].selected = false
    //     suggestionMethodType[1].selected = true
    //     suggestionMethodType[2].selected = false
    // }
    // if (style.suggestion_method_type === 2) {
    //     suggestionMethodType[0].selected = false
    //     suggestionMethodType[1].selected = false
    //     suggestionMethodType[2].selected = true
    // }

    // const priority = filters.priority

    // if (style.priority === 1) {
    //     priority[0].selected = true
    //     priority[1].selected = false
    //     priority[2].selected = false
    //     priority[3].selected = false
    //     priority[4].selected = false
    //     priority[5].selected = false
    // }
    // if (style.priority === 2) {
    //     priority[0].selected = false
    //     priority[1].selected = true
    //     priority[2].selected = false
    //     priority[3].selected = false
    //     priority[4].selected = false
    //     priority[5].selected = false
    // }
    // if (style.priority === 3) {
    //     priority[0].selected = false
    //     priority[1].selected = false
    //     priority[2].selected = true
    //     priority[3].selected = false
    //     priority[4].selected = false
    //     priority[5].selected = false
    // }
    // if (style.priority === 4) {
    //     priority[0].selected = false
    //     priority[1].selected = false
    //     priority[2].selected = false
    //     priority[3].selected = true
    //     priority[4].selected = false
    //     priority[5].selected = false
    // }
    // if (style.priority === 5) {
    //     priority[0].selected = false
    //     priority[1].selected = false
    //     priority[2].selected = false
    //     priority[3].selected = false
    //     priority[4].selected = true
    //     priority[5].selected = false
    // }
    // if (style.priority === 6) {
    //     priority[0].selected = false
    //     priority[1].selected = false
    //     priority[2].selected = false
    //     priority[3].selected = false
    //     priority[4].selected = false
    //     priority[5].selected = true
    // }

    // Vue.set(filters, 'smoking', smoking)
}

export const getVisitFilters = () => window.localStorage.getItem('visitFilter')

export const setVisitFilters = () => window.localStorage.setItem('visitFilter', true)

export const getStoredDefaultFilters = () => window.localStorage.getItem('defaultFilter')

export const getSettingDefaultFilters = (fromSetting, isPremium) => {
    const storedFilter = getStoredDefaultFilters()
    const defaultFilter = {
        basic: [
            {
                name: '선호이성상',
                value: 'preferences',
                selected: !fromSetting,
            },
            {
                name: '이미 소개 X',
                value: 'neverIntroduced',
                selected: !fromSetting,
            },
            {
                name: '20km 이내',
                value: 'closeUser',
                selected: false,
            },
        ],
        lastSign: isPremium
            ? [
                  {
                      name: '전체',
                      value: 0,
                      selected: true,
                  },
                  {
                      name: '1일 이내',
                      value: 1,
                      selected: false,
                  },
                  {
                      name: '2일 이내',
                      value: 2,
                      selected: false,
                  },
                  {
                      name: '7일 이내',
                      value: 7,
                      selected: false,
                  },
                  {
                      name: '7일 초과',
                      value: -7,
                      selected: false,
                  },
              ]
            : [
                  {
                      name: '전체',
                      value: 0,
                      selected: true,
                  },
                  {
                      name: '1일 이내',
                      value: 1,
                      selected: false,
                  },
                  {
                      name: '2일 이내',
                      value: 2,
                      selected: false,
                  },
                  {
                      name: '7일 이내',
                      value: 7,
                      selected: false,
                  },
              ],
    }
    const isFiltersLatestVersion = (storedFilter, defaultFilter) => {
        if (Object.keys(storedFilter).length !== Object.keys(defaultFilter).length) return false

        if (Object.keys(storedFilter).some(key => defaultFilter[key] === undefined)) return false

        if (
            Object.keys(storedFilter).some(
                key =>
                    (Array.isArray(storedFilter[key]) && !Array.isArray(defaultFilter[key])) ||
                    (!Array.isArray(storedFilter[key]) && Array.isArray(defaultFilter[key])) ||
                    (Array.isArray(storedFilter[key]) &&
                        Array.isArray(defaultFilter[key]) &&
                        storedFilter[key].length !== defaultFilter[key].length)
            )
        ) {
            return false
        }

        return Object.keys(storedFilter)
            .filter(key => Array.isArray(storedFilter[key]))
            .every(key => storedFilter[key].length === defaultFilter[key].length)
    }
    if (storedFilter) {
        const storedFilterJson = JSON.parse(storedFilter)
        if (!isFiltersLatestVersion(storedFilterJson, defaultFilter)) return defaultFilter
        return storedFilterJson
    } else return defaultFilter
}

export const getDefaultFilters = (genderOfCurrentChat, isPremium) =>
    Object.assign(getSettingDefaultFilters(false, isPremium), {
        minAge: 20,
        maxAge: 50,
        minHeight: 140,
        maxHeight: 199,
        houseDistance: 50,
        jobDistance: 50,
        searchByDistance: true,
        nickname: '',
        type: '',
        // suggestionMethodType: [
        //     {
        //         name: '둘다 추천',
        //         value: 0,
        //         selected: false,
        //     },
        //     {
        //         name: '매칭 가능성 위주로 추천',
        //         value: 1,
        //         selected: false,
        //     },
        //     {
        //         name: '인기 회원만 추천',
        //         value: 2,
        //         selected: false,
        //     },
        // ],
        // preferences: [
        //     {
        //         name: 'on',
        //         value: true,
        //         selected: true,
        //     },
        //     {
        //         name: 'off',
        //         value: false,
        //         selected: false,
        //     },
        // ],
        // no_ons_campaign: [
        //     {
        //         name: '전체',
        //         value: null,
        //         selected: true,
        //     },
        //     {
        //         name: '참여중',
        //         value: 1,
        //         selected: false,
        //     },
        // ],
        neverIntroduced: [
            {
                name: '님에게 이미 소개한 상대',
                value: 'send',
                selected: true,
            },
        ],
        // priority: [
        //     {
        //         name: '1 (외모)',
        //         value: 1,
        //         selected: false,
        //     },
        //     {
        //         name: '2',
        //         value: 2,
        //         selected: false,
        //     },
        //     {
        //         name: '3',
        //         value: 3,
        //         selected: false,
        //     },
        //     {
        //         name: '4',
        //         value: 4,
        //         selected: false,
        //     },
        //     {
        //         name: '5',
        //         value: 5,
        //         selected: false,
        //     },
        //     {
        //         name: '6 (스팩)',
        //         value: 6,
        //         selected: false,
        //     },
        // ],
        // appearanceGrade: [
        //     {
        //         name: '1',
        //         value: [1],
        //         selected: true,
        //     },
        //     {
        //         name: '10',
        //         value: [10],
        //         selected: true,
        //     },
        //     {
        //         name: '30',
        //         value: [30],
        //         selected: true,
        //     },
        //     {
        //         name: '50',
        //         value: [50],
        //         selected: true,
        //     },
        //     {
        //         name: '70',
        //         value: [70],
        //         selected: true,
        //     },
        //     {
        //         name: '100',
        //         value: [100],
        //         selected: true,
        //     },
        // ],
        income: [
            {
                name: '소득 관계 없음',
                value: 0,
                selected: true,
            },
            {
                name: '~ 2천만원대',
                value: 1,
                selected: true,
            },
            {
                name: '3 ~ 4천만원대',
                value: 2,
                selected: true,
            },
            {
                name: '5 ~ 7천만원대',
                value: 3,
                selected: true,
            },
            {
                name: '8 ~ 9천만원대',
                value: 4,
                selected: true,
            },
            {
                name: '1억원대',
                value: 5,
                selected: true,
            },
            {
                name: '2억원 이상',
                value: 6,
                selected: true,
            },
        ],
        asset: [
            {
                name: '자산 관계 없음',
                value: 10,
                selected: true,
            },
            {
                name: '~ 5천만원',
                value: 11,
                selected: true,
            },
            {
                name: '5천만원 ~ 1억원',
                value: 12,
                selected: true,
            },
            {
                name: '1 ~ 2억원대',
                value: 13,
                selected: true,
            },
            {
                name: '3 ~ 4억원대',
                value: 14,
                selected: true,
            },
            {
                name: '5 ~ 9억원대',
                value: 15,
                selected: true,
            },
            {
                name: '10억 이상',
                value: 16,
                selected: true,
            },
        ],
        // familyAsset: [
        //     {
        //         name: '1억원 이하',
        //         value: [1],
        //         selected: true,
        //     },
        //     {
        //         name: '1 ~ 4억원',
        //         value: [2],
        //         selected: true,
        //     },
        //     {
        //         name: '4 ~ 7억원',
        //         value: [3],
        //         selected: true,
        //     },
        //     {
        //         name: '7 ~ 10억원',
        //         value: [4],
        //         selected: true,
        //     },
        //     {
        //         name: '10 ~ 20억원',
        //         value: [5],
        //         selected: true,
        //     },
        //     {
        //         name: '20 ~ 30억원',
        //         value: [6],
        //         selected: true,
        //     },
        //     {
        //         name: '30 ~ 40억원',
        //         value: [7],
        //         selected: true,
        //     },
        //     {
        //         name: '40 ~ 50억원',
        //         value: [8],
        //         selected: true,
        //     },
        //     {
        //         name: '50 ~100억원',
        //         value: [9],
        //         selected: true,
        //     },
        //     {
        //         name: '100 ~ 200억원',
        //         value: [10],
        //         selected: true,
        //     },
        //     {
        //         name: '200 ~ 300억원',
        //         value: [11],
        //         selected: true,
        //     },
        //     {
        //         name: '300 ~ 400억원',
        //         value: [12],
        //         selected: true,
        //     },
        //     {
        //         name: '400 ~ 500억원',
        //         value: [13],
        //         selected: true,
        //     },
        //     {
        //         name: '500억 이상',
        //         value: [14],
        //         selected: true,
        //     },
        // ],
        // forceType: [
        //     {
        //         name: '나이',
        //         value: 1,
        //         selected: true,
        //     },
        //     {
        //         name: '키',
        //         value: 2,
        //         selected: true,
        //     },
        //     {
        //         name: '지역',
        //         value: 3,
        //         selected: true,
        //     },
        //     {
        //         name: '커리어',
        //         value: 4,
        //         selected: true,
        //     },
        //     {
        //         name: '학력',
        //         value: 5,
        //         selected: true,
        //     },
        //     {
        //         name: '경제력',
        //         value: 6,
        //         selected: true,
        //     },
        //     {
        //         name: '종교',
        //         value: 7,
        //         selected: true,
        //     },
        //     {
        //         name: '결혼계획',
        //         value: 8,
        //         selected: true,
        //     },
        // ],
        // interest: [
        //     {
        //         name: 'on',
        //         value: true,
        //         selected: false,
        //     },
        //     {
        //         name: 'off',
        //         value: false,
        //         selected: true,
        //     },
        // ],
        // targetHeightType:
        //     genderOfCurrentChat === 0
        //         ? [
        //               { name: '안 봄', value: [0], selected: true },
        //               { name: '나보다 큰', value: [1], selected: true },
        //               { name: '평균', value: [2], selected: true },
        //               { name: '큰 키', value: [3], selected: true },
        //           ]
        //         : [
        //               { name: '안 봄', value: [0], selected: true },
        //               { name: '작은 키', value: [2], selected: true },
        //               { name: '큰 키', value: [1], selected: true },
        //           ],
        // minTargetHeight: 140,
        // maxTargetHeight: 199,
        // targetFriendType: [
        //     {
        //         name: '안 봄',
        //         value: [0],
        //         selected: true,
        //     },
        //     {
        //         name: '평균 이상',
        //         value: [1],
        //         selected: true,
        //     },
        //     {
        //         name: '눈 높음',
        //         value: [2],
        //         selected: true,
        //     },
        // ],
        // targetSpecType: [
        //     {
        //         name: '안 봄',
        //         value: [0],
        //         selected: true,
        //     },
        //     {
        //         name: '비슷한',
        //         value: [1, 2],
        //         selected: true,
        //     },
        //     {
        //         name: '더 좋은',
        //         value: [3],
        //         selected: true,
        //     },
        // ],
        // targetSmokingType: [
        //     {
        //         name: '상관 없음',
        //         value: [0],
        //         selected: true,
        //     },
        //     {
        //         name: '비흡연자 선호',
        //         value: [1],
        //         selected: true,
        //     },
        //     {
        //         name: '흡연자 선호',
        //         value: [2],
        //         selected: true,
        //     },
        // ],
        // smoking: [
        //     {
        //         name: '흡연',
        //         value: true,
        //         selected: true,
        //     },
        //     {
        //         name: '비흡연',
        //         value: false,
        //         selected: true,
        //     },
        // ],
        // car: [
        //     {
        //         name: '보유',
        //         value: true,
        //         selected: true,
        //     },
        //     {
        //         name: '미보유',
        //         value: false,
        //         selected: true,
        //     },
        // ],
        // pet: [
        //     {
        //         name: '키움',
        //         value: true,
        //         selected: true,
        //     },
        //     {
        //         name: '키우지 않음',
        //         value: false,
        //         selected: true,
        //     },
        // ],
        // isStudent: [
        //     {
        //         name: '대학생',
        //         value: true,
        //         selected: true,
        //     },
        //     {
        //         name: '일반인(직장인)',
        //         value: false,
        //         selected: true,
        //     },
        // ],
        religion: $store.getters.religions.map(r => ({
            name: r.name,
            value: r.id,
            selected: true,
        })),
        // kind: $store.getters.kinds
        //     .filter(k => k.gender !== genderOfCurrentChat)
        //     .map(p => ({
        //         name: p.name,
        //         value: p.id,
        //         selected: true,
        //     })),
        // shape: $store.getters.shapes
        //     .filter(s => s.gender !== genderOfCurrentChat)
        //     .map(p => ({
        //         name: p.name,
        //         value: p.id,
        //         selected: true,
        //     })),
        // mbti: $store.getters.mbti.map(p => ({
        //     name: p.name,
        //     value: p.id,
        //     selected: true,
        // })),
        // jobDetailType: $store.getters.jobCategories.map(j => ({
        //     name: j.name,
        //     value: j.id,
        //     selected: true,
        // })),
        jobDetailType: [
            // {
            //     name: '모두 괜찮음',
            //     value: [0],
            //     selected: true,
            // },
            {
                name: '무직/취업준비/아르바이트',
                value: 1,
                selected: true,
            },
            {
                name: '대학원생(석박사)/대학생',
                value: 2,
                selected: true,
            },
            {
                name: '프리랜서',
                value: 3,
                selected: true,
            },
            {
                name: '사업가(개인사업/자영업)',
                value: 4,
                selected: true,
            },
            {
                name: '서비스직/영업직/판매직/현장직/생산직',
                value: 5,
                selected: true,
            },
            {
                name: '병원 (종합병원,대학병원,개인병원) ',
                value: 6,
                selected: true,
            },
            {
                name: '연구직/기술직/교육직/의료직',
                value: 7,
                selected: true,
            },
            {
                name: '중소기업',
                value: 8,
                selected: true,
            },
            {
                name: '중견기업',
                value: 9,
                selected: true,
            },
            {
                name: '대기업/외국계 기업/금융기관',
                value: 10,
                selected: true,
            },
            {
                name: '스타트업',
                value: 11,
                selected: true,
            },
            {
                name: '공무원/공기업',
                value: 12,
                selected: true,
            },
            {
                name: '전문직/CEO',
                value: 13,
                selected: true,
            },
        ],
        // school: $store.getters.schools.map(s => ({
        //     name: s.name,
        //     value: s.id,
        //     selected: true,
        // })),
        // schoolType: [
        //     ...$store.getters.schoolTypes.map(s => ({
        //         name: s.name,
        //         value: s.id,
        //         selected: true,
        //     })),
        //     {
        //         name: '명문대', // constants 에는 없지만, 기획적으로 추가된 필터.
        //         value: 17,
        //         selected: true,
        //     },
        // ],
        schoolType: [
            // {
            //     name: '전부 괜찮아요',
            //     value: 0,
            //     selected: true,
            // },
            {
                name: '고등학교 졸업',
                value: 1,
                selected: true,
            },
            {
                name: '전문대',
                value: 2,
                selected: true,
            },
            {
                name: '4년제 대학교',
                value: 3,
                selected: true,
            },
            {
                name: '인서울 / 지방거점국립대',
                value: 4,
                selected: true,
            },
            {
                name: '최고 명문대 (SKY / 포카 / 아이비리그 등)',
                value: 5,
                selected: true,
            },
            {
                name: '명문대 (서성한 / 과학기술원 등)',
                value: 6,
                selected: true,
            },
            {
                name: '해외대',
                value: 7,
                selected: true,
            },
        ],
        marryPlanType: [
            // {
            //     name: '모두 괜찮음',
            //     value: 0,
            //     selected: true,
            // },
            {
                name: '가급적 빨리하고 싶음',
                value: 1,
                selected: true,
            },
            {
                name: '언제든 가능함',
                value: 2,
                selected: true,
            },
            {
                name: '1~2년 정도 연애 후 결혼',
                value: 3,
                selected: true,
            },
            {
                name: '3~4년 정도 연애 후 결혼',
                value: 4,
                selected: true,
            },
            {
                name: '결혼 시기는 여유롭게 생각하고 있음',
                value: 5,
                selected: true,
            },
        ],
        meetingReviewType: [
            {
                name: '애프터 많이',
                value: 'after',
                selected: true,
            },
            {
                name: '평점 좋은',
                value: 'feedback',
                selected: true,
            },
            {
                name: '진정성 있는',
                value: 'sincerity',
                selected: true,
            },
            {
                name: '매너 있는',
                value: 'manner',
                selected: true,
            },
            {
                name: '실물 괜찮은',
                value: 'look',
                selected: true,
            },
        ],
        reviewKeywordType_f: [
            // 남성 키워드 - 여성만 노출
            {
                name: '훈훈해요',
                value: 1,
                selected: true,
            },
            {
                name: '스타일이 좋아요',
                value: 2,
                selected: true,
            },
            {
                name: '비율이 좋아요',
                value: 3,
                selected: true,
            },
            {
                name: '동안이에요',
                value: 4,
                selected: true,
            },
            {
                name: '피부가 좋아요',
                value: 5,
                selected: true,
            },
            {
                name: '웃는게 예뻐요',
                value: 6,
                selected: true,
            },
            {
                name: '선한 인상',
                value: 7,
                selected: true,
            },
            {
                name: '귀여운 인상',
                value: 8,
                selected: true,
            },
            {
                name: '남자다운 인상',
                value: 9,
                selected: true,
            },
            {
                name: '넓은 어깨',
                value: 10,
                selected: true,
            },
            {
                name: '탄탄한 체형',
                value: 11,
                selected: true,
            },
            {
                name: '자기 관리를 잘 했어요',
                value: 12,
                selected: true,
            },
            {
                name: '배려심있어요',
                value: 13,
                selected: true,
            },
            {
                name: '다정해요',
                value: 14,
                selected: true,
            },
            {
                name: '재미있어요',
                value: 15,
                selected: true,
            },
            {
                name: '센스있어요',
                value: 16,
                selected: true,
            },
            {
                name: '스마트해요',
                value: 17,
                selected: true,
            },
            {
                name: '비전있어요',
                value: 18,
                selected: true,
            },
            {
                name: '마음이 따뜻해요',
                value: 19,
                selected: true,
            },
            {
                name: '둥글둥글한 성격',
                value: 20,
                selected: true,
            },
            {
                name: '긍정적이에요',
                value: 21,
                selected: true,
            },
            {
                name: '대화를 잘 이끌어요',
                value: 22,
                selected: true,
            },
            {
                name: '이야기를 잘 들어줘요',
                value: 23,
                selected: true,
            },
            {
                name: '자신감 있어요',
                value: 24,
                selected: true,
            },
            {
                name: '허세가 없어요',
                value: 25,
                selected: true,
            },
            {
                name: '에너지가 좋아요',
                value: 26,
                selected: true,
            },
        ],
        reviewKeywordType_m: [
            // 여성 키워드 - 남성만 노출
            {
                name: '예뻐요',
                value: 1,
                selected: true,
            },
            {
                name: '귀여워요',
                value: 2,
                selected: true,
            },
            {
                name: '섹시해요',
                value: 3,
                selected: true,
            },
            {
                name: '청순해요',
                value: 4,
                selected: true,
            },
            {
                name: '세련됐어요',
                value: 5,
                selected: true,
            },
            {
                name: '동안이에요',
                value: 6,
                selected: true,
            },
            {
                name: '여성스러워요',
                value: 7,
                selected: true,
            },
            {
                name: '웃는게 예뻐요',
                value: 8,
                selected: true,
            },
            {
                name: '얼굴이 작아요',
                value: 9,
                selected: true,
            },
            {
                name: '하얀 피부',
                value: 10,
                selected: true,
            },
            {
                name: '피부가 좋아요',
                value: 11,
                selected: true,
            },
            {
                name: '글래머에요',
                value: 12,
                selected: true,
            },
            {
                name: '슬림해요',
                value: 13,
                selected: true,
            },
            {
                name: '몸매가 좋아요',
                value: 14,
                selected: true,
            },
            {
                name: '밝은 성격',
                value: 15,
                selected: true,
            },
            {
                name: '마음이 따뜻해요',
                value: 16,
                selected: true,
            },
            {
                name: '이야기를 잘 들어줘요',
                value: 17,
                selected: true,
            },
            {
                name: '리액션이 좋아요',
                value: 18,
                selected: true,
            },
            {
                name: '배려심있어요',
                value: 19,
                selected: true,
            },
            {
                name: '긍정적이에요',
                value: 20,
                selected: true,
            },
            {
                name: '둥글둥글한 성격',
                value: 21,
                selected: true,
            },
        ],
        // universityGrades: $store.getters.universityGrades.map(u => ({
        //     name: u.name,
        //     value: u.id,
        //     selected: true,
        // })),
        // professional: $store.getters.professional.map(p => ({
        //     name: p.name,
        //     value: p.id,
        //     selected: false,
        // })),
        // practitioner: $store.getters.practitioner.map(p => ({
        //     name: p.name,
        //     value: p.id,
        //     selected: false,
        // })),
        // professional: [
        //     {
        //         name: '전문대만',
        //         value: '2',
        //         selected: true,
        //     },
        // ],
        // practitioner: [
        //     {
        //         name: '전문직만',
        //         value: 8,
        //         selected: true,
        //     },
        //     {
        //         name: '비전문대만',
        //         value: 0,
        //         selected: true,
        //     },
        // ],

        // province: $store.getters.provinces.map(p => ({
        //     name: p.name,
        //     value: p.id,
        //     selected: true,
        // })),
        specGradeType: [
            {
                name: '1',
                value: 1,
                selected: true,
            },
            {
                name: '2',
                value: 2,
                selected: true,
            },
            {
                name: '3',
                value: 3,
                selected: true,
            },
            {
                name: '4',
                value: 4,
                selected: true,
            },
            {
                name: '5',
                value: 5,
                selected: true,
            },
            {
                name: '6',
                value: 6,
                selected: true,
            },
            {
                name: '7',
                value: 7,
                selected: true,
            },
            {
                name: '8',
                value: 8,
                selected: true,
            },
        ],
        lookGradeType: [
            {
                name: '10',
                value: 1,
                selected: true,
            },
            {
                name: '30',
                value: 2,
                selected: true,
            },
            {
                name: '50',
                value: 3,
                selected: true,
            },
            {
                name: '80',
                value: 4,
                selected: true,
            },
            {
                name: '100',
                value: 5,
                selected: true,
            },
        ],
        totalGradeType: [
            {
                name: 'D',
                value: 1,
                selected: true,
            },
            {
                name: 'C',
                value: 2,
                selected: true,
            },
            {
                name: 'B',
                value: 3,
                selected: true,
            },
            {
                name: 'A',
                value: 4,
                selected: true,
            },
            {
                name: 'S',
                value: 5,
                selected: true,
            },
        ],
    })

// 만약 맨 위로 올려져야 하는 채팅방이 현재 필터의 조건에 부합하지 않으면 무시 (위로 끌올 X)
export const shouldThisChatBeIgnored = (chat, chatFilters) => {
    if (chatFilters.lastSign.selected && chat.user.status !== 0) return true

    if (chatFilters.type === 'MALE' && chat.user.gender === 1) return true

    if (chatFilters.type === 'FEMALE' && chat.user.gender === 0) return true

    if (chatFilters.friends.selected && !chatFilters.dDayFriends.selected && !chat.expire_at) return true

    if (!chatFilters.friends.selected && chatFilters.dDayFriends.selected && chat.expire_at) return true

    if (chatFilters.hasTicket.selected && !chat.user.hasTickets) return true

    // if (chatFilters.premium.selected && !chat.user.$$premium) return true

    return false
}

export const chatFiltersToServerFilterPayload = ({ chatSearchValue, chatFilters, lastChat, limit }) => {
    const params = { limit: limit || 10 }
    if (chatSearchValue) {
        params.query = chatSearchValue
    }

    if (lastChat) params.last_updated_at = lastChat.last_message.updated_at
    if (chatFilters.type === 'MALE') params.gender = 0
    if (chatFilters.type === 'FEMALE') params.gender = 1
    if (chatFilters.todayGetUsers.selected) params.today_get_users = true
    // if (chatFilters.managerConfirm.selected) params.manager_confirm = true
    // if (chatFilters.postpaid.selected) params.postpaid = true
    // if (chatFilters.prepaid.selected) params.prepaid = true
    // if (chatFilters.postpaid.selected && chatFilters.prepaid.selected) params.postpaid = null
    // params.prepaid = null
    if (chatFilters.exceptUnlimited.selected) params.except_unlimited = true
    if (chatFilters.unlimited.selected) params.unlimited = true
    if (chatFilters.allday.selected) params.allday = true
    if (chatFilters.monday.selected) params.monday = true
    if (chatFilters.tuesday.selected) params.tuesday = true
    if (chatFilters.wednesday.selected) params.wednesday = true
    if (chatFilters.thursday.selected) params.thursday = true
    if (chatFilters.friday.selected) params.friday = true
    if (chatFilters.lastSign.selected) params.last_sign = 7
    if (chatFilters.notToday.selected) params.not_today = true
    if (chatFilters.lastWeek.selected) params.last_sign = 14
    if (chatFilters.capitalArea.selected) params.capital_area = true
    if (chatFilters.friends.selected && !chatFilters.dDayFriends.selected) params.type = 'normal'
    if (chatFilters.dDayFriends.selected && !chatFilters.friends.selected) params.type = 'instant'
    if (chatFilters.hasTicket.selected) params.has_ticket = true
    if (chatFilters.isPremium.selected) params.is_premium = true
    if (chatFilters.harvest.selected) params.harvest = true
    if (chatFilters.isAi.selected) params.is_ai = true
    if (chatFilters.bothLike.selected) params.both_like = true
    if (chatFilters.waitDating.selected) params.wait_dating = true
    if (chatFilters.bookmark.selected) params.bookmark = true
    if (chatFilters.aiFilter) params.manager_id = chatFilters.aiFilter
    if (chatFilters.created.selected) params.created = true
    if (chatFilters.ongoing_verification.selected) params.ongoing_verification = true
    if (chatFilters.judging.selected) params.judging = true
    if (chatFilters.judged.selected) params.judged = true
    if (chatFilters.complete_profile.selected) params.complete_profile = true
    if (chatFilters.confirmed.selected) params.confirmed = true
    if (chatFilters.failed.selected) params.failed = true
    if (chatFilters.odd.selected) params.odd = true
    if (chatFilters.even.selected) params.even = true

    if (chatFilters.type === 'SEARCH_DETAIL') {
        if (chatFilters.detail.matchCount.value !== 0) {
            params.match_count = chatFilters.detail.matchCount.value
        }

        ;['photoCount', 'agentCount', 'refundRate', 'payRate'].forEach(key => {
            if (chatFilters.detail[key].value !== 0) {
                params[$case.toSnake(key)] = chatFilters.detail[key].value
            }
        })
    }
    if (chatFilters.managerConfirm.selected) params.manager_confirm = true

    return params
}

export const filtersToSuperSearchPayload = filters => {
    const params = {
        min_age: filters.minAge,
        max_age: filters.maxAge,
        min_height: filters.minHeight,
        max_height: filters.maxHeight,
        // min_target_height: filters.minTargetHeight,
        // max_target_height: filters.maxTargetHeight,
    }

    if (filters.searchByDistance) {
        params.house_distance = filters.houseDistance
        params.job_distance = filters.jobDistance
        // } else {
        //     params.province_ids = filters.province.filter(item => item.selected).map(item => item.value)
    }
    params.name = filters.name
    params.type = filters.type

    params.never_introduced = filters.neverIntroduced
        .filter(filter => filter.selected)
        .reduce((result, curObj) => (result || []).concat(curObj.value || []), [])

    // Multiselect O
    // if (filters.priority.some(filter => !filter.selected)) {
    //     params.priority = filters.priority
    //         .filter(filter => filter.selected)
    //         .reduce((result, curObj) => (result || []).concat(curObj.value || []), [])
    // }
    // if (filters.appearanceGrade.some(filter => !filter.selected)) {
    //     params.appearance_grade = filters.appearanceGrade
    //         .filter(filter => filter.selected)
    //         .reduce((result, curObj) => (result || []).concat(curObj.value || []), [])
    // }
    // if (filters.income.some(filter => !filter.selected)) {
    //     params.income = filters.income
    //         .filter(filter => filter.selected)
    //         .reduce((result, curObj) => (result || []).concat(curObj.value || []), [])
    // }
    // if (filters.asset.some(filter => !filter.selected)) {
    //     params.asset = filters.asset
    //         .filter(filter => filter.selected)
    //         .reduce((result, curObj) => (result || []).concat(curObj.value || []), [])
    // }
    if (filters.asset.some(filter => !filter.selected) || filters.income.some(filter => !filter.selected)) {
        params.wealth_type = [...filters.income, ...filters.asset]
            .filter(filter => filter.selected)
            .reduce((result, curObj) => {
                if (curObj.value !== undefined) {
                    return (result || []).concat(curObj.value)
                } else {
                    return (result || []).concat([])
                }
            }, [])
    }
    // if (filters.familyAsset.some(filter => !filter.selected)) {
    //     params.family_asset = filters.familyAsset
    //         .filter(filter => filter.selected)
    //         .reduce((result, curObj) => (result || []).concat(curObj.value || []), [])
    // }
    // if (filters.shape.some(filter => !filter.selected)) {
    //     params.shape = filters.shape
    //         .filter(filter => filter.selected)
    //         .reduce((result, curObj) => (result || []).concat(curObj.value || []), [])
    // }
    // if (filters.kind.some(filter => !filter.selected)) {
    //     params.kind = filters.kind
    //         .filter(filter => filter.selected)
    //         .reduce((result, curObj) => (result || []).concat(curObj.value || []), [])
    // }
    // if (filters.mbti.some(filter => !filter.selected)) {
    //     params.mbti = filters.mbti
    //         .filter(filter => filter.selected)
    //         .reduce((result, curObj) => (result || []).concat(curObj.value || []), [])
    // }
    if (filters.religion.some(filter => !filter.selected)) {
        params.religion_ids = filters.religion
            .filter(filter => filter.selected)
            .reduce((result, curObj) => (result || []).concat(curObj.value || []), [])
    }
    // if (filters.universityGrades.some(filter => !filter.selected)) {
    //     params.university_grades = filters.universityGrades
    //         .filter(filter => filter.selected)
    //         .reduce((result, curObj) => (result || []).concat(curObj.value || []), [])
    // }
    // if (filters.professional.some(filter => !filter.selected)) {
    // params.professional = filters.professional
    //     .filter(filter => filter.selected)
    //     .reduce((result, curObj) => (result || []).concat(curObj.value || []), [])
    // }

    // 전문직 필터

    // if (filters.practitioner.every(filter => filter.selected)) {
    //     params.practitioner = []
    // } else {
    //     params.practitioner = filters.practitioner
    //         .filter(filter => filter.selected)
    //         .reduce((result, curObj) => (result || []).concat(curObj.value || []), [])
    // }

    // const types = ['heightType', 'friendType', 'specType', 'smokingType']
    // types.forEach(key => {
    //     if (filters[`target${$case.toCapital(key)}`].some(filter => !filter.selected)) {
    //         params[$case.toSnake(key)] = filters[`target${$case.toCapital(key)}`]
    //             .filter(filter => filter.selected)
    //             .reduce((result, curObj) => (result || []).concat(curObj.value || []), [])
    //     }
    // })

    // // Multiselect X
    // const noMultiSelect = ['lastSign', 'interest', 'smoking', 'isStudent', 'car', 'pet', 'no_ons_campaign', 'suggestionMethodType']
    // noMultiSelect.forEach(key => {
    //     if (filters[key].filter(filter => filter.selected).length === 1) {
    //         params[Vue.options.filters.$case.toSnake(key)] = filters[key].find(filter => filter.selected).value
    //     }
    // })

    if (filters.reviewKeywordType_f.some(filter => !filter.selected)) {
        params.review_keyword_type = filters.reviewKeywordType_f
            .filter(filter => filter.selected)
            .reduce((result, curObj) => (result || []).concat(curObj.name || []), [])
    } else if (filters.reviewKeywordType_m.some(filter => !filter.selected)) {
        params.review_keyword_type = filters.reviewKeywordType_m
            .filter(filter => filter.selected)
            .reduce((result, curObj) => (result || []).concat(curObj.name || []), [])
    }

    if (filters.meetingReviewType.some(filter => !filter.selected)) {
        params.meeting_review_type = filters.meetingReviewType
            .filter(filter => filter.selected)
            .reduce((result, curObj) => (result || []).concat(curObj.value || []), [])
    }

    if (filters.sortType) {
        params.sort_type = filters.sortType
    }

    const detailFilterPairs = {
        jobDetailType: 'job_detail_type',
        schoolType: 'school_type',
        // forceType: 'force_type',
        marryPlanType: 'marry_plan_type',
        specGradeType: 'spec_grade_type',
        lookGradeType: 'look_grade_type',
        totalGradeType: 'total_grade_type',
    }

    Object.keys(detailFilterPairs).forEach(key => {
        const selectedValues = (filters[key] || []).filter(item => item.selected).map(item => item.value)

        if (selectedValues.length === (filters[key] || []).length) return

        params[detailFilterPairs[key]] = selectedValues
    })
    return params
}

export const setDefaultFiltersOnChat = chat => {
    const defaultFilters = getDefaultFilters(chat.user.gender, chat.user.$$premium ? true : false)
    Vue.set(chat, '$$filters', defaultFilters)
    setAgeHeightFiltersByStyles(chat, chat.$$filters)
}

export const applyingFilters = filters => {
    if (!filters) return

    const curChatUser = $store.getters.chat.user || {}
    const defaultFilters = getDefaultFilters(curChatUser.gender, curChatUser.$$premium ? true : false)
    const sliderKeys = ['minAge', 'maxAge', 'minHeight', 'maxHeight', 'houseDistance', 'jobDistance']
    if (sliderKeys.some(key => defaultFilters[key] !== filters[key])) {
        return true
    }

    return Object.keys(defaultFilters).some(key => {
        // Don't check sliderKeys here because it's already checked above
        if (sliderKeys.indexOf(key) !== -1) return

        if (JSON.stringify(defaultFilters[key]) !== JSON.stringify(filters[key])) {
            return true
        }
    })
}
