<template>
    <div class="chats-filter-detail" v-if="filters.length > 0">
        <div class="hr" />
        <div class="big-title">종합지표로 친구를 검색해보세요! 🕺</div>
        <div class="slider filter-item">
            <div class="header flex-row items-center flex-between">
                <div class="title flex-wrap m-r-16">
                    <i class="material-icons" v-html="filters[0].icon" />
                    <span v-html="$translate(filters[0].label)" />
                </div>
                <div>
                    <span class="range flex-wrap" v-html="$translate(filters[0].value)" />
                    <span class="unit" v-html="'명'" />
                </div>
            </div>
            <VueSlider v-model="filters[0].value" v-bind="sliderOptions" />
            <div class="min-max flex-row flex-between">
                <span>{{ sliderOptions.min }}</span>
                <span>{{ sliderOptions.max }}+</span>
            </div>
        </div>
        <div class="filter-item" :key="filter.label" v-for="filter in filters.slice(1, 5)">
            <div class="title flex-row">
                <i v-if="filter.icon" class="material-icons flex-wrap" v-html="filter.icon" />
                <img class="flex-wrap" v-if="filter.img" :src="filter.img" />
                <div class="flex-wrap" v-html="$translate(filter.label)" />
                <div class="desc" v-html="`(${$translate(`${filter.label}_DESC`)})`" />
            </div>
            <div class="buttons">
                <button
                    @click="onClickButton(filter, button.label)"
                    :class="[button.label.toLowerCase(), filter.value === button.label.toLowerCase() ? 'selected' : '']"
                    :key="button.label"
                    v-for="button in filter.buttons"
                >
                    <span v-html="$translate(button.label)" />
                    <div
                        class="color"
                        :class="{
                            'bg-success': button.label === 'GOOD',
                            'bg-warning': button.label === 'NORMAL',
                            'bg-danger': button.label === 'BAD',
                        }"
                    />
                </button>
            </div>
        </div>
        <div class="bottom-overlay">
            <button @click="onClickSearchChats" class="btn btn-primary btn-search" v-html="$translate('SEARCH')" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ChatsFilterDetail',
    data: () => ({
        filters: [],
    }),
    mounted() {
        this.initFilters()
    },
    computed: {
        sliderOptions() {
            return {
                min: 0,
                max: 5,
                tooltip: 'none',
            }
        },
    },
    methods: {
        initFilters() {
            const detail = this.$store.getters.chatFilters.detail
            const buttons = [
                { label: 'GOOD', value: 1 },
                { label: 'NORMAL', value: 2 },
                { label: 'BAD', value: 3 },
            ]

            this.filters = [
                { key: 'matchCount', icon: 'favorite' },
                { key: 'photoCount', img: require('@/assets/images/chat-stat/agent.svg') },
                { key: 'agentCount', icon: 'person' },
                { key: 'refundRate', icon: 'monetization_on' },
                { key: 'payRate', icon: 'bubble_chart' },
            ].map(o => ({
                key: o.key,
                label: detail[o.key].label,
                icon: o.icon,
                img: o.img,
                value: 0,
                buttons,
            }))
        },
        onClickButton(filter, label) {
            filter.value = filter.value === 0 ? label.toLowerCase() : 0
            this.$gtm('chat_list_detailed_search', {
                key: filter.key,
            })
        },
        onClickSearchChats() {
            this.filters.forEach(filter => {
                this.$store.getters.chatFilters.detail[filter.key].value = filter.value !== 0 ? filter.value : 0
            })
            this.$store.dispatch('loadChats', { force: true })
            this.$emit('close')
        },
    },
}
</script>
