<template>
    <div class="menu-button" @click="$emit('click')">
        <div
            class="menu center"
            @mouseover="showSnackbar = true"
            @mouseleave="showSnackbar = false">
            <i class="material-icons" :class="[selectedClass]" v-if="icon">{{ icon }}</i>
            <div class="img-container" v-if="iconPng">
                <img v-if="selected" :src="iconPng.active">
                <img v-else :src="iconPng.inactive">
                <div
                    v-if="badgeCount > 0"
                    class="badge badge-count"
                    v-html="displayCount"/>
            </div>
            <span v-html="$translate(label)" v-if="label"/>
            <div
                v-if="badgeCount > 0"
                class="badge badge-count"
                v-html="displayCount"/>
            <div
                v-if="shortcut"
                class="badge badge-shortcut"
                v-html="shortcut"/>
            <img :src="dynamicSrc">
        </div>
        <div
            v-if="$store.getters.isMobile"
            class="f-12 text-center"
            v-html="$translate(snackbarText)"/>
        <Snackbar :bind="showSnackbar" :text="snackbarText"/>
    </div>
</template>
<script>
export default {
    name: 'MenuButton',
    props: [
        'badgeCount',
        'icon',
        'iconPng',
        'label',
        'images',
        'selected',
        'snackbarText',
        'shortcut',
    ],
    computed: {
        selectedClass() {
            return this.selected ? 'selected' : ''
        },
        displayCount() {
            return this.badgeCount > 99 ? '99+' : this.badgeCount
        },
        dynamicSrc() {
            if (!this.images) return
            if (!this.showSnackbar && !this.selected) {
                return this.images.normal
            }
            return this.images.active
        },
    },
    data: () => ({
        showSnackbar: false,
    }),
}
</script>