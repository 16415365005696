<template>
    <div class="distance flex-row items-center">
        <span v-if="distance >= 0" class="value flex-wrap" :class="{ 'c-primary': distance <= 20 }"
            >{{ distance }}km</span
        >
    </div>
</template>

<script>
export default {
    name: 'Distance',
    props: ['distance'],
}
</script>

<style lang="scss" scoped>
.c-primary {
    color: $purple-primary !important;
}
</style>
