<template>
    <div class="detail" :class="isInstantChat ? 'is-instant-chat' : ''">
        <h3 class="h3">
            <i class="material-icons">paid</i>
            소득정보
        </h3>
        <div class="item" v-for="item in incomeProfile" :key="item.key" v-show="item.value">
            <div class="key" v-html="item.key" />
            <!-- <div class="value">
                <span>{{ item.value }}</span>
                <span v-if="item.selective" class="badge-manager-only">매니저만 </span>
            </div> -->

            <div class="value">
                <div>
                    <div v-if="item.value_range">
                        {{ item.value_range }}
                    </div>
                    <div class="income_value">
                        {{ item.value }}
                        <span v-if="item.selective === 0 || item.selective === false" class="badge-manager-only"
                            >매니저만</span
                        >
                        <div v-if="item.description">{{ item.description }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Income',
    props: ['profile'],
    data: () => ({
        isInstantChat: false,
    }),
    methods: {},
    computed: {
        getHouseValue() {
            if (!(this.profile && this.profile.privacy_setting)) return []

            let houseOwner = ''
            switch (this.profile.house_owner) {
                case 0:
                    houseOwner = '본인소유'
                    break
                case 1:
                    houseOwner = '부모님소유'
                    break
                case 2:
                    houseOwner = '형제소유'
                    break
                default:
                    houseOwner = ''
                    break
            }

            const items = [this.profile.house_style, this.profile.house_payment, houseOwner]
            return items.filter(item => item).join(' /  ')
        },
        incomeProfile() {
            if (!(this.profile && this.profile.privacy_setting)) return []

            const items = [
                {
                    id: 1,
                    key: '연소득',
                    value: this.profile.income ? this.$krwParsed(this.profile.income) : '소득없음',
                    value_range:
                        this.profile.privacy_setting.show_income === 2
                            ? this.$profile.moneyRangeParsed(this.profile.income)
                            : null,
                    selective: this.profile.privacy_setting.show_income,
                },
                {
                    id: 2,
                    key: '보유자산',
                    value: this.profile.asset ? this.$krwParsed(this.profile.asset) : '자산없음',
                    value_range:
                        this.profile.privacy_setting.show_asset === 2
                            ? this.$profile.assetRangeParsed(this.profile.asset)
                            : null,
                    description: this.profile.asset_detail,
                    selective: this.profile.privacy_setting.show_asset,
                },
                {
                    id: 3,
                    key: '부동산(주거)',
                    value: this.getHouseValue,
                    selective: this.profile.privacy_setting.show_house_owner,
                },
                {
                    id: 4,
                    key: '재테크',
                    value: this.profile.finance || null,
                },
                {
                    id: 5,
                    key: '차량',
                    value: this.profile.car_type ? `${this.profile.car_type} (${this.profile.car_model})` : null,
                },
            ]
            return items.filter(item => item.value)
        },
    },
}
</script>
<style scoped lang="scss">
.h3 {
    color: #646468;
}
.item .key {
    color: #646468;
}

.instant-chat {
    .h3 {
        color: #fff;
    }
    .key {
        color: #fff;
    }
    .income_value {
        color: #fff;
    }
}
.before-confirm {
    .h3 {
        color: #fff;
    }
}
</style>
