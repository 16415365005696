<template>
    <div class="floating-interest" @click="onClick">
        <div class="content">
            <p class="text">
                {{ $nameOrNick(chat.user) }}님에게 <span class="interest f-bold">호감</span>있는 회원
                <span class="total f-bold">{{ total }}</span>
            </p>
            <span v-if="numberOfNewInterests > 0" class="new-badge m-l-8">{{ numberOfNewInterests }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FloatingInterest',
    props: ['chat', 'interests'],
    computed: {
        total() {
            return (this.interests || []).length
        },
        numberOfNewInterests() {
            return (this.interests || []).filter(interest => !interest.sent).length
        },
    },
    methods: {
        onClick() {
            this.$router.push({ name: 'InterestPage' })
        },
    },
}
</script>

<style lang="scss" scoped>
.floating-interest {
    $interest-color: #4a90e2;

    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100vw - 32px);
    margin-left: 16px;
    padding: 12px 16px;
    background-color: white;
    border-radius: 12px;
    border: solid 1px #f1f2f4;
    font-size: 14px;
    font-family: NotoSans-Medium;
    color: $grey-09;

    .delete-all {
    }

    .content {
        display: flex;
        align-items: center;

        .text {
            display: flex;
            align-items: center;
            margin: 0;
            line-height: normal;
        }

        .interest {
            margin-left: 2px;
            color: $interest-color;
        }

        .total {
            margin-left: 2px;
            color: $interest-color;
            font-size: 11px;
            align-self: flex-start;
        }
    }

    .new-badge {
        height: 16px;
        padding: 0 4px;
        border-radius: 6px;
        background-color: $interest-color;
        font-size: 10.5px;
        color: white;

        @include f-bold;

        &::before {
            content: 'N';
        }
    }

    .material-icons {
        color: #7f7e83;
    }
}
</style>
