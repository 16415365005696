<template>
    <div class="unread-separator">
        <i class="material-icons">bookmark</i>
        <span class="text f-medium">여기까지 읽었습니다.</span>
    </div>
</template>

<script>
export default {
}
</script>