import commonService from '@/services/common'

const defaultState = () => ({
    states: null,
    mannerFeedbacks: null,
    jobCategories: null,
    provinces: null,
    religions: null,
    schools: null,
    schoolTypes: null,
    universityGrades: null,
    professional: null,
    practitioner: null,
    profileOptions: null,
    lastApiCall: null,
    weekdays: 0,
    personalStory: null,
    payloads: {
        signupData: {
            optional_consent: 0,
            email: '',
            nickname: '',
            password: '',
        },
    },
    recentPlan: null,
})

const state = defaultState()

const getters = {
    states: state => state.states,
    mannerFeedbacks: state => state.mannerFeedbacks,
    jobCategories: state => state.jobCategories,
    provinces: state => state.provinces,
    schools: state => state.schools,
    schoolTypes: state => state.schoolTypes,
    universityGrades: state => state.universityGrades,
    professional: state => state.professional,
    practitioner: state => state.practitioner,
    weekdays: state => state.weekdays,
    personalStory: state => state.personalStory,
    shapes: state => {
        if (!state.profileOptions) return

        return state.profileOptions.filter(o => o.ptype === 'shape')
    },
    kinds: state => {
        if (!state.profileOptions) return

        return state.profileOptions.filter(o => o.ptype === 'kind')
    },
    mbti: state => {
        if (!state.profileOptions) return

        return state.profileOptions.filter(o => o.ptype === 'mbti')
    },
    religions: state => {
        if (!state.profileOptions) return

        return state.profileOptions.filter(o => o.ptype === 'religion')
    },
    lastApiCall: state => state.lastApiCall,
    payloads: state => state.payloads,
    recentPlan: state => state.recentPlan,
}

const actions = {
    async loadConstants({ commit }) {
        try {
            const constants = await commonService.constants()
            const profileOptions = await commonService.profileOptions()
            commit('setConstants', {
                constants,
                profileOptions,
            })
        } catch (e) {
            Promise.reject(e)
        }
    },
}

const mutations = {
    async setConstants(state, { constants, profileOptions }) {
        state.states = constants.states
        state.mannerFeedbacks = constants.manner_feedbacks
        state.jobCategories = constants.job_categories
        state.schools = constants.schools
        state.schoolTypes = constants.school_types
        state.provinces = constants.provinces
        state.religions = constants.religions
        state.profileOptions = profileOptions
        state.universityGrades = constants.university_grades
        state.professional = constants.professional
        state.practitioner = constants.practitioner
        state.weekdays = constants.weekdays
        state.personalStory = constants.personal_stories
    },
    setPayloads(state, payloads) {
        Object.keys(payloads).forEach(payload => {
            Object.keys(payloads[payload]).forEach(key => {
                state.payloads[payload][key] = payloads[payload][key]
            })
        })
    },
    setRecentPlan(state, value) {
        state.recentPlan = value
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
    defaultState,
}
