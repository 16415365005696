import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueMoment from 'vue-moment'
import './modules/axios'
import { store } from '@/store'
import VueLazyLoad from 'vue-lazyload'
import blankProfile from '@/assets/images/blank_profile.svg'

import * as filter from '@/filters'
import * as Global from '@/plugins/global'
import * as directives from '@/directives'

import AutoTexts from '@/components/common/AutoTexts'
import BadgeFriendType from '@/components/common/BadgeFriendType'
import BadgeActionPoint from '@/components/common/BadgeActionPoint'
import ChatButton from '@/components/common/ChatButton'
import Distance from '@/components/common/Distance'
import Emoji from '@/components/common/Emoji'
import SuperFilter from '@/components/common/SuperFilter'
import SuperFilterMob from '@/components/common/SuperFilterMob'
import LastSeen from '@/components/common/LastSeen'
import PhotoAndLastSeen from '@/components/common/PhotoAndLastSeen'
import MarryPlan from '@/components/common/MarryPlan'
import ScheduleProfile from '@/components/common/ScheduleProfile'
import IntroDay from '@/components/common/IntroDay'
import TextareaWithAutoTexts from '@/components/common/TextareaWithAutoTexts'
import Timer from '@/components/common/Timer'
import UserPhotosSelector from '@/components/common/UserPhotosSelector'
import UserProfile from '@/components/common/UserProfile'
import SimpleProfile from '@/components/common/SimpleProfile'
import MatchingHistory from '@/routes/home/MatchingHistory'
import DailyStat from '@/routes/home/DailyStat'
import BridgeHistoryDetail from '@/routes/manage-friends/BridgeHistoryDetail'
import BridgeHistories from '@/routes/manage-friends/BridgeHistories'
import SearchBox from '@/components/common/SearchBox'
import FilterIcon from '@/components/common/FilterIcon'
import { Carousel, Slide } from 'vue-carousel'
import VueSlider from 'vue-slider-component'

import Checkbox from '@/components/app/Checkbox'
import ContextMenu from '@/components/app/ContextMenu'
import Dropdown from '@/components/app/Dropdown'
import Loading from '@/components/app/Loading'
import Scroller from '@/components/app/Scroller'
import Snackbar from '@/components/app/Snackbar'
import Toggler from '@/components/app/Toggler'
import Tabs from '@/components/app/Tabs'
import TransitionExpand from '@/components/app/TransitionExpand'
import FloatingMenu from '@/components/app/FloatingMenu'
import MeetingFeedback from '@/components/common/MeetingFeedback'

import VueClipboard from 'vue-clipboard2'

// This $bus must be defined here for this to be able to be accessed in filters, plugins, directives.
Vue.prototype.$bus = new Vue({})
Object.keys(directives).forEach(k => {
    Vue.directive(k, directives[k])
})
Vue.use(VueMoment)
Vue.use(VueLazyLoad, {
    error: blankProfile,
})

// This $bus must be defined here for this to be able to be accessed in filters, plugins, directives.
Vue.prototype.$bus = new Vue({})
Object.keys(filter).forEach(k => {
    Vue.filter(k, filter[k])
})
Object.keys(Global).forEach(k => {
    Vue.use(Global[k])
})
Object.keys(directives).forEach(k => {
    Vue.directive(k, directives[k])
})
Vue.use(VueMoment)
Vue.component('AutoTexts', AutoTexts)
Vue.component('BadgeFriendType', BadgeFriendType)
Vue.component('BadgeActionPoint', BadgeActionPoint)
Vue.component('ChatButton', ChatButton)
Vue.component('Distance', Distance)
Vue.component('Emoji', Emoji)
Vue.component('SuperFilter', SuperFilter)
Vue.component('SuperFilterMob', SuperFilterMob)
Vue.component('LastSeen', LastSeen)
Vue.component('PhotoAndLastSeen', PhotoAndLastSeen)
Vue.component('TextareaWithAutoTexts', TextareaWithAutoTexts)
Vue.component('Timer', Timer)
Vue.component('UserPhotosSelector', UserPhotosSelector)
Vue.component('UserProfile', UserProfile)
Vue.component('SimpleProfile', SimpleProfile)
Vue.component('MatchingHistory', MatchingHistory)
Vue.component('DailyStat', DailyStat)
Vue.component('BridgeHistoryDetail', BridgeHistoryDetail)
Vue.component('BridgeHistories', BridgeHistories)
Vue.component('SearchBox', SearchBox)
Vue.component('FilterIcon', FilterIcon)
Vue.component('VueSlider', VueSlider)
Vue.component('Carousel', Carousel)
Vue.component('Slide', Slide)
Vue.component('Checkbox', Checkbox)
Vue.component('ContextMenu', ContextMenu)
Vue.component('Dropdown', Dropdown)
Vue.component('Loading', Loading)
Vue.component('Scroller', Scroller)
Vue.component('Snackbar', Snackbar)
Vue.component('Toggler', Toggler)
Vue.component('Tabs', Tabs)
Vue.component('TransitionExpand', TransitionExpand)
Vue.component('FloatingMenu', FloatingMenu)
Vue.component('MarryPlan', MarryPlan)
Vue.component('ScheduleProfile', ScheduleProfile)
Vue.component('IntroDay', IntroDay)
Vue.component('MeetingFeedback', MeetingFeedback)

Vue.use(VueClipboard)

require('@/assets/styles/index.scss')

Vue.config.productionTip = false

new Vue({
    render: h => h(App),
    router,
    store,
}).$mount('#app')
