<template>
    <div class="detail" :class="isInstantChat ? 'is-instant-chat' : ''">
        <h3 class="h3">
            <i class="material-icons">work</i>
            직장/직업 정보
        </h3>
        <div class="item-container">
            <span class="title-badge-purple f-15"
                >표시될 직장명:
                <span v-if="profile.company[0].show_company_name === 1" class="seperator-slash">{{
                    profile.company[0].company_name
                }}</span>
                <span v-else-if="profile.company[0].show_company_name === 2" class="seperator-slash">{{
                    profile.company[0].company_name_modified
                }}</span>
                <span v-else-if="profile.company[0].show_company_name === 3" class="seperator-slash"
                    >{{ profile.company[0].company_name }} ({{ profile.company[0].company_name_modified }})</span
                >
                <span v-else class="seperator-slash">노출 안함</span>
            </span>
        </div>
        <div class="item-container m-t-10" v-for="(item, idx) in profile.company" :key="idx">
            <!-- <span class="title-badge-purple">{{ isCurrent(item.employment_status) }}</span> -->
            <!-- <div class="key" v-if="!$profile.jobFreePage(item.job_category_id)"> -->
            <div class="key">
                직장명 표시 방법 <br />

                <div class="selector-container">
                    <div class="select" v-for="select in selectors" :key="`select-${select.id}`">
                        <img
                            class="m-r-4"
                            :src="
                                require(`@/assets/images/icons/${
                                    selectedShowMethod === select.id ? 'radio_on' : 'radio_off'
                                }.png`)
                            "
                            @click="onClickSelector(select.id)"
                            width="24px"
                            height="24px"
                        />
                        <span v-html="select.key" />
                    </div>
                </div>
            </div>
            <!-- <div class="item m-t-10" v-if="!$profile.jobFreePage(item.job_category_id)"> -->
            <div class="item">
                <div class="key">직장명</div>
                <div class="value">
                    <div class="career_value" v-if="item.company_name_modified">
                        {{ item.company_name_modified }}
                    </div>
                    <div class="career_value">
                        {{ item.company_name }}
                        <!-- <span v-if="item.show_company_name !== 1" class="badge-manager-only">매니저만</span> -->
                    </div>
                </div>
            </div>
            <!-- <div class="item" v-if="!$profile.jobFreePage(item.job_category_id)"> -->
            <div class="item">
                <div class="key">직장 위치</div>
                <div class="career_value">
                    {{ item.region_shorten
                    }}<span v-if="item.show_region_shorten === 0" class="badge-manager-only">부분공개</span>
                </div>
            </div>
            <!-- <div class="item" v-if="!$profile.jobFreePage(item.job_category_id)"> -->
            <div class="item">
                <div class="key">직장/직업/상태</div>
                <div class="value">
                    <span class="seperator-slash">{{ item.job_category_name }}</span>
                    <span class="seperator-slash">{{ item.job_name }}</span>
                    <span class="seperator-slash">{{ getEmploymentStatus(item.employment_type) }}</span>
                </div>
            </div>
            <!-- <div class="item" v-if="$profile.jobFreePage(item.job_category_id)"> -->
            <div class="item">
                <div class="key">직장명</div>
                <div class="value">
                    <span class="seperator-slash">{{ item.job_category_name }}</span>
                </div>
            </div>

            <!-- <div class="item" v-if="!$profile.jobFreePage(item.job_category_id)">
                <div class="key">부서/직급</div>
                <div class="value">
                    <span class="seperator-slash"
                        >{{ item.company_department }}

                        <span v-if="!item.show_company_department" class="badge-manager-only">매니저만</span>
                    </span>
                    <span class="seperator-slash"
                        >{{ item.company_rank }}
                        <span v-if="!item.show_company_rank" class="badge-manager-only">매니저만</span>
                    </span>
                </div>
            </div>
            <div class="item">
                <div class="key">담당업무</div>
                <div class="value">{{ item.company_task }}</div>
            </div> -->
        </div>
    </div>
</template>

<script>
// 숨기는 정보 : 직장명, 부서명, 직급
import userService from '@/services/user'

export default {
    name: 'Career',
    props: ['profile'],
    data: () => ({
        // selectedShowMethod: null
        isInstantChat: false,
    }),
    computed: {
        isCurrent() {
            return status => {
                return status ? '현직장' : '전직장'
            }
        },
        selectedShowMethod() {
            return this.profile.company[0].show_company_name
        },
        getEmploymentStatus() {
            return status => {
                let content = ''
                switch (status) {
                    case 0:
                        content = '정규직'
                        break
                    case 1:
                        content = '계약직'
                        break
                    case 2:
                        content = '파견직'
                        break
                    case 3:
                        content = '위촉직'
                        break
                    case 4:
                        content = '기타'
                        break
                }
                return content
            }
        },
        selectors() {
            return [
                {
                    id: 1,
                    key: `${this.profile.company[0].company_name}`,
                },
                {
                    id: 2,
                    key: this.profile.company[0].company_name_modified
                        ? `${this.profile.company[0].company_name_modified}`
                        : '입력 필요',
                },
                {
                    id: 3,
                    key: this.profile.company[0].company_name_modified
                        ? `${this.profile.company[0].company_name} (${this.profile.company[0].company_name_modified})`
                        : `${this.profile.company[0].company_name} (입력 필요)`,
                },
                {
                    id: 4,
                    key: `노출 안 함`,
                },
            ]
        },
    },
    // mounted() {
    //     this.init()
    // },
    methods: {
        // init() {
        //     this.selectedShowMethod = this.profile.company[0].show_company_name
        // },
        async onClickSelector(id) {
            this.$loading('true')
            try {
                // this.selectedShowMethod = id
                const payload = {
                    user_company_id: this.profile.company[0].id,
                    show_company_name: id,
                }
                const data = await userService.updateCompanyShowMethod(payload)
                this.$toast.success(data.msg)
                this.$set(this.profile.company[0], 'show_company_name', id)
            } catch (e) {
                // console.log('오류')
            }
            this.$loading('false')
        },
    },
}
</script>
<style scoped lang="scss">
.h3 {
    color: #646468;
}
.seperator-slash:not(:last-child)::after {
    content: '/';
    margin: 0 2px;
}
.key {
    margin-bottom: 15px;
    color: #646468;
    .select {
        display: flex;
        align-items: center;
        color: black;
    }
}
.instant-chat {
    .h3 {
        color: #fff;
    }
    .key {
        color: #fff;
        .select {
            color: #fff;
        }
    }
    .career_value {
        color: #fff;
    }
    .seperator-slash {
        color: #fff;
    }
}
.before-confirm {
    .h3 {
        color: #fff;
    }
    .key {
        color: #fff;
        .select {
            color: #fff;
        }
    }
    .content {
        color: #e1c4c4;
    }
}
</style>
