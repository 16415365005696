<template>
    <div class="daily-stat">
        <div class="top flex-row">
            <Dropdown class="flex-wrap m-l-4" :titleKey="'title'" :items="dropdownItems" />
        </div>
        <div class="body p-relative">
            <Loading :loading="loading" />
            <div class="thead">
                <div class="flex-row tr">
                    <div :key="column.title" v-for="column in columns" class="th">
                        {{ column.title | translate }}
                    </div>
                </div>
            </div>
            <div class="tbody">
                <div :key="rowIdx" v-for="(row, rowIdx) in dailyStat" class="flex-row tr">
                    <div>{{ row.date | formatDate('YY.MM.DD') }}</div>
                    <div class="c-primary f-medium">{{ numMatchings(row) }}건</div>
                    <div v-if="showSum" class="c-danger f-medium">{{ row.refund }}건</div>
                    <div v-if="showSum">{{ Math.round((row.active_time / 3600) * 10) / 10 }}시간</div>
                    <div>{{ numUsers(row) }}명</div>
                    <div>{{ numSources(row) }}명</div>
                    <div>{{ numMessages(row) }}개</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DailyStat',
    watch: {
        agent(newVal) {
            this.init()
        },
    },
    data: () => ({
        loading: false,
        dropdownItems: [],
    }),
    computed: {
        dailyStat() {
            return this.$store.getters.dailyStat || []
        },
        showSum() {
            return (this.selectedPage || {}).title === 'SUM'
        },
        selectedPage() {
            return this.dropdownItems.find(item => item.selected)
        },
        columns() {
            if (!this.dropdownItems) return

            if (!this.selectedPage) return

            const columns = [
                {
                    title: 'TIMESTAMP',
                },
                {
                    title: 'NUM_MATCHINGS',
                },
            ]

            if (this.showSum) {
                columns.push(...[{ title: 'NUM_REFUNDS' }, { title: 'ACTIVE_TIME' }])
            }

            columns.push(...[{ title: 'NUM_USERS' }, { title: 'NUM_SOURCES' }, { title: 'NUM_MESSAGES' }])
            return columns
        },
        agent() {
            return this.$store.getters.agent || {}
        },
    },
    mounted() {
        this.init()
    },
    methods: {
        async init() {
            if (!this.agent.id) return

            try {
                this.loading = true
                await this.$store.dispatch('loadDailyStat')
            } catch (e) {}
            this.loading = false

            this.dropdownItems = [
                {
                    title: 'SUM',
                    selected: true,
                },
                {
                    title: 'PER_HOUR',
                },
            ]
        },
        numMatchings(row) {
            if (this.showSum) return row.dating

            if (row.active_time > 0) {
                return Math.round((row.dating / (row.active_time / 3600)) * 10) / 10
            }

            return 0
        },
        numUsers(row) {
            if (this.showSum) return row.dating_user

            if (row.active_time > 0) {
                return Math.round((row.dating_user / (row.active_time / 3600)) * 10) / 10
            }

            return 0
        },
        numSources(row) {
            if (this.showSum) return row.dating_source

            if (row.active_time > 0) {
                return Math.round((row.dating_source / (row.active_time / 3600)) * 10) / 10
            }

            return 0
        },
        numMessages(row) {
            if (this.showSum) return row.msg

            if (row.active_time > 0) {
                return Math.round((row.msg / (row.active_time / 3600)) * 10) / 10
            }

            return 0
        },
    },
}
</script>
