<template>
    <div class="signup upload-photo">
        <Loading :loading="loading" :animation="'dot-falling'" />
        <div class="page-header">
            <div class="title">프로필 사진</div>
        </div>
        <div class="body">
            <div class="item-desc m-b-32">얼굴이 분명하게 나온 사진 <span class="f-bold">2장</span>이 꼭 필요해요!</div>
            <div class="photos">
                <div
                    :key="photo.url"
                    v-for="(photo, i) of photos"
                    class="photo"
                    :class="{ required: photo.required }"
                    @click.stop="!photo.url ? onClickPhoto(i) : null"
                    :style="{ backgroundImage: `url('${photo.url}')` }"
                >
                    <span v-if="photo.required && !photo.url" class="badge">필수</span>
                    <i v-if="!photo.url" class="material-icons">add</i>
                    <i v-if="photo.url" class="material-icons close-icon" @click.stop="removePhoto(i)">close</i>
                    <span v-if="photo.url" class="modify" @click.stop="onClickPhoto(i)">
                        <i class="material-icons modify-icon">create</i>
                    </span>
                </div>
                <input
                    ref="imageUploader"
                    type="file"
                    class="image-input display-none"
                    @change="onChangeImage($event)"
                    accept="image/*"
                />
            </div>
        </div>
        <button
            class="btn btn-primary"
            :class="{ disabled: disabled }"
            @click="next"
            v-html="$translate('NEXT')"
        ></button>
    </div>
</template>

<script>
import axios from '@/modules/axios'
import userService from '@/services/user'

export default {
    name: 'UploadPhotoPage',
    data: () => ({
        photos: null,
        selectedIndex: null,
        loading: false,
    }),
    computed: {
        disabled() {
            if (!this.photos || this.loading) return true
            return !this.photos.filter(p => p.required).every(p => p.blob)
        },
    },
    created() {
        this.photos = Array(6)
            .fill({})
            .map((p, i) => {
                p = { blob: null, name: null, url: null }
                this.$set(p, 'required', i < 2 ? true : false)
                return p
            })

        const storedPhotos = this.$store.getters.payloads.signupData.photos
        if (storedPhotos) {
            ;(storedPhotos || []).forEach((photo, idx) => {
                this.photos[idx] = storedPhotos[idx]
            })
        }
    },
    methods: {
        back() {
            this.$router.go(-1)
        },
        next() {
            if (this.disabled) return

            this.$store.commit('setPayloads', {
                signupData: {
                    photos: this.photos.filter(p => p.blob),
                },
            })
            // 회원가입 바로 하도록 수정
            // this.$router.push({name: 'FriendType'})
            this.createUser(this.$store.getters.payloads.signupData)
        },
        onClickPhoto(index) {
            this.selectedIndex = index
            this.$refs.imageUploader.click()
        },
        removePhoto(index) {
            this.photos[index].blob = null
            this.photos[index].url = null
            this.photos[index].name = null
        },
        onChangeImage(event) {
            if (!event || event.target.files.length === 0) return

            const selectedPhoto = this.photos[this.selectedIndex]
            selectedPhoto.name = event.target.files[0].name
            const file = event.target.files[0]
            if (file.size > 1000 * 1000 * 3) {
                this.$toast.error('IMAGE_TOO_BIG')
                this.$refs.imageUploader.value = ''
                return
            }
            this.$refs.imageUploader.value = ''

            this.$modal
                .custom({
                    component: 'ModalCropper',
                    options: {
                        imgFile: file,
                    },
                })
                .then(croppedFile => {
                    if (!croppedFile) return
                    selectedPhoto.url = URL.createObjectURL(croppedFile)
                    selectedPhoto.blob = croppedFile
                    this.selectedIndex = null
                })
        },
        async createUser(signupData) {
            try {
                this.loading = true
                const payload = this.preparedPayload(signupData)
                axios.setHeader({ 'X-User-Role': 'agent' })
                await userService.create(payload)
                await this.$store.dispatch('signIn', {
                    email: signupData.email,
                    password: signupData.password,
                })
            } catch (e) {
                if (e.data.key === 'auth_validation_expired') {
                    this.$store.commit('setNiceData', null)
                }
                this.$toast.error(e.data)
            } finally {
                this.loading = false
            }
        },
        preparedPayload(signupData) {
            const formData = new FormData()
            Object.entries(signupData).forEach(([key, value]) => {
                if (key === 'photos') {
                    value.forEach((p, i) => {
                        formData.append(`photo${i + 1}`, p.blob, p.name)
                    })
                } else if (key === 'gender') {
                    formData.append(key, value.name === 'MALE' ? '0' : '1')
                } else if (['state', 'station', 'region'].indexOf(key) !== -1) {
                    // 커스텀 인풋 허용 안되는 값들은 id만 넘김
                    formData.append(`${this.$case.toSnake(key)}_id`, value.id)
                } else if (['male_keywords', 'female_keywords'].indexOf(key) !== -1) {
                    formData.append(key, JSON.stringify(value))
                } else {
                    formData.append(key, value)
                }
            })
            return formData
        },
    },
}
</script>
