<template>
    <div v-if="user" class="user-info-basic flex-row items-center p-relative">
        <div v-show="mouseOver" class="schedule-info badge">
            {{ `매칭 ${user.profile.match_count}` }}
        </div>
        <div v-if="user.name" class="flex items-center flex-wrap" :class="{ married: marry }">
            <div class="flex-row m-b-10">
                <div class="checkbox" :class="{ checked: this.fromRecruiter }" @click="onClickOnboard('recruiter')">
                    <i class="zmdi zmdi-check" v-if="this.fromRecruiter" />
                </div>
                <span class="m-l-5 m-r-15">리크루터 회원</span>
                <div class="flex">
                    <div class="flex-row items-center">
                        <div
                            class="btn m-r-8"
                            :class="{ selected: this.isFree === 'paid' }"
                            @click="onIsFreeClick('paid')"
                        >
                            유료회원
                        </div>
                        <div class="btn" :class="{ selected: this.isFree === 'free' }" @click="onIsFreeClick('free')">
                            무료회원
                        </div>
                    </div>
                    <div v-if="this.isFree === 'created'" class="red">유/무료 여부를 체크해주세요.</div>
                </div>
            </div>
            <div class="flex-row items-center m-b-10">
                <i @mouseenter="onMouseEnter" @mouseleave="onMouseLeave" class="material-icons m-r-10"
                    >event_available</i
                >
                <div class="first flex-wrap" :class="{ married: marry, testUser }" v-html="userName" />
                <span
                    :class="{ married: marry }"
                    class="info flex-wrap"
                    v-html="
                        `${$options.filters.asAge(user.profile.birthday)}세 (${$moment(user.profile.birthday).format(
                            'YYYY'
                        )}년생)`
                    "
                />
            </div>
            <div class="flex-row items-center m-b-10">
                <span :class="{ married: marry }" class="info flex-wrap" v-html="`${user.profile.height}cm`" />
                <span :class="{ married: marry }" class="divider flex-wrap" v-html="'ㅣ'" />
                <span :class="{ married: marry }" class="info flex-wrap" v-html="user.profile.region_name" />

                <div class="badges-container flex-row flex-between">
                    <div class="badges flex-row flex-wrap items-center">
                        <div class="friend-functions flex-row items-center flex-wrap" v-if="chat && user.$$chatId">
                            <div class="divider m-l-8 m-r-8">|</div>
                            <div v-if="!hideBookmark" class="bookmark m-r-4" @click="onClickBookmark">
                                <img :src="bookmarkIcon" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex-row m-b-10">
                <span :class="{ married: marry }" class="info flex-wrap" v-html="user.phone_number" />
            </div>
            <div class="m-b-10 f-15 f-medium">
                <div class="m-b-10">
                    <span class="total-grade">종합: {{ user.profile.total_grade || 'null' }}</span> / 외모:
                    {{ lookGrade }} / 스펙: {{ user.profile.spec_score_admin || 'null' }}
                </div>
                <div>
                    수락률: {{ user.user_info ? Math.round(user.user_info.accept_rate * 1000) / 10 + '%' : '0%' }} ({{
                        user.user_info ? user.user_info.photo_cnt : 0
                    }}장) / 피수락률:
                    {{ user.user_info ? Math.round(user.user_info.accepted_rate * 1000) / 10 + '%' : '0%' }} ({{
                        user.user_info ? user.user_info.photoed_cnt : 0
                    }}장) / 매칭계수:
                    {{
                        user.user_info && user.user_info.matching_coefficient
                            ? user.user_info.matching_coefficient === 0
                                ? '0.01(0)'
                                : user.user_info.matching_coefficient
                            : '0(신규)'
                    }}
                </div>
            </div>
        </div>
        <div v-if="user.name" class="flex items-center flex-wrap" :class="{ married: marry }"></div>
    </div>
</template>

<script>
import userService from '@/services/user'

export default {
    name: 'UserInfoBasic',
    props: {
        user: {
            type: Object,
            required: true,
        },
        hideBookmark: {
            type: Boolean,
            default: true,
        },
        hideMemo: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        mouseOver: false,
        isDeposit: false,
        isFree: 'created',
        fromRecruiter: false,
    }),
    watch: {
        user() {
            this.setRecruiter
            this.setIsFree()
        },
    },
    computed: {
        testUser() {
            return (
                this.user.gender === 1 &&
                this.age <= 36 &&
                (this.user.profile.spec_rate || 0) >= 3 &&
                (this.user.profile.appearance_grade || 0) >= 3
            )
        },
        age() {
            if (!this.user.profile) return

            const birthday = this.user.profile.birthday
            if (!birthday) return

            return this.$options.filters.asAge(birthday)
        },
        userName() {
            return `${this.user.name}<span class='f-12'>(${this.user.id})</span>`
        },
        height() {
            if (!this.user.profile) return

            return this.user.profile.height
        },
        userChat() {
            return (this.$store.getters.chatList || []).find(c => c.user_id === this.user.id)
        },
        chat() {
            return this.$store.getters.chat
        },
        bookmarkIcon() {
            return require(`@/assets/images/chatroom/chat_bookmark_${this.chat.bookmark ? 'active' : 'inactive'}.svg`)
        },
        marry() {
            return this.user.profile.marry
        },
        setRecruiter() {
            return (this.fromRecruiter = this.user.from_recruiter)
        },
        lookGrade() {
            if (this.user.profile.look_grade === 0) {
                return 0
            } else if (this.user.profile.look_grade === 1) {
                return 10
            } else if (this.user.profile.look_grade === 2) {
                return 30
            } else if (this.user.profile.look_grade === 3) {
                return 50
            } else if (this.user.profile.look_grade === 4) {
                return 80
            } else if (this.user.profile.look_grade === 5) {
                return 100
            } else {
                return '평가 전'
            }
        },
    },
    methods: {
        async onClickBookmark() {
            const bookmark = !this.chat.bookmark

            try {
                this.$loading(true)
                await this.$store.dispatch('bookmarkChat', { chat: this.chat, bookmark })
                this.$toast.success(`북마크${bookmark ? ' ' : '가 해제'}되었습니다`)
            } catch (e) {
                this.$toast.error(e.data)
            } finally {
                this.$loading(false)
            }
        },
        onMouseEnter() {
            this.mouseOver = true
        },
        onMouseLeave() {
            this.mouseOver = false
        },
        async onClickOnboard(status) {
            // console.log(status)
            try {
                const payload = {
                    status: status,
                    user_id: this.user.id,
                }
                const res = await userService.updateOnboardStatus(payload)
                if (status === 'recruiter') {
                    this.fromRecruiter = !this.fromRecruiter
                    this.$toast.success(res.msg)
                }
            } catch (e) {
                this.$toast.error(e.msg)
            }
        },
        async onIsFreeClick(isFree) {
            this.isFree = isFree
            const payload = {
                status: 'check_paid',
                user_id: this.user.id,
                is_free: isFree,
            }
            const res = await userService.updateOnboardStatus(payload)
            this.$toast.success(res.msg)
        },
        setIsFree() {
            this.isFree = this.user.is_free
        },
    },
}
</script>

<style lang="scss" scoped>
.user-info-basic {
    position: relative;
    width: 100%;
    .red {
        color: red;
        font-size: 12px;
        text-align: center;
    }
    .selected {
        background-color: #aaa;
        color: #fff;
    }
    .schedule-info {
        position: absolute;
        top: -20px;
        background: black;
        color: white;
    }
    .first,
    .info {
        font-size: 18px;
        margin-right: 4px;
        color: $black;
        @include f-bold;
    }
    .marry {
        font-weight: bold;
        font-size: 15px;
        line-height: 22px;
        color: #ffffff !important;
        background-color: #ff3d6b;
        margin: 0px 4px;
        border-radius: 4px;
        padding: 0px 4px;
    }

    .second {
        font-size: 12px;
        color: $grey-07;
    }

    .badges {
        margin-left: 8px;

        .snackbar {
            top: 32px;
        }
    }

    .divider {
        color: $grey-04;
    }

    .friend-functions {
        .bookmark {
            display: -webkit-box !important;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: $grey-01;

            @include center;
        }
    }
    .married {
        color: #ff3d6b;
    }
    .testUser {
        color: $purple-primary;
    }
    .total-grade {
        color: $brand-accent;
    }
}

::v-deep .no-ons-campaign {
    &.third-panel {
        margin-top: 0;
        margin-left: 4px;
    }
}
</style>
