<template>
    <div class="chat-button center" @click="throttle(() => onClickBtn($event), 1500)">
        <i v-if="!shouldShowRecommendButton" class="zmdi zmdi-comment-more" />
        <div v-else class="recommend-button center">
            <img src="@/assets/images/email.svg" />
        </div>
    </div>
</template>

<script>
// import suggestService from '@/services/suggest'

export default {
    name: 'ChatButton',
    props: ['user', 'source_id'],
    data: () => ({
        timer: null,
    }),
    computed: {
        chat() {
            return this.$store.getters.chat || {}
        },
        chats() {
            return this.$store.getters.chats
        },
        shouldShowRecommendButton() {
            if ((this.user || {}).$$chatId) return false

            return !(this.user || {}).$$chatId || !this.user.is_dormant
        },
        myPremium() {
            if (this.chat.user.$$premium) {
                const premium = this.$options.filters.hasProduct(this.chat.user, 'premium')
                return this.$store.getters.agent.id === premium.agent_id
            }
            return false
        },
    },
    methods: {
        throttle(handler, delay) {
            if (!this.timer) {
                this.timer = setTimeout(() => {
                    this.timer = null
                }, delay)

                handler()
            }
        },
        async openChat() {
            if (!this.user) return

            const introducingUserId = this.$router.currentRoute.name.indexOf('Chat') > -1 ? this.chat.user.id : null

            this.$bus.$emit('openTab', 'ChatPage')
            this.$store.dispatch('loadChat', {
                userId: this.user.id,
                introducingUserId: introducingUserId,
            })
        },
        async openSuggestDialog() {
            const open = () => {
                if (this.$store.getters.isMobile) {
                    this.$modal.custom({
                        component: 'ModalRecommendMob',
                        options: {
                            recommendedUser: this.user,
                        },
                    })
                } else {
                    this.$modal.custom({
                        component: 'ModalRecommend',
                        options: {
                            recommendedUser: this.user,
                        },
                    })
                }
            }
            if (!this.$preferAge(this.user, this.chat.user)) {
                await this.$modal
                    .basic({
                        body: this.$translate('NOT_PREFER_USER'),
                        buttons: [
                            {
                                label: 'CANCEL',
                                class: 'btn-default',
                            },
                            {
                                label: 'SEND',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    .then(idx => {
                        if (idx === 1) open()
                    })
            } else open()
        },
        async onClickBtn(event) {
            event.stopPropagation()

            try {
                if (this.user.is_dormant) {
                    this.$toast.warning('DORMANT_USER')
                    return
                }

                // if (this.user.$$privacy) {
                // 수정필요
                //     await this.$modal
                //         .basic({
                //             body: this.$translate('NOT_PREFER_USER'),
                //             buttons: [
                //                 {
                //                     label: 'CANCEL',
                //                     class: 'btn-default',
                //                 },
                //                 {
                //                     label: 'SEND',
                //                     class: 'btn-primary',
                //                 },
                //             ],
                //         })
                //         .then(idx => {
                //             if (idx === 1) open()
                //         })
                //     return
                // }

                const chat = this.$getChat(this.user.id)
                if (
                    (chat && !chat.expire_at && this.$router.currentRoute.name !== 'MyPage') ||
                    (chat && this.$router.currentRoute.name === 'MyPage')
                ) {
                    this.openChat()
                } else {
                    // if (this.chat.user.$$premium) {
                    // 서버에서 agents 리스트 받아와서 동적으로 이름 체크해야하는데 우선 하드코딩해놓음.. 동적으로 받도록 하자
                    const agentsList = { 1: '고혜린', 2: '블로그 체험단', 4: '이승민', 5: '황지원', 6: '박선영' }
                    const agentName = agentsList[this.user.my_manager]
                    // const agentName =
                    //     this.user.my_manager === 1 ? '최성만' : this.user.my_manager === 3 ? '하정민' : '다른'
                    this.$toast.success(`${agentName} 주선자님의 담당 유저에요`)
                    return
                    // this.$loading(true)

                    // const data = await suggestService.makeChatRoom({
                    //     user_id: this.user.id,
                    //     source_id: this.source_id,
                    // })
                    // this.$toast.success(data.msg)
                    // this.$loading(false)

                    // this.$store.dispatch('loadChat', {
                    //     userId: this.user.id,
                    // })
                    // return
                    // }

                    // const suggests = await suggestService.all(this.user.id, this.source_id)
                    // let suggest
                    // suggests.forEach(s => {
                    //     if (!suggest) suggest = s
                    //     else if (s.status === 'agent_accept') suggest = s
                    //     else if (s.status === 'user_accept') suggest = s
                    // })

                    // if (!suggest) {
                    //     this.openSuggestDialog()
                    // } else if (suggest.status === 'request') {
                    //     this.$toast.error('이미 요청했습니다.')
                    // } else if (suggest.status === 'user_reject') {
                    //     this.$toast.success('유저가 거절한 회원입니다.')
                    // } else if (suggest.status === 'user_accept') {
                    //     this.$loading(true)
                    //     try {
                    //         const data = await suggestService.acceptRequest(suggest.id)
                    //         // 항상 서버에 요청하고 서버에서 처리
                    //         this.$toast.success(data.msg)

                    //         this.$store.dispatch('loadChat', {
                    //             userId: this.user.id,
                    //             introducingUserId: this.user.id,
                    //         })
                    //     } catch (e) {
                    //         this.$toast.error(e.data)
                    //     }
                    //     this.$loading(false)
                    // } else if (suggest.status === 'agent_accept') {
                    //     this.$store.dispatch('loadChat', {
                    //         userId: this.user.id,
                    //         introducingUserId: this.user.id,
                    //     })
                    // } else if (suggest.status === 'agent_reject') {
                    //     const onConfirm = async () => {
                    //         this.$loading(true)
                    //         try {
                    //             const data = await suggestService.acceptRequest(suggest.id)
                    //             // 항상 서버에 요청하고 서버에서 처리
                    //             this.$toast.success(data.msg)

                    //             this.$store.dispatch('loadChat', {
                    //                 userId: this.user.id,
                    //                 introducingUserId: this.user.id,
                    //             })
                    //         } catch (e) {
                    //             this.$toast.error(e.data)
                    //         }
                    //         this.$loading(false)
                    //     }
                    //     this.$modal
                    //         .basic({
                    //             body: '거절한 이력이 있는 회원입니다. 소개시켜 주시겠습니까?',
                    //             buttons: [
                    //                 {
                    //                     label: 'CANCEL',
                    //                     class: 'btn-default',
                    //                 },
                    //                 {
                    //                     label: 'SEND',
                    //                     class: 'btn-primary',
                    //                 },
                    //             ],
                    //         })
                    //         .then(idx => {
                    //             if (idx === 1) {
                    //                 onConfirm()
                    //             }
                    //         })
                    // } else if (suggest.status === 'request') {
                    //     this.$toast.success('오류가 발생했습니다.')
                    // }
                }
            } catch (e) {
                this.$toast.error(e.data)
            }
        },
    },
}
</script>
