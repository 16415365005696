<template>
    <div class="bridge-info card p-relative">
        <div class="top">
            <div class="flex-row items-center">
                <div class="title flex-wrap" v-html="$translate('BRIDGE_INFO')" />
            </div>
        </div>
        <div class="cards">
            <div class="applyDate">적용일: 2021년 4월 6일 정산부터</div>
            <div class="bridge-info-card-cont m-t-12 m-b-12">
                <div class="bridge-info-card first">
                    <div class="bridge-info-card-detail">
                        <div class="card-item">
                            <div class="title" v-html="'만남성공 보상'" />
                            <div class="grid-col">
                                <div class="reward"><span>11,000</span> (남직장인)</div>
                                <div class="reward"><span>8,000</span> (기타)</div>
                                <div class="reward"><span>3,500</span> (환급)</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bridge-info-card second">
                    <div class="bridge-info-card-detail">
                        <div>
                            <div class="title" v-html="'프리미엄 보상'" />
                            <div class="grid-col">
                                <div class="bonus">
                                    <div class="reward"><span>15,000</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="description">
                * <span class="f-bold">만남 성공 보상:</span> 매칭 건 중에서 환급이 되지 않았고, 매칭 후 14일이 지나
                확정된 매칭에 부여되는 보상
            </div>
            <div class="description">
                * <span class="f-bold">프리미엄 보상:</span> 환불 없이 정상적으로 14일이 지나 종료된 프리미엄 대해서
                정산
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BridgeInfo',
}
</script>
