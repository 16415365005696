<template>
    <div class="ai-manager-filter">
        <!-- <button class="default" :class="{disabled : currentManager !==}" >상담매니저</button> -->
        <!-- <button class="ai-test-manager">김해오름</button> -->
        <!-- <button class="ai-test-manager">하정민</button> -->
        <button
            v-for="(mng, idx) in managers"
            :key="idx"
            class="button"
            :class="[mng.class, { disable: currentManager !== mng.id }]"
            @click="setManager(mng.id)"
        >
            {{ mng.label }}
        </button>
    </div>
</template>

<script>
export default {
    name: 'AIManagerFilter',
    data: () => ({
        currentManager: null,
    }),
    mounted() {
        if (localStorage.getItem('currentManager')) {
            this.currentManager = parseInt(localStorage.getItem('currentManager'))
        } else {
            this.setManager(4)
        }
    },
    watch: {
        currentManager(val) {
            localStorage.setItem('currentManager', val)
            window.dispatchEvent(
                new CustomEvent('currentManager-changed', {
                    detail: {
                        storage: localStorage.getItem('currentManager'),
                    },
                })
            )
        },
    },
    computed: {
        managers() {
            return [
                {
                    id: 4,
                    label: '전체',
                    class: 'default',
                },
                {
                    id: 1,
                    label: '해오름',
                    class: 'ai-test-manager',
                },
                {
                    id: 3,
                    label: '정민',
                    class: 'ai-test-manager',
                },
                {
                    id: 5,
                    label: '지우',
                    class: 'ai-test-manager',
                },
            ]
        },
    },
    methods: {
        setManager(val) {
            this.currentManager = val
            this.$emit('onAiFilter', this.currentManager)
        },
    },
}
</script>
<style scoped lang="scss">
.ai-manager-filter {
    width: 100%;
    flex: 0;
    border-bottom: 1px solid #e9e9e9;
    display: flex;
    align-items: center;
    padding: 12px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 5px;
    button {
        padding: 12px;
        border-radius: 12px;
        border: none;
        color: #fff;
        width: 100%;
        background-color: #8a74ff;
        // &.default {

        // }

        &.disable {
            opacity: 0.3;
        }
        &.ai-test-manager {
            background-color: #4361ee;
        }
    }
}
</style>
