<template>
    <div class="user-v2" :class="{ selected: isSelectedUser && isSelectedUser(user) }" @click="onClickUser">
        <div class="flex-row items-center">
            <div class="photo-wrapper flex-wrap">
                <PhotoAndLastSeen :profile="user.photo_url" :lastSignInAt="user.last_sign_in_at" :userId="user.id" />
            </div>
            <div class="flex-fill">
                <div class="info m-t-2">
                    <div class="upper flex-row">
                        <div class="flex-row items-center">
                            <div class="name flex-wrap m-r-2" :class="{ testUser }">{{ user.name }}</div>
                            <span class="basic flex-row flex-wrap">
                                <span>({{ age }}세</span>
                                <span class="divider" v-html="'/'" />
                                <span>{{ user.profile.height }}cm</span>
                                <span class="divider" v-html="'/'" />
                                <span>{{ user.profile.total_grade }})</span>
                                <span class="divider" v-html="'/'" />
                                <Distance :distance="user.$$distance" />
                            </span>
                        </div>
                    </div>
                    <!-- <div class="rate flex-row f-11">
                        {{ user.user_info ? Math.round(user.user_info.accept_rate * 1000) / 10 + '%' : '0%' }}
                        /
                        {{ user.user_info ? Math.round(user.user_info.accepted_rate * 1000) / 10 + '%' : '0%' }}
                        / 
                        <span class="match-coef f-bold c-red"> {{ user.user_info && user.user_info.matching_coefficient ? user.user_info.matching_coefficient === 0 ? '0.01(0)' : user.user_info.matching_coefficient :'0.01(신규)' }} </span>
                    </div> -->

                    <div class="rate flex-row f-11">
                        {{ twoWayScore }} / {{ forwardWayScore }} / {{ backwardWayScore }}
                    </div>

                    <!-- <div class="job flex-cloumn f-10">
                        <div  class="flex-wrap" v-if="company && company.length > 0" v-html="user.profile.company[0].job_name" /> 

                       <div class="flex-row">
                            <div  style="break-word" v-if="company && company.length > 0" v-html="user.profile.company[0].job_category_name" /> /
                            <div  style="break-word" v-if="company && company.length > 0" v-html="user.profile.company[0].company_name">
                        </div>
                        </div>
                    </div>  -->

                    <div class="job flex-cloumn f-10">
                        <div
                            class="flex-wrap"
                            v-if="company && company.length > 0"
                            v-html="user.profile.company[0].job_name"
                        />
                        <div class="f-10" v-if="company && company.length > 0">
                            {{ user.profile.company[0].job_category_name }}
                            /
                            {{ user.profile.company[0].company_name }}
                        </div>
                    </div>
                </div>

                <div class="school flex-column f-11">
                    {{ user.profile.bachelor_m_name }}/{{ user.profile.bachelor_u_name }}
                    <div v-if="user.profile.master_m_name && user.profile.master_u_name">
                        {{ user.profile.master_m_name }}/{{ user.profile.master_u_name }}
                    </div>
                    <div v-if="user.profile.doctor_m_name && user.profile.doctor_u_name">
                        {{ user.profile.doctor_m_name }}/{{ user.profile.doctor_u_name }}
                    </div>
                </div>

                <div v-if="unlimited" class="product">
                    {{ userRatePlanRecent.name }} | 기간제 / {{ unlimitedValidUntil }} 만료<br />
                </div>
                <div v-else class="product">
                    {{ userRatePlanRecent.name }} | {{ userTickets.active }}<br />
                    {{
                        userTickets.active !== 0
                            ? `${user.tickets.filter(ticket => ticket.enabled)[0].price_per_ticket}원`
                            : null
                    }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getPlanInfo, parsePidToPlanName } from '@/store/data/product'
import productService from '@/services/product'
export default {
    name: 'UserV2',
    props: ['user', 'isSelectedUser', 'chatUser', 'isAIRecommendation', 'AIInfo'],
    data: () => ({
        price: null,
    }),
    mounted() {
        this.getProducts()
    },
    methods: {
        async onClickUser(event) {
            // if (this.chatUser) {
            //     if (!this.ratePlan.startTypeOrigin) {
            //         this.$modal.basic({
            //             body: '서비스 시작 날짜 입력을 안했습니다',
            //             buttons: [
            //                 {
            //                     label: 'CONFIRM',
            //                     class: 'btn-primary',
            //                 },
            //             ],
            //         })

            //         return
            //     }
            // }

            await this.$store.dispatch('loadIntroducingUser', this.user.id)

            if (this.isAIRecommendation) {
                this.$set(this.$store.getters.chat.$$introducingUser, '$$AIRecommendUser', this.AIInfo)
            }
            // if (
            //     this.chat.$$feedbackRequests &&
            //     this.chat.$$feedbackRequests.some(fb => !this.sentFeedback(fb.schedule.schedule_status))
            // ) {
            //     this.$modal.basic({
            //         body: this.$translate('PLEASE_SEND_MANNER_FEEDBACKS'),
            //         buttons: [
            //             {
            //                 label: 'CONFIRM',
            //                 class: 'btn-primary',
            //             },
            //         ],
            //     })
            // }
        },
        async getProducts() {
            if (this.userRatePlanRecent.order_id) {
                const res = await productService.orderInfo(this.userRatePlanRecent.order_id)
                if (res) this.price = res.order.price
            }
        },
        getScore(wayType) {
            if (!this.chat.user || !this.chat.user.user_info) {
                return '미정'
            }

            const result = this.result
            const userResult = result.find(r => r.user_id === this.user.id)
            const score = userResult && userResult[`${wayType}_score`]
            return score ? Math.round(score * 10) / 10 + '점' : '미정'
        },
    },
    computed: {
        chat() {
            return this.$store.getters.chat || {}
        },
        age() {
            if (!this.user.profile) return

            const birthday = this.user.profile.birthday
            if (!birthday) return

            return this.$options.filters.asAge(birthday)
        },
        testUser() {
            return (
                this.user.gender === 1 &&
                this.age <= 36 &&
                (this.user.profile.spec_rate || 0) >= 3 &&
                (this.user.profile.appearance_grade || 0) >= 3
            )
        },
        company() {
            return this.$options.filters.company(this.user.profile)
        },
        result() {
            const orderby = this.$currentSortOrder || 'two_way'
            return JSON.parse(this.chat.user.user_info[`${orderby}_result`] || '[]')
        },
        twoWayScore() {
            return this.getScore('two_way')
        },
        forwardWayScore() {
            return this.getScore('forward_way')
        },
        backwardWayScore() {
            return this.getScore('backward_way')
        },
        getCompanyName() {
            let jobName = null
            let jobCategory = null
            if (this.user.profile.company.length) {
                const com = this.user.profile.company.filter(c => c.employment_status === 1)
                if (com.length >= 1) {
                    jobName = com[0].job_name || ''
                    jobCategory = com[0].job_category_name || ''
                } else {
                    jobName = this.user.profile.company[0].job_name || ''
                    jobCategory = this.user.profile.company[0].job_category_name || ''
                }
                return `${jobCategory} ${jobName}`
            } else {
                return ''
            }
        },
        ratePlan() {
            return this.$store.getters.userRatePlanRecent
        },
        gender() {
            return this.chat.user.gender === 0 ? 'b' : 'a'
        },
        sentFeedback() {
            return status => {
                return (
                    status === 'send_feedback' ||
                    status.includes('complete_feedback') ||
                    status === `send_feedback_${this.gender}`
                )
            }
        },

        products() {
            // return this.user.products || []
            return this.user.products
        },
        subscription() {
            return this.products.find(p => p.ptype === 'subscription_plan')
        },
        unlimited() {
            return this.products.find(p => [9, 10].includes(p.product_id))
        },
        unlimitedValidUntil() {
            if (this.unlimited) {
                return this.$moment(this.unlimited.valid_until).format('YY/MM/DD')
            } else {
                return false
            }
        },
        productsNowOnSale() {
            return this.$store.getters.productsNowOnSale
        },
        userRatePlans() {
            const allRatePlans = this.products.filter(
                item => item.ptype === 'rate_plan' || item.ptype === 'subscription_plan' || item.ptype === 'ticket_plan'
            )

            const res = allRatePlans.map(item => {
                const {
                    id,
                    enabled,
                    product_id: pid,
                    valid_from: start,
                    valid_until: expire,
                    created_at: created,
                    order_id,
                } = item
                const {
                    name,
                    description,
                    original_price: originalPrice,
                    selling_price: sellingPrice,
                    valid_date: validDate,
                    amount,
                    amount_original,
                    cycle,
                } = (this.productsNowOnSale || {}).all.find(item => item.id === pid)
                const m = this.$moment

                return {
                    id,
                    created,
                    pid,
                    name,
                    originalPrice,
                    sellingPrice,
                    validDate,
                    amount,
                    amount_original,
                    order_id,
                    cycle,
                    description: description ? description.split(',') : null,
                    enabled,
                    startTypeOrigin: start,
                    expireTypeOrigin: expire,
                    start: start ? m(start).format('YY.M.D') : '미정',
                    expire: !enabled
                        ? -1
                        : start
                        ? m(m(m(expire).format('YYYY-MM-DD'))).diff(m().format('YYYY-MM-DD'), 'day')
                        : validDate,
                    // expire: -2,
                }
            })

            return res.sort((a, b) => b.id - a.id)
        },
        userRatePlanRecent() {
            const m = this.$moment
            const tickets = this.products.filter(item => item.ptype === 'ticket')
            const onGoing = this.userRatePlans.find(item => {
                const dateValid = item.startTypeOrigin && m().isBefore(m(item.expireTypeOrigin))
                const ticketsValid = tickets.filter(t => {
                    return item.id === t.related_user_product_id
                })
                const plan = parsePidToPlanName(item.pid)
                if (!dateValid) return false
                if (plan === 'EXTRA') {
                    return getPlanInfo(item.pid).amount ? ticketsValid.length : true
                } else {
                    return plan === 'UNLIMITED' ? true : ticketsValid.length
                }
            })
            const pending = this.userRatePlans.filter(item => item.start === '미정')
            const expired = this.userRatePlans.filter(item => {
                if (!item.enabled) return true

                const dateExpired = item.startTypeOrigin && m().isAfter(m(item.expireTypeOrigin))
                const ticketsValid = tickets.filter(t => {
                    return item.id === t.related_user_product_id
                })
                const plan = parsePidToPlanName(item.pid)
                if (dateExpired) return true
                if (plan === 'EXTRA') {
                    return getPlanInfo(item.pid).amount ? ticketsValid.length === 0 : true
                } else {
                    return plan === 'UNLIMITED' ? true : ticketsValid.length === 0
                }
            })

            if (onGoing) return { ...onGoing, status: 'on-going' }
            if (pending.length) return { ...pending[0], status: 'pending' }
            if (expired.length) return { ...expired[0], status: 'expired' }

            return { pid: 0, name: '현재 진행중인 요금제가 없습니다' }
        },
        userTickets() {
            // const tickets = this.products.filter(item => item.ptype === 'ticket')
            const tickets = this.user.tickets
            // if (this.userRatePlanRecent.pid === 0) {
            return {
                all: tickets.length,
                used: tickets.filter(ticket => !ticket.enabled).length,
                manner: tickets.filter(ticket => ticket.enabeld && ticket.ticket_type === 'manner_ticket').length,
                active: tickets.filter(ticket => ticket.enabled && ticket.ticket_type !== 'manner_ticket').length,
            }
            // }

            // const validTickets = tickets.filter(
            //     t =>
            //         this.userRatePlanRecent.startTypeOrigin <= t.valid_until &&
            //         this.userRatePlanRecent.expireTypeOrigin >= t.valid_until
            // )
            // // console.log('val', validTickets.length, validTickets)
            // return {
            //     all: this.userRatePlanRecent.amount,
            //     used: this.userRatePlanRecent.amount - validTickets.length,
            //     active: validTickets.length,
            // }
        },
    },
}
</script>
<style scoped lang="scss">
.product {
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: #111111;
}
</style>
