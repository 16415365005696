<template>
    <div v-if="timestamp" class="badge last-seen" :class="colorByDays">
        <span v-if="!noText">{{ diff | humanizeSeconds }}</span>
    </div>
</template>
<script>
export default {
    name: 'LastSeen',
    props: ['timestamp', 'noText'],
    data: () => ({
        now: null,
        interv: null,
    }),
    mounted() {
        this.now = this.$moment()
        this.interv = setInterval(_ => {
            this.now = this.$moment()
        }, 1000 * 60)
    },
    beforeDestroy() {
        clearInterval(this.interv)
    },
    computed: {
        colorByDays() {
            if (this.diff > 24 * 60 * 60 * 14) return 'bg-lightest'
            if (this.diff > 24 * 60 * 60 * 7) return 'bg-warning'
            return 'bg-success'
        },
        diff() {
            if (!this.now) {
                return 0
            }

            return this.now.diff(this.timestamp, 'seconds')
        },
    },
}
</script>
