import * as $http from 'axios'

export default {
    all: (chatId, isInterest) => {
        return $http.get(`v2/chats/${chatId}/chat_floatings${isInterest ? '?is_interest=true' : ''}`)
    },
    scheduleModal: chatId => $http.get(`v2/chats/${chatId}/chat_floatings/get_schedule_target`),
    // friendType: 1(normal), 2(daily), 3(recommended)
    create: (chatId, sourceId, friendId) =>
        $http.post(`v2/chats/${chatId}/chat_floatings`, {
            source_id: sourceId,
            friend_id: friendId,
        }),
    delete: (chatId, floatingId, reject) =>
        $http.delete(`v2/chats/${chatId}/chat_floatings/${floatingId}`, {
            params: { reject },
        }),
    deleteAll: (chatId, arr) => $http.delete(`v2/chats/${chatId}/chat_floatings/delete_all`, { data: { ids: arr } }),
}
