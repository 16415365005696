<template>
    <div
        v-if="userRatePlanRecent && userRatePlanRecent.status === 'pending'"
        class="badge-friend-type badge badge-brd"
        :class="{
            recommended: friendType === 'recommended',
            normal: friendType === 'normal',
            'premium-badge': isPremium,
        }"
        v-html="userRatePlanRecent.status === 'pending' ? '날짜입력' : ''"
    />
</template>

<script>
import { getPlanInfo, parsePidToPlanName } from '@/store/data/product'

export default {
    name: 'BadgeFriendType',
    props: ['chat', 'user'],
    computed: {
        friendType() {
            if (this.chat && !this.chat.expire_at) return 'normal'
            else return 'recommended'
        },
        isPremium() {
            return this.user.$$premium
        },
        friendTypeLabel() {
            if (this.user.$$premium) return `FRIEND_TYPE_PREMIUM`

            const chat = this.chat || this.$getChat(this.user.id)
            if (chat && chat.expire_at) {
                if (this.$moment(chat.expire_at).diff(this.$moment(), 'days') === 0) {
                    return `${this.$moment(chat.expire_at).diff(this.$moment(), 'hours')}시간 남음`
                } else {
                    return `D-${this.$moment(chat.expire_at).diff(this.$moment(), 'days')}`
                }
            } else if (chat) {
                return `FRIEND_TYPE_NORMAL`
            } else {
                return `FRIEND_TYPE_RECOMMENDED`
            }
        },
        userRatePlans() {
            if (!this.user.products) return []

            const allRatePlans = ((this.user || {}).products || {}).filter(item => item.ptype === 'rate_plan')
            const productsNowOnSale = this.$store.getters.productsNowOnSale

            const res = allRatePlans.map(item => {
                const {
                    id,
                    enabled,
                    product_id: pid,
                    valid_from: start,
                    valid_until: expire,
                    created_at: created,
                } = item
                const { name, description, original_price: originalPrice, selling_price: sellingPrice, valid_date: validDate, amount, cycle } = (
                    productsNowOnSale || {}
                ).all.find(item => item.id === pid)
                const m = this.$moment

                return {
                    id,
                    created,
                    pid,
                    name,
                    originalPrice,
                    sellingPrice,
                    validDate,
                    amount,
                    cycle,
                    description: description.split(','),
                    enabled,
                    startTypeOrigin: start,
                    expireTypeOrigin: expire,
                    start: start ? m(start).format('YY.M.D') : '미정',
                    expire: !enabled
                        ? -1
                        : start
                        ? m(m(m(expire).format('YYYY-MM-DD'))).diff(m().format('YYYY-MM-DD'), 'day')
                        : validDate,
                    // expire: -2,
                }
            })

            return res.sort((a, b) => b.id - a.id)
        },
        userRatePlanRecent() {
            if (!this.userRatePlans.length) return { pid: 0, name: '현재 진행중인 요금제가 없습니다' }

            const m = this.$moment
            const tickets = this.user.products.filter(item => item.ptype === 'ticket')
            const onGoing = (this.userRatePlans || {}).find(item => {
                const dateValid = item.startTypeOrigin && m().diff(m(item.expireTypeOrigin), 'days') < 0
                const ticketsValid = tickets.filter(t => {
                    return t.valid_until >= item.startTypeOrigin && t.valid_until <= item.expireTypeOrigin
                })
                const plan = parsePidToPlanName(item.pid)
                if (!dateValid) return false
                if (plan === 'EXTRA') {
                    return getPlanInfo(item.pid).amount ? ticketsValid.length : true
                } else {
                    return plan === 'UNLIMITED' ? true : ticketsValid.length
                }
            })
            const pending = this.userRatePlans.filter(item => item.start === '미정')
            const expired = this.userRatePlans.filter(item => {
                if (!item.enabled) return true

                const dateExpired = item.startTypeOrigin && m().diff(m(item.expireTypeOrigin), 'days') > 0
                const ticketsValid = tickets.filter(t => {
                    return t.valid_until >= item.startTypeOrigin && t.valid_until <= item.expireTypeOrigin
                })
                const plan = parsePidToPlanName(item.pid)
                if (dateExpired) return true
                if (plan === 'EXTRA') {
                    return getPlanInfo(item.pid).amount ? ticketsValid.length === 0 : true
                } else {
                    return plan === 'UNLIMITED' ? true : ticketsValid.length === 0
                }
            })

            if (onGoing) return { ...onGoing, status: 'on-going' }
            if (pending.length) return { ...pending[pending.length - 1], status: 'pending' }
            if (expired.length) return { ...expired[expired.length - 1], status: 'expired' }

            return { pid: 0, name: '현재 진행중인 요금제가 없습니다' }
        },
    },
}
</script>
