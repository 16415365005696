<template>
    <div v-if="me" class="edit-profile">
        <div class="page-header">
            <div class="title" v-html="$translate('EDIT_PROFILE')" />
        </div>
        <div class="immutable flex-row items-center">
            <PhotoAndLastSeen class="flex-wrap" :profile="me.photo_url" :gender="me.gender" :userId="me.user_id" />
            <div>
                <div class="name-nickname">
                    <span class="name" v-html="me.name" />
                    <span class="nickname" v-html="`(${me.nickname})`" />
                    <div class="badge display-table" v-html="$translate('PRO')" />
                </div>
                <div class="age-height-gender">
                    <span class="gender" v-html="$translate($options.filters.gender(me.gender).toUpperCase())" />
                </div>
            </div>
        </div>

        <EditPhotos
            v-if="(photos || []).length > 0"
            v-model="photos"
            @onChangeDeletePhotoIds="onChangeDeletePhotoIds"
        />
        <div class="hr" />

        <div class="keywords-intro">
            <div class="step keywords">
                <div class="each-gender flex-fill" :key="gender" v-for="gender in selectedGenders">
                    <div class="gender-container">
                        <div class="top">
                            <div class="title" v-html="$translate(`${gender.toUpperCase()}_INTRO`)" />
                            <div class="intro" v-html="$translate(`${gender.toUpperCase()}_AGENT_INTRO_MOBILE`)" />
                        </div>
                        <TextareaWithAutoTexts
                            @changedText="profileChanged = true"
                            :placeholder="`PLACEHOLDER_${gender.toUpperCase()}_INTRO`"
                            :hideAutotexts="false"
                            v-model="payload[`${gender}Intro`]"
                        />
                    </div>
                    <div class="hr" />
                </div>
            </div>
            <button @click="update" class="btn btn-primary bottom" v-html="$translate('SAVE')" />
        </div>
    </div>
</template>

<script>
import EditPhotos from './EditPhotos'
import profileService from '@/services/agent'

export default {
    name: 'EditProfilePage',
    components: {
        EditPhotos,
    },
    watch: {
        profile: {
            handler(newVal, oldVal) {
                this.profileChanged = !!oldVal
            },
            deep: true,
        },
        me: {
            handler(newVal, oldVal) {
                this.profileChanged = !!oldVal
                this.init()
                this.initPayload()
            },
            deep: true,
        },
    },
    data: () => ({
        profileChanged: false,
        disabled: false,
        deletePhotoIds: [],
        photos: [],
        payload: {
            maleIntro: null,
            femaleIntro: null,
        },
    }),
    computed: {
        me() {
            return this.$store.getters.agent || {}
        },

        profile() {
            return this.me.profile || {}
        },

        selectedGenders() {
            return ['female', 'male']
        },
    },
    mounted() {
        this.init()
        this.initPayload()
        this.$registerBackHandler(this.customBackAction)
        this.$nativeBridge.postMessage({
            action: 'allowSwipeGesture',
            value: false,
        })
    },
    beforeDestroy() {
        // 나갈 때 최신 프로필로 상태 업데이트
        this.$store.dispatch('loadAgentMe')
        this.$nativeBridge.postMessage({
            action: 'allowSwipeGesture',
            value: true,
        })
    },
    methods: {
        customBackAction() {
            if (!this.profileChanged) return true

            this.$modal
                .basic({
                    title: 'MODAL_DEFAULT_TITLE',
                    body: this.$translate('WARNING_GO_BACK'),
                    buttons: [
                        {
                            label: 'CANCEL',
                            class: 'btn-default',
                        },
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
                .then(idx => {
                    if (idx === 1) {
                        this.$unregisterBackHandler()
                        this.$router.go(-1)
                    }
                })
        },
        init() {
            try {
                this.photos = this.me.photos
            } catch (e) {
                this.$toast.error(e.data)
            }
        },
        onChangeDeletePhotoIds(deletePhotoIds) {
            this.deletePhotoIds = deletePhotoIds
            this.profileChanged = true
        },
        preparedPayload() {
            this.sections.reduce((result, keys) => (result || []).concat(keys))
            const form = new FormData()
            form.append('id', this.profile.id)
            form.append('intro', this.profile.intro || '')

            this.me.photos.filter(p => p.blob).forEach((p, idx) => form.append(`photo${idx + 1}`, p.blob, p.fileName))
            form.append('delete_photo_ids', JSON.stringify(this.deletePhotoIds))

            return form
        },

        async update() {
            const payload = new FormData()
            payload.append('male_intro', this.payload.maleIntro ? this.payload.maleIntro : '')
            payload.append('female_intro', this.payload.femaleIntro ? this.payload.femaleIntro : '')

            this.photos.filter(p => p.blob).forEach((p, idx) => payload.append(`photo${idx + 1}`, p.blob, p.fileName))
            payload.append('delete_photo_ids', JSON.stringify(this.deletePhotoIds))
            try {
                this.$loading(true)
                await profileService.updateProfile(payload)
                this.$store.dispatch('loadAgentMe')
                this.$loading(false)
                this.$toast.success('UPDATE_SUCCESS_AGENT_PROFILE')
                this.$unregisterBackHandler()
                this.$router.go(-1)
            } catch (e) {
                this.$toast.error(e.data)
            }
        },
        initPayload() {
            if (!this.me || this.me === {}) return
            this.payload.maleIntro = this.me.male_intro
            this.payload.femaleIntro = this.me.female_intro
        },
    },
}
</script>
