<template>
    <div class="chat flex-row">
        <!-- jobCategories being loaded means constants are prepared -->
        <Chats v-if="!isMobile && $store.getters.jobCategories" />
        <ChatsMobile v-else-if="isMobile && $store.getters.jobCategories" />
        <Chatroom v-if="!isMobile" />
        <div
            v-if="agent.show_me_the_money || agent.is_premium || agent.id === 9"
            class="as-card"
            :class="{ 'instant-chat': isInstantChat() }"
        >
            <!-- 여기다가 윗텝 넣을까? 그럼 여기거 클릭해서 선택했을떄 인트로듀씽 유저 까지 퍼트릴 방법이 있을까? getter? 그래서 그냥 인트로듀싱 유저 안에?-->
            <!-- <div class="meeting-feedback" v-if="isOpen">
            </div> -->
            <MeetingFeedbackList />
            <div>
                <AcceptedUser v-if="!isMobile && !isAlimi && chat.expire_at" />

                <IntroducingUser
                    :user="introducingUser"
                    v-if="!isMobile && !isAlimi"
                    @closeIntroducingUser="closeIntroducingUser"
                />
            </div>
            <SuperFilter
                v-if="showFilter && !isAlimi && !introducingUser"
                :filterChanged.sync="filterChanged"
                :filters="chat.$$filters || defaultFilters()"
            />
            <!-- <Friends v-if="!isMobile && !isAlimi && !isInstantChat()" :filterChanged="filterChanged" /> -->
            <Friends v-if="!isMobile && !isAlimi" :filterChanged="filterChanged" />
        </div>
    </div>
</template>

<script>
import Chats from './chats/Chats'
import ChatsMobile from '@/routes/mobile/chat/chats/ChatsMobile'
import Chatroom from './chatroom/ChatroomPage'
import AcceptedUser from './../../components/common/AcceptedUser'
import IntroducingUser from './introducing-user/IntroducingUser'
import Friends from './friends/FriendsPage'
import * as helpers from '@/store/data/helpers'
import MeetingFeedbackList from '@/routes/chat/meeting-feedback/MeetingFeedbackList'

export default {
    name: 'ChatPage',
    components: {
        Chats,
        ChatsMobile,
        Chatroom,
        AcceptedUser,
        IntroducingUser,
        Friends,
        MeetingFeedbackList,
    },
    data: () => ({
        filterChanged: false,
        introduce: {},
    }),
    computed: {
        introducingUser() {
            return this.chat.$$introducingUser
        },
        showFilter() {
            return this.$store.getters.showFriendsFilter
        },
        selectedTabFriends() {
            return this.$store.getters.selectedTabFriends
        },
        chat() {
            return this.$store.getters.chat || {}
        },
        isMobile() {
            return this.$store.getters.isMobile
        },
        isAlimi() {
            return ((this.$store.getters.chat || {}).user || {}).id === 0
        },
        agent() {
            return this.$store.getters.agent || {}
        },
    },
    methods: {
        isInstantChat() {
            return this.$store.getters.chat && this.$store.getters.chat.expire_at
        },
        async test(source) {
            return await this.$store.dispatch('loadUserDetail', { userId: source })
            // this.introduce = await this.$store.dispatch('loadUserDetail')
        },
        defaultFilters() {
            // This is just for preventing javascript errors,
            // once chat.$$filters is set, this won't be used anymore.
            return helpers.getDefaultFilters(this.chat.user.gender, false)
        },
        closeIntroducingUser() {
            this.$store.dispatch('closeIntroducingUser')
        },
    },
}
</script>
<style scoped lang="scss">
.as-card {
    position: relative;
}
</style>
