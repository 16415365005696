<template>
    <div class="header-bar flex-row" :class="[{ root: isRoot }, { 'instant-chat': isInstantChat() }]">
        <div @click="onClickLeftButton" class="left flex-row items-center flex-wrap">
            <i
                class="zmdi flex-wrap"
                :class="isRoot ? '' : $route.name === 'EditProfilePage' ? 'zmdi-close' : 'zmdi-chevron-left'"
            />
        </div>
        <div class="title">
            <div v-html="$translate(hideTitle ? '' : title)" />
            <div v-if="isChat" class="badges-wrapper flex-row">
                <LastSeen class="m-l-6 flex-wrap" :timestamp="chat.user.last_sign_in_at" />
            </div>
        </div>

        <div class="right flex-row items-center flex-wrap">
            <i v-if="isRoot" @click="onClickNotification" class="zmdi zmdi-notifications-none p-l-16" />
            <button
                class="c-primary"
                v-if="$route.name === 'AgentProfile'"
                @click="$bus.$emit('onClickUpdateAgentProfile')"
                v-html="$translate('SAVE')"
            />
            <template v-if="isChat">
                <div class="bookmark m-r-10" @click="onClickBookmark">
                    <img :src="bookmarkIcon" />
                </div>
                <div @click="onClickChatMenu">
                    <i class="material-icons">more_horiz</i>
                </div>
            </template>
            <div class="interest-removeall f-16" v-if="floatings" @click="deleteInterestAll">전체삭제</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HeaderBar',
    data: () => ({
        title: 'VANILLABRIDGE',
        customTitle: null,
    }),
    watch: {
        $route() {
            this.initTitle()
        },
        chat() {
            this.initTitle()
        },
    },
    computed: {
        chat() {
            return this.$store.getters.chat
        },
        floatings() {
            if (!this.chat) return false

            return this.chat.$$floatingsInterest.length && this.$route.name === 'InterestPage'
        },
        isRoot() {
            return (
                ['HomePage', 'ChatPage', 'MyPage', 'SettingsPage', 'SettingsPageMobile'].indexOf(this.$route.name) !==
                -1
            )
        },
        hideTitle() {
            return ['ChatroomPage'].indexOf(this.$route.name) === -1
        },
        isChat() {
            return this.$route.name === 'ChatroomPage' && this.chat && this.chat.user.id !== 0
        },
        bookmarkIcon() {
            return require(`@/assets/images/chatroom/chat_bookmark_${this.chat.bookmark ? 'active' : 'inactive'}.svg`)
        },
    },
    mounted() {
        this.initTitle()
        this.nativeBridgeUpdate()
    },
    methods: {
        deleteInterestAll() {
            if (!this.floatings) return
            this.$bus.$emit('deleteAll')
        },
        isInstantChat() {
            return this.$store.getters.chat && this.$store.getters.chat.expire_at && this.$route.name === 'ChatroomPage'
        },
        nativeBridgeUpdate() {
            if (this.isInstantChat()) {
                this.$nativeBridge.postMessage({
                    action: 'setBackgroundColor',
                    value: {
                        top: '#000000',
                        bottom: '$grey-09',
                    },
                })
            } else {
                this.$nativeBridge.postMessage({
                    action: 'setBackgroundColor',
                    value: '#FFFFFF',
                })
            }
        },
        initTitle() {
            this.updateCustomTitle()
            this.title = this.customTitle || this.$case.toSnake(this.$route.name).toUpperCase()
            this.customTitle = ''
        },
        onClickLeftButton() {
            if (this.isRoot) {
                return
            }

            this.$router.go(-1)
        },
        onClickNotification() {
            if (this.isRoot) {
                this.$bus.$emit('toggleNotifications')
            }
        },
        updateCustomTitle() {
            if (this.$route.name === 'ChatroomPage' && this.chat) {
                this.customTitle = `<span>${this.$nameOrNick(this.chat.user)}</span>`

                if (!(this.chat.user || {}).id) return

                const userInfo = this.isInstantChat()
                    ? `<span class="f-regular f-15">, ${this.$options.filters.asAge(
                          this.chat.user.profile.birthday
                      )}</span>`
                    : `<span class="f-regular f-15 m-l-8">(
                    ${this.$options.filters.asAge(this.chat.user.profile.birthday)},
                    ${this.$options.filters.noDetailRegion(this.chat.user.profile.region_name)}
                )</span>`

                this.customTitle = this.customTitle + userInfo
            }
        },
        onClickChatMenu() {
            this.$actionSheet({
                buttons: [
                    {
                        label: '메모하기',
                        handler: () => {
                            this.$bus.$emit('toggleMemo')
                        },
                    },
                    {
                        label: this.$translate('REPORT_USER'),
                        class: 'c-red',
                        handler: () => {
                            this.$router.push({
                                name: 'ReportUserPage',
                                params: { user: this.$store.getters.chat.user },
                            })
                        },
                    },
                ],
            })
        },
        async onClickBookmark() {
            const bookmark = !this.chat.bookmark

            try {
                this.$loading(true)
                await this.$store.dispatch('bookmarkChat', { chat: this.chat, bookmark })
                this.$toast.success(`북마크${bookmark ? ' ' : '가 해제'}되었습니다`)
            } catch (e) {
                this.$toast.error(e.data)
            } finally {
                this.$loading(false)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.img-profile {
    width: 32px !important;
    height: 32px;
    margin-right: 8px;
    border-radius: 40%;
}
</style>
