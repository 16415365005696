import Vue from 'vue'
import router from '@/router'
import * as filters from '@/filters/index'
import chatFloatingService from '@/services/chat-floating'
import agentService from '@/services/agent'
import chatService from '@/services/chat'
import userService from '@/services/user'
import autoTextService from '@/services/auto-text'
import * as helpers from './helpers'

const defaultState = () => ({
    chats: null,
    chatList: null, // Only for checking if open chat exists
    chat: null,
    message: null, // => last message from WebSocket
    connected: false,
    chatSearchValue: null,
    chatFilters: {
        type: 'ALL',
        lastSign: {
            label: 'LOGIN_STATUS_GREEN',
            selected: false,
        },
        male: {
            label: 'MALE',
            selected: false,
        },
        female: {
            label: 'FEMALE',
            selected: false,
        },
        todayGetUsers: {
            label: 'TODAY_GET_USERS',
            selected: false,
        },
        managerConfirm: {
            label: 'MANAGER_CONFIRM',
            selected: false,
        },
        smManager: {
            label: 'SMMANAGER',
            selected: false,
        },
        jmManager: {
            label: 'JMMANAGER',
            selected: false,
        },
        allday: {
            label: 'ALLDAY',
            selected: false,
        },
        monday: {
            label: 'MONDAY',
            selected: false,
        },
        tuesday: {
            label: 'TUESDAY',
            selected: false,
        },
        wednesday: {
            label: 'WEDNESDAY',
            selected: false,
        },
        thursday: {
            label: 'THURSDAY',
            selected: false,
        },
        friday: {
            label: 'FRIDAY',
            selected: false,
        },
        odd: {
            label: 'ODD',
            selected: false,
        },
        even: {
            label: 'EVEN',
            selected: false,
        },
        notToday: {
            label: 'NOT_TODAY',
            selected: false,
        },
        lastWeek: {
            label: 'LOGIN_STATUS_ORANGE',
            selected: false,
        },
        friends: {
            label: 'FRIENDS',
            selected: false,
        },
        dDayFriends: {
            label: 'D_DAY_FRIENDS',
            selected: false,
        },
        isAi: {
            label: 'IS_AI',
            selected: false,
        },
        capitalArea: {
            label: 'CAPITAL_AREA',
            selected: false,
        },
        hasTicket: {
            label: 'HAS_TICKET',
            selected: false,
        },
        isPremium: {
            label: 'FRIEND_TYPE_PREMIUM',
            selected: false,
        },
        harvest: {
            label: 'HARVEST',
            selected: false,
        },
        bothLike: {
            label: 'BOTH_LIKE',
            selected: false,
        },
        waitDating: {
            label: 'WAIT_DATING',
            selected: false,
        },
        bookmark: {
            label: 'BOOKMARK',
            selected: false,
        },
        // prepaid: {
        //     label: 'PREPAID',
        //     selected: false,
        // },
        // postpaid: {
        //     label: 'POSTPAID',
        //     selected: false,
        // },
        exceptUnlimited: {
            label: 'EXCEPT_UNLIMITED',
            selected: false,
        },
        unlimited: {
            label: 'UNLIMITED',
            selected: false,
        },
        created: {
            label: 'CREATED',
            selected: false,
        },
        complete_profile: {
            label: 'COMPLETE_PROFILE',
            selected: false,
        },
        ongoing_verification: {
            label: 'ONGOING_VERIFICATION',
            selected: false,
        },
        judging: {
            label: 'JUDGING',
            selected: false,
        },
        judged: {
            label: 'JUDGED',
            selected: false,
        },
        confirmed: {
            label: 'CONFIRMED',
            selected: false,
        },
        failed: {
            label: 'FAILED',
            selected: false,
        },
        // 상세검색
        detail: {
            matchCount: {
                label: 'MATCH_COUNT',
                value: 0,
            },
            photoCount: {
                label: 'PHOTO_COUNT',
                value: 0,
            },
            agentCount: {
                label: 'AGENT_COUNT',
                value: 0,
            },
            refundRate: {
                label: 'REFUND_RATE',
                value: 0,
            },
            payRate: {
                label: 'PAY_RATE',
                value: 0,
            },
        },
    },
    scrollTopChats: null,
    userLists: [],
    isMannerFeedbackOpen: false,
})

const state = defaultState()

const getters = {
    chats: state => state.chats,
    chatList: state => state.chatList,
    chat: state => state.chat,
    connected: state => state.connected,
    chatSearchValue: state => state.chatSearchValue,
    chatFilters: state => state.chatFilters,
    scrollTopChats: state => state.scrollTopChats,
    lastChat: state => ((state.chats || []).length > 0 ? state.chats[state.chats.length - 1] : null),
    userLists: state => state.userLists || [],
    isMannerFeedbackOpen: state => state.isMannerFeedbackOpen,
}

const actions = {
    async loadChatList({ commit }) {
        try {
            const data = await chatService.simple()
            commit('setChatList', data)
        } catch (e) {
            Promise.reject(e)
        }
    },
    async loadChats({ state, getters, commit }, options) {
        const force = (options || {}).force
        const loadMore = (options || {}).loadMore
        if (!force && helpers.canSkipApiCall(state, 'chats')) return

        try {
            const payload = helpers.chatFiltersToServerFilterPayload({
                chatSearchValue: state.chatSearchValue,
                chatFilters: state.chatFilters,
                limit: 30,
            })

            if (loadMore) {
                payload.last_updated_at = getters.lastChat.last_message.updated_at
            }

            commit('setLoading', { chats: true })
            const chats = await chatService.all(payload)
            commit('setChats', loadMore ? (state.chats || []).concat(chats) : chats)
            if (state.agent) {
                commit('setPremiumProducts')
            }
            // loadStoredFilters uses these constants, so these must be loaded first
            // If not, next dispatch('loadChats') will trigger loadStoredFilters
            if (['religions', 'jobCategories', 'schoolTypes', 'provinces'].every(key => state[key])) {
                helpers.loadStoredFilters(state.chats)
            }
            // helpers.loadStoredSort(state.chats)
        } catch (e) {
            return Promise.reject(e)
        } finally {
            commit('setLoading', { chats: false })
        }
    },
    /* payload: { id, is_private } */
    async loadChat({ state, dispatch, commit, getters }, { userId, introducingUserId }) {
        try {
            if (state.chat && state.chat.user.id === userId) {
                return
            }

            if (!state.chats) {
                await dispatch('loadChats')
            }

            let chat = (state.chats || []).find(c => c.user.id === userId)
            if (!chat) {
                chat = await dispatch('findChat', { userId })
            }
            if (!chat) {
                return Promise.reject(filters.translate('ERROR_NON_EXIST_CHAT'))
            }
            commit('setChat', chat)

            if (getters.isMobile && router.currentRoute.name !== 'ChatroomPage') {
                setTimeout(() => router.push({ name: 'ChatroomPage' }))
            }

            if (!chat.$$messages) {
                dispatch('loadMessages')
            }

            if (!userId) return

            commit('updateDistance', chat.user.location)
            dispatch('loadBadges')
            if (!chat.expire_at) dispatch('loadChatFloatings', chat.id)
            dispatch('loadChatStat', chat)

            if (!chat.$$filters) {
                helpers.setDefaultFiltersOnChat(chat)
            }

            if (!state.chat.$$recommendsFromDB) {
                dispatch('loadRecommendsFromDB', helpers.filtersToSuperSearchPayload(chat.$$filters))
            }
            // if (!state.chat.$$aiRecommendsFromDB) dispatch('loadAIRecommendsFromDB')
            if (!state.chat.$$AIRecommends) dispatch('loadAIRecommends') // 필터삭제

            if (!chat.$$introducingUser && !introducingUserId) {
                introducingUserId = helpers.getIntroducingUserIdFromLocalStorage(chat)
            }
            dispatch('loadFeedbackTarget')
            dispatch('loadMannerFeedbacks')

            if (router.currentRoute.name === 'ChatPage' && introducingUserId && !chat.expire_at) {
                dispatch('loadIntroducingUser', introducingUserId)
            }
        } catch (e) {
            return Promise.reject(e)
        }
    },

    async loadMannerFeedbacks({ state, commit }) {
        if (!state.chat || state.chat.expire_at) return

        try {
            commit('setLoading', { MannerFeedbackList: true })
            const currentChatId = state.chat.id
            // api 완성된거 붙이기
            const data = await chatService.getUserFeedbacks(currentChatId)
            commit('setMannerFeedbackOpen', false)
            // console.log(data.data)
            if (!data.length) return
            commit('setMannerFeedbacks', data)
        } catch (e) {
            return Promise.reject(e)
        } finally {
            commit('setLoading', { MannerFeedbackList: false })
        }
    },
    async loadFeedbackTarget({ state, commit }) {
        if (!state.chat || state.chat.expire_at) return

        try {
            commit('setLoading', { FeedbackTarget: true })
            const currentChatId = state.chat.id
            // api 완성된거 붙이기
            const data = await chatService.getFeedbackTarget(currentChatId)
            // console.log(data.data)
            if (!data.length) return

            commit('setFeedbackTarget', data)
        } catch (e) {
            return Promise.reject(e)
        } finally {
            commit('setLoading', { FeedbackTarget: false })
        }
    },
    async loadChatStat({ state }, chat) {
        if (!state.chat) return

        try {
            const currentChatId = state.chat.id
            const stat = await chatService.stat(chat.id)
            if (state.chat.id !== currentChatId) return
            Vue.set(state.chat, '$$stat', stat)
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async findChat(_, { userId, chatId }) {
        try {
            const chat = userId ? await userService.findChat(userId) : await chatService.detail(chatId)
            Vue.set(chat.user, '$$chatId', chat.id)
            Vue.set(chat.user, '$$premium', Vue.options.filters.hasProduct(chat.user, 'premium'))
            Vue.set(chat.user, '$$privacy', Vue.options.filters.hasProduct(chat.user, 'privacy'))
            return Promise.resolve(chat)
        } catch (e) {
            if (e.data.key === 'unavailable_user') {
                return Promise.reject(e)
            }

            // Resolve even if it's not found.
            return Promise.resolve({})
        }
    },
    async handleNewChat({ state, dispatch, commit }, message) {
        if (!state.chats) {
            await dispatch('loadChats')
        }

        const existingChat = (state.chats || []).find(chat => chat.id === message.chat_id)
        if (existingChat) return

        const chat = await dispatch('findChat', { chatId: message.chat_id })
        if ((state.chats || []).find(existing => existing.id === chat.id)) return
        if (!(state.chatList || []).find(existing => existing.id === chat.id)) {
            commit('setChatList', [
                { id: chat.id, user_id: chat.user.id, expire_at: chat.expire_at, memo: chat.memo },
                ...state.chatList,
            ])
        }

        // if (helpers.shouldThisChatBeIgnored(chat, state.chatFilters)) return

        commit('setChats', [chat, ...state.chats])
    },
    async loadChatFloatings({ state, commit }, chatId) {
        const currentChatId = state.chat.id
        const data = await chatFloatingService.all(chatId, true)
        if (!data || state.chat.id !== currentChatId) return

        commit('setChatFloatings', data)
        return Promise.resolve((data || []).length)
    },
    // 이거는 '서로호감 플로팅' 지우는 처리를 하는 별개의 로직임
    // (모바일에서는 deleteChatFloating을, 데스크톱에서는 deleteChatFloatingInterest를 실행해야함)
    async deleteChatFloatingInterest({ dispatch, getters }, { chat, floating, reject }) {
        const findAndDelete = floating => {
            const posExisting = (chat.$$floatingsInterest || []).findIndex(f => f.id === floating.id)
            if (posExisting < 0) return
            chat.$$floatingsInterest.splice(posExisting, 1)
        }

        const onConfirm = async () => {
            try {
                const data = await chatFloatingService.delete(chat.id, floating.id, reject)
                Vue.prototype.$toast.success(data.msg)
                findAndDelete(floating)
            } catch (e) {
                Vue.prototype.$toast.error(e.data)
            }
        }

        try {
            onConfirm()
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async updateRejectMessage({ dispatch, getters }, { rejectMessage, gender }) {
        try {
            const genderSuffix = gender === 'male' ? '남성' : '여성'
            const rejectMessageTemplate =
                gender === 'male'
                    ? getters.autoTextsAllCategory.reject_message_male
                    : getters.autoTextsAllCategory.reject_message_female
            if (
                rejectMessageTemplate === null ||
                rejectMessageTemplate === undefined ||
                rejectMessageTemplate.length === 0
            ) {
                const data = await autoTextService.post({
                    title: `거절메시지_${genderSuffix}`,
                    content: rejectMessage,
                    category: `reject_message_${gender}`,
                })

                getters.autoTextsAllCategory[`reject_message_${gender}`] = data
            } else {
                await autoTextService.deleteAll(`reject_message_${gender}`)

                getters.autoTextsAllCategory[`reject_message_${gender}`] = null
            }

            if (rejectMessage && rejectMessage.length > 0)
                Vue.prototype.$toast.success(filters.translate('UPDATE_SUCCESS_REJECT_MESSAGE'))
        } catch (e) {
            Vue.prototype.$toast.error(e.data)
            return Promise.reject(e)
        }
    },
    async updateSpreadMessage({ dispatch, getters }, { id, spreadMessage, gender }) {
        try {
            const genderSuffix = gender === 'male' ? '남성' : '여성'
            const spreadMessageTemplate =
                gender === 'male'
                    ? getters.autoTextsAllCategory.spread_message_male
                    : getters.autoTextsAllCategory.spread_message_female
            if (spreadMessageTemplate === undefined || spreadMessageTemplate.length === 0) {
                await autoTextService.post({
                    title: `뿌리기메시지_${genderSuffix}`,
                    content: spreadMessage,
                    category: `spread_message_${gender}`,
                })
            } else {
                await autoTextService.put({
                    id: id,
                    title: `뿌리기메시지_${genderSuffix}`,
                    content: spreadMessage,
                    category: `spread_message_${gender}`,
                })
            }

            if (spreadMessage !== '') Vue.prototype.$toast.success(filters.translate('UPDATE_SUCCESS_SPREAD_MESSAGE'))
        } catch (e) {
            Vue.prototype.$toast.error(e.data)
            return Promise.reject(e)
        }
    },
    async updateHelloMessage({ dispatch, getters }, { helloMessage, gender }) {
        try {
            const genderSuffix = gender === 'male' ? '남성' : '여성'
            const helloMessageTemplate =
                gender === 'male'
                    ? getters.autoTextsAllCategory.intro_new_user_male
                    : getters.autoTextsAllCategory.intro_new_user_female
            if (
                helloMessageTemplate === null ||
                helloMessageTemplate === undefined ||
                helloMessageTemplate.length === 0
            ) {
                const data = await autoTextService.post({
                    title: `신규회원메시지_${genderSuffix}`,
                    content: helloMessage,
                    category: `intro_new_user_${gender}`,
                })

                getters.autoTextsAllCategory[`intro_new_user_${gender}`] = data
            } else {
                await autoTextService.deleteAll(`intro_new_user_${gender}`)

                getters.autoTextsAllCategory[`intro_new_user_${gender}`] = null
            }

            if (helloMessage && helloMessage.length > 0)
                Vue.prototype.$toast.success(filters.translate('UPDATE_SUCCESS_HELLO_MESSAGE'))
        } catch (e) {
            Vue.prototype.$toast.error(e.data)
            return Promise.reject(e)
        }
    },

    async updateSalesTemplate({ dispatch, getters }, { salesMessage, userId }) {
        try {
            if (salesMessage === null || salesMessage === undefined || salesMessage.length === 0) {
                await autoTextService.deleteSalesTemplate({ params: { category: `sales_template`, userId: userId } })

                let newSalesTemplates = []
                if (
                    getters.autoTextsAllCategory.sales_template &&
                    getters.autoTextsAllCategory.sales_template.length > 0
                ) {
                    newSalesTemplates = getters.autoTextsAllCategory.sales_template.filter(
                        t => t.title === `세일즈템플릿_${userId}`
                    )
                    newSalesTemplates === []
                        ? (getters.autoTextsAllCategory.sales_template = null)
                        : (getters.autoTextsAllCategory.sales_template = newSalesTemplates)
                }
            } else {
                await autoTextService.post({
                    title: `세일즈템플릿_${userId}`,
                    content: salesMessage,
                    category: `sales_template`,
                    userId: userId,
                })
            }

            if (salesMessage && salesMessage.length > 0) {
                Vue.prototype.$toast.success(filters.translate('UPDATE_SUCCESS_SALES_MESSAGE'))
            } else {
                Vue.prototype.$toast.success(filters.translate('RESET_SUCCESS_SALES_MESSAGE'))
            }
        } catch (e) {
            Vue.prototype.$toast.error(e.data)
            return Promise.reject(e)
        }
    },

    async loadContacts({ state, commit }) {
        try {
            // const contacts = await agentService.sharedContacts(state.user.id, state.chat.user.id)
            const contacts = await agentService.sharedContacts()
            commit('setContacts', contacts)
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async addMessage({ state, commit }, message) {
        if (!state.chats) return
        const chatId = message.chat_id || message.message.chat_id
        let chat
        if ((state.chat || {}).id === chatId) {
            // 이 경우에는 $$messages가 세팅되어있는 state.chat을 써야함.
            // $$messages는 채팅방을 클릭해서 로드할때만 세팅되기 때문에,
            // 아래 else 분기를 타면 거기서 발견된 chat은 $$messages가 없음.
            chat = state.chat
        } else {
            chat = (state.chats || []).find(c => c.id === chatId)
        }

        // addMessage실행 전, handleNewChat이 이미 목록에 있는 chat이든 없던 챗이든 항상 맨 위로 끌어올리므로
        // state.chats 내에 메시지를 새로 추가할 채팅방이 반드시 존재해야함
        if (!chat) return

        commit('addMessage', {
            chat,
            message,
        })
    },
    async loadMessages({ state }, payload) {
        if (!state.chat) return

        try {
            const firstId = (payload || {}).firstId
            const currentChatId = state.chat.id
            const data = await chatService.messages(state.chat.id, firstId)
            const messages = (data || {}).messages
            if ((messages || []).length === 0 || state.chat.id !== currentChatId) return

            Vue.set(state.chat, '$$loadMore', (data || {}).load_more)
            Vue.set(state.chat, '$$firstMessageId', firstId)
            if (!state.chat.$$messages) {
                Vue.set(state.chat, '$$messages', messages)
                return
            }

            if (state.chat.$$messages.find(m => m.id === messages[0].id)) {
                return
            }

            state.chat.$$messages = state.chat.$$messages.concat(messages)
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async loadIntroducingUser({ state, dispatch, commit }, userId) {
        if (!userId) return
        if (state.chat && state.chat.$$introducingUser && state.chat.$$introducingUser.id === userId) return

        try {
            const currentChatId = state.chat.id
            const user = await dispatch('loadUserDetail', { userId })
            if (currentChatId !== state.chat.id) return
            const chat = (state.chatList || []).find(c => c.user_id === userId)
            if (chat) Vue.set(user, '$$chatId', chat.id)
            commit('setIntroducingUser', user)
            return Promise.resolve(user)
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async loadPremiumAcceptedUser({ state, dispatch, commit }, userId) {
        if (!userId) return
        if (state.chat && state.chat.$$introducingUser && state.chat.$$introducingUser.id === userId) return

        try {
            const user = await dispatch('loadUserDetail', { userId })
            const chat = (state.chatList || []).find(c => c.user_id === userId)
            if (chat) Vue.set(user, '$$chatId', chat.id)
            commit('setPremiumAcceptedUser', user)
            return Promise.resolve(user)
        } catch (e) {
            return Promise.reject(e)
        }
    },
    /* payload: { id, is_private } */
    async loadUserDetail({ state, commit }, { userId }) {
        try {
            let user = state.userLists.find(u => u.id === userId)
            user = await userService.detail(userId)
            const badges = await userService.getVerificationBadge(userId)
            const chat = (state.chatList || []).find(c => c.user_id === userId)

            if (chat) Vue.set(user, '$$chatId', chat.id)
            Vue.set(user, '$$premium', Vue.options.filters.hasProduct(user, 'premium'))
            Vue.set(user, '$$privacy', Vue.options.filters.hasProduct(user, 'privacy'))
            Vue.set(user, '$$verification_badges', badges)
            commit('setUserLists', [user, ...state.userLists])

            if (state.chat) {
                user.$$distance = Vue.prototype.$distance(state.chat.user.location, user.location)
            }
            return Promise.resolve(user)
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async loadUserImages({ dispatch }, userId) {
        const profile = await dispatch('loadUserDetail', { userId })

        const photos = profile.photos
        if (!photos || photos.length === 0) return

        return Promise.resolve(photos)
    },
    async closeIntroducingUser({ state, commit }) {
        commit('setIntroducingUser', null)
        helpers.removeIntroducingUserInLocalStorage(state.chat)
    },
    async closePremiumAcceptedUser({ state, commit }) {
        commit('setPremiumAcceptedUser', null)
        helpers.removePremiumAcceptedUserInLocalStorage(state.chat)
    },
    async checkAlreadyIntroduced({ state }, { source, callbacks }) {
        try {
            const send = async () => {
                if (!Vue.prototype.$preferAge(state.chat.user, source)) {
                    await Vue.prototype.$modal
                        .basic({
                            body: Vue.prototype.$translate('NOT_PREFER_USER'),
                            buttons: [
                                {
                                    label: 'CANCEL',
                                    class: 'btn-default',
                                },
                                {
                                    label: 'SEND',
                                    class: 'btn-primary',
                                },
                            ],
                        })
                        .then(idx => {
                            if (idx === 1) callbacks.sendLinkedPhotoMessage()
                        })
                } else callbacks.sendLinkedPhotoMessage()
            }
            const data = await chatService.checkPhotoData({
                user_id: state.chat.user.id,
                source_id: source.id,
            })

            // 소개 이력 없음 (보내도 됨 ok)
            if (data.status === 'ok') {
                send()
                // 인자가 true이면 모달 띄우지 않고 강제삭제
                callbacks.deleteChatFloatingInterest()
                return
            }

            let buttons = [
                {
                    label: 'CANCEL',
                    class: 'btn-default',
                },
                {
                    label: 'CONFIRM',
                    class: 'btn-primary',
                },
            ]

            let title = 'MODAL_ALREADY_INTRODUCED'

            if (data.status === 'me') {
                buttons[1].label = 'SEND'
            }

            if (data.msg === 3) {
                title = 'MODAL_ALREADY_REJECTED'
            }

            if (data.msg === 4 || data.msg === 5 || data.msg === 6) {
                title = 'MODAL_BLOCKED_CONTACTS'
                buttons = [
                    {
                        label: 'CONFIRM',
                        class: 'btn-primary',
                    },
                ]
            }

            if (data.msg === 7) {
                title = 'SUPER_PRIVACY_ON'
                buttons = [
                    {
                        label: 'CONFIRM',
                        class: 'btn-primary',
                    },
                ]
            }

            if (data.msg === 8) {
                title = 'MODAL_BLOCKED_STYLE'
                buttons = [
                    {
                        label: 'CANCEL',
                        class: 'btn-default',
                    },
                    {
                        label: 'CONFIRM',
                        class: 'btn-primary',
                    },
                ]
            }

            setTimeout(_ => {
                Vue.prototype.$modal
                    .basic({
                        title,
                        body: data.message,
                        buttons: buttons,
                    })
                    .then(selectedBtnIdx => {
                        if (data.msg === 4 || data.msg === 5 || data.msg === 6 || data.msg === 7) return
                        if (selectedBtnIdx === 1) send()
                        if (data.status === 'me' || selectedBtnIdx === 1) callbacks.deleteChatFloatingInterest()
                    })
            })
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async bookmarkChat({ getters, commit }, { chat, bookmark }) {
        try {
            // 디비 업데이트
            await chatService.update(chat.id, { bookmark })

            // 메모리에 들고있는 상태 업데이트
            const chats = [...getters.chats]
            const chatIndex = chats.findIndex(c => c.id === chat.id)
            const thisChat = chats[chatIndex]

            thisChat.bookmark = bookmark
            chats.splice(chatIndex, 1, thisChat)
            commit('setChat', thisChat)
            commit('setChats', chats)
        } catch (e) {
            return e
        }
    },
}

const mutations = {
    closeOpenedChat(state) {
        state.chat = null
    },
    setChats(state, chats) {
        state.chats = chats.map(c => {
            Vue.set(c.user, '$$chatId', c.id)
            return c
        })
    },
    setChatList(state, chatList) {
        state.chatList = chatList
    },
    setUserLists(state, userLists) {
        state.userLists = userLists
    },
    setChat(state, chat) {
        if (!chat) {
            state.chat = null
            return
        }
        state.chat = chat
        const unreadTemp = state.chat.unread

        // 여기까지 읽었습니다 표시를 위해 unread를 임시로 저장
        if (unreadTemp > 0) {
            Vue.set(state.chat, '$$unread', unreadTemp)
        } else {
            Vue.set(state.chat, '$$unread', 0)
        }

        if (state.chats) {
            state.chats.forEach(c => Vue.set(c, '$$selected', c.id === chat.id))
        }
        Vue.prototype.$bus.$emit('updateUnread')
        Vue.set(state.chat.user, '$$premium', Vue.options.filters.hasProduct(chat.user, 'premium'))
        Vue.set(state.chat.user, '$$privacy', Vue.options.filters.hasProduct(chat.user, 'privacy'))
    },
    removeChat(state, chatId) {
        if (!chatId) {
            return
        }
        if (state.chat && state.chat.id === chatId) {
            state.chat = null
        }

        let idx = (state.chats || []).findIndex(c => c.id === chatId)
        if (idx > -1) {
            state.chats.splice(idx, 1)
        }
        idx = (state.chatList || []).findIndex(c => c.id === chatId)
        if (idx > -1) {
            state.chatList.splice(idx, 1)
        }
    },
    setPremiumProducts(state) {
        if (!state.agent) return

        state.chats.forEach(c => {
            Vue.set(c.user, '$$premium', Vue.options.filters.hasProduct(c.user, 'premium'))
        })
    },
    setPhotoHistories(state, histories) {
        Vue.set(state.chat, '$$histories', histories)
    },
    setChatFloatings(state, chatFloatingsInterest) {
        if (!state.chat) return

        chatFloatingsInterest.forEach(f => {
            f.user.$$distance = Vue.prototype.$distance(state.chat.user.location, f.user.location)
            f.user.$$premium = Vue.options.filters.hasProduct(f.user, 'premium')
            f.user.$$privacy = Vue.options.filters.hasProduct(f.user, 'privacy')
            f.user.$$chatId = (Vue.prototype.$getChat(f.user.id) || {}).id
        })
        Vue.set(state.chat, '$$floatingsInterest', chatFloatingsInterest)
    },
    updateDistance(state, location) {
        if (!state.friends) return

        state.friends.forEach(f => {
            if (!f.user.location) return

            Vue.set(f.user, '$$distance', Vue.prototype.$distance(location, f.user.location))
        })
    },
    setSort(state, sort) {
        if (!state.chat) return

        Vue.set(state.chat, '$$sort', sort)
        // helpers.updateSortInLocalStorage(state.chats)
    },
    setContacts(state, contacts) {
        if (!state.chat) return

        Vue.set(state.chat, '$$contacts', contacts)
    },
    saveTextareaInCurrentChat(state, text) {
        if (!state.chat) return
        Vue.set(state.chat, '$$text', text)
    },
    addMessage(state, { chat, message }) {
        if (!message) return

        // We increase chat.unread for <ChatItem> only when it's opened.
        const setLastMessage = (state, chat) => {
            chat.last_message = message.message

            if (
                state.agent.user_id !== message.message.user_id &&
                (!state.chat || state.chat.id !== chat.id || router.currentRoute.name !== 'ChatroomPage')
            ) {
                chat.unread++
            }
        }

        if (!chat) return

        setLastMessage(state, chat)

        const moveThisToTop = state.chats.map(c => c.id).indexOf(chat.id)
        if (moveThisToTop && !helpers.shouldThisChatBeIgnored(chat, state.chatFilters)) {
            state.chats.splice(moveThisToTop, 1)
            state.chats.unshift(chat)
        }

        if (chat.$$messages && chat.$$messages.length > 0 && chat.$$messages[0].id !== message.message.id) {
            chat.$$messages.unshift(message.message)
        }
    },
    setIntroducingUser(state, user) {
        if (!state.chat) return

        Vue.set(state.chat, '$$introducingUser', user)
        helpers.updateIntroducingUsersInLocalStorage(state.chats)
    },
    setPremiumAcceptedUser(state, user) {
        if (!state.chat) return

        Vue.set(state.chat, '$$premiumAcceptedUser', user)
        helpers.updatePremiumAcceptedUsersInLocalStorage(state.chats)
    },
    connected(state, payload) {
        if (payload === true || payload === false) {
            state.connected = payload
        }
    },
    setChatSearchValue(state, chatSearchValue) {
        state.chatSearchValue = chatSearchValue
    },
    setChatFilters(state, chatFilters) {
        state.chatFilters = chatFilters
    },
    setScrollTopChats(state, scrollTopChats) {
        state.scrollTopChats = scrollTopChats
    },
    setMannerFeedbackOpen(state, value) {
        state.isMannerFeedbackOpen = value
    },
    setFeedbackTarget(state, value) {
        Vue.set(state.chat, '$$feedbackRequests', value)
    },
    setMannerFeedbacks(state, value) {
        Vue.set(state.chat, '$$mannerFeedbacks', value)
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
    defaultState,
}
