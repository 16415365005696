<template>
    <div v-if="styles" class="styles" :class="{ mobile: $store.getters.isMobile }">
        <div class="title">
            <i class="material-icons">favorite</i>
            <span>매칭선호 사항</span>
        </div>
        <div class="style" :key="item.key" v-for="item in filteredItems">
            <span class="key">{{ item.key | translate }}</span>
            <span class="value f-bold flex-fill" :class="{ force: item.force }">{{ item.value }}</span>
        </div>
    </div>
</template>

<script>
// import style from '@/assets/constants/style'

export default {
    name: 'Styles',
    props: ['styles', 'gender'],
    methods: {
        parseMultiSelector(key) {
            const keyType =
                (key === 'income' || key === 'asset') && this.styles.wealth_type.length > 0
                    ? JSON.parse(this.styles.wealth_type)
                    : this.styles[`${key}_type`]
                    ? JSON.parse(this.styles[`${key}_type`])
                    : this.styles[`${key}_ids`]
                    ? JSON.parse(this.styles[`${key}_ids`])
                    : []
            if (!keyType.length) return ''
            
            const filteredKeyType =
                key === 'income' || key === 'asset'
                    ? keyType.filter(val => (key === 'income' ? val < 10 : val >= 10))
                    : keyType

            const keyArr = filteredKeyType.map(k => {
                const target = this.constants[key].find(item => item.value === k)
                if (target && (target.value !== '' || target.value !== 'undefined')) {
                    return target.title
                }
            })
            return keyArr.join(', ')
        },
    },
    computed: {
        force() {
            return this.styles.force_type ? JSON.parse(this.styles.force_type) : []
        },
        filteredItems() {
            return this.items.filter(
                item => item.value !== null && item.value !== 'null' && item.value !== '' && item.value !== undefined
            )
        },
        priorityText() {
            switch (this.styles.priority) {
                case 6:
                    return '조건이 훨씬 더 중요'
                case 5:
                    return '조건이 더 중요'
                case 4:
                    return '조건이 약간 더 중요'
                case 3:
                    return '외적인 요소가 약간 더 중요'
                case 2:
                    return '외적인 요소가 더 중요'
                case 1:
                    return '외적인 요소가 훨씬 더 중요'
                default:
                    return ''
            }
        },
        items() {
            if (!this.styles) return

            return [
                {
                    key: 'SUGGESTION_METHOD_TYPE',
                    value: this.constants.suggestion_method.find(
                        item => item.value === this.styles.suggestion_method_type
                    ).title,
                    force: false,
                },
                {
                    key: 'PRIORITY',
                    // value: style.PRIORITY[this.styles.priority],
                    value: this.priorityText,
                    force: false,
                },
                {
                    key: 'FORCE_TYPE',
                    // value: style.PRIORITY[this.styles.priority],
                    value: this.parseMultiSelector('force'),
                    force: false,
                },
                {
                    key: 'FRIEND_TYPE',
                    value: this.styles.appearance_type,
                    force: false,
                },
                {
                    key: 'AGE',
                    value: this.styles.min_age + '~' + this.styles.max_age,
                    force: false,
                },
                {
                    key: 'HEIGHT_TYPE',
                    value: this.styles.min_height + '~' + this.styles.max_height,
                    force: false,
                },
                {
                    key: 'HOUSE_DISTANCE',
                    value: this.styles.house_distance,
                    force: false,
                },
                {
                    key: 'JOB_DISTANCE',
                    value: this.styles.job_distance,
                    force: false,
                },
                {
                    key: 'JOB_DETAIL_TYPE',
                    value: this.parseMultiSelector('job_detail'),
                    force: false,
                },
                {
                    key: 'INCOME_TYPE',
                    value: this.parseMultiSelector('income'),
                    force: false,
                },
                {
                    key: 'ASSET_TYPE',
                    value: this.parseMultiSelector('asset'),
                    force: false,
                },
                {
                    key: 'SCHOOL_TYPE',
                    value: this.parseMultiSelector('school'),
                    force: false,
                },
                {
                    key: 'UNIVERSITY_TYPE',
                    value: this.parseMultiSelector('university'),
                    force: false,
                },
                {
                    key: 'RELIGION',
                    value: this.parseMultiSelector('religion'),
                    force: false,
                },
                {
                    key: 'MARRY_PLAN_TYPE',
                    value: this.parseMultiSelector('marry_plan'),
                    force: false,
                }
            ]
        },
        constants() {
            return {
                suggestion_method: [
                    { title: '둘다 소개받기', value: 0, gender: [0, 1] },
                    { title: '매칭 가능성 높은 회원만 소개받기', value: 1, gender: [0, 1] },
                    { title: '인기 회원만 소개받기', value: 2, gender: [0, 1] },
                ],
                force: [
                    { title: '외모', value: 1, gender: [0, 1] },
                    { title: '나이', value: 2, gender: [0, 1] },
                    { title: '키', value: 3, gender: [0, 1] },
                    { title: '지역', value: 4, gender: [0, 1] },
                    { title: '커리어(직업/직장)', value: 5, gender: [0, 1] },
                    { title: '학력', value: 6, gender: [0, 1] },
                    { title: '경제력', value: 7, gender: [0, 1] },
                    { title: '종교', value: 8, gender: [0, 1] },
                    { title: '결혼계획', value: 9, gender: [0, 1] },
                ],
                smoking: [
                    { title: '상관없음', value: 0, gender: [0, 1] },
                    { title: '비흡연', value: 1, gender: [0, 1] },
                    { title: '흡연', value: 2, gender: [0, 1] },
                ],
                religion: [
                    { title: '전부 괜찮아요', value: 0, gender: [0, 1] },
                    { title: '무교', value: 1, gender: [0, 1] },
                    { title: '기독교', value: 2, gender: [0, 1] },
                    { title: '천주교', value: 3, gender: [0, 1] },
                    { title: '불교', value: 4, gender: [0, 1] },
                    { title: '기타', value: 5, gender: [0, 1] },
                ],
                job_detail: [
                    { title: '전부 괜찮아요', value: 0, gender: [0, 1] },
                    { title: '무직/취업준비/아르바이트', value: 1, gender: [0, 1] },
                    { title: '대학원생 (석박사) /대학생', value: 2, gender: [0, 1] },
                    { title: '프리랜서', value: 3, gender: [0, 1] },
                    { title: '사업가 (개인사업/자영업)', value: 4, gender: [0, 1] },
                    { title: '서비스직/영업직/판매직/현장직/생산직', value: 5, gender: [0, 1] },
                    { title: '병원 (종합병원,대학병원,개인병원)', value: 6, gender: [0, 1] },
                    { title: '연구직/기술직/교육직/의료직', value: 7, gender: [0, 1] },
                    { title: '중소기업', value: 8, gender: [0, 1] },
                    { title: '중견기업', value: 9, gender: [0, 1] },
                    { title: '대기업/외국계 기업/금용기관', value: 10, gender: [0, 1] },
                    { title: '스타트업', value: 11, gender: [0, 1] },
                    { title: '공무원/공기업', value: 12, gender: [0, 1] },
                    { title: '전문직/CEO', value: 13, gender: [0, 1] },
                ],
                income: [
                    { title: '전부 소득 관계 없음', value: 0, gender: [0, 1] },
                    { title: '~ 2천만원대', value: 1, gender: [0, 1] },
                    { title: '3~4천만원대', value: 2, gender: [0, 1] },
                    { title: '5~7천만원대', value: 3, gender: [0, 1] },
                    { title: '8~9천만원대', value: 4, gender: [0, 1] },
                    { title: '1억원대', value: 5, gender: [0, 1] },
                    { title: '2억원대 이상', value: 6, gender: [0, 1] },
                ],
                asset: [
                    { title: '자산 관계 없음', value: 10, gender: [0, 1] },
                    { title: '~ 5천만원', value: 11, gender: [0, 1] },
                    { title: '5천만원~1억원대', value: 12, gender: [0, 1] },
                    { title: '1~2억원대', value: 13, gender: [0, 1] },
                    { title: '3~4억원대', value: 14, gender: [0, 1] },
                    { title: '5~9억원대', value: 15, gender: [0, 1] },
                    { title: '10억 이상', value: 16, gender: [0, 1] },
                ],
                school: [
                    {
                        title: '전부 괜찮아요',
                        value: 0,
                        gender: [0, 1],
                    },
                    {
                        title: '고등학교 졸업',
                        value: 1,
                        // desc: '로스쿨, 의대(의전), 치대(치전), 한의대(한의전), 약대, 수의대',
                        gender: [0, 1],
                    },
                    {
                        title: '전문대',
                        value: 2,
                        // desc: '서울대, 연세대, 고려대, 카이스트, 포항공대',
                        gender: [0, 1],
                    },
                    {
                        title: '4년제 대학교',
                        value: 3,
                        // desc: '서강대, 성균관대, 한양대',
                        gender: [0, 1],
                    },
                    {
                        title: '인서울 / 지방거점국립대',
                        value: 4,
                        // desc: '중앙대, 경희대, 한국외대, 서울시립대',
                        gender: [0, 1],
                    },
                    {
                        title: '최고 명문대',
                        value: 5,
                        desc: 'SKY / 포카 / 아이비리그 등',
                        gender: [0, 1],
                    },
                    {
                        title: '명문대',
                        value: 6,
                        desc: '서성한 / 과학기술원 등',
                        gender: [0, 1],
                    },
                ],
                university: [
                    {
                        title: '전부 괜찮아요',
                        value: 0,
                        gender: [0, 1],
                    },
                    {
                        title: '전문대학원(대학교)',
                        value: 2,
                        desc: '로스쿨, 의대(의전), 치대(치전), 한의대(한의전), 약대, 수의대',
                        gender: [0, 1],
                    },
                    {
                        title: '서연고카포',
                        value: 3,
                        desc: '서울대, 연세대, 고려대, 카이스트, 포항공대',
                        gender: [0, 1],
                    },
                    {
                        title: '서성한',
                        value: 4,
                        desc: '서강대, 성균관대, 한양대',
                        gender: [0, 1],
                    },
                    {
                        title: '중경외시',
                        value: 5,
                        desc: '중앙대, 경희대, 한국외대, 서울시립대',
                        gender: [0, 1],
                    },
                    {
                        title: '서울소재4년제',
                        value: 6,
                        desc: '건국대, 동국대, 홍익대 등 서울 소재 4년제',
                        gender: [0, 1],
                    },
                    {
                        title: '주요국립대학교',
                        value: 8,
                        desc: '부산대, 경북대, 전남대, 전북대, 충남대, 충북대,인천대',
                        gender: [0, 1],
                    },
                    {
                        title: '수도권4년제',
                        value: 9,
                        desc: '경기 소재 4년제',
                        gender: [0, 1],
                    },
                    {
                        title: '지방 4년제',
                        value: 10,
                        desc: '지방 4년제',
                        gender: [0, 1],
                    },
                    {
                        title: '전문대학(2,3년제)',
                        value: 11,
                        desc: '초대졸',
                        gender: [0, 1],
                    },
                    {
                        title: '고등학교 졸업',
                        value: 12,
                        desc: '고등학교 졸업',
                        gender: [0, 1],
                    },
                    {
                        title: '세계 100대 해외대학교',
                        value: 1,
                        desc: '아이비리그, 케임브릿지 등 QS Top 100대학교',
                        gender: [0, 1],
                    },
                    {
                        title: '해외대학교',
                        value: 13,
                        desc: '해외 소재 대학교',
                        gender: [0, 1],
                    },
                    {
                        title: '교대/교원대',
                        value: 7,
                        desc: '서울교대, 경인교대, 한국교원대 등',
                        gender: [0, 1],
                    },
                    {
                        title: '특수학교',
                        value: 14,
                        desc: '과학기술원(GIST, UNIST 등), 경찰대, 사관학교',
                        gender: [0, 1],
                    },
                    {
                        title: '예체능계',
                        value: 15,
                        desc: '서울예대, 한예종, 한국체대, 예체능계열 학과 등',
                        gender: [0, 1],
                    },
                ],
                marry_plan: [
                    { title: '전부 괜찮아요', value: 0, gender: [0, 1] },
                    { title: '가급적 빨리 하고싶어요', value: 1, gender: [0, 1] },
                    { title: '1~2년 정도 연애하고 결혼하고 싶어요', value: 2, gender: [0, 1] },
                    { title: '3~4년 정도 연애하고 결혼하고 싶어요', value: 3, gender: [0, 1] },
                    { title: '마음에 드는 사람을 만나면 언제든 가능해요', value: 4, gender: [0, 1] },
                    {
                        title: '결혼할만한 상대와 연애하고 싶지만, 결혼 시기는 여유롭게 생각하고 있어요',
                        value: 5,
                        gender: [0, 1],
                    },
                ],
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.styles {
    .title {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        padding: 0 8px;

        i {
            color: $purple-primary;
            margin-right: 8px;
        }
    }
    .style {
        font-size: 14px !important;
        display: flex;
        margin-bottom: 4px;
    }
    .key {
        width: 30%;
        font-size: 14px !important;
    }
    .value {
        @include f-bold;
        font-size: 14px !important;
        color: black;

        &.force {
            color: red !important;
        }
    }
    &.mobile {
        .value {
            &.force {
                color: red !important;
            }
        }
    }
}
</style>
