import * as $http from 'axios'

export default {
    constants: () => $http.get('v2/constants'),
    gitDescriptions: () => $http.get('v2/git_descriptions'),
    properties: params => $http.get('v2/properties', { params }),
    trackVb: payload => $http.post('v2/analytics', payload),
    stations: (region, name) => $http.get(`v2/stations?region=${region}&name=${name}`),
    regions: (name, district) => $http.get(`v2/regions?name=${name}&district=${district}`),
    profileOptions: () => $http.get('v2/profile_options'),
    reportReasons: target => $http.get(`v2/report_reasons?rtype=${target}`),
}
