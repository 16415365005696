<template>
    <div v-if="floating" class="floatingv2 p-relative" @click.stop="onClickFloating">
        <PhotoAndLastSeen
            class="no-touch display-table m-a"
            :lastSignInAt="floating.user.last_sign_in_at"
            :profile="floating.user.photo_url"
            :userId="floating.user.id"
            :sent="floating.sent"
            :likeProfile="true"
        />
        <i @click.stop="deleteFloatingInterest" class="material-icons">close</i>
        <div class="info">
            <div class="nickname-age flex-row items-center">
                <div class="nickname lines-1" v-html="floating.user.name" />
                <div class="age lines-1" v-html="Age" />
                <BadgeFriendType :user="floating" :chat="userChat" />
            </div>
            <div class="height-region flex-row">
                <div v-html="floating.user.profile.height" />
                <div v-html="'ㅣ'" style="color: #c8c8cd" />
                <div v-html="$options.filters.noDetail(floating.user.profile.region_name)" />
                <Distance :distance="floating.user.$$distance" />
            </div>
            <div class="product height-region">
                {{ userRatePlanRecent.name }} | {{ userRatePlanRecent.amount_original - userTickets.used }}회<br />
                {{ price ? `${(price / userRatePlanRecent.amount_original).toLocaleString()}원` : null }}
            </div>
        </div>
    </div>
</template>

<script>
import { getPlanInfo, parsePidToPlanName } from '@/store/data/product'
import productService from '@/services/product'
export default {
    name: 'FloatingV2',
    props: ['floating', 'chatId'],
    data: () => ({
        price: null,
    }),
    computed: {
        friendType() {
            return this.$options.filters.friendType(this.floating)
        },
        chat() {
            return this.$store.getters.chat || {}
        },
        Age() {
            const user = this.floating.user
            const age = this.$options.filters.asAge(user.profile.birthday)

            return `${age}`
        },
        userChat() {
            return (this.$store.getters.chatList || []).find(c => c.user_id === this.floating.user.id)
        },
        products() {
            // return this.user.products || []
            return this.floating.user_product
        },
        productsNowOnSale() {
            return this.$store.getters.productsNowOnSale
        },
        userRatePlans() {
            const allRatePlans = this.products.filter(item => item.ptype === 'rate_plan')

            const res = allRatePlans.map(item => {
                const {
                    id,
                    enabled,
                    product_id: pid,
                    valid_from: start,
                    valid_until: expire,
                    created_at: created,
                    order_id,
                } = item
                const {
                    name,
                    description,
                    pre_price: prePrice,
                    price,
                    valid_date: validDate,
                    amount,
                    amount_original,
                    cycle,
                } = (this.productsNowOnSale || {}).all.find(item => item.id === pid)
                const m = this.$moment

                return {
                    id,
                    created,
                    pid,
                    name,
                    prePrice,
                    price,
                    validDate,
                    amount,
                    amount_original,
                    order_id,
                    cycle,
                    description: description.split(','),
                    enabled,
                    startTypeOrigin: start,
                    expireTypeOrigin: expire,
                    start: start ? m(start).format('YY.M.D') : '미정',
                    expire: !enabled
                        ? -1
                        : start
                        ? m(m(m(expire).format('YYYY-MM-DD'))).diff(m().format('YYYY-MM-DD'), 'day')
                        : validDate,
                    // expire: -2,
                }
            })

            return res.sort((a, b) => b.id - a.id)
        },
        userRatePlanRecent() {
            const m = this.$moment
            const tickets = this.products.filter(item => item.ptype === 'ticket')
            const onGoing = this.userRatePlans.find(item => {
                const dateValid = item.startTypeOrigin && m().isBefore(m(item.expireTypeOrigin))
                const ticketsValid = tickets.filter(t => {
                    return item.id === t.related_user_product_id
                })
                const plan = parsePidToPlanName(item.pid)
                if (!dateValid) return false
                if (plan === 'EXTRA') {
                    return getPlanInfo(item.pid).amount ? ticketsValid.length : true
                } else {
                    return plan === 'UNLIMITED' ? true : ticketsValid.length
                }
            })
            const pending = this.userRatePlans.filter(item => item.start === '미정')
            const expired = this.userRatePlans.filter(item => {
                if (!item.enabled) return true

                const dateExpired = item.startTypeOrigin && m().isAfter(m(item.expireTypeOrigin))
                const ticketsValid = tickets.filter(t => {
                    return item.id === t.related_user_product_id
                })
                const plan = parsePidToPlanName(item.pid)
                if (dateExpired) return true
                if (plan === 'EXTRA') {
                    return getPlanInfo(item.pid).amount ? ticketsValid.length === 0 : true
                } else {
                    return plan === 'UNLIMITED' ? true : ticketsValid.length === 0
                }
            })

            if (onGoing) return { ...onGoing, status: 'on-going' }
            if (pending.length) return { ...pending[0], status: 'pending' }
            if (expired.length) return { ...expired[0], status: 'expired' }

            return { pid: 0, name: '현재 진행중인 요금제가 없습니다' }
        },
        userTickets() {
            const tickets = this.products.filter(item => item.ptype === 'ticket')
            if (this.userRatePlanRecent.pid === 0) {
                return {
                    all: tickets.length,
                    used: tickets.filter(ticket => !ticket.enabled).length,
                    active: tickets.filter(ticket => ticket.enabled).length,
                }
            }

            const validTickets = tickets.filter(
                t =>
                    this.userRatePlanRecent.startTypeOrigin <= t.valid_until &&
                    this.userRatePlanRecent.expireTypeOrigin >= t.valid_until
            )
            // console.log('val', validTickets.length, validTickets)
            return {
                all: this.userRatePlanRecent.amount,
                used: this.userRatePlanRecent.amount - validTickets.length,
                active: validTickets.length,
            }
        },
    },
    methods: {
        deleteFloatingInterest() {
            this.$store.dispatch('deleteChatFloatingInterest', {
                chat: this.chat,
                floating: this.floating,
                reject: true,
            })
        },
        async onClickFloating() {
            if (this.$store.getters.isMobile) {
                this.$emit('clickFloating')
                return
            }
            try {
                if (this.floating.user.profile.status !== 'confirmed') {
                    await this.$modal.basic({
                        body: '프로필 심사 안된 유저입니다!',
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                }

                await this.$store.dispatch('loadIntroducingUser', this.floating.user.id)
            } catch (e) {
                this.$toast.error(e.data)
            }
        },
        async getProducts() {
            if (this.userRatePlanRecent.order_id) {
                const res = await productService.orderInfo(this.userRatePlanRecent.order_id)
                if (res) this.price = res.order.price
            }
        },
    },
    mounted() {
        this.getProducts()
    },
}
</script>

<style lang="scss" scoped>
$panel-width-friends: 264px;
$img-size: calc((#{$panel-width-friends} - 8px - 2 * 16px) / 2);

.floatingv2 {
    user-select: none;
    width: $img-size;

    &:last-child {
        margin-bottom: 16px;
    }

    /deep/ .photo-and-last-seen {
        padding: 0;
        border-radius: 8px;
    }

    /deep/ .img-profile {
        width: $img-size;
        height: $img-size;
        padding: 0;
        border-radius: 8px;
    }

    .info {
        .flex-row * {
            flex: 0 0 auto;
        }
        margin-top: 4px;
    }

    .badge {
        display: table;
        line-height: 16px;
    }

    .nickname {
        color: black;
        font-size: 12px;
        line-height: 16px;
        margin-right: 4px;
        max-width: 51px;
        font-family: NotoSans-Medium;
    }
    .age {
        color: black;
        font-size: 12px;
        line-height: 16px;
        margin-right: 4px;
        // max-width: 80px;
        font-family: NotoSans-Medium;
    }

    .height-region {
        font-size: 10px;
        letter-spacing: -0.2px;

        .distance {
            margin-left: 4px;
        }
    }

    .material-icons {
        color: white;
        width: 24px;
        height: 24px;
        font-size: 20px;
        position: absolute;
        right: 8px;
        top: 8px;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
