<template>
    <div class="like-tags" :class="{ mobile: $store.getters.isMobile }">
        <div class="title" v-html="'취향 태그'" />
        <div class="content">
            <div class="tag-container" :key="idx" v-for="(tagCategory, idx) in tagCategories">
                <div class="title" v-html="tagCategory.title" />
                <div class="contents">
                    <div :key="idx" class="tag-content" v-html="tag.name" v-for="(tag, idx) in tagCategory.tags" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'LikeTags',
    props: ['user'],
    computed: {
        tagCategories() {
            if (!this.user) return

            const keywords = this.user.keywords || []
            const keywordsList = []

            keywords.map(w => {
                if (keywordsList[w.keyword_category_id - 1]) {
                    keywordsList[w.keyword_category_id - 1].tags.push({ name: w.name })
                } else {
                    keywordsList[w.keyword_category_id - 1] = { title: w.title, tags: [{ name: w.name }] }
                }
            })

            return keywordsList.filter(x => x.title)
        },
    },
}
</script>

<style lang="scss" scoped>
.like-tags {
    margin-top: 20px;

    .title {
        font-size: 15px;
        font-weight: 500;
        color: $black;
        font-family: NotoSans-Medium;
        margin-bottom: 12px;
    }

    .content {
        display: flex;
        flex-wrap: wrap;
        .tag-container {
            width: 50%;
            .title {
                font-size: 10px;
                font-weight: 500;
                color: $grey-07;
                font-family: NotoSans-Medium;
                margin-bottom: 4px;
            }

            .contents {
                display: flex;
                flex-wrap: wrap;

                .tag-content {
                    padding: 3px 8px;
                    margin-bottom: 6px;
                    margin-right: 4px;
                    border-radius: 6px;
                    background-color: $grey-01;
                    width: fit-content;
                    // height: fit-content;
                    min-height: 20px;
                    height: fit-content;

                    font-size: 10px;
                    font-weight: normal;
                    color: $black;

                    &:last-child {
                        margin-bottom: 16px;
                    }
                }
            }
        }
    }

    &.mobile {
        padding: 24px 16px 0px 16px;
        margin-top: 0px;

        .title {
            font-size: 18px;
            margin-bottom: 24px;
        }

        .tag-container {
            width: 100%;

            .title {
                font-size: 14px;
                margin-bottom: 12px;
            }

            .contents {
                display: flex;
                flex-wrap: wrap;

                .tag-content {
                    padding: 4px 12px;
                    margin-bottom: 12px;
                    margin-right: 8px;
                    border-radius: 8px;
                    background-color: $grey-01;
                    width: fit-content;
                    // height: fit-content;
                    min-height: 28px;
                    height: fit-content;

                    font-size: 14px;
                    font-weight: normal;
                    color: $black;

                    &:last-child {
                        margin-bottom: 32px;
                    }
                }
            }
        }
    }
}
</style>
