<template>
    <div class="key-binder"/>
</template>

<script>
export default {
    name: 'KeyBinder',
    mounted() {
        document.addEventListener('keydown', this.onKeydown)
        document.addEventListener('keyup', this.onKeyup)
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.onKeydown)
        document.removeEventListener('keyup', this.onKeyup)
    },
    methods: {
        onKeydown(event) {
            this.$bus.$emit('onShortcut', event)

            return this.$onKeydownHandler(event)
        },
        onKeyup(event) {
            this.$bus.$emit('onShortcut', event)
        },
    },
}
</script>