<template>
    <div class="photo-and-last-seen p-relative">
        <div
            class="img-profile"
            :class="genderClass"
            @click.stop="onClickProfileImage"
            v-lazy:background-image="profile"
        />
        <LastSeen :noText="'true'" :timestamp="lastSignInAt" />
        <div v-if="showBookmark && isBookmarked" class="bookmark">
            <img src="@/assets/images/rating/white_star.svg" />
        </div>
        <div class="badge-container">
            <div class="new-floating" v-if="!sent">N</div>
            <div class="both-like" v-if="isBothLike">서로 호감</div>
            <div class="judging" v-if="isJudging">심사 전!</div>
            <div class="manner-like-icon" v-if="isManner">매너 수락</div>
        </div>
        <BadgeFriendType v-if="chat" class="flex-wrap m-r-4" :chat="chat" :user="chat.user" />
    </div>
</template>

<script>
export default {
    name: 'PhotoAndLastSeen',
    props: {
        userId: Number,
        profile: String,
        chat: Object,
        lastSignInAt: String,
        gender: Number,
        customHandler: Function,
        sent: {
            type: Boolean,
            default: true,
        },
        likeProfile: Boolean,
        showBookmark: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        async onClickProfileImage() {
            if (this.customHandler) {
                this.customHandler()
                return
            }

            let photos
            try {
                photos = await this.$store.dispatch('loadUserImages', this.userId)
            } catch (e) {
                this.$toast.error(e.data)
                return
            }

            if (!photos) return

            this.$modal.images({
                images: photos.map(p => p.url),
            })
            this.$emit('click')
        },
    },
    computed: {
        genderClass() {
            if (this.gender !== undefined) {
                return this.$options.filters.gender(this.gender)
            }
            return null
        },
        isBothLike() {
            if (!this.likeProfile) return false

            const floatingsInterest = this.$store.getters.chat.$$floatingsInterest
            let bothLike = false
            floatingsInterest.map(f => {
                if (f.user.id === this.userId) bothLike = f.both_like
            })

            return bothLike
        },
        isManner() {
            if (!this.likeProfile) return false

            const floatingsInterest = this.$store.getters.chat.$$floatingsInterest
            let isManner = false
            floatingsInterest.map(f => {
                if (f.user.id === this.userId) isManner = f.is_manner
            })

            return isManner
        },
        isJudging() {
            if (!this.likeProfile) return false

            const floatingsInterest = this.$store.getters.chat.$$floatingsInterest
            // console.log('87 : ', floatingsInterest, this.userId)
            let isJudging = false
            floatingsInterest.map(f => {
                if (f.user.id === this.userId) isJudging = f.user.profile.status !== 'confirmed'
            })

            return isJudging
        },
        isBookmarked() {
            return this.chat.bookmark
        },
    },
}
</script>
