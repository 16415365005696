<template>
    <div class="floating-menu" :class="{'floating': floating}">
        <div class="items flex-row">
            <div
                @click="item && item.handler()"
                class="item-wrapper flex-fill"
                :key="item.key"
                v-for="item in items">
                <div class="item">
                    <img :src="item.img">
                    <div class="menu-title lines-1" v-html="$translate(item.title)"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['items', 'floating'],
}
</script>
