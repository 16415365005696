<template>
    <div class="ticket-state">
        <div class="ticket ticket-active">
            <div class="title" v-html="`잔여`" />
            <div class="cnt badge" v-html="userTickets.active" />
        </div>
        <div class="ticket ticket-all">
            <div class="title" v-html="`지급`" />
            <div class="cnt" v-html="userTickets.all" />
        </div>
        <div class="ticket ticket-used">
            <div class="title" v-html="`사용`" />
            <div class="cnt" v-html="userTickets.used" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'TicketState',
    props: {
        userTickets: Object,
    },
}
</script>

<style scoped lang="scss">
.ticket-state {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 12px;
    border: 1px solid $grey-05;
    padding: 8px;
    border-radius: 8px;
    background: white;

    .ticket {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .title {
            font-size: 14px;
        }
        .cnt {
            font-size: 18px;
            color: black;
        }

        &.ticket-active {
            position: relative;

            .cnt {
                background: $pink-deep;
                color: white;
            }
        }
    }
}
</style>
