import { store as $store } from '@/store'
import './modal'
import Vue from 'vue'
import router from '@/router'
import blankProfile from '@/assets/images/blank_profile.svg'
import friendService from '@/services/friend'
import * as helpers from '@/store/data/helpers'
import * as Hangul from 'hangul-js'

const asAge = birthday => Vue.options.filters.asAge(birthday)

export const Shortcuts = {
    install(Vue) {
        Vue.prototype.$translate = key => Vue.options.filters.translate(key)

        Vue.prototype.$blankProfile = blankProfile

        Vue.prototype.$backHandlers = {}

        // 현재 라우트의 백 버튼에 대한 동작을 오버라이드하는 콜백을 등록한다
        Vue.prototype.$registerBackHandler = callback => {
            Vue.prototype.$backHandlers[router.currentRoute.name] = callback
        }

        Vue.prototype.$unregisterBackHandler = () => delete Vue.prototype.$backHandlers[router.currentRoute.name]

        Vue.prototype.$alert = arg => {
            Vue.prototype.$modal.custom({
                component: 'ModalAlert',
                options:
                    typeof arg === 'object'
                        ? {
                              title: arg.title,
                              body: Vue.options.filters.translate(arg.body),
                          }
                        : {
                              title: 'MODAL_DEFAULT_TITLE',
                              body: Vue.options.filters.translate(arg),
                          },
            })
        }

        Vue.prototype.$log = (...args) => {
            Vue.prototype.$nativeBridge.postMessage({
                action: 'propWebLogToRN',
                value: args,
            })
        }

        Vue.prototype.$actionSheet = ({ buttons, style }) => {
            return Vue.prototype.$modal.custom({
                component: 'ModalActionSheet',
                options: {
                    buttons,
                    style,
                },
            })
        }

        Vue.prototype.$isTester = () => {
            if (!$store.getters.agent) return

            return [4749].indexOf($store.getters.agent.id) !== -1
        }

        Vue.prototype.$isRootRoute = () =>
            ['LoginPage', 'HomePage', 'ChatPage', 'MyPage', 'SettingsPage', 'SettingsPageMobile'].indexOf(
                router.currentRoute.name
            ) !== -1

        Vue.prototype.$numArray = len => Array.apply(null, { length: len }).map(Number.call, Number)

        Vue.prototype.$loading = payload => {
            $store.commit('setLoading', payload)
        }

        Vue.prototype.$copy = obj => JSON.parse(JSON.stringify(obj))

        Vue.prototype.$mustParse = str => {
            let obj
            try {
                obj = JSON.parse(str)
            } catch (e) {}
            return obj
        }

        Vue.prototype.$nameOrNick = user => {
            if (user.name) return user.name

            return user.profile.nickname
        }
        Vue.prototype.$throttle = (handler, delay) => {
            let last = 0
            const now = new Date().getTime()
            if (now - last < delay) {
                return
            }
            last = now
            return handler()
        }
        Vue.prototype.$vbConstants = {
            types: {
                height: {
                    male: [
                        { title: '중요하지 않아요', value: 0 },
                        { title: '작은 분이 좋아요', value: 2 },
                        { title: '큰 분이 좋아요', value: 1 },
                    ],
                    female: [
                        { title: '중요하지 않아요', value: 0 },
                        { title: '저보다 크면 괜찮아요', value: 1 },
                        { title: '평균정도면 괜찮아요', value: 2 },
                        { title: '크지 않으면 만나고 싶지 않아요', value: 3 },
                    ],
                },
                friend: [
                    { title: '잘 안 봐요', value: 0 },
                    { title: '평균 정도면 괜찮아요', value: 1 },
                    { title: '눈이 높은 편이에요', value: 2 },
                ],
                spec: [
                    { title: '중요하지 않아요', value: 0 },
                    { title: '저와 비슷한 수준이면 좋겠어요', value: 1 },
                    { title: '저와 비슷하거나 더 좋은 분이 좋아요', value: 3 },
                    { title: '저보다 좋은 스펙은 부담스러워요', value: 2 },
                ],
            },
        }

        Vue.prototype.$case = Vue.options.filters.$case
    },
}

export const Toast = {
    install(Vue) {
        Vue.prototype.$toast = {
            custom: function (message, options) {
                $store.dispatch('setToast', {
                    message,
                    type: 'custom',
                    options,
                })
            },
            success: function (message, duration) {
                $store.dispatch('setToast', {
                    message,
                    type: 'success',
                    options: {
                        duration,
                    },
                })
            },
            warning: function (message, duration) {
                $store.dispatch('setToast', {
                    message,
                    type: 'warning',
                    options: {
                        duration,
                    },
                })
            },
            error: function (message, duration) {
                $store.dispatch('setToast', {
                    message,
                    type: 'error',
                    options: {
                        duration,
                    },
                })
            },
        }
    },
}

export const Animate = {
    install(Vue) {
        Vue.prototype.$animate = {
            shake: elem => {
                if (!elem) return

                elem.classList.add('shake')
                setTimeout(_ => {
                    elem.classList.remove('shake')
                }, 500)
            },
            //
            react: (elem, duration, size) => {
                const originalStyle = Vue.prototype.$copy(elem.style)
                const transitionTime = duration > 0 && duration < 5000 ? duration : 40
                const scaleSize = size < 1 && size > 0 ? size : 0.75

                elem.style.transform = `scale(${scaleSize})`
                elem.style.transition = `all ${transitionTime / 1000}s ease`
                setTimeout(_ => {
                    elem.style = originalStyle
                    elem.style.transition = `all ${transitionTime / 1000}s ease`
                }, transitionTime)
                setTimeout(_ => {
                    elem.style = originalStyle
                }, transitionTime * 2)
            },
        }
    },
}

export const FilterBySearchValue = {
    install(Vue) {
        // orderedContains: big이 small을 포함하는가? (순서 고려)
        // EX:) [1, 5, 7], [0, 1, 3, 5, 7, 9] => true
        // EX:) [5, 1, 7], [0, 1, 3, 5, 7, 9] => false
        const orderedContains = (small, big) => {
            if (!small || !big) return false

            const arr = {
                small: Vue.prototype.$hangul.disassemble(small, true).map(group => group[0]),
                big: Vue.prototype.$hangul.disassemble(big, true).map(group => group[0]),
            }

            const foundIndices = []
            let orderedIndices = []
            for (let i = 0; i < arr.small.length; i++) {
                const foundIndex = arr.big.indexOf(arr.small[i])
                if (foundIndex === -1) return false

                foundIndices.push(foundIndex)
            }
            orderedIndices = Vue.prototype.$copy(foundIndices).sort()
            return JSON.stringify(foundIndices) === JSON.stringify(orderedIndices)
        }

        // chosungSearching: 현재 유저의 검색 키워드가 초성만으로 이루어져 있는가?
        const chosungSearching = value => {
            const d = (value, grouped) => Vue.prototype.$hangul.disassemble(value, grouped)

            return d(value, true).length === d(value).length
        }

        Vue.prototype.$filterBySearchValue = (list, searchValue) => {
            if (searchValue && searchValue.trim()) {
                const splitted = searchValue.trim().toLowerCase().split(' ')
                list = list.filter(item => {
                    const user = item.profile ? item : item.user
                    const items = {}
                    items.userName = (user.name || '').toLowerCase()
                    items.nickname = (user.profile.nickname || '').toLowerCase()
                    items.region = (user.profile.region_name || '').toLowerCase()
                    items.age = (asAge(user.profile.birthday) || '').toString()
                    items.profileIntro = (user.profile.intro || '').toLowerCase()
                    items.styleIntro = ((user.style || {}).intro || '').toLowerCase()

                    return splitted.some(value => {
                        if (chosungSearching(value) && orderedContains(value, items.userName)) {
                            return true
                        }

                        return ['userName', 'nickname', 'region', 'age', 'profileIntro', 'styleIntro'].some(key =>
                            items[key].includes(value)
                        )
                    })
                })
            }
            return list
        }

        Vue.prototype.$applyFriendsFilter = (users, friendsFilter, currentChat) => {
            if (!friendsFilter) return

            if (friendsFilter.basic.find(f => f.value === 'closeUser').selected) {
                users = users.filter(user => user.$$distance <= 20)
            }

            if (friendsFilter.basic.find(f => f.value === 'neverIntroduced').selected) {
                users = users.filter(user => !user.$$alreadyIntroduced)
            }

            let age
            users = users.filter(user => {
                age = asAge(user.profile.birthday)
                return !user.profile.birthday || (age >= friendsFilter.minAge && age <= friendsFilter.maxAge)
            })

            users = users.filter(
                user =>
                    !user.profile.height ||
                    (user.profile.height >= friendsFilter.minHeight && user.profile.height <= friendsFilter.maxHeight)
            )

            // If not all selected...
            if (friendsFilter.lastSign.some(f => !f.selected)) {
                const found = friendsFilter.lastSign.find(f => f.selected)
                if (!found) return

                // last sign 은 전체 value 가 -1 이라서 >0 으로 체크
                if (found.value > 0) {
                    users = users.filter(
                        user =>
                            Vue.prototype.$moment().diff(user.last_sign_in_at, 'seconds') < 60 * 60 * 24 * found.value
                    )
                }
            }
            if (friendsFilter.lookScoreAdmin.some(f => !f.selected)) {
                const found = friendsFilter.appearanceGrade.filter(f => f.selected).flatMap(f => f.value)
                if (!found) return

                if (Array.isArray(found)) {
                    users = users.filter(user => found.indexOf(user.profile.look_score_admin) !== -1)
                }
            }
            if (friendsFilter.religion.some(f => !f.selected)) {
                const found = friendsFilter.religion.filter(f => f.selected).flatMap(f => f.name)
                if (!found) return

                if (Array.isArray(found)) {
                    users = users.filter(user => found.indexOf(user.profile.religion_name) !== -1)
                }
            }

            const detailFilterPairs = [
                { key: 'smoking', values: ['smoking'] },
                { key: 'isStudent', values: ['is_student'] },
                { key: 'jobDetailType', values: ['job_detail_type'] },
                { key: 'school', values: ['school_id'] },
                { key: 'schoolType', values: ['school_type'] },
                { key: 'province', values: ['province1_id', 'province2_id'] },
            ]

            detailFilterPairs.forEach(filter => {
                const selectedFilter = friendsFilter[filter.key]
                if (!selectedFilter) {
                    helpers.setDefaultFiltersOnChat(currentChat)
                }

                const selectedValues = friendsFilter[filter.key].filter(item => item.selected).map(item => item.value)

                if (selectedValues.length === friendsFilter[filter.key].length) {
                    // This means we shouldn't filter for it's denoting 'ALL'
                    return
                }

                users = users.filter(user => {
                    if (!user.profile) return true

                    return filter.values.some(key => selectedValues.indexOf(user.profile[key]) !== -1)
                })
            })
            return users
        }
    },
}

export const Hooks = {
    install(Vue) {
        Vue.prototype.$hooks = {
            async superSearch(filters) {
                try {
                    await $store.dispatch('loadRecommendsFromDB', helpers.filtersToSuperSearchPayload(filters))
                    const len = ($store.getters.chat.$$recommendsFromDB || []).length
                    console.log('list', $store.getters.chat.$$recommendsFromDB)
                    if (len === 0) {
                        Vue.prototype.$toast.error('검색 결과에 해당하는 회원이 없습니다')
                    } else {
                        Vue.prototype.$toast.success(Vue.prototype.$translate('SUPER_SEARCH_DONE').replace(/%s/, len))
                    }
                } catch (e) {
                    Vue.prototype.$toast.error(e)
                }
            },
            async onConfirmDeleteFriend(friendIds) {
                try {
                    Vue.prototype.$loading(true)
                    const data = await friendService.disconnectFriends(friendIds)
                    Vue.prototype.$toast.success(data.msg)
                    $store.dispatch('removeFriends', friendIds)
                } catch (e) {
                    Vue.prototype.$toast.error(e.data)
                }
                Vue.prototype.$loading(false)
            },
        }
    },
}

export const Sorted = {
    install(Vue) {
        Vue.prototype.$sorted = (list, sorter) => {
            if (!sorter) {
                // ;(list || []).sort((u1, u2) => {
                //     if (u1.status === u2.status) {
                //         if (u1.$$distance < u2.$$distance) {
                //             return -1
                //         }
                //         // if ((u1.user_info && u1.user_info.matching_coefficient ? u1.user_info.matching_coefficient : 0.01) > (u2.user_info && u1.user_info.matching_coefficient ? u2.user_info.matching_coefficient : 0.01)) {
                //         //     return -1
                //         // }
                //     }
                //     return u1.status < u2.status ? -1 : 1
                // })
            } else {
                if (['two_way', 'forward_way', 'backward_way'].includes(sorter.orderby)) {
                    Vue.prototype.$currentSortOrder = sorter.orderby
                    return
                } else {
                    ;(list || []).sort((u1, u2) => {
                        if (u1.status === u2.status) {
                            if (sorter.orderby === 'ageAsc') {
                                const age1 = asAge(u1.profile.birthday)
                                const age2 = asAge(u2.profile.birthday)
                                if (age1 < age2) {
                                    return -1
                                }
                            } else if (sorter.orderby === 'ageDesc') {
                                const age1 = asAge(u1.profile.birthday)
                                const age2 = asAge(u2.profile.birthday)
                                if (age1 > age2) {
                                    return -1
                                }
                            } else if (sorter.orderby === 'name') {
                                if (u1.name < u2.name) {
                                    return -1
                                }
                            } else if (sorter.orderby === 'distance') {
                                if (u1.$$distance < u2.$$distance) {
                                    return -1
                                }
                            } else if (sorter.orderby === 'acceptRate') {
                                const acceptRate1 =
                                    u1.user_info && u1.user_info.accept_rate ? u1.user_info.accept_rate : 0
                                const acceptRate2 =
                                    u2.user_info && u2.user_info.accept_rate ? u2.user_info.accept_rate : 0
                                if (acceptRate1 > acceptRate2) {
                                    return -1
                                }
                            } else if (sorter.orderby === 'acceptedRate') {
                                const acceptedRate1 =
                                    u1.user_info && u1.user_info.accepted_rate ? u1.user_info.accepted_rate : 0
                                const acceptedRate2 =
                                    u2.user_info && u2.user_info.accepted_rate ? u2.user_info.accepted_rate : 0
                                if (acceptedRate1 > acceptedRate2) {
                                    return -1
                                }
                                // } else if (sorter.orderby === 'matchingCoefficient') {
                                //     if ((u1.user_info && u1.user_info.matching_coefficient ? u1.user_info.matching_coefficient : 0.01) > (u2.user_info && u1.user_info.matching_coefficient ? u2.user_info.matching_coefficient : 0.01)) {
                                //         return -1
                                //     }
                            }
                        }
                        return u1.status < u2.status ? -1 : 1
                    })
                }
            }
            return list
        }
    },
}

export const Distance = {
    install(Vue) {
        Vue.prototype.$distance = (loc1, loc2) => {
            if (!loc1 || !loc2) return

            const lat1 = parseFloat(loc1.lat)
            const lon1 = parseFloat(loc1.lng)
            const lat2 = parseFloat(loc2.lat)
            const lon2 = parseFloat(loc2.lng)

            const R = 6371
            const dLat = ((lat2 - lat1) * Math.PI) / 180
            const dLon = ((lon2 - lon1) * Math.PI) / 180
            const a =
                Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos((lat1 * Math.PI) / 180) *
                    Math.cos((lat2 * Math.PI) / 180) *
                    Math.sin(dLon / 2) *
                    Math.sin(dLon / 2)
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
            const d = R * c
            return Math.floor(d)
        }
    },
}

export const Scroll = {
    install(Vue) {
        const duration = 50
        const increment = 5

        const easeInOutQuad = (t, b, c, d) => {
            t /= d / 2
            if (t < 1) return (c / 2) * t * t + b
            t--
            return (-c / 2) * (t * (t - 2) - 1) + b
        }

        const animateScroll = (el, currentTime, start, change, limit, direction) => {
            currentTime += increment
            if (direction === 'up' || direction === 'down') {
                el.scrollTop = easeInOutQuad(currentTime, start, change, duration)
            }

            if (direction === 'left' || direction === 'right') {
                el.scrollLeft = easeInOutQuad(currentTime, start, change, duration)
            }

            if (currentTime < duration) {
                if (!limit) {
                    setTimeout(() => animateScroll(el, currentTime, start, change, limit, direction), increment)
                } else {
                    if (direction === 'up' && start - el.scrollTop < limit) {
                        setTimeout(() => animateScroll(el, currentTime, start, change, limit, direction), increment)
                    }
                    if (direction === 'down' && el.scrollTop - start < limit) {
                        setTimeout(() => animateScroll(el, currentTime, start, change, limit, direction), increment)
                    }
                    if (direction === 'left' && start - el.scrollLeft < limit) {
                        setTimeout(() => animateScroll(el, currentTime, start, change, limit, direction), increment)
                    }
                    if (direction === 'right' && el.scrollLeft - start < limit) {
                        setTimeout(() => animateScroll(el, currentTime, start, change, limit, direction), increment)
                    }
                }
            }
        }

        Vue.prototype.$scroll = {
            up: (el, animate, limit) => {
                if (!el) return

                const currentTime = 0
                const start = el.scrollTop
                const change = 0 - start

                if (animate) {
                    animateScroll(el, currentTime, start, change, limit, 'up')
                } else {
                    el.scrollTop = el.scrollTop - limit || 0
                }
            },
            down: (el, animate, limit) => {
                if (!el) return

                const currentTime = 0
                const start = el.scrollTop
                const change = el.scrollHeight - start

                if (animate) {
                    animateScroll(el, currentTime, start, change, limit, 'down')
                } else {
                    el.scrollTop = limit || el.scrollHeight
                }
            },
            left: (el, animate, limit) => {
                if (!el) return

                const currentTime = 0
                const start = el.scrollLeft
                const change = 0 - start

                if (animate) {
                    animateScroll(el, currentTime, start, change, limit, 'left')
                } else {
                    el.scrollLeft = el.scrollLeft - limit || 0
                }
            },
            right: (el, animate, limit) => {
                if (!el) return

                const currentTime = 0
                const start = el.scrollLeft
                const change = el.scrollWidth - start

                if (animate) {
                    animateScroll(el, currentTime, start, change, limit, 'right')
                } else {
                    el.scrollLeft = limit || el.scrollWidth
                }
            },
        }
    },
}

export const OnKeydownHandler = {
    install(Vue) {
        const pressedKeyCodes = {}

        const ctrl = key => pressedKeyCodes.ControlLeft && pressedKeyCodes[key]

        Vue.prototype.$bus.$on('onShortcut', event => {
            if (event.type === 'keydown') pressedKeyCodes[event.code] = true
            if (event.type === 'keyup') delete pressedKeyCodes[event.code]
        })

        Vue.prototype.$onKeydownHandler = () => {
            // if (ctrl('Digit1')) {
            //     navigate('HomePage')
            //     return
            // }

            // if (ctrl('Digit2')) {
            //     navigate('ChatPage')
            //     return
            // }

            // if (ctrl('Digit3')) {
            //     navigate('MyPage')
            //     return
            // }

            // if (ctrl('Digit4')) {
            //     navigate('GiftPage')
            //     return
            // }

            // if (ctrl('Digit5')) {
            //     navigate('SettingsPage')
            //     return
            // }

            // if (ctrl('Digit6')) {
            //     Vue.prototype.$bus.$emit('toggleNotification')
            //     return
            // }

            if (ctrl('ArrowUp')) {
                const chats = $store.getters.chats || []
                const currentChat = $store.getters.chat

                if (chats.length === 0 || !currentChat || ($store.getters.modals || []).length > 0) return

                const idx = chats.findIndex(c => c.id === currentChat.id)

                if (idx === 0) return

                const nextChat = chats[idx - 1]
                $store.dispatch('loadChat', { userId: nextChat.user.id })
            }

            if (ctrl('ArrowDown')) {
                const chats = $store.getters.chats || []
                const currentChat = $store.getters.chat

                if (chats.length === 0 || !currentChat || ($store.getters.modals || []).length > 0) return

                const idx = chats.findIndex(c => c.id === currentChat.id)

                if (idx === chats.length - 1) return

                const nextChat = chats[idx + 1]
                $store.dispatch('loadChat', { userId: nextChat.user.id })
            }

            if (ctrl('Comma')) {
                const agent = $store.getters.agent
                if (!agent || !agent.is_premium) {
                    Vue.prototype.$bus.$emit('callIntroduceFriend')
                    return
                }
            }

            if (ctrl('Period')) {
                const agent = $store.getters.agent
                if (!agent || !agent.is_premium) {
                    Vue.prototype.$bus.$emit('callSpreadFriend')
                }
            }
        }
    },
}

export const H = {
    install(Vue) {
        Vue.prototype.$hangul = Hangul
    },
}

export const NativeBridge = {
    install(Vue) {
        Vue.prototype.$nativeBridge = {
            postMessage: obj => {
                if (!window.ReactNativeWebView) return

                window.ReactNativeWebView.postMessage(JSON.stringify(obj))
            },
        }

        Vue.prototype.$updateAppIconBadgeCount = () => {
            const totalUnreads = ($store.getters.chats || []).reduce((acc, chat) => acc + (chat.unread || 0), 0)

            window.ReactNativeWebView.postMessage(
                JSON.stringify({
                    action: 'setIconBadgeCount',
                    value: totalUnreads,
                })
            )
        }
    },
}

export const GetChat = {
    install(Vue) {
        Vue.prototype.$getChat = userId => {
            const existing = ($store.getters.chatList || []).find(chat => chat.user_id === userId)
            if (!existing) return

            return existing
        }
    },
}

export const preferAge = {
    install(Vue) {
        Vue.prototype.$preferAge = (user, source) => {
            const checkAge = (userAge, sourceAge, userGender) => {
                if (userGender === 0) {
                    /* 남자는
                    25 이하 +10까지
                    25~31 +8까지
                    32 이상 +6까지
                    */
                    if (userAge <= 25) return sourceAge <= userAge + 10
                    else if (userAge <= 31) return sourceAge <= userAge + 8
                    else return sourceAge <= userAge + 6
                } else {
                    /* 여자는
                    29 이하 -4~+10까지
                    30~32 -6~+7까지
                    33 이상 -10~+8까지
                    */
                    if (userAge <= 29) return sourceAge <= userAge + 10 && sourceAge >= userAge - 4
                    else if (userAge <= 32) return sourceAge <= userAge + 7 && sourceAge >= userAge - 6
                    else return sourceAge <= userAge + 8 && sourceAge >= userAge - 10
                }
            }
            if (user && user.profile && source && source.profile) {
                const userAge = asAge(user.profile.birthday)
                const sourceAge = asAge(source.profile.birthday)
                if (user.style && user.style.min_age && user.style.max_age) {
                    if (sourceAge >= user.style.min_age && sourceAge <= user.style.max_age) {
                        return true
                    } else {
                        return checkAge(userAge, sourceAge, user.gender)
                    }
                } else {
                    return checkAge(userAge, sourceAge, user.gender)
                }
            } else return true
        }
    },
}

export const Profile = {
    install(Vue) {
        Vue.prototype.$profile = {
            moneyRangeParsed(value) {
                if (!value) {
                    return '소득없음'
                } else if (value < 2000) {
                    return '2천만원 미만'
                } else if (value < 4000) {
                    return '2~3천만원대'
                } else if (value < 7000) {
                    return '4~6천만원대'
                } else if (value < 10000) {
                    return '7~9천만원대'
                } else if (value < 15000) {
                    return '1억 ~ 1억4천만원대'
                } else if (value < 20000) {
                    return '1억5천 ~1억9천만원대'
                } else if (value < 30000) {
                    return '2억원대'
                } else if (value < 40000) {
                    return '3억원대'
                } else {
                    return '4억원 이상'
                }
            },
            assetRangeParsed(value) {
                if (!value) {
                    return '자산없음'
                } else if (value < 10000) {
                    return '1억원 미만'
                } else if (value < 40000) {
                    return '1~3억원대'
                } else if (value < 70000) {
                    return '4~6억원대'
                } else if (value < 100000) {
                    return '7~9억원대'
                } else if (value < 200000) {
                    return '10억원대'
                } else if (value < 300000) {
                    return '20억원대'
                } else if (value < 400000) {
                    return '30억원대'
                } else if (value < 500000) {
                    return '40억원대'
                } else if (value < 1000000) {
                    return '50억~90억원대'
                } else {
                    return '100억 이상'
                }
            },
            jobFreePage: jobCategoryId => {
                return [1, 12, 13, 14, 16, 17].includes(jobCategoryId)
            },
            parentStatus: {
                0: { id: 0, title: '함께 거주' },
                1: { id: 1, title: '별거' },
                2: { id: 2, title: '이혼' },
                3: { id: 3, title: '사별' },
            },
            houseOwner: {
                0: {
                    id: 0,
                    name: '본인 소유',
                },
                1: {
                    id: 1,
                    name: '부모님 소유',
                },
                2: {
                    id: 2,
                    name: '형제 소유',
                },
            },
            employmentType: {
                0: {
                    id: 0,
                    title: '정규직',
                    key: 'regular',
                },
                1: {
                    id: 1,
                    title: '계약직',
                    key: 'contract',
                },
                2: {
                    id: 2,
                    title: '파견직',
                    key: 'dispatch',
                },
                3: {
                    id: 3,
                    title: '위촉직',
                    key: 'commissioned',
                },
                4: {
                    id: 4,
                    title: '기타',
                    key: 'others',
                },
            },
            familyType: {
                0: { id: 0, name: 'father' },
                1: { id: 1, name: 'mother' },
                2: { id: 2, name: 'sibling' },
            },
            educationStatus: {
                0: { id: 0, title: '초등학교 졸업' },
                1: { id: 1, title: '중학교 졸업' },
                2: { id: 2, title: '고등학교 졸업' },
                3: { id: 3, title: '대학교 졸업' },
                4: { id: 4, title: '대학교 석사 졸업' },
                5: { id: 5, title: '대학교 박사 졸업' },
            },
            userEduStatus: {
                0: {
                    id: 0,
                    title: '졸업',
                    key: 'graduation',
                    highSchool: true,
                },
                1: {
                    id: 3,
                    title: '재학',
                    key: 'enrollment',
                },
                2: {
                    id: 1,
                    title: '수료',
                    key: 'completion',
                },
                3: {
                    id: 2,
                    title: '중퇴',
                    key: 'withdrawal',
                    highSchool: true,
                },
                4: {
                    id: 4,
                    title: '휴학',
                    key: 'absence',
                },
                5: {
                    id: 5,
                    title: '편입재학',
                    key: 'transfer_enrollment',
                },
                6: {
                    id: 6,
                    title: '편입졸업',
                    key: 'transfer_graduation',
                },
            },
        }
    },
}

export const getMoney = {
    install(Vue) {
        Vue.prototype.$krwParsed = value => {
            const val = String(value)
            if (val.length > 8) {
                const zo = val.slice(0, val.length - 8)
                const billion = val.slice(val.length - 8, val.length - 4)
                const rest = val.slice(val.length - 4, val.length)

                return `${zo}조 ${Number(billion) ? `${Number(billion).toLocaleString()}억 ` : ''}${
                    Number(rest) ? `${Number(rest).toLocaleString()}만원` : ''
                }`
            }
            if (val.length > 4) {
                const billion = val.slice(0, val.length - 4)
                const rest = val.slice(val.length - 4, val.length)

                return `${Number(billion) ? `${Number(billion).toLocaleString()}억 ` : ''}${
                    Number(rest) ? `${Number(rest).toLocaleString()}만원` : ''
                }`
            }

            return `${Number(val).toLocaleString()} 만원`
        }
    },
}
