<template>
    <div class="action-default-layout card">
        <div class="content">
            <div class="side" :class="[friendType, instant]" />
            <slot />
        </div>
    </div>
</template>
<script>
export default {
    name: 'ActionDefaultLayout',
    props: ['message'],
    computed: {
        chat() {
            return this.$store.getters.chat || {}
        },
        friendType() {
            if (this.message.mtype.includes('recommend')) return 'recommended'
            return this.$options.filters.friendType(this.chat.user)
        },
        instant() {
            return this.chat.expire_at ? 'instant' : ''
        },
    },
}
</script>
