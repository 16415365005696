<template>
    <div class="signup password">
        <div class="page-header">
            <div class="title">비밀번호</div>
        </div>
        <form class="body">
            <div v-if="email" class="item-desc">{{ email }}</div>
            <div class="input-wrapper m-b-12">
                <input
                    ref="password"
                    type="password"
                    class="flex-fill"
                    placeholder="비밀번호를 6자 이상 입력해주세요"
                    v-model="password"
                    @input="onInputPassword"
                />
            </div>
            <div class="input-wrapper">
                <input
                    type="password"
                    class="flex-fill"
                    placeholder="비밀번호를 확인해주세요"
                    v-model="passwordConfirm"
                    @input="onInputPasswordConfirm"
                />
            </div>
            <div class="error" v-if="error">
                <i class="material-icons">error_outline</i>
                {{ error }}
            </div>
        </form>
        <button
            class="btn btn-primary"
            :class="{ disabled: disabled }"
            @click="next()"
            v-html="$translate('NEXT')"
        ></button>
    </div>
</template>

<script>
export default {
    name: 'PasswordPage',
    data: () => ({
        password: '',
        passwordConfirm: '',
        error: null,
        disabled: true,
    }),
    mounted() {
        this.$refs.password.focus()
    },
    computed: {
        email() {
            return this.$store.getters.payloads.signupData.email
        },
    },
    methods: {
        checkPassword() {
            if (this.password && this.password.length < 6) {
                this.error = '비밀번호는 6자리 이상이어야 합니다.'
                this.disabled = this.error ? true : false
                return
            }

            if (this.password && this.passwordConfirm && this.password !== this.passwordConfirm) {
                this.error = '비밀번호가 일치하지 않습니다.'
                this.disabled = this.error ? true : false
                return
            }

            this.error = null
            this.disabled = !this.password || !this.passwordConfirm
        },
        onInputPassword(event) {
            this.password = event.target.value
            this.checkPassword()
        },

        onInputPasswordConfirm(event) {
            this.passwordConfirm = event.target.value
            this.checkPassword()
        },
        next() {
            if (this.error || this.disabled) return

            if (!this.error) {
                this.$store.commit('setPayloads', {
                    signupData: {
                        password: this.$store.getters.payloads.signupData.password || this.password,
                    },
                })
                this.$router.push({ name: 'NicknamePage' })
            }
        },
        back() {
            this.$router.go(-1)
        },
    },
}
</script>
