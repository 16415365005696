import VerificationPage from '@/routes/signup/VerificationPage'
import ConsentPage from '@/routes/signup/ConsentPage'
import EmailPage from '@/routes/signup/EmailPage'
import PasswordPage from '@/routes/signup/PasswordPage'
import NicknamePage from '@/routes/signup/NicknamePage'
import StatePage from '@/routes/signup/StatePage'
import StationPage from '@/routes/signup/StationPage'
import RegionPage from '@/routes/signup/RegionPage'
import UploadPhotoPage from '@/routes/signup/UploadPhotoPage'
import FriendTypePage from '@/routes/signup/FriendTypePage'
import KeywordsPage from '@/routes/signup/KeywordsPage'
import IntroPage from '@/routes/signup/IntroPage'

export default [{
    path: '/signup/verification',
    name: 'VerificationPage',
    component: VerificationPage,
}, {
    path: '/signup/consent',
    name: 'ConsentPage',
    component: ConsentPage,
}, {
    path: '/signup/email',
    name: 'EmailPage',
    component: EmailPage,
}, {
    path: '/signup/password',
    name: 'PasswordPage',
    component: PasswordPage,
}, {
    path: '/signup/nickname',
    name: 'NicknamePage',
    component: NicknamePage,
}, {
    path: '/signup/state',
    name: 'StatePage',
    component: StatePage,
}, {
    path: '/signup/station',
    name: 'StationPage',
    component: StationPage,
}, {
    path: '/signup/region',
    name: 'RegionPage',
    component: RegionPage,
}, {
    path: '/signup/upload_photo',
    name: 'UploadPhotoPage',
    component: UploadPhotoPage,
}, {
    path: '/signup/friend_type',
    name: 'FriendTypePage',
    component: FriendTypePage,
}, {
    path: '/signup/keywords',
    name: 'KeywordsPage',
    component: KeywordsPage,
}, {
    path: '/signup/intro',
    name: 'IntroPage',
    component: IntroPage,
}]