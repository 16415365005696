<template>
    <div v-if="user" class="user-styles" :class="{ mobile: $store.getters.isMobile }">
        <Styles :styles="user.style" :gender="user.gender" />
        <OnlyToManager :content="profile.only_to_manager" />
    </div>
</template>
<script>
import Styles from '@/components/common/Styles'
import OnlyToManager from '@/components/common/OnlyToManager'

export default {
    name: 'UserStyles',
    props: ['user', 'tableOnly'],
    components: {
        Styles,
        OnlyToManager,
    },
    computed: {
        profile() {
            return (this.user || {}).profile || {}
        },
        stylesIntro() {
            if (!this.user.style) return

            return (this.user.style.intro || '').trim() || '-'
        },
    },
}
</script>

<style lang="scss" scoped>
.user-styles {
    &.mobile {
        .title {
            font-size: 18px;
        }
        .intro {
            color: $black;
            font-size: 15px;
        }
    }
}
</style>
