import Vue from 'vue'
import dashboardService from '@/services/dashboard'
import refundService from '@/services/refund'

import * as helpers from './helpers'

const defaultState = () => ({
    notices: null,
    guides: null,
    pinned: null,
    totalRanking: null,
    myRanking: null,
    weeklyStat: null,
    dailyStat: null,
    datings: null,
    refunds: null,
})

const state = defaultState()

const getters = {
    notices: state => state.notices,
    guides: state => state.guides,
    pinned: state => state.pinned,
    totalRanking: state => state.totalRanking,
    myRanking: state => state.myRanking,
    weeklyStat: state => state.weeklyStat,
    dailyStat: state => state.dailyStat,
    datings: state => state.datings,
    refunds: state => state.refunds,
}

const actions = {
    async loadNotices({ state, getters, commit, dispatch }) {
        try {
            const notices = await dashboardService.notices('notice')
            if (!notices || notices.length === 0) return

            notices.forEach(notice => {
                notice.is_new = Vue.prototype.$moment().diff(notice.created_at, 'seconds') < 60 * 60 * 24 * 2
            })

            await dispatch('loadPinned')

            commit('setNotices', {
                notices,
                solvedQuiz: (notices || [])
                    .concat(state.guides || [])
                    .concat(state.pinned || [])
                    .every(notice => notice.quizzes.every(quiz => quiz.is_solved)),
            })
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async loadPinned({ commit }) {
        try {
            const pinned = await dashboardService.notices('pinned')
            if (!pinned || pinned.length === 0) return

            commit('setPinned', pinned)
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async loadRefunds({ commit }) {
        try {
            const data = await refundService.all()
            data.forEach(refund => {
                refund.user.$$type = 'REFUND_USER'
                refund.user.$$todo = 'REFUND_USER_TODO'
                refund.relative_user.$$type = 'REFUND_RELATIVE_USER'
                if (!refund.target_is_checked) {
                    refund.relative_user.$$todo = 'REFUND_RELATIVE_USER_BEFORE'
                } else {
                    if (refund.target_refund_reason_code === 'reject') {
                        refund.relative_user.$$todo = 'REFUND_RELATIVE_USER_REJECT'
                    } else if (refund.target_refund_reason_code === 'contact') {
                        refund.relative_user.$$todo = 'REFUND_RELATIVE_USER_CONTACT'
                    } else if (refund.target_refund_reason_code === 'meet') {
                        refund.relative_user.$$todo = 'REFUND_RELATIVE_USER_MEET'
                    } else {
                        refund.relative_user.$$todo = 'REFUND_RELATIVE_USER_AFTER'
                    }
                }
            })
            commit('setRefunds', data)
            commit('populateRefundObservables')
            return Promise.resolve(data)
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async loadTotalRanking({ commit }) {
        try {
            const totalRanking = await dashboardService.totalRanking()
            if (!totalRanking) return

            commit('setTotalRanking', totalRanking)
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async loadMyRanking({ commit }) {
        try {
            const myRanking = await dashboardService.myRanking()
            if (!myRanking) return

            commit('setMyRanking', myRanking)
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async loadWeeklyStat({ commit }) {
        try {
            const weeklyStat = await dashboardService.weeklyStat()
            if (!weeklyStat) return

            commit('setWeeklyStat', weeklyStat)
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async loadDailyStat({ commit }) {
        if (helpers.canSkipApiCall(state, 'dailyStat')) return

        try {
            const dailyStat = await dashboardService.activity()
            if (!dailyStat) return

            commit('setDailyStat', dailyStat)
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async loadDatings({ commit }) {
        if (helpers.canSkipApiCall(state, 'datings')) return

        try {
            commit('setLoading', { datings: true })
            const datings = await dashboardService.datings()
            if (!datings) return

            commit('setDatings', datings)
        } catch (e) {
            return Promise.reject(e)
        } finally {
            commit('setLoading', { datings: false })
        }
    },
}

const mutations = {
    setNotices(state, { notices, solvedQuiz }) {
        state.notices = notices
        if (state.agent) {
            Vue.set(state.agent, '$$solvedQuiz', solvedQuiz)
        }
    },
    setGuides(state, guides) {
        state.guides = guides
    },
    setPinned(state, pinned) {
        state.pinned = pinned
    },
    setRefunds(state, refunds) {
        state.refunds = refunds
    },
    setTotalRanking(state, totalRanking) {
        state.totalRanking = totalRanking
    },
    setMyRanking(state, myRanking) {
        state.myRanking = myRanking
    },
    setWeeklyStat(state, weeklyStat) {
        state.weeklyStat = weeklyStat
    },
    setDailyStat(state, dailyStat) {
        state.dailyStat = dailyStat
    },
    setDatings(state, datings) {
        state.datings = datings
    },
    populateRefundObservables(state) {
        if (!state.refunds) return

        state.refunds.forEach(refund =>
            [refund.user, refund.relative_user].forEach(user => {
                // Vue.set(user, '$$friendType', Vue.options.filters.friendType(user, true))
                user.friendType = Vue.options.filters.friendType(user, true)
                Vue.set(user, '$$chatId', (Vue.prototype.$getChat(user.id) || {}).id)
            })
        )
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
    defaultState,
}
