<template>
    <div v-if="showMessage" class="message flex-row" :class="{ 'justify-end': me }">
        <div
            class="timestamp flex-wrap"
            v-if="['profile-photo', 'multi-photo', 'photo'].indexOf(message.mtype) === -1 && me"
        >
            {{ message.created_at | formatDate('A h:mm') }}
        </div>
        <MessageAction
            v-if="message.mtype !== 'text' && message.mtype !== 'premium-counsel' && message.mtype !== 'cancel-fee-paid'"
            class="flex-wrap"
            :message="message"
        />
        <MessageText
            v-if="message.mtype === 'text' || message.mtype === 'premium-counsel' || message.mtype === 'cancel-fee-paid'"
            class="flex-wrap"
            :message="message"
        />
        <div
            class="timestamp flex-wrap"
            v-if="['profile-photo', 'multi-photo', 'photo'].indexOf(message.mtype) === -1 && !me"
        >
            {{ message.created_at | formatDate('A h:mm') }}
        </div>
    </div>
</template>
<script>
import MessageAction from './MessageAction'
import MessageText from './MessageText'

export default {
    name: 'Message',
    props: ['message', 'me'],
    components: {
        MessageAction,
        MessageText,
    },
    computed: {
        // We don't need to show mtype === 'request' since it always comes after contact_done.
        showMessage() {
            return this.message && this.message.mtype !== 'request'
        },
    },
}
</script>
