<template>
    <div class="user-detail-premium-comment">
        <div class="title">
            <div class="text" v-html="'주선자 코멘트'" />
            <img class="flex-wrap m-l-8" src="@/assets/images/premium_blue.svg" />
        </div>
        <div class="body">
            <div class="agent">
                <img class="agent-photo flex-wrap" :src="info.photo_url" />
                <div class="agent-name" v-html="`주선자 <b>${info.name}</b>`" />
            </div>
            <div class="comment" v-html="info.agent_intro || ''" />
            <div class="footer">
                <img class="footer-img flex-wrap m-r-8" src="@/assets/images/exclamation.svg" />
                <span class="footer-text" v-html="'주선자가 회원님과 직접 미팅을 가진 후 작성했어요'" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UserDetailPremiumComment',
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        rating: null,
        shouldClamp: true,
    }),
    mounted() {
        const dom = this.$refs.agentMessage
        if (dom) {
            this.shouldClamp = dom.scrollHeight - 1 > dom.clientHeight
        }
    },
    computed: {
        info() {
            return this.user.premium_infos.length > 0 ? this.user.premium_infos[0].premium_info : {}
        },
    },
    methods: {},
}
</script>

<style lang="scss" scoped>
.user-detail-premium-comment {
    .title {
        display: flex;
        margin-left: 16px;
        margin-top: 60px;

        .text {
            font-size: 18px;
            color: black;
            font-family: NotoSans-Medium;
        }
    }
    .body {
        // width: 328px;
        height: fit-content;
        margin: 20px 16px 32px;
        padding: 16px 16px 14px;
        border-radius: 16px;
        border: solid 1px $grey-02;
        background-image: $brand-premium-grad;

        .agent {
            display: flex;
            .agent-photo {
                width: 28px;
                height: 28px;
                margin: 0 0px 12px 0;
                border-radius: 11.2px;
                border: solid 1px $grey-03;
            }
            .agent-name {
                margin: 8px 8px;

                color: $yellow-premium;
                font-size: 12px;
            }
        }

        .comment {
            color: white;
            font-size: 14px;
            line-height: 1.57;
            letter-spacing: -0.2px;
        }

        .footer {
            margin-top: 12px;
            display: flex;
            color: $yellow-premium;
            font-size: 12px;
            .footer-img {
                width: 12px;
                height: 12px;
            }
            .footer-text {
                align-self: center;
            }
        }
    }
    hr {
        height: 1px;
        background-color: $grey-02;
        border: none;
    }
}
</style>
