<template>
    <div class="filter-item p-relative" :class="title.toLowerCase().replace(/_/g, '-')">
        <div class="title flex-row items-center">
            <span class="flex-wrap" v-html="$translate(title)" />
        </div>
        <div class="buttons">
            <button
                v-if="!hideAll"
                @click="selectAll"
                class="btn btn-brd"
                :class="{ primary: allSelected, mobile: $store.getters.isMobile }"
            >
                {{ 'ALL' | translate }}
            </button>
            <button
                @click="applyFilterDetail(item)"
                class="btn btn-brd"
                :class="{ primary: item.selected && (!allSelected || hideAll), mobile: $store.getters.isMobile }"
                :key="idx"
                v-html="`${name || ''}${$translate(item.name)}`"
                v-for="(item, idx) in items"
            ></button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FilterItem',
    props: ['title', 'items', 'hideAll', 'preventMultiSelect', 'user'],
    computed: {
        allSelected() {
            if (this.title === 'PRACTITIONER') {
                return this.items.every(item => !item.selected)
            }
            return this.items.every(item => item.selected)
        },
        name() {
            return (this.user || {}).name
        },
    },
    methods: {
        applyFilterDetail(item) {
            if (this.preventMultiSelect) {
                this.items.forEach(i => {
                    i.selected = i.name === item.name
                })
                this.emit(item)
                return
            }

            if (this.allSelected && !this.hideAll) {
                this.items.forEach(item => {
                    item.selected = false
                })
                item.selected = true
                this.emit(item)
                return
            }

            item.selected = !item.selected

            if (this.items.every(item => !item.selected) && !this.hideAll) {
                this.selectAll()
            }

            this.emit(item)
        },
        selectAll() {
            if (this.title === 'PRACTITIONER') {
                this.items.forEach(item => {
                    item.selected = false
                })
                return
            }
            this.items.forEach(item => {
                item.selected = true
            })
        },
        emit(item) {
            this.$emit('onChange', {
                title: this.title,
                items: this.items,
                item,
            })
        },
    },
    mounted() {
        this.emit()
    },
}
</script>
