<template>
    <div class="user-verification-badges">
        <div class="badge" :class="{ contract: badge.id === 1 }" v-for="badge in badgesList" :key="badge.id">
            <span class="m-r-4" v-html="badge.title" />
            <div class="status" :class="{ confirmed: badge.confirmed, pending: badge.enabled }">
                <span v-if="badge.confirmed">O</span>
                <span v-else-if="badge.enabled">△</span>
                <span v-else>X</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UserVerificationBadges',
    props: ['user'],
    computed: {
        userVerificationBadges() {
            return (this.user || {}).$$verification_badges || []
        },
        badgesList() {
            return [
                // {
                //     title: '계약서',
                //     id: 1,
                //     enabled:
                //         (this.userVerificationBadges.find(b => b.verification_badge_id === 1 && b.enabled) || {}).enabled || false,
                //     confirmed:
                //         (this.userVerificationBadges.find(b => b.verification_badge_id === 1 && b.confirmed) || {}).confirmed || false,
                // },
                {
                    title: '신분증',
                    id: 13,
                    enabled:
                        (this.userVerificationBadges.find(b => b.verification_badge_id === 13 && b.enabled) || {})
                            .enabled || false,
                    confirmed:
                        (this.userVerificationBadges.find(b => b.verification_badge_id === 13 && b.confirmed) || {})
                            .confirmed || false,
                },
                // {
                //     title: '소득',
                //     id: 14,
                //     enabled:
                //         (this.userVerificationBadges.find(b => b.verification_badge_id === 14 && b.enabled) || {}).enabled || false,
                //     confirmed:
                //         (this.userVerificationBadges.find(b => b.verification_badge_id === 14 && b.confirmed) || {}).confirmed ||
                //         false,
                // },
                {
                    title: '직장',
                    id: 15,
                    enabled:
                        (this.userVerificationBadges.find(b => b.verification_badge_id === 15 && b.enabled) || {})
                            .enabled || false,
                    confirmed:
                        (this.userVerificationBadges.find(b => b.verification_badge_id === 15 && b.confirmed) || {})
                            .confirmed || false,
                },
                // {
                //     title: '혼인관계',
                //     id: 20,
                //     enabled:
                //         (this.userVerificationBadges.find(b => b.verification_badge_id === 20 && b.enabled) || {}).enabled || false,
                //     confirmed:
                //         (this.userVerificationBadges.find(b => b.verification_badge_id === 20 && b.confirmed) || {}).confirmed ||
                //         false,
                // },
                // {
                //     title: '가족관계',
                //     id: 21,
                //     enabled:
                //         (this.userVerificationBadges.find(b => b.verification_badge_id === 21 && b.enabled) || {}).enabled || false,
                //     confirmed:
                //         (this.userVerificationBadges.find(b => b.verification_badge_id === 21 && b.confirmed) || {}).confirmed ||
                //         false,
                // },
                // {
                //     title: '제적등본',
                //     id: 22,
                //     enabled:
                //         (this.userVerificationBadges.find(b => b.verification_badge_id === 22 && b.enabled) || {}).enabled || false,
                //     confirmed:
                //         (this.userVerificationBadges.find(b => b.verification_badge_id === 22 && b.confirmed) || {}).confirmed ||
                //         false,
                // },
                {
                    title: '학력',
                    id: 23,
                    enabled:
                        (this.userVerificationBadges.find(b => b.verification_badge_id === 45 && b.enabled) || {})
                            .enabled || false,
                    confirmed:
                        (this.userVerificationBadges.find(b => b.verification_badge_id === 45 && b.confirmed) || {})
                            .confirmed || false,
                },
                // {
                //     title: '석사',
                //     id: 24,
                //     enabled:
                //         (this.userVerificationBadges.find(b => b.verification_badge_id === 24) || {}).enabled || false,
                //     confirmed:
                //         (this.userVerificationBadges.find(b => b.verification_badge_id === 24) || {}).confirmed ||
                //         false,
                // },
                // {
                //     title: '박사',
                //     id: 25,
                //     enabled:
                //         (this.userVerificationBadges.find(b => b.verification_badge_id === 25) || {}).enabled || false,
                //     confirmed:
                //         (this.userVerificationBadges.find(b => b.verification_badge_id === 25) || {}).confirmed ||
                //         false,
                // },
            ]
        },
    },
}
</script>

<style scoped lang="scss">
.user-verification-badges {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 4px;

    margin: 16px 0;

    .badge {
        background: white;
        color: black;
        display: flex;
        align-items: center;
        padding: 0 0 0 4px;
        border-radius: 15px;

        &.contract {
            background: #dff0ff;
            //color: $grey-06;
        }

        .status {
            background: $grey-06;
            width: 16px;
            height: 16px;
            color: white;
            border-radius: 50%;
            padding: 4px;
            @include center;

            &.pending {
                background: #ff800b;
            }
            &.confirmed {
                background: #3da2ff;
            }
        }
    }
}
</style>
