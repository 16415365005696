<template>
    <div class="loading" v-if="loading && shouldShowLoading">
        <Preloader class="m-a" />
    </div>
</template>

<script>
import Preloader from '@/components/app/Preloader'

export default {
    components: { Preloader },
    props: ['loading'],
    data: () => ({
        shouldShowLoading: false,
    }),
    mounted() {
        this.init()
    },
    watch: {
        loading() {
            this.init()
        },
    },
    methods: {
        init() {
            this.shouldShowLoading = false
            if (this.loading) {
                setTimeout(() => {
                    this.shouldShowLoading = true
                }, 150)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.loading {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: transparent;
    z-index: 10;
    display: flex;
    align-items: center;
}
</style>
