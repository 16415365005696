<template>
    <div class="users-list flex p-relative">
        <!-- <hr class="hor-divider" v-if="chat.user"/> -->
        <div
            v-if="filterChanged"
            class="overlay"
            @click="$toast.error('검색을 완료한 후, 유저 프로필을 확인해주세요!')"
        />
        <Loading :loading="loading" />

        <div v-if="chat.user" class="title-and-count-and-filter flex-wrap">
            <template v-if="justAsSelector">
                <div class="count-and-sort-row">
                    <div class="selected-count">
                        <div class="selected">{{ numSelected }}명</div>
                        <div class="m-l-4">/ {{ numFilteredUsers }}명</div>
                    </div>
                    <div class="flex-row">
                        <Dropdown
                            class="flex-wrap"
                            @change="sort => dropdownChanged(sort)"
                            :hideCaret="true"
                            :titleKey="'title'"
                            :items="dropdownItems"
                        />
                        <span v-if="$store.getters.isMobile" class="divider flex-wrap m-r-10 m-l-10" v-html="'|'" />
                        <FilterIcon v-if="$store.getters.isMobile" class="flex-wrap" @click="onClickFilters" />
                    </div>
                </div>
                <div class="flex-row m-b-12">
                    <div class="select-all-btn center" v-html="'50명 선택'" @click="onClickSelectAll(50)"></div>
                    <div class="select-all-btn center m-l-8" v-html="'100명 선택'" @click="onClickSelectAll(100)"></div>
                    <div
                        class="select-all-btn center m-l-8"
                        :class="{ selectedAll: selectedAll }"
                        v-html="selectAllText"
                        @click="onClickSelectAll(-1)"
                    ></div>
                </div>
            </template>
            <template v-else>
                <div class="m-b-12" v-if="!$store.getters.isMobile">
                    <img v-if="!$store.getters.isMobile" class="icon" :src="icons.title" />
                    <span
                        class="search-detail-title m-l-8"
                        :class="{ mobile: $store.getters.isMobile }"
                        v-html="$translate('SEARCH_USERS')"
                    />
                </div>
                <div class="flex-row">
                    <div class="search-detail-btn m-b-12 center" v-html="'필터로 검색'" @click="onClickFilters"></div>
                    <div
                        class="search-nickname-btn m-b-12 center"
                        v-html="'닉네임으로 검색'"
                        @click="onClickSearchNickname"
                    ></div>
                </div>
                <div class="flex-row" v-if="chat.user.is_ai">
                    <div class="search-ai-btn m-b-12 center" v-html="'AI 추천'" @click="onClickAISearch"></div>
                </div>
                <!-- <div class="flex-row" v-if="showAiBtn">
                    <div class="search-ai-btn m-b-12 center" v-html="'AI모델 추천'" @click="onClickAI"></div>
                </div> -->

                <div class="">
                    <Dropdown
                        class="flex-wrap"
                        @change="sort => dropdownChanged(sort)"
                        :hideCaret="true"
                        :titleKey="'title'"
                        :items="dropdownItems"
                    />
                </div>
            </template>
        </div>

        <Scroller :direction="'up'" :dom="verticalList" :hideAtEdge="true" />

        <div
            @scroll="onScrollList"
            ref="ver-scroll"
            class="ver-scroll flex-fill"
            v-show="(filteredUsers || []).length > 0 && userPrivacyTermsAgreed"
            name="fade"
        >
            <component
                :is="newVersion ? 'UserV2' : 'User'"
                :chatUser="chat.user"
                :key="idx"
                :justAsSelector="justAsSelector"
                :isSelectedUser="isSelectedUser"
                :style="{ 'background-color': findSchoolColor(user) }"
                @onClickUser="user => $emit('onClickUser', user)"
                v-for="(user, idx) in gradual"
                :user="isAIRecommendation ? user.user : user"
                :isAIRecommendation="isAIRecommendation"
                :AIInfo="isAIRecommendation ? user : false"
            />
        </div>

        <div class="empty flex-wrap" v-show="users && numFilteredUsers === 0 && chat.user">
            <div class="search-empty" v-html="emptyStr" />
        </div>

        <div v-show="!users && !loading" class="flex-wrap">
            <div class="empty">
                <div class="search-empty" v-html="$translate('EMPTY_RECOMMENDS')" />
            </div>
        </div>

        <div v-show="users && numFilteredUsers === 0 && !chat.user" class="chat-unset">
            <div class="title" v-html="$translate('CHAT_UNSET_TITLE')" />
            <div class="text" v-html="$translate('CHAT_UNSET_TEXT')" />
        </div>
    </div>
</template>

<script>
import User from './User'
import UserV2 from './UserV2'
import chatService from '@/services/chat'
import commonService from '@/services/common'
import * as helpers from '@/store/data/helpers'

export default {
    name: 'UsersList',
    props: [
        'vbRecommend', // 빠른 주선 기능을 사용하고 싶을 때만 주입
        'justAsSelector',
        'filters',
        'isSelectedUser',
        'newVersion',
        'filterChanged',
        'showSuperFilter',
    ],
    components: { User, UserV2 },
    computed: {
        showFilter: {
            get() {
                return this.$store.getters.showFriendsFilter
            },
            set(newVal) {
                this.$store.commit('setShowFriendsFilter', newVal)
            },
        },
        selectAllText() {
            return this.selectedAll ? '선택 해제' : '전체 선택'
        },
        numSelected() {
            return (this.filteredUsers || []).filter(user => user.$$selected).length
        },
        loading() {
            return this.$store.getters.loading[this.$case.toCamel('superSearch')]
        },
        chat() {
            return this.$store.getters.chat || {}
        },
        sort() {
            return this.chat.$$sort || null
        },
        emptyStr() {
            return this.$translate('SEARCH_EMPTY')
        },
        users() {
            let users
            if (this.isAIRecommendation) {
                users = this.chat.$$AIRecommends
                // if (this.filters.type === 'ai') {
                //     users = this.chat.$$aiRecommendsFromDB
                // users  = []
            } else {
                users = this.chat.$$recommendsFromDB
            }
            // const users = this.chat.$$recommendsFromDB
            if (!users) return

            if (!this.chat.user) {
                // Don't show any friend if chat isn't set
                return []
            }

            if (this.justAsSelector) {
                const friendIds = this.$store.getters.chatList.filter(c => c.expire_at === null).map(c => c.user_id)
                return users.filter(
                    user =>
                        !user.is_dormant &&
                        user.gender !== this.chat.user.gender &&
                        !friendIds.includes(user.id) &&
                        this.$preferAge(user, this.chat.user)
                )
            }

            return users.filter(user => !user.is_dormant && user.gender !== this.chat.user.gender)
        },
        filteredUsers() {
            if (this.nickname) {
                if (!this.users) return
                const all = this.$copy(this.users)
                const sameNick = (all || []).find(user => user.profile.nickname === this.nickname)
                const idx = all.indexOf(sameNick)
                if (idx > -1) all.splice(idx, 1)
                return (sameNick ? [sameNick] : []).concat(this.$sorted(all || [], this.sort))
                // } else if (this.isRequestAI) {
            } else {
                if (this.filters && 'sortType' in this.filters) {
                    return this.users
                }
                return this.$sorted(this.users || [], this.sort)
            }
        },
        numFilteredUsers() {
            if (!this.chat.user) return 0

            return (this.filteredUsers || []).length
        },
        dropdownItems() {
            const items = [
                // {
                //     title: 'BY_MATCHING_COEFFICIENT_DESC',
                //     orderby: 'matchingCoefficient',
                //     selected: true,
                // },
                {
                    title: 'BY_TWO_WAY_DESC',
                    orderby: 'two_way',
                    selected: this.chat.user.user_info && this.chat.user.user_info.two_way_score,
                },
                {
                    title: 'BY_FORWARD_WAY_DESC',
                    orderby: 'forward_way',
                },
                {
                    title: 'BY_BACKWARD_WAY_DESC',
                    orderby: 'backward_way',
                },
                {
                    title: 'BY_DISTANCE_ASC',
                    orderby: 'distance',
                    selected: !(this.chat.user.user_info && this.chat.user.user_info.two_way_score),
                },
                {
                    title: 'BY_AGE_ASC',
                    orderby: 'ageAsc',
                },
                {
                    title: 'BY_AGE_DESC',
                    orderby: 'ageDesc',
                },
                {
                    title: 'BY_NAME_ASC',
                    orderby: 'name',
                },
                {
                    title: 'BY_ACCEPT_RATE',
                    orderby: 'acceptRate',
                },
                {
                    title: 'BY_ACCEPTED_RATE',
                    orderby: 'acceptedRate',
                },
            ]

            if (this.chat.$$sort) {
                items.forEach(item => (item.selected = item.orderby === this.chat.$$sort.orderby))
            }
            return items
        },
        applyingFilters() {
            return helpers.applyingFilters(this.filters)
        },
        icons() {
            return {
                title: require('@/assets/images/account_heart.svg'),
            }
        },
        agent() {
            return this.$store.getters.agent || {}
        },
        userPrivacyTermsAgreed() {
            const me = this.$store.getters.agent

            return me.user_privacy_terms_version === 1
        },
    },
    data: () => ({
        gradual: [],
        verticalList: null,
        nickname: null,
        selectedAll: false,
        isRequestAI: false,
        isAIRecommendation: false,
        showAiBtn: false,
        scrollDebounceTimeout: null,
        chatJustChanged: false,
    }),
    mounted() {
        this.refreshGradual()

        this.$bus.$on('setFriendScrollTop', this.setScrollTop)
        window.localStorage.setItem('searchedAI', false)
    },
    beforeDestroy() {
        window.localStorage.removeItem('sort')
        this.$bus.$off('setFriendScrollTop', this.setScrollTop)
    },
    watch: {
        chat(newVal, oldVal) {
            if (!newVal || !oldVal || newVal.id === oldVal.id) return
            if (newVal.id !== oldVal.id) {
                window.localStorage.setItem('searchedAI', false)
            }

            this.refreshGradual()

            this.chatJustChanged = true

            this.setScrollTop()

            setTimeout(() => {
                this.chatJustChanged = false
            }, 500)
        },
        sort() {
            this.refreshGradual()
        },
        filteredUsers(newVal, oldVal) {
            const key = 'numRecommendsFromDB'

            const tabCounts = {}
            tabCounts[key] = this.numFilteredUsers
            this.$store.commit('setTabCounts', tabCounts)
            this.verticalList = this.$refs['ver-scroll']
            this.refreshGradual()
        },
        numSelected(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.selectedAll = newVal === this.numFilteredUsers
            }
        },
    },
    methods: {
        dropdownChanged(sort) {
            console.log('Selected sort type:', sort.orderby)
            this.$store.commit('setSort', sort)
            this.filters.sortType = sort.orderby
            this.superSearch()
        },
        superSearch() {
            this.$bus.$emit('setFriendScrollTop')
            this.$store.commit('setRecommendsFromDB', [])
            this.$set(this.chat, '$$filtersTemp', this.$copy(this.filters))
            const payload = {
                ...this.filters,
                sortType: this.filters.sortType,
            }
            console.log('Super Search Payload:', payload)
            this.$hooks.superSearch(payload)
            this.$emit('onClickSuperSearch')
            this.$emit('update:filterChanged', false)
            localStorage.setItem('searchedAI', false)
        },
        setScrollTop() {
            const verScrollElement = this.$refs['ver-scroll']
            if (verScrollElement) {
                verScrollElement.scrollTop = 0
            }
        },
        // onClickAI() {
        //     this.$toast.success('AI 모델이 추천하는 목록입니다.')
        //     this.isAIRecommendation = true
        //     this.gradual = this.chat.$$AIRecommends
        //     // if (this.justAsSelector) {
        //     //     this.$emit('onClickFilters')
        //     // } else {
        //     //     this.showFilter = true
        //     //     this.$store.dispatch('closeIntroducingUser')
        //     // }
        // },
        findSchoolColor(user) {
            if (!user.profile) return '#fff'
            const bachelor = user.profile.bachelor_u_name
            const master = user.profile.master_u_name
            const doctor = user.profile.doctor_u_name
            // // console.log('272 ' , user, bachelor, master, doctor)
            if (
                bachelor === '서울대' ||
                master === '서울대' ||
                doctor === '서울대' ||
                bachelor === '서울대학교' ||
                master === '서울대학교' ||
                doctor === '서울대학교'
            ) {
                return '#DDFFDD'
            } else if (
                (bachelor === '고려대' && master !== '연세대' && doctor !== '연세대') ||
                (bachelor !== '연세대' && master === '고려대' && doctor !== '연세대') ||
                (bachelor !== '연세대' && master !== '연세대' && doctor === '고려대')
            ) {
                return '#FFDDDD'
            } else if (
                (bachelor === '연세대' && master !== '고려대' && doctor !== '고려대') ||
                (bachelor !== '고려대' && master === '연세대' && doctor !== '고려대') ||
                (bachelor !== '고려대' && master !== '고려대' && doctor === '연세대')
            ) {
                return '#eef7ff'
            }

            return '#e3d3ed'
        },
        refreshGradual() {
            if (!this.filteredUsers) return

            if (this.interv) {
                clearInterval(this.interv)
            }

            this.gradual = []

            // if (this.$store.getters.isMobile) {
            let cursor = 0
            const bulk = 20

            if (this.filteredUsers.length <= 200) {
                this.gradual = this.filteredUsers
            }

            const cycle = () => {
                this.gradual.push(...this.filteredUsers.slice(cursor, bulk + cursor))
                cursor += bulk
            }

            this.interv = setInterval(_ => {
                if (this.gradual.length >= this.filteredUsers.length) {
                    clearInterval(this.interv)
                    return
                }
                cycle()
            }, 20)
            // } else {
            //     this.gradual = this.filteredUsers
            // }
        },
        async onClickVbRecommend() {
            if (this.numSelected > 0) {
                this.filteredUsers.forEach(user => (user.$$selected = false))
                commonService.trackVb({ event_name: 'vb_recommend_off' })
                return
            }

            const userIds = this.filteredUsers.map(user => user.id)
            if ((userIds || []).length === 0) {
                this.$toast.error('ERROR_YOU_SHOULD_SEE_USERS')
                return
            }

            try {
                const data = await chatService.vbRecommend(this.vbRecommend.chat.id, userIds, this.vbRecommend.limit)
                if (!data || data.length === 0) {
                    this.$toast.error('NO_VB_RECOMMEND')
                    return
                }

                data.forEach(userId => {
                    const found = this.filteredUsers.find(user => userId === user.id)
                    if (found) found.$$selected = true
                })

                commonService.trackVb({
                    event_name: 'vb_recommend_on',
                    params: {
                        candidates: userIds.length,
                        selected: data.length,
                        limit: this.vbRecommend.limit,
                    },
                })
            } catch (e) {
                this.$toast.error(e.data)
            }
        },
        onClickFilters() {
            this.isAIRecommendation = false
            const showPrivacyModal = !this.userPrivacyTermsAgreed

            if (showPrivacyModal && !this.$store.getters.isMobile) {
                this.$modal.custom({
                    component: 'ModalPrivacyAgreement',
                    options: {
                        preventCloseOnMousedownMask: true,
                        preventCloseOnEscape: true,
                    },
                })

                return
            }

            if (!this.chat.user) {
                this.$toast.warning('OPEN_CHAT_FIRST')
                return
            }

            if (this.justAsSelector) {
                this.$emit('onClickFilters')
                // console.log('1')
            } else {
                this.showFilter = true
                // console.log('2')
                this.$store.dispatch('closeIntroducingUser')
            }
        },
        // onClickAISearch() {
        //     this.$store.commit('setAIRecommendsFromDB')
        //     this.filters.type = 'ai'
        //     this.$hooks.superSearch(this.filters)
        //     this.$emit('onClickSuperSearch')
        //     this.$emit('update:filterChanged', false)
        // },
        onClickSearchNickname() {
            this.isAIRecommendation = false

            const showPrivacyModal = !this.userPrivacyTermsAgreed

            if (showPrivacyModal && !this.$store.getters.isMobile) {
                this.$modal.custom({
                    component: 'ModalPrivacyAgreement',
                    options: {
                        preventCloseOnMousedownMask: true,
                        preventCloseOnEscape: true,
                    },
                })

                return
            }

            this.$modal
                .input({
                    title: '네임으로 회원 검색',
                    inputs: [
                        {
                            placeholder: '회원의 네임을 입력해주세요',
                            type: 'input',
                        },
                    ],
                    buttons: 'SEARCH',
                    error: '네임을 입력해주세요',
                })
                .then(inputs => {
                    if (!inputs || inputs.length === 0) return

                    this.$store.commit('setRecommendsFromDB', [])
                    const filters = helpers.getDefaultFilters(this.chat.user.gender, false)
                    filters.name = inputs[0].text
                    this.nickname = filters.name
                    this.$hooks.superSearch(filters)
                })
        },
        async onClickAISearch() {
            if (!this.chat.user.profile.total_grade) {
                this.$modal.basic({
                    body: '아직 종합 등급이 없는 유저입니다',
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
                return
            }
            // this.$toast.success('AI 모델이 추천하는 목록입니다.')

            this.isAIRecommendation = true

            this.$store.commit('setRecommendsFromDB', [])
            const filters = helpers.getDefaultFilters(this.chat.user.gender, false)
            filters.type = 'ai'
            filters.sortType = this.sort.orderby
            await this.$hooks.superSearch(filters)
            this.filteredUsers = this.users
            // const recommendedUsers = this.$store.getters.chat.$$recommendsFromDB
            // console.log('recommendedUsers', recommendedUsers)
            // if (recommendedUsers && recommendedUsers.length > 0) {
            //     for (const user of recommendedUsers) {
            //         // this.$loading(true)
            //         // console.log('each user', user.user_id)

            //         try {
            //             // Assuming this.$bus.$emit is asynchronous or returns a Promise
            //             await this.$bus.$emit('onClickSendAllPictures', user.user_id, 'regular_m_v2')
            //         } catch (error) {
            //             console.error('Error sending pictures:', error)
            //         }
            //         // this.$loading(false)
            //     }
            // }
            this.refreshGradual()
            localStorage.setItem('searchedAI', true)
        },
        onClickSelectAll(num) {
            if (num > 0) {
                if (this.numFilteredUsers < num) {
                    this.$toast.success(`${num}명을 선택할 수 없습니다`)
                    return
                }
                const list = []
                while (list.length < num) {
                    const rand = Math.floor(Math.random() * this.numFilteredUsers)
                    if (!list.includes(rand)) list.push(rand)
                }
                ;(this.filteredUsers || []).forEach((user, idx) => {
                    user.$$selected = list.includes(idx)
                })
            } else {
                ;(this.filteredUsers || []).forEach(user => {
                    user.$$selected = !this.selectedAll
                })
            }
        },
        onScrollList(event) {
            const searchedAi = localStorage.getItem('searchedAI')
            console.log(searchedAi)

            if (this.chatJustChanged || searchedAi === 'true') return

            const scrollTop = event.target.scrollTop
            if (event.target.scrollHeight === 0) return
            const diff = event.target.scrollHeight - scrollTop - event.target.clientHeight
            const isBottom = diff <= 10
            if (!isBottom) return

            clearTimeout(this.scrollDebounceTimeout)

            if (!this.scrollDebounceTimeout) {
                this.$store.dispatch('loadRecommendsFromDB', helpers.filtersToSuperSearchPayload(this.chat.$$filters))
            }

            this.scrollDebounceTimeout = setTimeout(() => {
                this.scrollDebounceTimeout = null
            }, 1000)
        },
    },
}
</script>

<style lang="scss" scoped>
.users-list {
    .count-and-sort-row {
        padding-bottom: 11px;
        border-bottom: 1px solid $grey-02;
        margin-bottom: 11px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .selected-count {
            display: flex;
            flex-direction: row;
            font-size: 11px;
            color: $grey-07;

            .selected {
                @include f-bold;
                color: $purple-primary;
            }
        }
    }

    .select-all-btn {
        padding: 6px 12px;
        border-radius: 6px;
        font-size: 11px;
        @include f-medium;
        color: $grey-07;
        border: solid 1px $grey-03;

        &.selectedAll {
            color: $purple-primary;
            border: solid 1px $purple-primary;
        }
    }

    .search-detail-btn {
        width: 100%;
        height: 32px;
        background-color: #8a74ff;
        color: white;
        font-size: 12px;
        border-radius: 8px;
        margin-right: 8px;
        cursor: pointer;
        font-family: NotoSans-Medium;
    }
    .search-ai-btn {
        @extend .search-detail-btn;
        background-color: $brand-success;
        margin-right: 0;
    }
    .search-nickname-btn {
        width: 100%;
        height: 32px;
        border: solid 1px $purple-primary;
        background-color: white;
        color: $purple-primary;
        font-size: 12px;
        border-radius: 8px;
        cursor: pointer;
        font-family: NotoSans-Medium;
    }
}
</style>
