<template>
    <div class="accpeted-user flex" v-if="myUsers.length > 0">
        <div class="selected-myuser is-instant-chat flex">
            <div class="title">
                <div class="text" v-html="`${$nameOrNick(chat.user)}님이 수락한 내 담당 회원`" />
                <img class="exclamation" @click="showInfo = true" src="@/assets/images/exclamation.svg" />
                <div class="tips-arrow" v-if="showInfo" />
                <div class="info" v-if="showInfo">
                    <div>
                        <span v-html="$nameOrNick(chat.user)" />님이 수락한 <span>내 담당 회원</span>들이 표시됩니다.
                        <span v-if="$store.getters.isMobile"><br /></span>
                        <span class="pink">(다른 회원은 소개할 수 없어요!)</span>
                    </div>
                    <i class="zmdi zmdi-close" @click="showInfo = false" />
                </div>
            </div>

            <div class="list">
                <div class="container">
                    <div
                        class="photo-container"
                        :key="myUser.id"
                        v-for="myUser in myUsers"
                        @click="onClickMyUser((myUser || {}).source_id)"
                    >
                        <div class="photo" v-lazy:background-image="myUser.user.photo_url">
                            <div class="photo-badge" />
                        </div>

                        <div class="name" v-html="myUser.user.name" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import suggestService from '@/services/suggest'

export default {
    name: 'AcceptedUser',
    data: () => ({
        showInfo: false,
        myUsers: [],
    }),
    watch: {
        chat(oldVal, newVal) {
            if (oldVal && newVal && oldVal.id !== newVal.id) {
                this.init()
            }
        },
    },
    computed: {
        chat() {
            return this.$store.getters.chat || {}
        },
    },
    mounted() {
        let show = window.localStorage.getItem('showAcceptedUserInfo')
        this.showInfo = show === null
        show = true
        window.localStorage.setItem('showAcceptedUserInfo', JSON.stringify(show))
        // window.localStorage.removeItem('showAcceptedUserInfo')
        // if (this.showInfo) {
        //     this.$modal.basic({
        //         body: this.$translate('ACTION_INFORM_INSTANT_CHAT').replace(/%s/, this.$nameOrNick(this.chat.user)),
        //         buttons: [
        //             {
        //                 label: 'CONFIRM',
        //                 class: 'btn-primary',
        //             },
        //         ],
        //     })
        // }
        this.init()
    },
    methods: {
        async init() {
            this.myUsers = await suggestService.userSuggests(this.chat.user.id)
            const introId = (this.chat.$$introducingUser || {}).id
            if (
                !this.$store.getters.isMobile &&
                this.myUsers.filter(u => u.id === introId).length === 0 &&
                this.myUsers[0]
            ) {
                this.$store.dispatch('loadIntroducingUser', this.myUsers[0].source_id)
            } else {
                this.$store.commit('setShowFriendsFilter', false)
            }
            this.$set(this.chat, '$$acceptedUser', this.myUsers)
        },
        onClickMyUser(userId) {
            if (this.$store.getters.isMobile) {
                this.$router.push({
                    name: 'UserDetailPage',
                    params: {
                        userId: userId,
                        from: 'AcceptedUser',
                    },
                })
            } else {
                this.$store.dispatch('loadIntroducingUser', userId)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.accpeted-user {
    $grid-gap: 8px;
    position: relative;
    background-color: $grey-09;
    border: none !important;
    margin-bottom: 8px;

    .selected-myuser {
        display: flex;
        max-width: 560px;
        border-radius: 20px;
        border: solid 1px $grey-07;
        background-color: $grey-08;

        .title {
            color: $grey-04;
            font-size: 12px;
            font-family: NotoSans-Medium;
            margin: 10px 16px;
            display: flex;
            height: fit-content;

            .text {
                height: fit-content;
            }
            .exclamation {
                margin-left: 4px;
                margin-top: 2px;
                width: 14px;
                height: 14px;
                object-fit: contain;
            }

            .tips-arrow {
                margin: 16px 0px 0px -12px;
                width: 0;
                height: 0;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;

                border-bottom: 5px solid white;
            }
            .info {
                position: absolute;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: calc(100% - 32px);
                top: 32px;
                padding: 10px 12px;
                background-color: white;
                border-radius: 8px;
                font-size: 13px;
                color: $grey-09;
                font-family: NotoSans-Regular;

                span {
                    font-family: NotoSans-Bold;

                    &.pink {
                        font-family: NotoSans-Regular;
                        color: $pink-deep;
                    }
                }

                i {
                    width: 12px;
                    height: 12px;
                }
            }
        }

        .list {
            display: flex;
            margin-bottom: 8px;
            padding: 0 16px;

            .container {
                display: flex;
                overflow-y: auto;

                .photo-container {
                    &:not(:first-child) {
                        margin-left: 16px;
                    }

                    .photo {
                        width: 48px;
                        height: 48px;
                        border-radius: 19.2px;
                        border: solid 1px $grey-07;
                        background-size: contain;
                        margin-bottom: 4px;

                        .photo-badge {
                            width: 8px;
                            height: 8px;
                            border: solid 1px white;
                            border-radius: 50%;
                            background-color: $green;
                            min-width: 0px;
                        }
                    }

                    .name {
                        max-width: 48px;
                        font-size: 11px;
                        text-align: center;
                        color: white;
                    }
                }
            }
        }
    }
}
</style>
