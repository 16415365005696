<template>
    <div class="filter-icon" @click="$emit('click')">
        <i
            class="zmdi zmdi-tune"
            :class="{'active': showFilter}">
            <div
                class="badge-on"
                v-show="applyingFilter"/>
        </i>
    </div>
</template>

<script>
export default {
    props: ['applyingFilter', 'showFilter'],
}
</script>