<template>
    <div class="reject-message">
        <div class="reject-message-container">
            <div class="section">
                <div class="reject-message-title" v-html="$translate(`EDIT_REJECT_MESSAGE_TITLE`)" />
                <div class="reject-message-body" v-html="$translate(`EDIT_REJECT_MESSAGE_BODY`)" />
            </div>
            <div class="content">
                <div class="reject-message-container">
                    <div class="container-top">
                        <div class="container-message">
                            <span class="container-title" v-html="$translate(`LIKE_REJECT_MALE`)"> </span>
                            <span class="container-ex" v-html="$translate(`LIKE_REJECT_MALE_EX`)"> </span>
                        </div>
                        <button
                            v-if="!isExistTextM"
                            @click="rejectMessageChangedM ? updateRejectMessage('male') : null"
                            class="btn"
                            :class="rejectMessageChangedM ? 'save' : 'btn-block save-off'"
                            v-html="$translate('DO_SAVE')"
                        />
                        <button
                            v-else
                            @click="modifyRejectMessage('male')"
                            class="btn save"
                            v-html="$translate('DO_MODIFY')"
                        />
                    </div>
                    <div class="textarea-container">
                        <div v-if="messagesM[0] != ''" class="preview-container" :class="{ modify: isExistTextM }">
                            <div class="preview-message" :key="idx" v-for="(message, idx) in messagesM">
                                {{ message }}
                            </div>
                        </div>
                        <textareaWithAutoTexts
                            v-if="!isExistTextM"
                            :continueEnter="true"
                            :placeholder="$translate('PLACEHOLDER_MESSAGE')"
                            @onEnter="onEnterM"
                            v-model="rejectMessageTempM"
                        />
                    </div>
                </div>
                <div class="reject-message-container">
                    <div class="container-top">
                        <div class="container-message">
                            <span class="container-title" v-html="$translate(`LIKE_REJECT_FEMALE`)"> </span>
                            <span class="container-ex" v-html="$translate(`LIKE_REJECT_FEMALE_EX`)"> </span>
                        </div>
                        <button
                            v-if="!isExistTextF"
                            @click="rejectMessageChangedF ? updateRejectMessage('female') : null"
                            class="btn"
                            :class="rejectMessageChangedF ? 'save' : 'btn-block save-off'"
                            v-html="$translate('DO_SAVE')"
                        />
                        <button
                            v-else
                            @click="modifyRejectMessage('female')"
                            class="btn save"
                            v-html="$translate('DO_MODIFY')"
                        />
                    </div>
                    <div class="textarea-container">
                        <div v-if="messagesF[0] != ''" class="preview-container" :class="{ modify: isExistTextF }">
                            <div class="preview-message" :key="idx" v-for="(message, idx) in messagesF">
                                <span v-html="message" />
                            </div>
                        </div>
                        <textareaWithAutoTexts
                            v-if="!isExistTextF"
                            :continueEnter="true"
                            :placeholder="$translate('PLACEHOLDER_MESSAGE')"
                            @onEnter="onEnterF"
                            v-model="rejectMessageTempF"
                        />
                    </div>
                </div>
            </div>
            <div class="example">
                <div class="example-container">
                    <div class="text">
                        <div class="title" v-html="'주선자 view'"></div>
                        <span class="body">호감 회원 'X버튼' 클릭 > 거절 메시지 자동 발송 </span>
                    </div>
                    <img class="reject-message-example-1" src="@/assets/images/reject_message_example_2.png" />
                </div>
                <div class="example-container">
                    <div class="text">
                        <div class="title" v-html="'회원 view'"></div>
                        <span class="body">거절 메시지 받음 </span>
                    </div>
                    <img class="reject-message-example-2" src="@/assets/images/reject_message_example_1.png" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RejectMessagePc',
    data: () => ({
        rejectMessageChangedM: false,
        rejectMessageChangedF: false,
        rejectMessageTextM: [],
        rejectMessageTextF: [],
        rejectMessageTempM: '',
        rejectMessageTempF: '',
        isExistTextM: false,
        isExistTextF: false,
    }),
    mounted() {
        this.initRejectMessage()
    },
    computed: {
        rejectMessageM() {
            const autoTexts = this.$store.getters.autoTextsAllCategory
            if (autoTexts && autoTexts.reject_message_male !== undefined) {
                return autoTexts.reject_message_male
            } else {
                return []
            }
        },
        rejectMessageF() {
            const autoTexts = this.$store.getters.autoTextsAllCategory
            if (autoTexts && autoTexts.reject_message_female !== undefined) {
                return autoTexts.reject_message_female
            } else {
                return []
            }
        },
        messagesM() {
            return this.rejectMessageTextM
        },
        messagesF() {
            return this.rejectMessageTextF
        },
    },
    methods: {
        async initRejectMessage() {
            await this.$store.dispatch('loadAutoTextsAllCategory')
            this.rejectMessageM.map(message => {
                this.rejectMessageTextM.push(message.content)
            })
            this.rejectMessageF.map(message => {
                this.rejectMessageTextF.push(message.content)
            })

            this.isExistTextM = this.rejectMessageTextM.length > 0 ? true : false
            this.isExistTextF = this.rejectMessageTextF.length > 0 ? true : false
        },

        async updateRejectMessage(gender) {
            this.$store.dispatch('updateRejectMessage', {
                rejectMessage: gender === 'male' ? this.rejectMessageTextM : this.rejectMessageTextF,
                gender,
            })

            if (gender === 'male') {
                this.isExistTextM = true
            } else {
                this.isExistTextF = true
            }
        },

        async modifyRejectMessage(gender) {
            this.$modal
                .basic({
                    title: '',
                    body: this.$translate('ALERT_MESSAGE_RESET'),
                    buttons: [
                        {
                            label: 'CANCEL',
                            class: 'btn-default',
                        },
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
                .then(selectedBtnIdx => {
                    if (selectedBtnIdx === 1) {
                        this.$store.dispatch('updateRejectMessage', {
                            rejectMessage: [],
                            gender,
                        })

                        if (gender === 'male') {
                            this.rejectMessageTextM = []
                            this.rejectMessageTempM = ''
                            this.rejectMessageChangedM = false
                            this.isExistTextM = false
                        } else {
                            this.rejectMessageTextF = []
                            this.rejectMessageTempF = ''
                            this.rejectMessageChangedF = false
                            this.isExistTextF = false
                        }
                    }
                })
        },

        onEnterM(text) {
            if (!text) return
            this.rejectMessageTempM = text
            this.rejectMessageTextM.push(text)

            if (this.rejectMessageTextM.length > 0) this.rejectMessageChangedM = true
            this.rejectMessageTempM = ''
            const dom = document.getElementsByClassName('preview-container')[0]
            if (dom) {
                this.$scroll.down(dom, true)
            }
        },

        onEnterF(text) {
            if (!text) return

            this.rejectMessageTempF = text

            this.rejectMessageTextF.push(text)
            if (this.rejectMessageTextF.length > 0) this.rejectMessageChangedF = true

            this.rejectMessageTempF = ''
            const dom = document.getElementsByClassName('preview-container')[1]
            if (dom) {
                this.$scroll.down(dom, true)
            }
        },
    },
}
</script>
