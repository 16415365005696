<template>
    <div
        class="chats-filter"
        :class="{ flex: $store.getters.chatFilters.type === 'SEARCH_DETAIL' }"
        @click="closeOnClickOverlay"
    >
        <div class="background-wrapper">
            <div class="filter-item">
                <div class="buttons grid-5">
                    <button
                        @click="onFilter('allday')"
                        class="grid"
                        :class="{ primary: filters.allday.selected }"
                        v-html="$translate(filters.allday.label)"
                    />
                    <button
                        @click="onFilter('todayGetUsers')"
                        class="grid"
                        :class="{ primary: filters.todayGetUsers.selected }"
                        v-html="'이번주에 받을 사람들~'"
                    />
                </div>
                <div class="title" v-html="$translate('PAYMENT_TYPE')" />
                <div class="buttons grid-5">
                    <!-- <button
                        @click="onFilter('prepaid')"
                        class="grid"
                        :class="{ primary: filters.prepaid.selected }"
                        v-html="'선불제만'"
                    />
                    <button
                        @click="onFilter('postpaid')"
                        class="grid"
                        :class="{ primary: filters.postpaid.selected }"
                        v-html="'후불제만'"
                    /> -->
                    <button
                        @click="onFilter('exceptUnlimited')"
                        class="grid"
                        :class="{ primary: filters.exceptUnlimited.selected }"
                        v-html="'만남권만'"
                    />
                    <button
                        @click="onFilter('unlimited')"
                        class="grid"
                        :class="{ primary: filters.unlimited.selected }"
                        v-html="'기간제만'"
                    />
                </div>
                <div class="filter-item">
                    <div class="title" v-html="$translate('INTRODUCING_DAY')" />
                    <div class="buttons grid-5">
                        <button
                            @click="onFilter('monday')"
                            class="grid"
                            :class="{ primary: filters.monday.selected }"
                            v-html="$translate(filters.monday.label)"
                        />
                        <button
                            @click="onFilter('tuesday')"
                            class="grid"
                            :class="{ primary: filters.tuesday.selected }"
                            v-html="$translate(filters.tuesday.label)"
                        />
                        <button
                            @click="onFilter('wednesday')"
                            class="grid"
                            :class="{ primary: filters.wednesday.selected }"
                            v-html="$translate(filters.wednesday.label)"
                        />
                        <button
                            @click="onFilter('thursday')"
                            class="grid"
                            :class="{ primary: filters.thursday.selected }"
                            v-html="$translate(filters.thursday.label)"
                        />
                        <button
                            @click="onFilter('friday')"
                            class="grid"
                            :class="{ primary: filters.friday.selected }"
                            v-html="$translate(filters.friday.label)"
                        />
                    </div>
                </div>
                <div class="filter-item">
                    <div class="title" v-html="$translate('USER_ID')" />
                    <div class="buttons grid-5">
                        <button
                            @click="onFilter('odd')"
                            class="grid"
                            :class="{ primary: filters.odd.selected }"
                            v-html="$translate(filters.odd.label)"
                        />
                        <button
                            @click="onFilter('even')"
                            class="grid"
                            :class="{ primary: filters.even.selected }"
                            v-html="$translate(filters.even.label)"
                        />
                    </div>
                </div>
                <div class="filter-item">
                    <div class="title" v-html="$translate('MANAGER_CONFIRM')" />
                    <div class="buttons">
                        <button
                            @click="onFilter('managerConfirm')"
                            :class="{ primary: filters.managerConfirm.selected }"
                            v-html="$translate(filters.managerConfirm.label)"
                        />
                    </div>
                </div>
                <div class="title" v-html="'가입 진행 상태별'" />
                <div class="buttons grid-3">
                    <button
                        @click="onFilter('created')"
                        class="grid"
                        :class="{ primary: filters.created.selected }"
                        v-html="'본인인증 완료'"
                    />
                    <button
                        @click="onFilter('complete_profile')"
                        class="grid"
                        :class="{ primary: filters.complete_profile.selected }"
                        v-html="'프로필 작성 완료'"
                    />
                    <button
                        @click="onFilter('ongoing_verification')"
                        class="grid"
                        :class="{ primary: filters.ongoing_verification.selected }"
                        v-html="'서류인증 1개 이상 완료'"
                    />
                </div>
                <div class="buttons grid-3">
                    <button
                        @click="onFilter('judging')"
                        class="grid"
                        :class="{ primary: filters.judging.selected }"
                        v-html="'서류인증 완료'"
                    />
                    <button
                        @click="onFilter('judged')"
                        class="grid"
                        :class="{ primary: filters.judged.selected }"
                        v-html="'가입심사 통과'"
                    />
                    <button
                        @click="onFilter('failed')"
                        class="grid"
                        :class="{ primary: filters.failed.selected }"
                        v-html="'탈락'"
                    />
                </div>
                <div class="buttons grid-3">
                    <button
                        @click="onFilter('confirmed')"
                        class="grid"
                        :class="{ primary: filters.confirmed.selected }"
                        v-html="'매칭매니저 배정됨'"
                    />
                </div>
            </div>
            <div class="filter-item">
                <div class="title" v-html="$translate('BASIC')" />
                <div class="buttons grid">
                    <button
                        @click="onFilter('lastSign')"
                        class="grid"
                        :class="{ primary: filters.lastSign.selected }"
                        v-html="$translate(filters.lastSign.label)"
                    />
                    <button
                        @click="onFilter('notToday')"
                        class="grid"
                        :class="{ primary: filters.notToday.selected }"
                        v-html="$translate(filters.notToday.label)"
                    />
                    <button
                        v-if="isPremium"
                        @click="onFilter('lastWeek')"
                        class="grid"
                        :class="{ primary: filters.lastWeek.selected }"
                        v-html="$translate(filters.lastWeek.label)"
                    />
                    <button
                        @click="onFilter('friends')"
                        class="grid"
                        :class="{ primary: filters.friends.selected }"
                        v-html="'담당 회원'"
                    />
                    <button
                        @click="onFilter('dDayFriends')"
                        class="grid"
                        :class="{ primary: filters.dDayFriends.selected }"
                        v-html="'임시 대화방'"
                    />
                    <button
                        @click="onFilter('bookmark')"
                        class="grid"
                        :class="{ primary: filters.bookmark.selected }"
                        v-html="$translate(filters.bookmark.label)"
                    />
                    <button
                        @click="onFilter('isAi')"
                        class="grid"
                        :class="{ primary: filters.isAi.selected }"
                        v-html="$translate(filters.isAi.label)"
                    />
                </div>
            </div>

            <div class="filter-item">
                <div class="title-with-icon">
                    <div class="title" v-html="$translate('DATING_STATUS')" />
                    <i @click="onClickDatingStatusInfo" class="material-icons">error_outline</i>
                </div>
                <div class="buttons">
                    <!-- ///////////////////////////////////// -->
                    <button
                        @click="onFilter('harvest')"
                        :class="{ primary: filters.harvest.selected }"
                        v-html="$translate(filters.harvest.label)"
                    />
                    <button
                        @click="onFilter('bothLike')"
                        :class="{ primary: filters.bothLike.selected }"
                        v-html="$translate(filters.bothLike.label)"
                    />
                    <button
                        @click="onFilter('waitDating')"
                        :class="{ primary: filters.waitDating.selected }"
                        v-html="$translate(filters.waitDating.label)"
                    />
                </div>
            </div>

            <!-- 수도권 -->
            <div class="filter-item">
                <div class="title" v-html="$translate('REGION')" />
                <div class="buttons">
                    <button
                        @click="onFilter('capitalArea')"
                        :class="{ primary: filters.capitalArea.selected }"
                        v-html="$translate(filters.capitalArea.label)"
                    />
                </div>
            </div>

            <div class="filter-item">
                <div class="title" v-html="$translate('ITEM')" />
                <div class="buttons">
                    <button
                        @click="onFilter('hasTicket')"
                        :class="{ primary: filters.hasTicket.selected }"
                        v-html="$translate(filters.hasTicket.label)"
                    />
                    <button
                        v-if="isPremium"
                        @click="onFilter('isPremium')"
                        :class="{ primary: filters.isPremium.selected }"
                    >
                        <span v-html="$translate(filters.isPremium.label)" />
                    </button>
                </div>
            </div>
            <ChatsFilterDetail v-if="$store.getters.chatFilters.type === 'SEARCH_DETAIL'" @close="$emit('close')" />
        </div>
    </div>
</template>

<script>
import ChatsFilterDetail from './ChatsFilterDetail'

export default {
    name: 'ChatsFilter',
    components: { ChatsFilterDetail },
    computed: {
        filters() {
            return this.$store.getters.chatFilters
        },
        keysToImmediateReload() {
            return [
                'todayGetUsers',
                'smManager',
                'jmManager',
                'allday',
                'monday',
                'tuesday',
                'wednesday',
                'thursday',
                'friday',
                'lastSign',
                'notToday',
                'lastWeek',
                'friends',
                'dDayFriends',
                'hasTicket',
                'isPremium',
                'harvest',
                'bothLike',
                'waitDating',
                'bookmark',
                'isAi',
                'managerConfirm',
                'capitalArea',
                // 'postpaid',
                // 'prepaid',
                'exceptUnlimited',
                'unlimited',
                'created',
                'complete_profile',
                'ongoing_verification',
                'judging',
                'judged',
                'confirmed',
                'failed',
                'odd',
                'even',
            ]
        },
        agent() {
            return this.$store.getters.agent || {}
        },
        isPremium() {
            return this.agent ? this.agent.is_premium : false
        },
    },
    methods: {
        onFilter(key) {
            this.filters[key].selected = !this.filters[key].selected
            if (this.keysToImmediateReload.indexOf(key) !== -1) {
                this.$store.dispatch('loadChats', { force: true })
            }
        },
        closeOnClickOverlay(event) {
            const cl = event.target.classList
            if (cl.contains('chats-filter') && !cl.contains('filters')) {
                this.$emit('close')
            }
        },
        onClickDatingStatusInfo() {
            this.$modal.custom({
                component: 'ModalDatingStatusInfo',
            })
        },
    },
}
</script>
