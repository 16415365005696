<template>
    <div class="checkbox" :class="{ checked: value }" @click="onClickCheckbox">
        <i class="zmdi zmdi-check" v-if="value" />
    </div>
</template>
<script>
export default {
    name: 'Checkbox',
    props: ['value'],
    methods: {
        onClickCheckbox(event) {
            event.stopPropagation()
            this.$emit('input', this.value !== true)
        },
    },
}
</script>
