import Vue from 'vue'
import chatService from '@/services/chat'
import * as helpers from './helpers'

const addFriendObservablesToUser = obj => {
    const user = obj.user ? obj.user : obj
    Vue.set(user, '$$selected', user.$$selected || false)
    // Vue.set(user, '$$friendType', user.$$friendType || Vue.options.filters.friendType(obj, true))
    if (obj.chat_id && obj.user) {
        Vue.set(obj.user, '$$chatId', obj.chat_id)
    }
}

const defaultState = () => ({
    friends: null,
    requests: null,
    showFriendsFilter: false,
    tabCounts: {
        numFriends: 0,
        numRecommends: 0,
        numRecommendsFromDB: 0,
    },
})

const state = defaultState()

const getters = {
    friends: state => state.friends,
    requests: state => state.requests,
    showFriendsFilter: state => state.showFriendsFilter,
    tabCounts: state => state.tabCounts,
    meetingReviewType: state => state.meetingReviewType,
    reviewKeywordType: state => state.reviewKeywordType,
}

const actions = {
    async loadFriends({ state, commit }, force) {
        if (!force && helpers.canSkipApiCall(state, 'friends')) return

        try {
            commit('setLoading', { friends: true })
            // const friends = await friendService.all({
            //     // params: {
            //     //     limit: 10,
            //     // },
            // })
            const friends = []
            if (!friends || friends.length === 0) return

            commit('setFriends', friends)
            if (state.chat) {
                commit('updateDistance', state.chat.user.location)
            }
        } catch (e) {
            return Promise.reject(e)
        } finally {
            commit('setLoading', { friends: false })
        }
    },
    async loadRecommendsFromDB({ state, commit }, filters) {
        // if (!state.chat || state.chat.expire_at) return
        if (!state.chat) return

        try {
            commit('setLoading', { superSearch: true })
            const currentChatId = state.chat.id
            let start
            if (!state.chat.$$recommendsFromDB) {
                start = 0
            } else {
                start = state.chat.$$recommendsFromDB.length
                if (start >= 200) return commit('setLoading', { superSearch: false })
            }
            const filtersAndStart = { ...filters, start: start }
            const data = await chatService.recommendsFromDB(state.chat.id, filtersAndStart)
            if (!data || state.chat.id !== currentChatId) return

            if (state.chat.$$recommendsFromDB) {
                commit('setRecommendsFromDB', [...state.chat.$$recommendsFromDB, ...data])
            } else {
                commit('setRecommendsFromDB', data)
            }
        } catch (e) {
            return Promise.reject(e)
        } finally {
            commit('setLoading', { superSearch: false })
        }
    },
    async loadAIRecommendsFromDB({ state, commit }, filters) {
        if (!state.chat || state.chat.expire_at) return

        try {
            const currentChatId = state.chat.id
            let start
            if (!state.chat.$$aiRecommendsFromDB) {
                start = 0
            } else {
                start = state.chat.$$aiRecommendsFromDB.length
            }
            const filtersAndStart = { ...filters, start: start, type: 'ai' }
            const data = await chatService.aiRecommendsFromDB(state.chat.id, filtersAndStart)
            console.log(data)
            if (!data || state.chat.id !== currentChatId) return

            if (state.chat.$$aiRecommendsFromDB) {
                commit('setAIRecommendsFromDB', [...state.chat.$$aiRecommendsFromDB, ...data])
            } else {
                commit('setAIRecommendsFromDB', data)
            }
        } catch (e) {
            return Promise.reject(e)
        }
    },
    removeFriends({ state, commit }, friendIds) {
        if ((friendIds || []).length === 0) return

        // 나중에는 friends가 아닌 chatList처럼 chat.id만 모은 전체집합같은 friendList를 받아서 써야될듯
        friendIds.forEach(friendId => {
            const friendIdx = (state.friends || []).findIndex(f => f.id === friendId)
            if (friendIdx > -1) {
                const friend = state.friends[friendIdx]
                state.friends.splice(friendIdx, 1)

                const chatIdx = (state.chats || []).findIndex(c => c.user.id === friend.user.id)
                if (chatIdx > -1) {
                    const chat = state.chats[chatIdx]
                    if (state.chat && state.chat.id === chat.id) {
                        commit('closeOpenedChat')
                    }
                    state.chats.splice(chatIdx, 1)
                }
                const chatListIdx = (state.chatList || []).findIndex(c => c.user_id === friend.user.id)
                if (chatListIdx > -1) {
                    state.chatList.splice(chatListIdx, 1)
                }
            }
        })
    },
}

const mutations = {
    setFriends(state, friends) {
        state.friends = friends
        state.friends.forEach(f => {
            addFriendObservablesToUser(f)
            const premium = Vue.options.filters.hasProduct(f.user, 'premium')
            if (premium) {
                Vue.set(f.user, '$$premium', premium)
            }
            const privacy = Vue.options.filters.hasProduct(f.user, 'pravicy')
            if (privacy) {
                Vue.set(f.user, '$$privacy', privacy)
            }
        })
        Vue.set(state.tabCounts, 'numFriends', friends.length)
    },
    setRecommendsFromDB(state, recommendsFromDB) {
        if (recommendsFromDB) {
            ;(recommendsFromDB || []).forEach(user => {
                addFriendObservablesToUser(user)
            })
        }

        Vue.set(state.chat, '$$recommendsFromDB', recommendsFromDB)
        Vue.set(state.tabCounts, 'numRecommendsFromDB', recommendsFromDB.length)

        if (state.chat.$$recommendsFromDB) {
            ;(state.chat.$$recommendsFromDB || []).forEach(rec =>
                Vue.set(rec, '$$distance', Vue.prototype.$distance(state.chat.user.location, rec.location))
            )
        }
    },
    setAIRecommendsFromDB(state, aiRecommendsFromDB) {
        if (aiRecommendsFromDB) {
            ;(aiRecommendsFromDB || []).forEach(user => {
                addFriendObservablesToUser(user)
            })
        }

        Vue.set(state.chat, '$$aiRecommendsFromDB', aiRecommendsFromDB)

        if (state.chat.$$aiRecommendsFromDB) {
            ;(state.chat.$$aiRecommendsFromDB || []).forEach(rec =>
                Vue.set(rec, '$$distance', Vue.prototype.$distance(state.chat.user.location, rec.location))
            )
        }
    },
    setAIRecommends(state, AIRecommends) {
        if (AIRecommends) {
            ;(AIRecommends || []).forEach(user => {
                addFriendObservablesToUser(user)
            })
        }

        Vue.set(state.chat, '$$AIRecommends', AIRecommends)

        if (state.chat.$$AIRecommends) {
            ;(state.chat.$$AIRecommends || []).forEach(rec =>
                Vue.set(rec.user, '$$distance', Vue.prototype.$distance(state.chat.user.location, rec.user.location))
            )
        }
    },
    setRequests(state, requests) {
        if (!requests) return

        state.requests = {}

        Vue.set(state.requests, 'normal', requests.normal)
    },
    markAsIntroduced(state, userIds) {
        if (!userIds || userIds.length === 0) return

        if (state.chat) {
            const recommendsFromDB = state.chat.$$recommendsFromDB || []
            const filter = state.chat.$$filters
            if ((filter.neverIntroduced.find(f => f.value === 'send') || {}).selected) {
                userIds.forEach(userId => {
                    const foundIdx = recommendsFromDB.findIndex(u => u.id === userId)
                    if (foundIdx > -1) {
                        recommendsFromDB.splice(foundIdx, 1)
                    }
                })
            }
        }
    },
    markAsIntroducedTo(state, userIds) {
        if (!userIds || userIds.length === 0) return

        if (state.chat) {
            const recommendsFromDB = state.chat.$$recommendsFromDB || []
            // const filter = state.chat.$$filters
            // if ((filter.neverIntroduced.find(f => f.value === 'send') || {}).selected) {
            userIds.forEach(userId => {
                const foundIdx = recommendsFromDB.findIndex(u => u.id === userId)
                if (foundIdx > -1) {
                    recommendsFromDB.splice(foundIdx, 1)
                }
            })
            // }
        }
    },
    setTabCounts(state, tabCounts) {
        const keys = ['numFriends', 'numRecommends', 'numRecommendsFromDB']
        keys.forEach(key => {
            if (tabCounts[key] >= 0) {
                state.tabCounts[key] = tabCounts[key]
            }
        })
    },
    setShowFriendsFilter(state, showFriendsFilter) {
        state.showFriendsFilter = showFriendsFilter
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
    defaultState,
}
