<template>
    <div class="bottom-buttons">
        <button class="btn btn-brd flex-fill btn-all" v-html="$translate('ALL_PHOTOS')" @click="sendAll" />
        <button class="btn btn-brd flex-fill btn-choose" v-html="$translate('CHOOSE_PHOTO')" @click="choosePhoto" />
        <div class="ver-line" />
        <ChatButton class="flex-wrap" :class="user | friendType" :user="user" :source_id="chat.user.id" />
    </div>
</template>

<script>
import chatService from '@/services/chat'

export default {
    name: 'BottomButtons',
    props: ['user'],
    computed: {
        chat() {
            return this.$store.getters.chat
        },
        agent() {
            return this.$store.getters.agent
        },
        myPremiumUser() {
            if ((this.chat.user || {}).$$premium) {
                const premium = this.$options.filters.hasProduct(this.chat.user, 'premium')
                return this.$store.getters.agent.id === premium.agent_id
            }
            return false
        },
        isAbleRecommend() {
            if (!this.$store.getters.agent) return true
            if (!this.chat.user.$$premium) return true
            return this.myPremiumUser
        },
    },
    methods: {
        sendAll() {
            if (!this.isAbleRecommend) {
                this.isAbleRecommendAlert()
                return
            }

            const selectedImageUrls = (this.user.photos || []).map(photo => photo.url)
            const source = this.user

            this.sendPhotos({ selectedImageUrls, source })
        },
        choosePhoto() {
            if (!this.isAbleRecommend) {
                this.isAbleRecommendAlert()
                return
            }

            this.$modal.custom({
                component: 'ModalPhotosSelector',
                options: { user: this.user },
            })
        },
        sendPhotos({ selectedImageUrls, source, isMichagam, sentByWho }) {
            if (!this.isAbleRecommend) {
                this.isAbleRecommendAlert()
                return
            }

            this.$store.dispatch('checkAlreadyIntroduced', {
                source,
                callbacks: {
                    sendLinkedPhotoMessage: () =>
                        this.sendLinkedPhotoMessage(selectedImageUrls, source, isMichagam, sentByWho),
                    deleteChatFloatingInterest: () => {
                        if (!this.chat) return

                        const floating = (this.chat.$$floatingsInterest || []).find(f => f.user.id === source.id)
                        if (floating) {
                            this.$store.dispatch('deleteChatFloatingInterest', {
                                chat: this.chat,
                                floating: floating,
                                reject: false,
                            })
                        }
                    },
                },
            })
        },
        async sendLinkedPhotoMessage(imageUrls, source, isMichagam, sentByWho) {
            const payload = {
                source_id: source.id,
                photo_url_path: imageUrls,
                is_premium: this.myPremiumUser,
                is_michagam: isMichagam,
                sent_by_who: sentByWho,
            }

            try {
                await chatService.sendLinkedPhotoMessage(this.chat.id, payload)
                this.$store.dispatch('loadBadges')
                this.$toast.success('사진을 보냈어요!')
                this.$bus.$emit('onPhotosSent', imageUrls)

                this.updateChatHigh()

                this.$store.commit('markAsIntroduced', [this.user.id])
                setTimeout(() => {
                    this.$router.go(-1)
                }, 0)
            } catch (e) {
                if (e.data && e.data !== undefined) this.$toast.error(e.data)
            }
        },
        updateChatHigh() {
            // const chat = (this.$store.getters.chats || []).find(c => c.id === this.chat.id)
            // if (!chat) return
        },
        isAbleRecommendAlert() {
            if (this.chat.user.$$premium) {
                this.$modal.basic({
                    title: 'NOTICE',
                    body: this.$translate('CANNOT_RECOMMEND_PREMIUM_USER'),
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
            }

            // if (this.chat.user.$$privacy) {
            //     this.$modal.basic({
            //         title: 'NOTICE',
            //         body: this.$translate('CANNOT_RECOMMEND_PRIVACY_USER'),
            //         buttons: [
            //             {
            //                 label: 'CONFIRM',
            //                 class: 'btn-primary',
            //             },
            //         ],
            //     })
            // }
        },
    },
    mounted() {
        this.$set(this.user, '$$chatId', (this.$getChat(this.user.id) || {}).id)
        this.$bus.$on('onClickSendPicture', this.sendPhotos)
    },
    beforeDestroy() {
        this.$bus.$off('onClickSendPicture', this.sendPhotos)
    },
}
</script>

<style lang="scss" scoped>
.bottom-buttons {
    display: flex;
    align-items: center;
    padding: 16px;
    border-top: solid 1px #f1f2f4;

    .btn {
        height: 48px;
        font-size: 15px;
        color: #646468;
    }

    .btn-all {
        margin-right: 8px;
    }

    .btn-choose {
        margin-right: 11px;
    }

    .ver-line {
        height: 32px;
        width: 1px;
        background-color: #f1f2f4;
        margin-right: 11px;
    }

    /deep/ .chat-button {
        width: 48px;
        height: 48px;
        background-color: #f1f2f4;
        border-radius: 8px;

        i {
            font-size: 22px;
        }
    }
}
</style>
