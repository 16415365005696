<template>
    <div
        class="notification flex-row flex-between items-center"
        :class="{ clickable: json }"
        @click.stop="onClickNotification"
    >
        <div class="flex-fill">
            <div class="lines-2">{{ notification.content }}</div>
            <div class="time">{{ diff | humanizeSeconds }}</div>
        </div>
        <i v-if="json" class="zmdi zmdi-chevron-right flex-wrap" />
    </div>
</template>

<script>
export default {
    name: 'Notification',
    props: ['notification'],
    data: () => ({
        now: null,
    }),
    mounted() {
        this.init()
    },
    computed: {
        diff() {
            if (!this.now) {
                return 0
            }

            return this.now.diff(this.notification.created_at, 'seconds')
        },
        json() {
            const json = this.$mustParse(this.notification.onload)
            let showAction = true
            if (!json.type || json.type === 'agent-discover-refresh') {
                showAction = false
            }

            return showAction ? json : null
        },
        chats() {
            return this.$store.getters.chats || []
        },
    },
    methods: {
        init() {
            this.now = this.$moment()
            setInterval(_ => {
                this.now = this.$moment()
            }, 1000 * 60)
        },
        onClickNotification() {
            if (!this.json || !this.json.type) return

            if (this.json.type === 'specific-chat') {
                const userId = ((this.chats.find(c => c.id === this.json.chat_id) || {}).user || {}).id
                if (userId) {
                    const openChat = async () => {
                        await this.$store.dispatch('loadChat', { userId })
                        this.$bus.$emit('openTab', 'ChatPage')

                        if (this.$store.getters.isMobile && this.$route.name !== 'ChatroomPage') {
                            this.$router.push({ name: 'ChatroomPage' })
                        }
                    }
                    openChat()
                } else {
                    this.$toast.error(
                        this.$translate('ERROR_NON_EXIST_CHAT_WITH_NICKNAME').replace(/%s/, this.json.nickname)
                    )
                }
            } else if (this.json.type.includes('agent_friend')) {
                this.$bus.$emit('openTab', 'MyPage')
            }
            this.$emit('close')
        },
    },
}
</script>
