<template>
    <div class="floating-memo" v-show="chat.user">
        <div class="textarea-wrapper">
            <div class="memo" v-if="friendMemoOpened">
                <textarea
                    ref="textarea"
                    v-model="chat.memo"
                    @input="resize"
                    @keydown="onKeydown"
                    :placeholder="$translate('PLACEHOLDER_FRIEND_MEMO')"
                />
            </div>
            <div @click="onClickMemo" class="memo" v-else v-html="memo" />
            <div class="icon" @click="friendMemoOpened = false">
                <i class="material-icons">keyboard_arrow_{{ friendMemoOpened ? 'up' : 'down' }}</i>
            </div>
        </div>
    </div>
</template>

<script>
import chatService from '@/services/chat'
import debounce from '@/modules/debounce'

export default {
    name: 'FloatingMemo',
    data: () => ({
        friendMemoOpened: false,
        showSbMemo: false,
    }),
    watch: {
        chat() {
            this.friendMemoOpened = false
        },
    },
    computed: {
        chat() {
            return this.$store.getters.chat || {}
        },
        memo() {
            const memo = this.chat.memo || this.$translate('PLACEHOLDER_FRIEND_MEMO')

            return memo.replace(/\n/g, '<br/>')
        },
    },
    methods: {
        resize(e) {
            const tx = this.$refs.textarea
            tx.style.height = 'auto'
            tx.style.height = tx.scrollHeight + 'px'
        },
        onKeydown: debounce(async function (event) {
            const memo = event.target.value || ''
            try {
                await chatService.update(this.chat.id, { memo })
            } catch (e) {}
        }, 200),
        onClickMemo() {
            if (!this.chat) {
                this.$toast.error('ERROR_MEMO_NOT_FRIEND')
                return
            }

            this.friendMemoOpened = !this.friendMemoOpened
        },
    },
}
</script>

<style scoped lang="scss">
.floating-memo {
    background: transparent;
    //height: 100px;
    //height: fit-content;
    width: 100%;
    z-index: 3;
    color: black;

    .textarea-wrapper {
        margin: 12px;
        width: 100%;
        height: 100%;
        background: white;
        border-radius: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .memo {
            width: 100%;
            height: 100%;
            padding: 12px;

            textarea {
                width: 100%;
                height: 100%;
                overflow: visible;
                border: none;
            }
        }
    }
}
</style>
