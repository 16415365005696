<template>
    <div class="privacy-status flex-row" v-if="chat.user && isPrivacy" @click="onClickPrivacy">
        <i class="material-icons flex-wrap security">security</i>
        <div class="privacy-remain" v-html="`${privacyRemainDay()}일 남음`" />
    </div>
</template>

<script>
export default {
    name: 'PrivacyStatus',
    computed: {
        chat() {
            return this.$store.getters.chat || {}
        },
        isPrivacy() {
            return this.chat.user.$$privacy
        },
    },
    methods: {
        onClickPrivacy() {
            this.$modal.basic({
                title: 'SUPER_PRIVACY_ON',
                body: `슈퍼 프라이버시를 적용 중인 회원입니다.`,
                buttons: [
                    {
                        label: 'CONFIRM',
                        class: 'btn-primary',
                    },
                ],
            })
        },

        privacyRemainDay() {
            const privacy = this.chat.user.products.filter(p => p.ptype === 'privacy' && p.enabled === true)
            if (privacy === null || privacy === []) return

            const validUntil = privacy[0].valid_until.split('T')[0]

            return this.$moment(validUntil).diff(this.$moment(), 'days')
        },
    },
}
</script>

<style lang="scss" scoped>
.privacy-status {
    width: 103px;
    height: 32px;
    border-radius: 16px;
    background-color: $blue-facebook;
    margin-right: 16px;
    margin-top: 12px;
    padding: 6px 12px 6px 10px;
    align-self: flex-end;

    .security {
        font-size: 20px;
        margin-right: 6px;
        color: white;
    }
    .privacy-remain {
        color: white;
        font-size: 13px;
        @include f-bold;
        align-self: center;
    }
}
</style>
