<template>
    <div
        class="intro-day"
        :class="[selected ? 'selected' : '', bgColor, canClick ? 'click' : '']"
        @click="onClickIntroDay"
    >
        {{ dateParsed }}
    </div>
</template>

<script>
export default {
    name: 'IntroDay',
    props: ['introducingDay', 'selected', 'canClick'],
    computed: {
        bgColor() {
            const day = this.introducingDay
            return day === 1 || day === 8
                ? 'mon'
                : day === 2 || day === 9
                ? 'tue'
                : day === 3 || day === 10
                ? 'wed'
                : day === 4 || day === 11
                ? 'thur'
                : 'fri'
        },
        dateParsed() {
            const day = this.introducingDay
            return day === 1 || day === 8
                ? '월'
                : day === 2 || day === 9
                ? '화'
                : day === 3 || day === 10
                ? '수'
                : day === 4 || day === 11
                ? '목'
                : '금'
        },
    },
    methods: {
        onClickIntroDay() {
            if (!this.canClick) return
            this.$emit('clickDay', this.introducingDay)
        },
    },
}
</script>

<style scoped lang="scss">
.intro-day {
    @include f-regular;
    @include center;
    font-size: 12px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: $grey-05;
    background: $grey-01;
    padding: 2px;

    &.click {
        cursor: pointer;
    }

    &.selected {
        color: white;

        &.mon {
            background: #ff3d6b;
        }
        &.tue {
            background: #ffaa00;
        }
        &.wed {
            background: #3da2ff;
        }
        &.thur {
            background: #1eb82d;
        }
        &.fri {
            background: #424242;
        }
    }
}
</style>
