<template>
    <div class="signup email">
        <div class="page-header">
            <div class="title">이메일</div>
        </div>
        <div class="body">
            <div class="input-wrapper">
                <input
                    ref="email"
                    type="email"
                    class="flex-fill"
                    :placeholder="$translate('PLACEHOLDER_EMAIL')"
                    v-model="email"
                    @keydown="onKeydown"
                />
                <i v-if="email" class="material-icons flex-wrap" @click="email = ''">cancel</i>
            </div>
            <div class="error" v-if="error">
                <i class="material-icons">error_outline</i>
                이메일 형식이 올바르지 않습니다
            </div>
        </div>
        <button
            class="btn btn-primary"
            :class="{ disabled: disabled }"
            @click="next"
            v-html="$translate('NEXT')"
        ></button>
    </div>
</template>

<script>
import userService from '@/services/user'

export default {
    name: 'EmailPage',
    data: () => ({
        error: null,
        disabled: true,
        email: '',
    }),
    mounted() {
        this.email = this.$store.getters.payloads.signupData.email
        this.$refs.email.focus()
        this.checkEmailFormat()
    },
    methods: {
        onKeydown() {
            setTimeout(() => {
                this.email = this.$refs.email.value
                this.checkEmailFormat()
            })
        },
        checkEmailFormat() {
            if (!this.email) {
                this.error = null
                this.disabled = true
                return
            }

            const regValidEmail = /[a-zA-Z0-9][\w.-]+@[\w-]+\.[a-zA-Z]{2,4}(\.[a-zA-Z]{2,4})?/
            if (!regValidEmail.test(this.email)) {
                this.error = '이메일 형식이 올바르지 않습니다'
                this.disabled = this.error ? true : false
                return
            }

            this.error = null
            this.disabled = this.error ? true : false
        },
        async next() {
            if (this.error || this.disabled) return

            const temp = this.email
            const { result, msg } = await userService.checkEmail(temp)
            if (!result) return this.$toast.error(msg)
            this.$store.commit('setPayloads', {
                signupData: {
                    email: temp,
                },
            })
            this.$router.push({ name: 'PasswordPage' })
        },
    },
}
</script>
