<template>
    <div class="search-box">
        <div class="flex-row items-center section-search">
            <div class="input-wrapper flex-row items-center">
                <i @click="$emit('onChange', searchValue)" class="zmdi zmdi-search flex-wrap cursor-pointer m-r-8" />
                <input
                    ref="searchValue"
                    @keydown="onKeydown"
                    :placeholder="$translate(placeholder)"
                    class="flex-fill"
                    v-model="searchValue"
                />
            </div>
        </div>
    </div>
</template>

<script>
import debounce from '@/modules/debounce'

export default {
    name: 'SearchBox',
    props: ['placeholder', 'initialValue'],
    data: () => ({
        searchValue: null,
    }),
    watch: {
        initialValue() {
            this.searchValue = this.initialValue
        },
    },
    mounted() {
        this.searchValue = this.initialValue
    },
    methods: {
        onKeydown: debounce(function () {
            const dom = this.$refs.searchValue
            if (!dom) return

            this.$emit('onKeydown', dom.value)
        }, 200),
    },
}
</script>
