<template>
    <div class="badge-action-point" v-if="!$isTester()">
        <div class="wrapper" :class="{'bordered': bordered }">
            <span class="icon">P</span><span class="plus" v-html="'+'"/><span>{{ actionPoint }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BadgeActionPoint',
    props: ['bordered', 'action'],
    computed: {
        actionPoint() {
            return this.action || (this.$store.getters.badges || {}).action_point || 0
        },
    },
}
</script>