<template>
    <div class="signup keywords">
        <div class="page-header">
            <div class="dots m-b-16">
                <div
                    v-for="i in [1, 2, 3]"
                    :key="i"
                    class="dot"
                    :class="{'selected': i === 2}">
                </div>
            </div>
            <div class="title m-b-10">키워드 선택</div>
            <div class="item-desc">
                <div>소개할 친구를 <span class="f-bold c-primary">키워드</span>로 표현해보세요</div>
                <div class="sub-desc m-t-4">(최대 10개 선택 가능)</div>
            </div>
        </div>
        <div class="body">
            <div
                class="each-gender"
                :key="gender"
                v-for="gender in selectedGenders">
                <div class="top flex-row items-center flex-between">
                    <div class="title" v-html="$translate(`FRIENDS_${gender.toUpperCase()}`)"/>
                </div>
                <button class="btn-primary btn-sm" @click="addKeyword(gender)">
                    <i class="material-icons m-r-4">add</i>
                    {{ 'DIRECT_INPUT' | translate }}
                </button>
                <div class="keyword-list">
                    <div
                        @click="keyword.$$selected = !keyword.$$selected"
                        class="btn-brd keyword"
                        :class="{'primary': keyword.$$selected}"
                        :key="keyword.name"
                        v-for="keyword in keywords[`${gender}`]"
                        v-html="keyword.name"/>
                </div>
            </div>
            <button
                    class="btn btn-primary"
                    @click="next"
                    v-html="$translate('NEXT')">
            </button>
        </div>
    </div>
</template>

<script>
import agentService from '@/services/agent'

export default {
    name: 'KeywordsPage',
    data: () => ({
        keywords: {
            male: [],
            female: [],
        },
    }),
    mounted() {
        const male_keyword = this.$store.getters.payloads.signupData.male_keywords || []
        const female_keyword = this.$store.getters.payloads.signupData.female_keywords || []
        agentService.keywords().then(data => {
            this.keywords.male = data.male_keywords.map(name => {
                const idx = male_keyword.indexOf(name)
                if (idx > -1) {
                    male_keyword.splice(idx, 1)
                }
                return {name: name, $$selected: idx > -1}
            })
            male_keyword.forEach(k => this.keywords.male.push({name: k, $$selected: true}))
            this.keywords.female = data.female_keywords.map(name => {
                const idx = female_keyword.indexOf(name)
                if (idx > -1) {
                    female_keyword.splice(idx, 1)
                }
                return {name: name, $$selected: female_keyword.includes(name)}
            })
            female_keyword.forEach(k => this.keywords.female.push({name: k, $$selected: true}))
        })
    },
    computed: {
        selectedGenders() {
            if (this.$store.getters.payloads.signupData.friend_type == 'both') {
                return ['male', 'female']
            } else if (this.$store.getters.payloads.signupData.friend_type == 'male') {
                return ['male']
            } else if (this.$store.getters.payloads.signupData.friend_type == 'female') {
                return ['female']
            }
        },
    },
    methods: {
        addKeyword(gender) {
            const onConfirm = name => this.keywords[`${gender}`].push({
                name,
                $$selected: true,
            })

            this.$modal.input({
                title: 'DIRECT_INPUT',
                inputs: [{
                    placeholder: this.$translate('DIRECT_INPUT'),
                    type: 'input',
                }],
            }).then(inputs => {
                if (!inputs || inputs.length === 0) return

                onConfirm(inputs[0].text)
            })
        },
        next() {
            if (this.error || this.disabled) return

            this.$store.commit('setPayloads', {
                signupData: {
                    male_keywords: this.keywords.male.filter(k => k.$$selected).map(k => k.name),
                    female_keywords: this.keywords.female.filter(k => k.$$selected).map(k => k.name),
                },
            })
            this.$router.push({name: 'IntroPage'})
        },
    },
}
</script>
