<template>
    <div class="weekly-stat card">
        <div class="top flex-row items-center">
            <div class="title flex-wrap" v-html="$translate('WEEKLY_STAT')" />
            <i ref="info" @click="onClickInfo" class="zmdi zmdi-info-outline flex-wrap m-l-8" />
        </div>
        <div class="p-l-20 p-r-20 p-b-20">
            <div class="week-range" v-html="weekRange" />
            <div class="stat-middle-card flex-row items-center" v-if="weeklyStat">
                <div class="stat">
                    <div class="key" v-html="$translate('TOTAL_RANKING_MATCHING')" />
                    <div class="value" v-html="`${weeklyStat.datings}건`" />
                </div>
                <div class="stat">
                    <div class="key" v-html="$translate('REFUNDS')" />
                    <div class="value" v-html="`${weeklyStat.refunds}건`" />
                </div>
            </div>
        </div>
        <div class="stat-bottom flex-row">
            <div class="stat" :key="idx" v-for="(item, idx) in bottomStats">
                <div class="key" v-html="$translate(item.title)" />
                <div class="value" v-html="item.value" />
            </div>
        </div>
        <div class="buttons">
            <button class="btn btn-brd" @click="openModal('DailyStat')">
                {{ 'DAILY_STAT' | translate }} <i class="material-icons">chevron_right</i>
            </button>
            <button class="btn btn-brd" @click="openModal('MatchingHistory')">
                {{ 'MATCHING_HISTORY' | translate }} <i class="material-icons">chevron_right</i>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WeeklyStat',
    computed: {
        weekRange() {
            const end = this.$moment()
            const start = this.$moment().add(-6, 'days')
            return `${start.format('M월D일')} ~ ${end.format('M월D일')}`
        },
        weeklyStat() {
            return this.$store.getters.weeklyStat
        },
        bottomStats() {
            if (!this.weeklyStat) return

            return [
                {
                    title: 'ACTIVE_TIME',
                    value: `${Math.round((this.weeklyStat.active_times / 3600) * 10) / 10}${this.$translate('HOURS')}`,
                },
                {
                    title: 'NUM_USERS',
                    value: `${this.weeklyStat.users}명`,
                },
                {
                    title: 'NUM_SOURCES',
                    value: `${this.weeklyStat.sources}명`,
                },
            ]
        },
    },
    methods: {
        openModal(component) {
            this.$modal.slot({ component, title: this.$case.toConst(component) })
        },
        onClickInfo() {
            const info = this.$refs.info
            const rect = info.getBoundingClientRect()
            const options = {
                title: 'WEEKLY_STAT',
                titleDesc: 'WEEKLY_STAT_DESC',
                items: [
                    { key: 'WEEKLY_STAT_TIME_K', value: 'WEEKLY_STAT_TIME_V' },
                    { key: 'WEEKLY_STAT_USER_K', value: 'WEEKLY_STAT_USER_V' },
                    { key: 'WEEKLY_STAT_PROFILE_K', value: 'WEEKLY_STAT_PROFILE_V' },
                ],
                footer: 'WEEKLY_STAT_FOOTER',
            }

            if (!this.$store.getters.isMobile) {
                options.style = {
                    position: 'absolute',
                    top: `${rect.top}px`,
                    right: `${window.innerWidth - rect.left}px`,
                }
            }

            this.$modal.custom({
                component: 'ModalHelp',
                options,
            })
        },
    },
    mounted() {
        this.$store.dispatch('loadWeeklyStat')
    },
}
</script>
