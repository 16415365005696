<template>
    <div class="premium-restriction flex flex-between">
        <span class="valid-until f-bold">
            <img class="m-r-6" src="../../assets/images/premium-crown.svg" />
            <p v-html="premiumValidUntil" />
        </span>
        <span class="f-medium c-white" v-html="$translate('PREMIUM_RESTRICTION_MESSAGE')" />
    </div>
</template>
<script>
export default {
    name: 'PremiumRestriction',
    props: {
        validUntil: String,
    },
    computed: {
        premiumValidUntil() {
            return this.$translate('PREMIUM_VALID_UNTIL').replace(
                '%s',
                this.$options.filters.formatDate(this.validUntil, 'chatList')
            )
        },
    },
}
</script>
<style lang="scss" scoped>
.premium-restriction {
    line-height: normal;
    width: 325px;
    padding: 10px 16px;
    background-color: #0e0b54;
    border-radius: 12px;
    margin: 0 16px;
    font-size: 12px;
}

@media (max-width: 480px) {
    .premium-restriction {
        width: calc(100vw - 2 * 16px);
    }
}

.valid-until {
    display: flex;
    flex-direction: row;
    color: #eabf7d;
}

p {
    margin: 0;
}
</style>
