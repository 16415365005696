<template>
    <div class="message-action p-relative" v-if="isVisible" :class="message.mtype">
        <ActionDefaultLayout v-if="useDefaultLayout" :message="message">
            <component :is="componentByMessageType" :message="message" />
        </ActionDefaultLayout>
        <component v-else :is="componentByMessageType" :message="message" />
    </div>
</template>
<script>
import ActionDefaultLayout from './ActionDefaultLayout'

export default {
    name: 'MessageAction',
    props: ['message'],
    components: {
        ActionDefaultLayout,
        ActionContactDone: () => import('./message-actions/ActionContactDone'),
        ActionContactRequest: () => import('./message-actions/ActionContactRequest'),
        ActionOpenChat: () => import('./message-actions/ActionOpenChat'),
        // ActionShare: () => import('./message-actions/ActionShare'),
        ActionShareDone: () => import('./message-actions/ActionShareDone'),
        ActionShareRequest: () => import('./message-actions/ActionShareRequest'),
        ActionRecommendRequest: () => import('./message-actions/ActionRecommendRequest'),
        ActionRecommendAccept: () => import('./message-actions/ActionRecommendAccept'),
        ActionRecommendReject: () => import('./message-actions/ActionRecommendReject'),
        ActionNoti: () => import('./message-actions/ActionNoti'),
        ActionAlimiBasic: () => import('./message-actions/ActionAlimiBasic'),
        ActionPhoto: () => import('./message-actions/ActionPhoto'),
        ActionGiftInfo: () => import('./message-actions/ActionGiftInfo'),
        ActionRefundStatus: () => import('./message-actions/ActionRefundStatus'),
        ActionPremiumStart: () => import('./message-actions/ActionPremiumStart'),
        ActionPremiumChat: () => import('./message-actions/ActionPremiumChat'),
        ActionPremiumSurveyEnd: () => import('./message-actions/ActionPremiumSurveyEnd'),
        ActionPremiumRating: () => import('./message-actions/ActionPremiumRating'),
        ActionUpdatedProfile: () => import('./message-actions/ActionUpdatedProfile'),
        ActionVerificationCompleted: () => import('./message-actions/ActionVerificationCompleted'),
        ActionMoreProfile: () => import('./message-actions/ActionMoreProfile'),
        ActionDropout: () => import('./message-actions/ActionDropout'),

        // 약속잡기
        ActionScheduleStart: () => import('./message-actions/ActionScheduleStart'),
        ActionScheduleFinish: () => import('./message-actions/ActionScheduleFinish'),
        ActionScheduleConfirm: () => import('./message-actions/ActionScheduleConfirm'),
        ActionScheduleComplete: () => import('./message-actions/ActionScheduleComplete'),
        ActionConfirmedProfile: () => import('./message-actions/ActionConfirmedProfile'),
        ActionReschedule: () => import('./message-actions/ActionReschedule'),
        ActionReselect: () => import('./message-actions/ActionReselect'),
        ActionScheduleReservationAgent: () => import('./message-actions/ActionScheduleReservationAgent'),
        ActionScheduleReservationComplete: () => import('./message-actions/ActionScheduleReservationComplete'),
        ActionScheduleAgentArrangeable: () => import('./message-actions/ActionScheduleAgentArrangeable'),
        ActionScheduleAgentUnarrangeable: () => import('./message-actions/ActionScheduleAgentUnarrangeable'),
        ActionRequestCancel: () => import('./message-actions/ActionRequestCancel'),
        ActionWaiveConfirmed: () => import('./message-actions/ActionWaiveConfirmed'),

        // 유저 프로필 변경시
        ActionProfileUpdated: () => import('./message-actions/ActionProfileUpdated'),
        // 매너 피드백
        ActionMannerFeedback: () => import('./message-actions/ActionMannerFeedback'),
        ActionUserConfirmedProfile: () => import('./message-actions/ActionUserConfirmedProfile'),

        ActionNoticeSendJoinProfile: () => import('./message-actions/ActionNoticeSendJoinProfile'),
        ActionNoticeInformDocument: () => import('./message-actions/ActionNoticeInformDocument'),
        ActionNoticeApproveDocument: () => import('./message-actions/ActionNoticeApproveDocument'),
        ActionNoticeAssignManager: () => import('./message-actions/ActionNoticeAssignManager'),

        // 서류 인증
        ActionNoticeVerification: () => import('./message-actions/ActionNoticeVerification'),
    },
    computed: {
        content() {
            return this.$mustParse(this.message.content)
        },
        componentByMessageType() {
            // return 'ActionScheduleReservationManager'

            if (this.message.mtype.includes('confirmed-profile')) return 'ActionUserConfirmedProfile'
            if (this.message.mtype.includes('noti') && this.message.mtype !== 'induce-noti') return 'ActionNoti'
            if (this.message.mtype.includes('prepare-verification')) return 'ActionNoti'
            if (this.message.mtype.includes('openchat')) return 'ActionNoti'
            if (this.message.mtype.includes('photo')) return 'ActionPhoto'
            if (this.message.mtype.includes('manner')) return 'ActionMannerFeedback'
            if (this.message.mtype === 'premium-survey-end') return 'ActionPremiumSurveyEnd'
            // if (this.message.mtype === 'premium-rating') return 'ActionPremiumRating'
            if (this.message.mtype === 'induce-noti') return 'ActionNoticeSendJoinProfile'
            if (this.message.mtype === 'profile-completed') return 'ActionNoticeInformDocument'
            if (this.message.mtype === 'verification-uploaded-all') return 'ActionNoticeApproveDocument'
            if (this.message.mtype === 'verification-completed-all') return 'ActionNoticeAssignManager'
            if (this.message.mtype === 'verification-unavailable') return 'ActionNoticeVerification'
            if (this.message.mtype === 'request-dropout') return 'ActionDropout'

            if (this.message.mtype.includes('schedule')) {
                if (this.message.mtype.includes('reservation-agent')) return 'ActionScheduleReservationAgent'
                if (this.message.mtype.includes('reservation-complete')) return 'ActionScheduleReservationComplete'
                if (this.message.mtype.includes('schedule-start') || this.message.mtype.includes('restart-b'))
                    return 'ActionScheduleStart'
                if (this.message.mtype.includes('finish')) return 'ActionScheduleFinish'
                if (this.message.mtype.includes('change') || this.message.mtype.includes('restart-a'))
                    return 'ActionReschedule'
                if (this.message.mtype.includes('refin')) return 'ActionReselect'
                if (this.message.mtype.includes('agent-arrangeable')) return 'ActionScheduleAgentArrangeable'
                if (this.message.mtype.includes('agent-unarrangeable')) return 'ActionScheduleAgentUnarrangeable'

                return 'Action' + this.$options.filters.toPascal(this.message.mtype, '-', 2)
            }

            if (this.message.mtype.includes('request-cancel-fee')) return 'ActionRequestCancel'
            if (this.message.mtype.includes('waive')) return 'ActionWaiveConfirmed'
            
            return 'Action' + this.$options.filters.toPascal(this.message.mtype, '-', 2)
        },
        useDefaultLayout() {
            return ['alimi', 'photo', 'gift-info', 'confirm-profile'].every(key => !this.message.mtype.includes(key))
        },
        isVisible() {
            if (['refund-status-user'].indexOf(this.message.mtype) !== -1) return false

            return true
        },
    },
}
</script>
