import * as $http from 'axios'

export default {
    get: () => $http.get(`v2/agents`),
    me: () => $http.get('v2/agents/me'),
    update: agent => $http.put('v2/agents/me', agent),
    updateProfile: agentProfile => $http.put('v2/agents/me/agent_profiles', agentProfile),
    updateMainPhoto: photoId => $http.put(`v2/users/me/photos/${photoId}/set_main`),
    actuallyUsedPhoto: (photoId, payload) => $http.put(`v2/users/me/photos/${photoId}/actually_used`, payload),
    checkUpdateEnable: () => $http.get(`v2/agents/me/check_photo_update_enabled`),
    ratings: () => $http.get(`v2/agents/me/agent_ratings`),
    solveQuiz: quiz =>
        $http.post(`v2/quizzes/${quiz.id}/user_quizzes`, {
            quiz_id: quiz.id,
            is_solved: quiz.is_solved,
        }),
    bridgeHistory: {
        all: () => $http.get(`v2/agents/me/agent_bridge_histories`),
        detail: id => $http.get(`v2/agents/me/agent_bridge_histories/${id}`),
    },
    datingInfo: () => $http.get('v2/datings/info'),
    keywords: () => $http.get('v2/agents/keywords'),
    gifts: {
        all: () => $http.get('v2/agents/me/gifts'),
        request: giftIds => $http.put('v2/agents/me/gifts/request_gifts', { ids: giftIds }),
    },
    testStat: () => $http.get('v2/agents/me/test_stat'),
    sharedContacts: () => $http.get('v2/agents/me/shared_contacts'),

    deleteContact: payload => $http.put(`v2/agents/me/delete_contacts`, payload),

    getAllAgentsInfo: () => $http.get(`v2/agents/all_info`),
}
