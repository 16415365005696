<template>
    <div class="upload-photos">
        <div class="grid-col">
            <div class="photo" v-for="(photo, i) in photos" @click.stop="onClickPhoto(i)" :key="i">
                <i v-if="!photo.url" class="material-icons">add</i>
                <img class="img-cover" v-if="photo.url" :src="photo.url" alt="" />
                <i @click.stop="editPhotoDesc(i)" v-if="photo.url" class="material-icons icon-wrapper pencil"
                    >add_comment</i
                >
                <i @click.stop="removePhoto(i)" v-if="photo.url" class="material-icons icon-wrapper close-icon"
                    >close</i
                >
            </div>
            <input
                ref="imageUploader"
                type="file"
                class="image-input display-none"
                accept="image/*"
                @change="onChangeImage"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'UploadTrustPhoto',
    components: {},
    data: () => ({
        photos: null,
        selectedIndex: null,
    }),
    watch: {
        agentPhotos: {
            handler(newVal, oldVal) {
                this.init()
            },
            deep: true,
        },
    },
    computed: {
        agentPhotos() {
            return this.$store.getters.agent.agent_photos
        },
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.photos = Array(6)
                .fill({})
                .map((p, idx) => {
                    p = {
                        blob: null,
                        name: null,
                        url: this.agentPhotos[idx] ? this.agentPhotos[idx].url : null,
                        desc: this.agentPhotos[idx] ? this.agentPhotos[idx].desc : '',
                        id: this.agentPhotos[idx] ? this.agentPhotos[idx].id : null,
                    }
                    return p
                })
        },
        onClickPhoto(i) {
            if (this.photos[i].url) {
                const photos = this.photos.filter(p => p.url).map(p => p.url)
                const idx = photos.indexOf(this.photos[i].url)

                this.$modal.images({
                    images: photos,
                    selectedIndex: idx,
                })

                return
            }
            this.selectedIndex = i
            this.$refs.imageUploader.click()
        },
        onChangeImage(event) {
            const selectedPhoto = this.photos[this.selectedIndex]
            if (selectedPhoto.blob) this.$emit('pushDeletePhotoId', selectedPhoto.id)

            const imgFile = event.target.files[0]
            selectedPhoto.name = imgFile.name

            this.$refs.imageUploader.value = ''

            this.$modal
                .custom({
                    component: 'ModalCropper',
                    options: {
                        imgFile,
                        pc: true,
                    },
                })
                .then(croppedFile => {
                    if (!croppedFile) return
                    selectedPhoto.url = URL.createObjectURL(croppedFile)
                    selectedPhoto.blob = croppedFile
                    this.selectedIndex = null
                    this.$emit('uploadPhoto', this.photos)
                })
        },
        async editPhotoDesc(i) {
            const res = await this.$modal.custom({
                component: 'ModalInput',
                options: {
                    title: '이미지 설명',
                    inputs: [
                        {
                            type: 'input',
                            text: this.photos[i].desc ? this.photos[i].desc : '',
                            placeholder: '이미지 설명을 입력해주세요!',
                        },
                    ],
                },
            })
            this.photos[i].desc = res[0].text
            this.$emit('uploadPhoto', this.photos)
        },
        removePhoto(i) {
            this.$emit('pushDeletePhotoId', this.photos[i].id)

            this.photos[i].id = null
            this.photos[i].blob = null
            this.photos[i].url = null
            this.photos[i].name = null
            this.photos[i].desc = null

            this.$emit('uploadPhoto', this.photos)
        },
    },
}
</script>

<style scoped lang="scss">
.upload-photos {
    $grid-no: 2;
    $grid-gap: 8px;
    $edit-profile-padding: 16px;

    .grid-col {
        grid-template-columns: repeat($grid-no, 1fr);
        grid-gap: $grid-gap;

        .photo {
            &:nth-child(2n) {
                justify-self: end;
            }

            position: relative;
            border-radius: 8px;
            overflow: hidden;
            width: 160px;
            height: 160px;
            border: 1px solid $grey-02;
            background-color: white;
            margin-bottom: 6px;

            @include center;

            .img-cover {
                width: 100%;
                height: 100%;
                border: 1px solid $grey-02;
            }

            .material-icons {
                color: $grey-05;
                font-size: 24px;

                &.icon-wrapper {
                    position: absolute;
                    color: white;

                    width: 25px;
                    height: 25px;
                    background: rgba(0, 0, 0, 0.5);
                    border-radius: 50%;
                    font-size: 20px;
                    @include center;

                    &.pencil {
                        top: 10px;
                        right: 40px;
                        font-size: 14px;
                    }
                    &.close-icon {
                        top: 10px;
                        right: 10px;
                    }
                }
            }
        }
    }
}
</style>
