<template>
    <div class="detail" v-if="user" :class="isInstantChat ? 'is-instant-chat' : ''">
        <h3 class="h3"><i class="material-icons">article</i>퍼스널 스토리</h3>
        <div class="stories m-b-14" v-for="(item, idx) in userPersonalStories" :key="idx">
            <div class="title-box m-b-10">
                <div class="title f-16 f-bold">
                    {{ idx + 1 }}. {{ personalStories[item.personal_story_id - 1].name }}
                </div>
                <i @click="editStory(item.id)" class="material-icons-outlined edit f-18">mode_edit</i>
            </div>
            <div class="content">
                {{ modifiedStory && item.id === modifiedStory.id ? modifiedStory.content : item.content }}
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'PersonalStory',
    props: ['user'],
    data: () => ({
        modifiedStory: null,
        isInstantChat: false,
    }),
    computed: {
        userPersonalStories() {
            return this.user.user_personal_stories || ''
        },
        personalStories() {
            return this.$store.getters.personalStory
        },
    },
    methods: {
        async editStory(personalStoryId) {
            const content = this.userPersonalStories.find(ups => ups.id === personalStoryId).content
            const idx = await this.$modal.custom({
                component: 'ModalEditUserPersonalStory',
                options: {
                    personalStoryId: personalStoryId,
                    content: this.modifiedStory ? this.modifiedStory.content : content,
                    userId: this.user.id,
                },
            })
            // console.log(idx)
            if (idx === 1) {
                // console.log(idx)
                const data = await this.$store.dispatch('loadUserDetail', { userId: this.user.id })
                const storiesArray = data.user_personal_stories
                const s = storiesArray.find(story => story.id === personalStoryId)
                this.modifiedStory = s
            }
        },
    },
}
</script>
<style scoped lang="scss">
.h3 {
    color: #646468;
}
.title-box {
    position: relative;
    display: flex;
    i {
        height: fit-content;
        position: absolute;
        right: 10px;
    }
}
.content {
    display: flex;
    align-items: center;
    line-height: 18px;
    color: black;
}
.instant-chat {
    .h3 {
        color: #fff;
    }
    .content {
        color: #f5e0e0;
    }
}
.before-confirm {
    .h3 {
        color: #fff;
    }
    .content {
        color: #f5e0e0;
    }
}
</style>
