<template>
    <transition name="slide-up">
        <div
            class="snackbar"
            v-show="show && !$store.getters.isMobile"
            @mouseover="show = true"
            @mouseleave="show = false"
            v-html="$translate(text)"
        />
    </transition>
</template>
<script>
export default {
    name: 'Snackbar',
    props: ['bind', 'text'],
    data: () => ({
        show: false,
    }),
    watch: {
        bind(newVal) {
            this.show = this.bind
        },
    },
}
</script>

<style scoped>
.slide-up-enter-active,
.slide-up-leave-active {
    transition: all 0.2s ease-in-out;
}

.slide-up-enter,
.slide-up-leave-to {
    opacity: 0;
    transform: translateY(10px);
}
</style>
