<template>
    <div class="photo-upload section">
        <div class="profile-title with-btn">
            <span v-html="$translate('AGENT_PROFILE_PHOTO_UPLOAD')" />
            <button :class="{ disabled }" v-html="$translate('SAVE')" @click="submit" />
        </div>
        <div class="photo-upload-instructions m-b-16">
            <div
                class="m-b-4 f-12"
                v-for="idx in 2"
                :key="idx"
                v-html="$translate(`AGENT_PROFILE_PHOTO_UPLOAD_INSTRUCTIONS_${idx}`)"
            />
        </div>
        <UploadTrustPhoto @uploadPhoto="onUploadPhoto" @pushDeletePhotoId="pushDeletePhotoId" />
    </div>
</template>

<script>
import UploadTrustPhoto from '@/routes/settings/UploadTrustPhoto'
export default {
    name: 'EditTrustPhotos',
    components: {
        UploadTrustPhoto,
    },
    data: () => ({
        photos: [],
        deletePhotoIds: [],
        disabled: true,
    }),
    computed: {},
    methods: {
        onUploadPhoto(photo) {
            this.photos = photo
            this.disabled = false
        },
        pushDeletePhotoId(id) {
            if (id) {
                this.deletePhotoIds.push(id)
                this.disabled = false
            }
        },
        async submit() {
            this.$emit('onSubmitPhotos', this.photos, this.deletePhotoIds)
            this.deletePhotoIds = []
            this.disabled = true
        },
    },
}
</script>
