const chat = {
    CHANNEL_USER: 'UserChannel',

    MESSAGE_TYPE_WELCOME: 'welcome',
    MESSAGE_TYPE_CONFIRM_SUBSCRIPTION: 'confirm_subscription',
    MESSAGE_TYPE_PING: 'ping',
    MESSAGE_TYPE_OPEN_CHAT: 'open-chat',

    COMMAND_SUBSCRIBE: 'subscribe',
    COMMAND_MESSAGE: 'message',

    MESSAGE_ACTION_ALERT: 'alert',
    MESSAGE_ACTION_MESSAGE: 'message',
    MESSAGE_ACTION_UPDATE_MESSAGE: 'update_message',
    MESSAGE_ACTION_UNREAD: 'unread',
}

chat.SKIP_TYPE = [
    chat.MESSAGE_TYPE_WELCOME,
    chat.MESSAGE_TYPE_CONFIRM_SUBSCRIPTION,
    chat.MESSAGE_TYPE_PING,
]

export default chat