import * as $http from 'axios'

export default {
    activity: () => $http.get(`v2/agents/activity`),
    datings: () => $http.get(`v2/datings`),
    notices: (ntype) => $http.get(`v2/notices?ntype=${ntype}`),
    totalRanking: () => $http.get('v2/agents/total_ranking'),
    myRanking: () => $http.get('v2/agents/my_ranking'),
    weeklyStat: () => $http.get('v2/agents/weekly_stat'),
    banners: (category) => $http.get(`v2/banners?category=${category}`),
}