<template>
    <div class="mobile-floatings">
        <FloatingInterest :chat="chat" :interests="interests" />
        <PrivacyStatus v-if="user" />
    </div>
</template>

<script>
import FloatingInterest from './FloatingInterest'
import PrivacyStatus from './PrivacyStatus'

export default {
    name: 'MobileFloatings',
    components: { FloatingInterest, PrivacyStatus },
    props: {
        user: {
            type: Object,
        },
        expireAt: {
            type: String,
        },
    },
    data: () => ({
        sourceUser: null,
    }),
    watch: {
        chat(oldVal, newVal) {
            if (oldVal && newVal && oldVal.id !== newVal.id) {
                this.init()
            }
        },
    },
    computed: {
        chat() {
            return this.$store.getters.chat || {}
        },
        interests() {
            return this.chat.$$floatingsInterest || []
        },
        shouldRender() {
            return this.interests.length > 0
        },
    },
    mounted() {
        this.init()
    },
    methods: {
        async init() {},
    },
}
</script>

<style lang="scss" scoped>
.mobile-floatings {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
</style>
