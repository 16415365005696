<template>
    <div class="matching-history">
        <div class="body p-relative">
            <Loading :loading="loading" />
            <div class="thead">
                <div class="flex-row tr">
                    <div :key="column.title" v-for="column in columns" class="th">
                        {{ column.title | translate }}
                    </div>
                </div>
            </div>
            <div class="tbody">
                <div :key="rowIdx" v-for="(row, rowIdx) in datings" class="flex-row tr">
                    <div>{{ row.date | formatDate('YY.MM.DD') }}</div>
                    <div :class="gender(row.user.gender)">
                        {{ row.user.name ? row.user.name : row.user.nickname }}
                        {{ row.user.name ? '(' + row.user.nickname + ')' : '' }}
                    </div>
                    <div :class="gender(row.target.gender)">
                        {{ row.target.name ? row.target.name : row.target.nickname }}
                        {{ row.user.name ? `(` + row.user.nickname + ')' : '' }}
                    </div>
                    <div>{{ row.status }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MatchingHistory',
    data: () => ({
        data: [],
    }),
    computed: {
        loading() {
            return this.$store.getters.loading.datings
        },
        datings() {
            return this.$store.getters.datings || []
        },
        columns() {
            return [
                {
                    title: 'TIMESTAMP',
                },
                {
                    title: 'DATING_USER',
                },
                {
                    title: 'DATING_TARGET',
                },
                {
                    title: 'STATUS',
                },
            ]
        },
    },
    mounted() {
        this.init()
    },
    methods: {
        async init() {
            try {
                await this.$store.dispatch('loadDatings')
            } catch (e) {}
        },
        gender(gender) {
            return gender === 0 ? 'c-male' : 'c-female'
        },
    },
}
</script>
