<template>
    <div
        ref="message"
        class="message-text"
        :class="{ 'mouse-down': mouseDown }"
        @mousedown="onMouseDown"
        @mouseup="onMouseUp"
    >
        {{ msgUpdated ? msgUpdated.content : msg }}
        <div @click="editMessage()" v-if="message.direction === 2" class="material-icons-outlined edit">mode_edit</div>
    </div>
</template>
<script>
import chatService from '@/services/chat'
export default {
    name: 'MessageText',
    props: ['message'],
    data: () => ({
        mouseDown: false,
        msgUpdated: null,
    }),
    computed: {
        msg() {
            return (this.message.content || '').trim()
        },
        chat() {
            return this.$store.getters.chat || {}
        },
    },
    mounted() {
        this.initTouchEvents()
    },
    methods: {
        initTouchEvents() {
            const message = this.$refs.message
            message.addEventListener('touchstart', this.onMouseDown)
            message.addEventListener('touchend', this.onMouseUp)
            message.addEventListener('mouseleave', this.onMouseUp)
            message.addEventListener('touchmove', this.onMouseUp)
        },
        onMouseDown() {
            const doc = this
            this.mouseDown = true

            setTimeout(() => {
                if (this.mouseDown) {
                    this.$copyText(this.message.content)
                        // $copyText 라이브러리가 Uncaught in promise 하는 경우가 존재하여 resolve인 경우만 핸들링
                        .then(() => {
                            doc.$toast.success('메시지가 복사되었습니다.')
                        })
                        .catch(() => {})
                    this.mouseDown = false
                }
            }, 700)
        },
        onMouseUp() {
            this.mouseDown = false
        },
        async editMessage() {
            const idx = await this.$modal.custom({
                component: 'ModalEditMessage',
                options: {
                    msg: this.msgUpdated ? this.msgUpdated.content : this.msg,
                    chatId: this.message.chat_id,
                    messageId: this.message.id,
                    userId: this.message.user_id,
                },
            })
            // console.log(idx)
            if (idx === 1) {
                const messagesArray = await chatService.messages(this.chat.id)
                const messages = messagesArray.messages
                const m = messages.find(msg => msg.id === this.message.id)
                this.msgUpdated = m
            }
        },
    },
}
</script>
<style scoped lang="scss">
.message-text {
    position: relative;
    .edit {
        position: absolute;
        right: -15px;
        bottom: -15px;
        color: #8a74ff !important;
        background-color: #ddd;
        border-radius: 50%;
        padding: 5px;
    }
}
</style>
