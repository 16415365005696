<template>
    <div
        class="meeting-feedback"
        v-if="isMannerFeedbackOpen"
        :class="[introducingUser || showFilter ? 'absolute' : 'relative']"
    >
        <div class="meeting-feedback-container">
            <div class="title">
                <span> 만남피드백 </span>
                <img
                    @click="onClickClose"
                    :src="require(`@/assets/images/icons/close.png`)"
                    alt=""
                    width="24px"
                    height="24px"
                />
            </div>
            <FeedbackItem v-for="(feedbackItem, idx) in feedbacks" :key="idx" :feedbackItem="feedbackItem" />
        </div>
    </div>
</template>

<script>
import FeedbackItem from '@/routes/chat/meeting-feedback/FeedbackItem'

export default {
    name: 'MeetingFeedbackList',
    props: {},
    data: () => ({
        // feedbacks: [],
    }),
    components: {
        FeedbackItem,
    },
    mounted() {
        this.getUserFeedbacks()
    },
    computed: {
        chat() {
            return this.$store.getters.chat || {}
        },
        introducingUser() {
            return this.chat.$$introducingUser ? true : false
        },
        showFilter() {
            return this.$store.getters.showFriendsFilter
        },
        isMannerFeedbackOpen() {
            return this.$store.getters.isMannerFeedbackOpen
        },
        feedbacks() {
            if (!this.chat.$$mannerFeedbacks) return []
            return this.chat.$$mannerFeedbacks.map(r => {
                const user = {
                    photo: r.urls[0],
                    name: r.name,
                    birthday: r.birthday,
                    job: r.job,
                }
                const meet = {
                    date: r.schedule.date_confirmed,
                    place: r.schedule.meet_place,
                }
                const steps = Object.fromEntries(Object.entries(r).filter(([key]) => key.includes('step')))

                const feedbackQuestion = steps.step_6.length
                    ? [
                          {
                              id: 1,
                              q: '만남 어땠는지',
                          },
                          {
                              id: 10,
                              q: '만나지 않은 이유',
                          },
                          {
                              id: 2,
                              q: '연락할 의향',
                          },
                          {
                              id: 3,
                              q: '가벼운 목적인지',
                          },
                          {
                              id: 4,
                              q: '매너 있었는지',
                          },
                          {
                              id: 5,
                              q: '실물 어떤지',
                          },
                          {
                              id: 6,
                              q: '키워드',
                          },
                          {
                              id: 7,
                              q: '추가 작성',
                          },
                      ]
                    : [
                          {
                              id: 1,
                              q: '만남 어땠는지',
                          },
                          {
                              id: 10,
                              q: '만나지 않는 이유',
                          },
                          {
                              id: 2,
                              q: '연락할 의향',
                          },
                          {
                              id: 3,
                              q: '좋았던 점',
                          },
                          {
                              id: 4,
                              q: '안 좋았던 점',
                          },
                          {
                              id: 5,
                              q: '추가 작성',
                          },
                      ]
                const feedbackItem = feedbackQuestion.map(f => {
                    return { ...f, a: steps[`step_${f.id}`] }
                })
                // console.log('test', user, meet, feedbackItem)
                return { user: user, meet: meet, feedback: feedbackItem }
            })
        },
    },

    methods: {
        onClickClose() {
            this.$store.commit('setMannerFeedbackOpen', false)
        },
        getUserFeedbacks() {
            // mock data
        },
    },
}
</script>

<style scoped lang="scss">
.meeting-feedback {
    // margin: 16px 0;
    // height: 90%;
    height: 100%;
    background: #fff;
    z-index: 3;
    top: 0;
    left: 0;
    width: $panel-width-introducing-user;
    border-radius: 0px 16px 16px 0px;
    border: 1px solid #f1f2f4;
    overflow-y: scroll !important;
    &.absolute {
        position: absolute !important;
        margin-top: 16px;
        height: 95%;
        height: calc(100% - 32px);
        height: -webkit-calc(100% - 32px);
        height: -moz-calc(100% - 32px);
    }
    &.relative {
        position: relative;
    }
    .meeting-feedback-container {
        // overflow-y: scroll;
        overflow: show;
        position: relative;
        .title {
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
            color: #000000;
            padding: 24px 16px 32px;
            display: flex;
            background-color: #fff;
            align-items: center;
            justify-content: space-between;
            position: sticky;
            top: 0;
        }
    }
}
</style>
