import Vue from 'vue'
import axios from '@/modules/axios'
import router from '@/router'
import agentService from '@/services/agent'
import autotextService from '@/services/auto-text'
import commonService from '@/services/common'
import userService from '@/services/user'

const transformLoginResponseToHeader = ({ user, device }) => ({
    'X-User-Email': user.email,
    'X-User-Token': user.token,
    'X-User-Role': 'agent',
    'X-User-Web-Version': window.location.href.includes('jj-manager.vanillabridge.com') ? 'new' : 'old',
    'X-User-OS': device ? JSON.stringify(device) : '',
})

const setHeaderAndRedirectToMain = async ({ commit, dispatch, data }) => {
    if (!data) {
        return Promise.reject(data)
    }

    const header = transformLoginResponseToHeader(data)
    axios.setHeader(header)
    commit('setHeader', header)

    dispatch('detectMobile')
    dispatch('loadConstants')
    await dispatch('afterAuthCallbacks')
    router.push({ name: 'ChatPage' })
}

const defaultState = () => ({
    actionPointList: null,
    agent: null,
    autoTexts: null,
    autoTextsAllCategory: null,
    badges: null,
    device: null,
    header: null,
    notifications: null,
    gifts: null,
    testStat: null,
    ratings: null,
})

const state = defaultState()

const getters = {
    actionPointList: state => state.actionPointList,
    agent: state => state.agent,
    autoTexts: state => state.autoTexts,
    autoTextsAllCategory: state => state.autoTextsAllCategory,
    badges: state => state.badges,
    device: state => state.device,
    header: state => state.header,
    notifications: state => state.notifications,
    gifts: state => state.gifts,
    testStat: state => state.testStat,
}

const actions = {
    async loadAuthToken({ commit, dispatch }) {
        const header = JSON.parse(window.localStorage.getItem('header'))
        if (!header) {
            window.localStorage.removeItem('header')
            return Promise.reject()
        }

        if (header['X-User-Role'] !== 'agent') {
            return dispatch('signOut')
        }

        if (!header['X-User-Web-Version']) {
            header['X-User-Web-Version'] = window.location.href.includes('jj-manager.vanillabridge.com') ? 'new' : 'old'
        }
        axios.setHeader(header)
        commit('setHeader', header)
    },
    async loadBadges({ state, commit }) {
        if (!state.agent) return

        const data = await userService.badges(state.agent.user_id)
        commit('setBadges', data)
    },
    async signIn({ commit, dispatch }, payload) {
        const data = await Vue.prototype.$http.post('login', payload)
        setHeaderAndRedirectToMain({ commit, dispatch, data })
    },
    async signOut({ commit, getters }) {
        window.localStorage.removeItem('header')
        window.localStorage.removeItem('introducingUsers')
        // window.localStorage.removeItem('filters')
        // window.localStorage.removeItem('defaultFilter')
        window.localStorage.removeItem('sort')
        window.localStorage.removeItem('hasSeenProfileSpreadExample')
        window.localStorage.removeItem('visitFilter')
        window.localStorage.removeItem('hasShownInstantChatIntro')
        commit('initAppData')
        commit('setDevice', getters.device)
        if (router.currentRoute.name !== 'LoginPage') {
            router.push({ name: 'LoginPage' })
        }

        if (window.deviceToken) {
            userService.deleteToken(window.deviceToken.registration_id)
        }
    },
    async loadAgentMe({ state, dispatch, commit }) {
        try {
            const data = await agentService.me()
            if (!data) return

            commit('setAgent', data)
            // dispatch('loadTestStat')
            // dispatch('loadRatings')

            if (state.chats) {
                commit('setPremiumProducts')
            }
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async loadTestStat({ commit }) {
        const data = await agentService.testStat()
        commit('setTestStat', data)
    },
    async loadAutoTexts({ commit }) {
        try {
            const data = await autotextService.all()
            commit('setAutoTexts', data)
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async loadAutoTextsAllCategory({ state, commit }) {
        try {
            const data = await autotextService.allCategory()
            commit('setAutoTextsAllCategory', data)
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async loadActionPointAmount({ commit }) {
        try {
            const data = await commonService.properties({ key: 'action_point_amount' })
            if (!data) return

            const values = Vue.prototype.$mustParse(data[0].value)
            commit('setActionPointList', values)
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async loadNotifications({ state, commit }, offset) {
        try {
            const data = await userService.chunkedNotifications(
                offset === 0 ? offset : (state.notifications || []).length
            )
            commit('setNotifications', {
                offset: offset,
                items: data.notifications,
            })
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async loadGifts({ commit }) {
        try {
            const gifts = await agentService.gifts.all()
            commit('setGifts', gifts)
            return gifts
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async loadRatings({ commit }) {
        try {
            const ratings = await agentService.ratings()
            commit('setRatings', ratings)
            return ratings
        } catch (e) {
            return Promise.reject(e)
        }
    },
}

const mutations = {
    setHeader(state, header) {
        state.header = header
    },
    setAgent(state, payload) {
        let solvedQuiz = false
        if (state.agent) solvedQuiz = state.agent.$$solvedQuiz

        state.agent = payload
        Vue.set(state.agent, '$$solvedQuiz', solvedQuiz)
        Vue.set(state.agent, 'profile', payload.profile)
    },
    setAutoTexts(state, autoTexts) {
        state.autoTexts = autoTexts
    },
    setAutoTextsAllCategory(state, autoTexts) {
        state.autoTextsAllCategory = autoTexts
    },
    setActionPointList(state, payload) {
        state.actionPointList = payload
    },
    setBadges(state, badges) {
        state.badges = badges
    },
    setDevice(state, device) {
        state.device = device
    },
    setNotifications(state, payload) {
        if (!payload) return

        if (payload.offset === 0 || !state.notifications) {
            state.notifications = payload.items
        } else {
            state.notifications = state.notifications.concat(payload.items)
        }
    },
    setGifts(state, gifts) {
        if (!gifts) return

        state.gifts = gifts
    },
    setTestStat(state, stat) {
        if (!stat) return

        state.testStat = stat
    },
    setRatings(state, ratings) {
        if (!ratings) return

        state.ratings = ratings
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
    defaultState,
}
