<template>
    <div id="app">
        <div v-if="!$store.getters.isMobile" class="route flex-row desktop">
            <ControlPanel v-if="$route.name !== 'LoginPage'" class="flex-wrap" />
            <RouterView />
        </div>
        <div v-else class="mobile flex">
            <HeaderBar v-show="!noheader" class="flex-wrap" />
            <EditMemo />
            <RouterView class="route flex-wrap" :class="{ noheader: noheader, nonav: nonav }" />
            <ControlPanel v-if="!nonav" class="flex-wrap" />
        </div>
        <Notifications :class="{ mobile: $store.getters.isMobile }" />
        <Toast />
        <ProgressView />
        <Modal
            :class="$store.getters.isMobile ? 'mobile' : 'desktop'"
            :key="idx"
            :modal="modal"
            v-for="(modal, idx) in $store.getters.modals"
        />
        <KeyBinder />
        <HealthCheck />
        <NativeBridge />
        <ChatPump v-if="$route.name !== 'LoginPage'" />
    </div>
</template>

<script>
import HeaderBar from '@/components/control-panel/HeaderBar'
import EditMemo from '@/components/mobile/EditMemo'
import Notifications from '@/components/control-panel/Notifications'
import ControlPanel from '@/components/control-panel/ControlPanel'
import ChatPump from '@/routes/chat/chatroom/ChatPump'
import ProgressView from '@/components/app/ProgressView'
import Toast from '@/components/app/Toast'
import Modal from '@/components/app/Modal'
import KeyBinder from '@/components/app/KeyBinder'
import NativeBridge from '@/components/app/NativeBridge'
import HealthCheck from '@/components/app/HealthCheck'

export default {
    components: {
        HeaderBar,
        EditMemo,
        Notifications,
        ControlPanel,
        ChatPump,
        ProgressView,
        Toast,
        Modal,
        KeyBinder,
        NativeBridge,
        HealthCheck,
    },
    name: 'App',
    computed: {
        noheader() {
            return (
                ['LoginPage', 'UserDetailPage', 'VerificationPage', 'ReportUserPage'].indexOf(this.$route.name) !== -1
            )
        },
        nonav() {
            return !this.isRoot
        },
        isRoot() {
            return (
                ['HomePage', 'ChatPage', 'MyPage', 'SettingsPage', 'SettingsPageMobile'].indexOf(this.$route.name) !==
                -1
            )
        },
    },
    created() {
        this.init()
    },
    mounted() {
        this.overrideDefaultMobileCSSBase()
        this.$nativeBridge.postMessage({
            action: 'onMountWebView',
        })
        window.addEventListener('resize', this.watchInnerWidth)
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.watchInnerWidth)
    },
    methods: {
        async init() {
            this.$store.dispatch('detectMobile')
            this.$store.dispatch('loadConstants')
            try {
                await this.$store.dispatch('loadAuthToken')
                this.$store.dispatch('afterAuthCallbacks')
            } catch (e) {
                this.$store.dispatch('signOut')
            }
        },
        watchInnerWidth() {
            this.$store.dispatch('detectMobile')
        },
        overrideDefaultMobileCSSBase() {
            if (this.$store.getters.isMobile) {
                const body = document.getElementsByTagName('body')[0]
                body.style.fontSize = `${15}px`
            }
        },
    },
}
</script>
