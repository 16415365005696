<template>
    <div class="user-products">
        <div class="recent-info">
            <RatePlan v-for="plan in userRatePlans" :key="plan.id" :user-rate-plan="plan" />
        </div>
        <div class="ticket-info">
            <TicketState :user-tickets="userTickets" />
        </div>
        <div class="load-more">
            <i @click="openHistory = !openHistory" class="material-icons">{{
                `keyboard_arrow_${openHistory ? 'up' : 'down'}`
            }}</i>
        </div>
        <div class="plan-history m-b-12" v-if="userRatePlans.length > 1">
            <div class="toggle-container grid-col" v-show="openHistory">
                <RatePlan
                    v-for="plan in userRatePlans.filter(plan => plan.expire < 0)"
                    :hide-expire="true"
                    :user-rate-plan="plan"
                    :key="plan.id"
                />
            </div>
        </div>
        <div v-if="unlimited" class="product-info-unlimited f-bold m-b-24">기간제 : {{ unlimitedValidUntil }}까지</div>
        <div class="product-info m-b-24">
            잔여횟수 : {{ userRatePlanRecent.amount_original - userTickets.used }}회ㅣ{{
                price ? `${(price / userRatePlanRecent.amount_original).toLocaleString()}원` : null
            }}
        </div>
        <button class="btn btn-primary flex-wrap m-t-8 m-b-10" @click="addProduct">
            {{ 'ADD_PRODUCT' | translate }}
        </button>
    </div>
</template>

<script>
import RatePlan from '@/components/common/RatePlan'
import TicketState from '@/components/common/TicketState'
import productService from '@/services/product'

export default {
    name: 'UserProducts',
    components: { RatePlan, TicketState },
    data: () => ({
        openHistory: false,
        price: null,
    }),
    props: {
        user: Object,
    },
    methods: {
        parsePidToPlanName(pid) {
            /* eslint-disable */
            const unlimited = [9, 10]
            const prepaid = [14, 15, 16]

            return unlimited.includes(pid) ? 'UNLIMITED' : prepaid.includes(pid) ? 'PREPAID' : 'EXTRA'
        },
        getPlanInfo(pid) {
            return this.ratePlanNowOnSale.all.find(item => item.id === pid)
        },
        async getProducts() {
            if (this.userRatePlanRecent.order_id) {
                const res = await productService.orderInfo(this.userRatePlanRecent.order_id)
                if (res) this.price = res.order.price
            }
        },
        addProduct() {
            // console.log(this.user)
            if (this.user.profile.status !== 'judged' && this.user.profile.status !== 'confirmed') {
                this.$toast.error('가입 심사 통과자가 아닙니다')
            } else {
                this.$modal
                    .custom({
                        component: 'ModalAddProduct',
                        options: {
                            user: this.user,
                        },
                    })
                    .then(result => {
                        if (result === 1) {
                            this.loadUserDetail()
                        }
                    })
            }
        },
    },
    watch: {
        userRatePlanRecent(newVal) {
            this.getProducts()
        },
    },
    mounted() {
        this.getProducts()
    },
    computed: {
        products() {
            // return this.user.products || []
            return this.$store.getters.userProducts
        },
        ratePlanNowOnSale() {
            const productNowOnSale = this.$store.getters.productsNowOnSale
            if (!productNowOnSale) return []

            return productNowOnSale.rate_plan || []
        },
        userRatePlans() {
            return this.$store.getters.userRatePlans || []
        },
        userRatePlanRecent() {
            return this.$store.getters.userRatePlanRecent
        },
        userTickets() {
            // const tickets = this.products.filter(item => item.ptype === 'ticket')
            const tickets = this.user.tickets
            // if (this.userRatePlanRecent.pid === 0) {
            return {
                all: tickets.length,
                used: tickets.filter(ticket => !ticket.enabled).length,
                active: tickets.filter(ticket => ticket.enabled).length,
            }
            // }

            // const validTickets = tickets.filter(
            //     t =>
            //         this.userRatePlanRecent.startTypeOrigin <= t.valid_until &&
            //         this.userRatePlanRecent.expireTypeOrigin >= t.valid_until
            // )
            // // console.log('val', validTickets.length, validTickets)
            // return {
            //     all: this.userRatePlanRecent.amount,
            //     used: this.userRatePlanRecent.amount - validTickets.length,
            //     active: validTickets.length,
            // }
        },
        unlimited() {
            return this.products.find(p => [9, 10].includes(p.product_id))
        },
        unlimitedValidUntil() {
            if (this.unlimited) {
                return this.$moment(this.unlimited.valid_until).format('YY/MM/DD')
            } else {
                return false
            }
        },
    },
}
</script>

<style scoped lang="scss">
.user-products {
    .recent-info {
        display: grid;
        grid-template-columns: auto auto;
        margin: 12px 0;
        grid-gap: 6px;
    }

    .ticket-info {
        margin: 12px 0;
    }

    .load-more {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .plan-history {
        .toggle-container {
            border-top: 1px solid black;
            padding-top: 12px;
            grid-gap: 6px;
        }
    }
    .product-info {
        color: #ff3d6b;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
    }
    .product-info-unlimited {
        color: black;
        font-size: 18px;
    }
}
</style>
