<template>
    <div class="dropdown flex-row">
        <div v-if="dropdownLabel" @click="opened = !opened" class="dropdown-label flex-fill" ref="dropdown-icon">
            {{ this.$translate(items.find(item => item.selected).title) }}
        </div>
        <i v-if="!hideCaret" @click="opened = !opened" class="zmdi zmdi-caret-down flex-wrap" />
        <div @click="onClickDropdownOverlay" v-show="opened" class="dropdown-overlay">
            <transition name="slide-down">
                <ul ref="ul" v-show="prepared">
                    <li
                        :key="idx"
                        :class="{ selected: item.selected }"
                        @click="onClickItem(idx)"
                        v-for="(item, idx) in displayItems"
                    >
                        {{ itemLabel(item) }}
                        <transition name="fade">
                            <i v-if="item.selected" class="zmdi zmdi-check" />
                        </transition>
                    </li>
                </ul>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Dropdown',
    props: ['titleKey', 'items', 'hideCaret'],
    computed: {
        displayItems() {
            const displayItems = this.items
            displayItems.forEach((item, idx) => {
                this.$set(item, 'selected', item.selected === true)
                if (item.selected === true) {
                    this.selected = idx
                }
            })
            return displayItems
        },
        dropdownLabel() {
            const selected = this.items.find(item => item.selected)
            if (!selected) return

            return this.$translate(selected.title)
        },
    },
    watch: {
        opened(newVal) {
            if (newVal === true) {
                setTimeout(_ => this.initUlPosition())
            } else {
                this.prepared = false
                this.selectedIdx = null
            }
        },
    },
    data: () => ({
        opened: false,
        prepared: false,
        selectedIdx: null,
    }),
    methods: {
        emitDefaultSort() {
            const selectedItems = this.items.filter(item => item.selected)
            if (!selectedItems || selectedItems.length === 0) return

            this.$emit('onChange', selectedItems[0])
        },
        itemLabel(item) {
            return this.$translate(this.titleKey ? item[this.titleKey] : item)
        },
        initUlPosition() {
            const ico = this.$refs['dropdown-icon']
            const ul = this.$refs.ul

            if (ico && ul) {
                const icoBound = ico.getBoundingClientRect()
                ul.getBoundingClientRect()
                ul.style.top = `${icoBound.bottom}px`
                ul.style.right = `${window.innerWidth - icoBound.right}px`
                this.prepared = true
            }
        },
        onClickDropdownOverlay(event) {
            if (event.target.classList.contains('dropdown-overlay')) {
                this.opened = false
            }
        },
        onClickItem(idx) {
            if (this.selectedIdx === idx) return

            this.displayItems.forEach((di, diIdx) => {
                di.selected = idx === diIdx
            })
            const itemToEmit = this.$copy(this.items[idx])
            this.$forceUpdate()
            this.$emit('change', itemToEmit)
            this.selectedIdx = idx
            this.opened = false
        },
    },
}
</script>
