<template>
    <div class="my-ranking card p-relative">
        <Loading :loading="loading" />
        <div class="top">
            <div class="flex-row items-center">
                <div class="title flex-wrap" v-html="$translate('MY_RANKING')" />
                <i ref="info" @click="onClickInfo" class="zmdi zmdi-info-outline flex-wrap m-l-8" />
            </div>
        </div>
        <div class="cards" :class="{ 'flex-row': !$store.getters.isMobile }">
            <div class="my-ranking-card-match flex flex-between" v-if="bigCard">
                <div>
                    <div class="flex-row">
                        <div class="title" v-html="$translate('MY_RANKING_MATCH')" />
                        <div class="badge flex-wrap m-l-8" :class="adaptiveColor" v-html="`상위 ${bigCard.rank}%`" />
                    </div>
                    <div class="value" v-html="`${bigCard.value}`" />
                </div>
                <div>
                    <div class="hr" />
                    <div class="rank-ment" v-html="$translate(adaptiveMent)" />
                </div>
            </div>
            <div class="grid-col">
                <MyRankingCard
                    :title="card.title"
                    :value="card.value"
                    :rank="card.rank"
                    :key="idx"
                    v-for="(card, idx) in smallCards"
                />
            </div>
        </div>
    </div>
</template>

<script>
import MyRankingCard from './MyRankingCard'

export default {
    name: 'MyRanking',
    components: { MyRankingCard },
    data: () => ({
        loading: false,
    }),
    computed: {
        myRanking() {
            return this.$store.getters.myRanking
        },
        cards() {
            if (!this.myRanking) return

            const r = v => Math.round(v)
            return [
                { key: 'match', filter: v => r(v * 10) / 10, postfix: '건' },
                { key: 'user', filter: v => r(v * 10) / 10, postfix: '명' },
                { key: 'source', filter: v => r(v * 10) / 10, postfix: '명' },
                { key: 'refund_ratio', filter: v => r(v * 100), postfix: '%' },
                { key: 'roi_week', filter: v => r(v * 100), postfix: '%' },
            ].map(item => ({
                title: `MY_RANKING_${item.key.toUpperCase()}`,
                value: `${item.filter((this.myRanking[item.key] || {}).value)}${item.postfix}`,
                rank: `${(this.myRanking[item.key] || {}).rank}`,
            }))
        },
        bigCard() {
            if (!this.cards) return

            return this.cards[0]
        },
        smallCards() {
            if (!this.cards) return

            return this.cards.slice(1, 5)
        },
        adaptiveColor() {
            if (!this.bigCard) return

            if (this.bigCard.rank < 30) return 'green'
            if (this.bigCard.rank < 50) return 'orange'
            return 'gray'
        },
        adaptiveMent() {
            if (!this.bigCard) return

            if (this.bigCard.rank < 10) return 'AGENT_MENT_BEST'
            if (this.bigCard.rank < 30) return 'AGENT_MENT_GOOD'
            if (this.bigCard.rank < 50) return 'AGENT_MENT_SOSO'
            return 'AGENT_MENT_POOR'
        },
    },
    async mounted() {
        try {
            this.loading = true
            await this.$store.dispatch('loadMyRanking')
        } catch (e) {}
        this.loading = false
    },
    methods: {
        onClickInfo() {
            const info = this.$refs.info
            const rect = info.getBoundingClientRect()
            const options = {
                title: 'MY_RANKING',
                titleDesc: 'MY_RANKING_DESC_MODAL',
                items: [
                    { key: 'MY_RANKING_MATCHINGS_K', value: 'MY_RANKING_MATCHINGS_V' },
                    { key: 'MY_RANKING_SOURCES_K', value: 'MY_RANKING_SOURCES_V' },
                    { key: 'MY_RANKING_PROFILES_K', value: 'MY_RANKING_PROFILES_V' },
                    { key: 'MY_RANKING_ROI_K', value: 'MY_RANKING_ROI_V' },
                ],
                footer: 'MY_RANKING_FOOTER',
            }

            if (!this.$store.getters.isMobile) {
                options.style = {
                    position: 'absolute',
                    top: `${rect.top}px`,
                    left: `${rect.left + 24}px`,
                }
            }

            this.$modal.custom({
                component: 'ModalHelp',
                options,
            })
        },
    },
}
</script>
