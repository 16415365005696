import Vue from 'vue'
import Router from 'vue-router'
import { store as $store } from '@/store'

import commonRoutes from './common'
import mobileRoutes from './mobile-only'
import signupRoutes from './signup'

Vue.use(Router)

const router = new Router({
    routes: [...commonRoutes, ...mobileRoutes, ...signupRoutes],
})

router.beforeEach(async(to, from, next) => {
    if (!router.options.routes.some(r => r.name === to.name)) return next('/not-found')

    window.onpopstate = () => {
        // If the dialog is opened,
        // just close the dialog and don't move route
        if ($store.getters.modals.length > 0) {
            $store.commit('popModal')
            return next(false)
        }

        if ($store.getters.showNotifications) {
            $store.commit('setShowNotifications', false)
            return next(false)
        }

        const currentCustomBackHandler = Vue.prototype.$backHandlers[to.name]
        if (currentCustomBackHandler) {
            const allowBack = currentCustomBackHandler()
            return next(allowBack ? true : false)
        }

        if (Vue.prototype.$isRootRoute()) return next(false)
    }

    next()
})

router.afterEach(() => {
    Vue.prototype.$nativeBridge.postMessage({
        action: 'setCanGoBack',
        value: !Vue.prototype.$isRootRoute(),
    })
})

export default router
