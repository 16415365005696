<template>
    <div class="friends bg-white" :class="{ 'open-third-tap': showThirdTap }">
        <div class="flex layout">
            <FloatingsV2 v-if="!$store.getters.isMobile" class="flex-wrap" />
            <UsersList
                class="flex-fill"
                :newVersion="!$store.getters.isMobile"
                :filterChanged="showSuperFilter && filterChanged"
                :isSelectedUser="isSelectedUser"
                :selectedTabFriends="$store.getters.selectedTabFriends"
                :filters="chat.$$filters"
            />
        </div>
    </div>
</template>

<script>
import FloatingsV2 from './FloatingsV2'
import UsersList from './UsersList'
import chatService from '@/services/chat'

export default {
    name: 'FriendsPage',
    props: ['filterChanged'],
    components: {
        FloatingsV2,
        UsersList,
    },
    data: () => ({
        enableSpreadAgenting: false,
        enableIntroduceAgenting: false,
        enableDating: false,
        loading: false,
        enableSpreadAgentingTemp: true,
        enableIntroduceAgentingTemp: true,
        showInfo: false,
    }),
    computed: {
        icons() {
            return {
                spread: require('@/assets/images/account_outer.svg'),
                introduce: require('@/assets/images/account_inner.svg'),
                spread_inactive: require('@/assets/images/account_outer_white.svg'),
                introduce_inactive: require('@/assets/images/account_inner_white.svg'),
                info: require('@/assets/images/exclamation.svg'),
            }
        },
        showThirdTap() {
            return this.$store.getters.showFriendsFilter || (this.$store.getters.chat || {}).$$introducingUser
        },
        showSuperFilter() {
            return this.$store.getters.showFriendsFilter && this.$store.getters.selectedTabFriends === 'SUPER_SEARCH'
        },
        chat() {
            return this.$store.getters.chat || {}
        },
        agent() {
            return this.$store.getters.agent || {}
        },
        myPremium() {
            if (this.chat.user.$$premium) {
                const premium = this.$options.filters.hasProduct(this.chat.user, 'premium')
                return this.$store.getters.agent.id === premium.agent_id
            }
            return false
        },
        isAbleRecommend() {
            if (!this.$store.getters.agent) return true
            if (!this.chat.user.$$premium && !this.chat.user.$$privacy) return true
            return this.$store.getters.agent.is_premium && this.chat.user.$$premium && this.myPremium
        },
    },
    watch: {
        chat(newVal, oldVal) {
            if (newVal && oldVal && newVal.id === oldVal.id) return
            this.agentingEnabled()
        },
    },
    mounted() {
        this.init()
        this.agentingEnabled()
        this.$bus.$on('callIntroduceFriend', this.introduceFriend)
        this.$bus.$on('callSpreadFriend', this.spreadFriend)
        this.isShowInfo()
    },
    beforeDestroy() {
        this.$bus.$off('callIntroduceFriend', this.introduceFriend)
        this.$bus.$off('callSpreadFriend', this.spreadFriend)
    },
    methods: {
        async init() {
            if (this.$store.getters.isMobile && !this.$store.getters.chat) {
                this.$router.go(-1)
                return
            }

            try {
                await this.$store.dispatch('loadFriends')
            } catch (e) {}
        },
        isSelectedUser(user) {
            if (!this.$store.getters.chat) return

            return (this.$store.getters.chat.$$introducingUser || {}).id === user.id
        },
        isShowInfo() {
            let show = window.localStorage.getItem('showAutoMatchingInfo')
            this.showInfo = show === null
            show = false
            window.localStorage.setItem('showAutoMatchingInfo', JSON.stringify(show))
        },
        onClickInfo() {
            this.showInfo = !this.showInfo
        },
        async spreadFriend() {
            // if (this.loading) {
            //     return
            // }
            // this.loading = true
            // if (!this.enableSpreadAgenting) {
            //     this.$toast.error(this.$translate('UNABLE_AUTO_AGENTING'))
            //     this.loading = false
            //     return
            // } else if (!this.enableDating) {
            //     // if (!this.enableDating) {
            //     this.$toast.error(this.$translate('UNABLE_AGENTING_USER'))
            //     this.loading = false
            //     return
            // }
            //
            // this.enableSpreadAgentingTemp = false
            // try {
            //     this.$loading(true)
            //     const data = await chatService.spreadSource(this.chat.id)
            //     this.$toast.success(data.msg)
            //     // alert(data.msg)
            //     // this.$emit('close')
            // } catch (e) {
            //     if (!this.$store.getters.isMobile && e.data.key === 'input_autotext') {
            //         this.$router.push({
            //             name: 'SettingsPage',
            //             params: {
            //                 defaultShow: 'spreadMessage',
            //             },
            //         })
            //     }
            //     this.$toast.error(e.data)
            // } finally {
            //     this.loading = false
            //     this.$loading(false)
            // }

            if (!this.isAbleRecommend) {
                this.isAbleRecommendAlert()
                return
            }

            const user = await this.$store.dispatch('loadUserDetail', { userId: this.chat.user.id })
            this.$modal.custom({
                component: 'ModalSpreadProfile',
                options: {
                    user: user,
                    type: 'SUPER_SEARCH',
                },
            })
        },
        async introduceFriend() {
            if (!this.isAbleRecommend) {
                this.isAbleRecommendAlert()
                return
            }

            if (this.loading) {
                return
            }
            this.loading = true
            if (!this.enableIntroduceAgenting) {
                this.$toast.error(this.$translate('UNABLE_AUTO_AGENTING'))
                this.loading = false
                return
            } else if (!this.enableDating) {
                // if (!this.enableDating) {
                this.$toast.error(this.$translate('UNABLE_AGENTING_USER'))
                this.loading = false
                return
            } else if (this.chat.user.gender !== 0) {
                return
            }

            this.enableIntroduceAgentingTemp = false

            try {
                this.$loading(true)
                const data = await chatService.askSource(this.chat.id)
                this.$toast.success(data.msg)
                // alert(data.msg)
            } catch (e) {
                this.$toast.error(e.data)
            } finally {
                this.$loading(false)
                this.loading = false
            }
        },
        async agentingEnabled() {
            if (!this.chat.id) return
            const agentingEnable = await chatService.agentingEnabled(this.chat.id)
            this.enableSpreadAgenting = agentingEnable.spread_enabled
            this.enableIntroduceAgenting = agentingEnable.ask_enabled
            this.enableDating = agentingEnable.dating_enabled
            this.enableSpreadAgentingTemp = true
            this.enableIntroduceAgentingTemp = true
        },
        isAbleRecommendAlert() {
            if (this.chat.user.$$premium) {
                this.$modal.basic({
                    title: 'NOTICE',
                    body: this.$translate('CANNOT_RECOMMEND_PREMIUM_USER'),
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
                // this.$alert(this.$translate('CANNOT_RECOMMEND_PREMIUM_USER'))
                return
            }

            if (this.chat.user.$$privacy) {
                this.$modal.basic({
                    title: 'NOTICE',
                    body: this.$translate('CANNOT_RECOMMEND_PRIVACY_USER'),
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })

                // this.$alert(this.$translate('CANNOT_RECOMMEND_PREMIUM_USER'))
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.hr-4 {
    height: 4px !important;
    background-color: $grey-02;
}
.auto-matching-info {
    display: flex;
    width: 255px;
    height: 50px;
    padding: 8px 15px 8px 10px;
    border-radius: 8px;
    color: white;
    font-size: 11px;
    background-color: $grey-09;
    .info-text {
    }
}
</style>
