<template>
    <div class="user" :class="{ selected: isSelectedUser && isSelectedUser(user) }" @click="onClickUser">
        <div class="flex-row">
            <div class="photo-wrapper flex-wrap">
                <PhotoAndLastSeen
                    :profile="user.photo_url"
                    :lastSignInAt="user.last_sign_in_at"
                    :userId="user.id"
                    :preventModalImages="justAsSelector"
                />
            </div>
            <div class="p-t-4 flex-fill">
                <div class="info">
                    <div class="upper flex-row m-b-4">
                        <div class="flex-row items-center">
                            <div class="name flex-wrap m-r-4">{{ user.name }}</div>
                            <span class="basic">
                                <span>({{ user.profile.birthday | asAge }}세</span>
                                <span class="divider" v-html="'ㅣ'" />
                                <span>{{ user.profile.height }}cm)</span>
                            </span>
                        </div>
                    </div>
                    <div class="flex-row">
                        <div class="region flex-wrap m-r-4">{{ user.profile.region_name }}</div>
                        <Distance :distance="user.$$distance" />
                    </div>

                    <div class="job flex-row">
                        <div class="flex-wrap" v-html="user.profile.job_name" />
                        <div class="divider flex-wrap" v-html="'ㅣ'" />
                        <div class="flex-wrap" v-if="company" v-html="company" />
                    </div>
                </div>
            </div>
            <div
                v-if="justAsSelector"
                class="flex-wrap fake-clicking-area"
                @click.stop="user.$$selected = !user.$$selected"
            >
                <Checkbox class="flex-wrap" v-model="user.$$selected" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'User',
    props: ['user', 'justAsSelector', 'isSelectedUser', 'isAIRecommendation'],
    methods: {
        async onClickUser(event) {
            if (!this.justAsSelector) {
                try {
                    await this.$store.dispatch('loadIntroducingUser', this.user.id)
                    if (this.isAIRecommendation) {
                        this.$set(this.$store.getters.chat.$$introducingUser, '$$AIRecommendUser', true)
                    }
                    if (this.$store.getters.isMobile) {
                        this.$router.push({
                            name: 'UserDetailPage',
                            params: {
                                userId: this.user.id,
                                from: 'User',
                            },
                        })
                    }
                } catch (e) {
                    this.$toast.error(e.data)
                }
            } else {
                this.$emit('onClickUser', this.user)
            }
            // this.$store.commit('setShowFriendsFilter', false)
        },
    },
    computed: {
        company() {
            return this.$options.filters.company(this.user.profile)
        },
        chat() {
            return this.$store.getters.chat || {}
        },
        introducingUser() {
            return this.chat.$$introducingUser || {}
        },
    },
}
</script>
