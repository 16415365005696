<template>
    <div class="gift-item">
        <div class="item m-b-8">
            <img class="item-image" :class="{ dimmed: status !== 'received' }" :src="product.photo_url" />
            <div v-if="status !== 'received'" class="dimmed-overlay"></div>
            <div class="user">
                <img class="user-image" :src="user.photo_url" @error="src = $blankProfile" />
                <div class="user-info">
                    <div v-if="user.name" class="name-nickname">
                        <span class="name f-bold" v-html="user.name" />
                        <span class="nickname f-medium">({{ user.nickname }})</span>
                    </div>
                    <div v-else class="name-nickname">
                        <span class="name f-bold" v-html="user.nickname" />
                    </div>
                    <div class="date" v-html="receivedDate" />
                </div>
            </div>
            <Checkbox v-if="status === 'received'" v-model="gift.checked" />
            <div v-else class="complete-badge">
                <div class="text-wrapper f-bold">
                    {{ completeBadgeText }}
                </div>
            </div>
        </div>
        <div class="item-info f-medium">
            <span class="description">{{ product.description }}</span>
            <span class="name">{{ product.name }}</span>
            <span v-if="status === 'received'" class="before-request">발송 신청 전</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GiftItem',
    props: ['gift'],
    computed: {
        receivedDate() {
            if (!this.gift.created_at) return ''

            return `받은 날짜 ${this.$options.filters.formatDate(this.gift.created_at, 'YY.MM.DD')}`
        },
        user() {
            return this.gift.user
        },
        product() {
            return this.gift.product
        },
        status() {
            return this.gift.status
        },
        completeBadgeText() {
            if (this.status === 'requested') return '신청 완료'
            else if (this.status === 'confirmed') return '발송 완료'

            return ''
        },
    },
}
</script>

<style lang="scss" scoped>
.gift-item {
    width: 160px;

    .item {
        position: relative;
        width: 100%;
        height: 160px;
        border: solid 1px #f1f2f4;
        border-radius: 12px;

        .item-image {
            width: 100%;
            height: 100%;
            border-radius: 12px;

            &.dimmed {
                opacity: 0.5;
            }
        }

        .dimmed-overlay {
            position: absolute;
            top: 0;
            border-radius: 12px;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
        }

        .complete-badge {
            position: absolute;
            width: 72px;
            height: 72px;
            top: 8px;
            right: 8px;
            border: solid 3px white;
            border-radius: 44px;
            display: flex;
            align-items: center;
            justify-content: center;

            .text-wrapper {
                width: 60px;
                height: 60px;
                border: solid 1px white;
                border-radius: 44px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
                font-size: 12px;
                transform: rotate(-20deg);
            }
        }

        .checkbox {
            position: absolute;
            top: 8px;
            right: 8px;
        }

        .user {
            position: absolute;
            bottom: 0;
            height: 72px;
            width: 100%;
            padding: 6px;
            display: flex;
            align-items: flex-end;
            background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5) 98%);
            border-radius: 0 0 12px 12px;

            &-image {
                width: 36px;
                height: 36px;
                border-radius: 40%;
                margin-right: 8px;
            }

            .user-info {
                color: white;
                font-size: 12px;

                .name {
                    margin-right: 2px;
                }
                .date,
                .nickname {
                    font-size: 11px;
                }
            }
        }
    }

    .item-info {
        display: flex;
        flex-direction: column;

        .description {
            margin-bottom: 2px;
            font-size: 11px;
            color: #949498;
        }

        .name {
            margin-bottom: 8px;
            font-size: 14px;
            color: black;
        }

        .before-request {
            font-size: 12px;
            color: #8a74ff;
        }
    }
}
</style>
