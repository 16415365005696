import * as $http from 'axios'

export default {
    all: params => $http.get('v2/chats', { params }),
    detail: id => $http.get(`v2/chats/${id}`),
    simple: () => $http.get('v2/chats/simple'),
    recommends: (chatId, excludedUserIds) => {
        let defaultEndpoint = `v2/chats/${chatId}/recommended`
        if (excludedUserIds && excludedUserIds.length > 0) {
            defaultEndpoint += `?excluded_ids=${excludedUserIds.join(',')}`
        }

        return $http.get(defaultEndpoint)
    },
    recommendsFromDB: (chatId, filter) => $http.post(`v2/chats/${chatId}/recommended_filter`, filter),
    saveFilter: (chatId, filter) => $http.put(`v2/chats/${chatId}/filter`, { filter }),
    messages: (chatId, firstId) => $http.get(`v2/chats/${chatId}/messages?first_id=${firstId}`),
    requestContact: chatId => $http.post(`v2/chats/${chatId}/messages/request_contact`),
    // payload: { contact: { sc_id }} // 회원 => 회원
    requestDating: (chatId, payload) => $http.post(`v2/chats/${chatId}/messages/request_dating`, payload),
    // payload: { user_id: 대화 상대 (chatUser), source_id: 소개할 사람 (introducingUser) }
    checkPhotoData: payload => $http.post('chats/check_photodata', payload),
    photoHistories: chatId => $http.get(`v2/chats/${chatId}/photo_histories`),
    // payload: { messages: [...Message], user_ids: [] }
    // spreadProfile: (chatId, payload) => $http.post(`v2/chats/${chatId}/spread_profile`, payload),
    // payload: { photo_url_path }
    sendLinkedPhotoMessage: (chatId, payload) => $http.post(`v2/chats/${chatId}/send_photo_linked`, payload),
    sendPhotoMessage: (chatId, data) => $http.post(`v2/chats/${chatId}/send_photo`, data),
    vbRecommend: (chatId, userIds, limit) =>
        $http.post(`v2/chats/${chatId}/vb_recommend`, { user_ids: userIds, limit }),
    stat: chatId => $http.get(`v2/chats/${chatId}/stat`),
    spreadEnabled: chatId => $http.get(`v2/chats/${chatId}/spread_enabled`),
    spreadAll: () => $http.post(`v2/chats/spread_all`), // 한번에 제안 뿌리기
    agentingEnabled: chatId => $http.get(`v2/chats/${chatId}/agenting_enabled`), // 1회 확인
    spreadSource: chatId => $http.post(`v2/chats/${chatId}/spread_source`), // 제안뿌리기
    askSource: chatId => $http.post(`v2/chats/${chatId}/ask_source`), // 소개하기
    askSourceAll: payload => $http.post(`v2/chats/ask_source_all`, payload), // 소개하기
    update: (chatId, payload) => $http.put(`v2/chats/${chatId}`, payload),
    delete: chatId => $http.delete(`v2/chats/${chatId}`),
    startPremium: (chatId, messageId) => $http.put(`v2/chats/${chatId}/messages/${messageId}/start_premium`),
    updateContent: (chatId, messageId, payload) =>
        $http.put(`v2/chats/${chatId}/messages/${messageId}/update_content`, payload),
    sendSalesTemplate: (chatId, payload) => $http.post(`v2/chats/${chatId}/send_sales_template`, payload),
    referral: chatId => $http.post(`v2/chats/${chatId}/referral`),
    // isSurprised: payload => $http.put(`v2/chats/is_surprised`, payload),
    checkPhotoMessage: params => $http.get(`v2/chats/check_photo_message`, { params }),

    // getAiTarget: params => $http.get(`v2/ai_judging_targets/get_ai_target`, { params }),

    // 만남피드백 리스트
    getFeedbackTarget: chatId => $http.get(`v2/chats/${chatId}/get_feedback_target`),
    getUserFeedbacks: chatId => $http.get(`v2/chats/${chatId}/get_user_feedbacks`),

    aiJudgingResults: payload => $http.post(`v2/ai_judging_results`, payload),
    sendSelfProfile: chatId => $http.post(`v2/chats/${chatId}/send_self_profile`),

    onboardSms: chatId => $http.post(`v2/chats/${chatId}/onboard_sms`),
    makeInstantChat: payload => $http.post('v2/chats/make_instant_chat', payload),
}
