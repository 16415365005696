<template>
    <div class="detail" :class="isInstantChat ? 'is-instant-chat' : ''">
        <h3 class="h3">
            <i class="material-icons">people</i>
            가족관계
        </h3>
        <div class="item" v-for="item in familyProfile" :key="item.key">
            <div class="key" v-html="item.key" />
            <div class="value">
                <div>
                    <div class="family_value" v-if="item.value_range">
                        {{ item.value_range }}
                    </div>
                    <div class="family_value">
                        {{ item.value }}
                        <span v-if="item.selective === 0 || item.selective === false" class="badge-manager-only"
                            >매니저만</span
                        >
                    </div>
                </div>
            </div>
        </div>
        <div class="item-container" v-for="(item, idx) in profile.family" :key="idx">
            <span class="title-badge-purple">{{ getFamilyType(item.family_type) }}</span>
            <div class="item" v-if="getLiveStatus(item)">
                <div class="key">생사여부</div>
                <div class="value">
                    <div class="family_value">{{ getLiveStatus(item) }}</div>
                    <span v-if="!item.show_live_status" class="badge-manager-only">매니저만</span>
                </div>
            </div>
            <div class="item">
                <div class="key">최종학력</div>
                <div class="value">
                    <div class="family_value">
                        {{ getEducationStatus(item.education_status) }} - {{ item.university_name }}
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="key">직장/직업</div>
                <div class="value">
                    <span class="seperator-slash">{{ item.company_name }}</span>
                    <span class="seperator-slash"
                        >{{ item.job_name }}({{ isCurrent(item.job_status) }})
                        <span v-if="!item.show_job_name" class="badge-manager-only">매니저만</span>
                    </span>
                </div>
            </div>
        </div>
        <div class="item">
            <div class="key" v-html="`가족소개`" />
            <div class="value">
                <div class="family_value">
                    {{ this.profile.family_intro }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// 가족 비공개 사항 : 직업 , 생사여부
export default {
    name: 'Family',
    props: ['profile'],
    data: () => ({
        isInstantChat: false,
    }),
    methods: {},
    computed: {
        isCurrent() {
            return status => {
                return status ? '현직' : '전직'
            }
        },
        getFamilyType() {
            return type => {
                let result = ''
                switch (type) {
                    case 0:
                        result = '아버지'
                        break
                    case 1:
                        result = '어머니'
                        break
                    default:
                        result = '형제'
                        break
                }
                return result
            }
        },
        getEducationStatus() {
            return status => {
                let content = ''
                switch (status) {
                    case 0:
                        content = '초등학교 졸업'
                        break
                    case 1:
                        content = '중학교 졸업'
                        break
                    case 2:
                        content = '고등학교 졸업'
                        break
                    case 3:
                        content = '대학교 졸업'
                        break
                    case 4:
                        content = '대학교 석사 졸업'
                        break
                    case 5:
                        content = '대학교 박사 졸업'
                        break
                }
                return content
            }
        },
        getLiveStatus() {
            return item => {
                if (item.family_type === 2) return false
                return item.live_status ? '살아계심' : '작고'
            }
        },
        familyProfile() {
            if (!(this.profile && this.profile.privacy_setting)) return []

            const { brother_count: bro, sister_count: sis, order_of_children: ooc } = this.profile
            const familyRelation = (bro || sis) && ooc

            const items = [
                {
                    id: 1,
                    key: '본가지역',
                    value: this.profile.hometown_region_name,
                },
                {
                    id: 2,
                    key: '형제관계',
                    value: familyRelation ? `${bro}남 ${sis}녀 중 ${ooc}째` : false,
                },
                {
                    id: 3,
                    key: '부모님관계',
                    value: this.getParentStatus(this.profile.parent_status),
                    selective: false,
                },
                {
                    id: 4,
                    key: '집안자산',
                    value: this.profile.family_asset ? this.$krwParsed(this.profile.family_asset) : '자산없음',
                    value_range:
                        this.profile.privacy_setting.show_family_asset === 2
                            ? !this.profile.family_asset
                                ? '자산없음'
                                : this.$profile.assetRangeParsed(this.profile.family_asset)
                            : null,
                    selective: this.profile.privacy_setting.show_family_asset,
                },
            ]
            return items.filter(item => item.value)
        },
        getParentStatus() {
            return status => {
                let result = ''
                switch (status) {
                    case 0:
                        result = '함께 거주'
                        break
                    case 1:
                        result = '별거'
                        break
                    case 2:
                        result = '이혼'
                        break
                    case 3:
                        result = '사별'
                        break
                }
                return result
            }
        },
    },
}
</script>

<style scoped lang="scss">
.h3 {
    color: #646468;
}
.key {
    color: #646468;
}
.seperator-slash:not(:last-child)::after {
    content: '/';
    margin: 0 2px;
}
.instant-chat {
    .h3 {
        color: #fff;
    }
    .key {
        color: #fff;
    }
    .family_value {
        color: #fff;
    }
    .seperator-slash {
        color: #fff;
    }
}
.before-confirm {
    .h3 {
        color: #fff;
    }
}
</style>
