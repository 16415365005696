<template>
    <div class="chat-item-mobile" @click="onClickChatItem">
        <div class="content flex-row center">
            <PhotoAndLastSeen
                class="flex-wrap m-r-12"
                :class="[genderClass]"
                :chat="chat"
                :profile="chat.user.photo_url"
                :userId="chat.user.id"
                :customHandler="onClickChatItemProfileImage"
                :showBookmark="true"
            />
            <div class="flex-row p-t-4">
                <div class="info">
                    <div class="flex-row">
                        <div class="name lines-1 flex-wrap">{{ $nameOrNick(chat.user) }}</div>
                        <div v-if="chat.user.id" class="status flex-row items-center flex-wrap">
                            <div v-if="instantChat" class="source" v-html="`${instantSourceCnt}명 소개 중`" />
                            <template v-else>
                                <div v-if="newbie" class="badge badge-brd red m-r-4" v-html="$translate('NEWBIE')" />
                            </template>
                            <LastSeen :timestamp="(chat.user || {}).last_sign_in_at" />
                        </div>
                    </div>
                    <div v-if="chat.user.id" class="age-region mobile hor-separated">
                        <span>({{ chat.user.profile.birthday | asAge }}세</span>
                        <span>{{ chat.user.profile.region_name | noDetail }})</span>
                    </div>
                    <div class="last-message mobile" :class="isAlimi ? 'lines-2' : 'lines-1'" v-html="lastMessage" />
                </div>
                <div class="right">
                    <div class="time-unread">
                        <div class="timestamp flex-wrap" :class="{ 'o-0': lastMessageAt === 'NONE' }">
                            {{ lastMessageAt }}
                        </div>
                    </div>
                    <div
                        v-if="!instantChat"
                        class="badge-like-count mobile"
                        :class="{ 'o-0': chat.likes_count === undefined || chat.likes_count === 0 }"
                    >
                        N호감 {{ chat.likes_count }}
                    </div>
                    <div
                        class="badge badge-count mobile"
                        :class="{ 'o-0': chat.unread === 0, instant: instantChat, premium: isPremiumChat }"
                    >
                        {{ chat.unread }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['chat'],
    data: () => ({
        contextmenu: null,
    }),
    methods: {
        async onClickChatItem() {
            const dom = document.getElementsByClassName('chat-input-textarea')[0]
            if (dom && dom.value) {
                this.$store.commit('saveTextareaInCurrentChat', dom.value)
            }

            await this.$store.dispatch('loadChat', {
                userId: this.chat.user.id,
            })

            this.chat.likes_count = 0
        },
        async onClickChatItemProfileImage() {
            this.$router.push({
                name: 'UserDetailPage',
                params: {
                    userId: this.chat.user.id,
                },
            })
        },
    },
    computed: {
        isAlimi() {
            return !this.chat.user.id
        },
        genderClass() {
            if (this.isAlimi) return

            return this.$options.filters.gender(this.chat.user.gender)
        },
        newbie() {
            return this.$options.filters.isNewbie(this.chat.user)
        },
        lastMessage() {
            const lastMessage = this.chat.last_message
            if (!lastMessage) return

            switch (lastMessage.mtype) {
                case 'text':
                case 'premium-counsel':
                    return lastMessage.content
                case 'photo':
                    return this.$translate('PHOTO')
                case 'gift-info': {
                    const user = (this.$mustParse(lastMessage.content) || {}).user
                    return this.$nameOrNick(user) + '님이 ' + (this.$mustParse(lastMessage.content) || {}).msg
                }
                default:
                    return (this.$mustParse(lastMessage.content) || {}).msg
            }
        },
        lastMessageAt() {
            const result =
                this.$options.filters.formatDate((this.chat.last_message || {}).created_at, 'chatList') || 'NONE'
            return result
        },
        instantChat() {
            return this.chat.expire_at
        },
        instantSourceCnt() {
            return this.chat.suggest_count || 0
        },
        isPremiumChat() {
            return this.chat.user.$$premium
        },
    },
}
</script>
