<template>
    <div class="tabs" :class="{'short-underbar': shortUnderbar}">
        <div
            @click="onClickTab(tab, idx)"
            class="tab"
            :class="{'selected': tab.selected, 'flex-fill': !shortUnderbar, 'flex-wrap': shortUnderbar}"
            :key="idx"
            v-for="(tab, idx) in tabs">
            <div class="flex-row items-center">
                <span class="title flex-wrap" v-html="$translate(tab.title)"/>
                <span
                    v-show="tab.count"
                    class="badge badge-count flex-wrap"
                    v-html="tab.count"/>
            </div>
            <div v-if="shortUnderbar && tab.selected" class="hr flex-wrap"/>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Tabs',
    props: ['tabs', 'shortUnderbar'],
    methods: {
        onClickTab(tab, selectedIdx) {
            this.tabs.forEach((tab, idx) => this.$set(tab, 'selected', idx === selectedIdx))
            this.$emit('update:tabs', this.tabs)
            this.$emit('onClickTab', { tab, index: selectedIdx })
        },
    },
}
</script>