<template>
    <div class="signup nickname">
        <div class="page-header">
            <div class="title">닉네임</div>
        </div>
        <div class="body">
            <div class="item-desc"><span class="f-bold c-primary">한글 5자 이내</span>로 입력해주세요</div>
            <div class="input-wrapper nickname">
                <input
                    ref="nickname"
                    class="flex-fill"
                    placeholder="한글 5자 이내"
                    v-model="nickname"
                    @keydown="onKeydown"
                />
                <i v-if="nickname" class="material-icons flex-wrap" @click="nickname = ''">cancel</i>
            </div>
            <div class="ok" v-if="nicknameOk">
                <i class="material-icons c-primary">check_circle</i>
                {{ nicknameOk }}
            </div>
            <div class="error" v-if="error">
                <i class="material-icons">error_outline</i>
                {{ error }}
            </div>
        </div>
        <button
            class="btn btn-primary"
            :class="{ disabled: disabled }"
            @click="next"
            v-html="$translate('NEXT')"
        ></button>
    </div>
</template>

<script>
import userService from '@/services/user'
import debounce from '@/modules/debounce'

export default {
    name: 'NicknamePage',
    data: () => ({
        nicknameOk: null,
        error: null,
        disabled: true,
        nickname: '',
    }),
    mounted() {
        this.nickname = this.$store.getters.payloads.signupData.nickname
        this.$refs.nickname.focus()
        this.checkNickname()
    },
    methods: {
        onKeydown: debounce(function () {
            this.nickname = this.$refs.nickname.value
            this.checkNickname()
        }, 200),
        async checkNickname() {
            if (!this.nickname) {
                this.error = null
                this.nicknameOk = null
                this.disabled = true
                return
            }

            try {
                const temp = this.nickname
                const res = await userService.checkNickname(temp)
                this.error = res.result ? null : res.msg
                this.nicknameOk = res.result ? '사용 가능한 닉네임입니다 :)' : null
                this.disabled = this.nicknameOk ? false : true
                if (res.result) {
                    this.$store.commit('setPayloads', {
                        signupData: {
                            nickname: temp,
                        },
                    })
                }
            } catch (e) {}
        },
        next() {
            if (this.error || this.disabled) return

            this.$router.push({ name: 'StatePage' })
        },
    },
}
</script>
