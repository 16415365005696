import * as $http from 'axios'

export default {
    all: () => $http.get('v2/auto_texts'),
    allCategory: () => $http.get('v2/auto_texts/all_category'),
    post: autoText => $http.post('v2/auto_texts', autoText),
    put: autoText => $http.put(`v2/auto_texts/${autoText.id}`, autoText),
    delete: autoTextId => $http.delete(`v2/auto_texts/${autoTextId}`),
    deleteAll: category =>
        $http.delete('v2/auto_texts/delete_all', {
            params: {
                category,
            },
        }),
    deleteSalesTemplate: payload => $http.delete('v2/auto_texts/delete_sales_template', payload),
    sendOnceReply: payload => $http.post('v2/auto_texts/send_once_reply', payload),
}
