<template>
    <div class="login">
        <div class="entrance">
            <img class="logo" src="@/assets/images/login_logo.png" @click="increaseCounter" />
            <div class="title">{{ 'FOR_PRO_AGENT' | translate }}</div>
            <form>
                <div class="form-control">
                    <input
                        autocomplete="name"
                        name="email"
                        type="email"
                        :placeholder="$translate('PLACEHOLDER_EMAIL')"
                        v-model="email"
                        @keydown.enter="login"
                    />
                </div>
                <div class="form-control m-b-40">
                    <input
                        autocomplete="name"
                        name="password"
                        type="password"
                        :placeholder="$translate('PLACEHOLDER_PASSWORD')"
                        v-model="password"
                        @keydown.enter="login"
                    />
                </div>
                <button
                    v-if="$store.getters.isMobile"
                    type="button"
                    ref="signupButton"
                    class="btn btn-primary m-b-16"
                    @click="signup"
                >
                    {{ 'SIGNUP' | translate }}
                </button>
                <button
                    type="button"
                    ref="loginButton"
                    class="btn btn-primary"
                    :class="shouldDisableLogin"
                    @click="login"
                >
                    {{ 'LOGIN' | translate }}
                </button>
            </form>
        </div>

        <div class="footer" @click="increaseLoginCounter">
            <div class="content">
                <div class="company">(주) 바닐라브릿지</div>
                <div class="info">
                    <div class="adaptive">
                        <span>서울특별시 강남구 역삼동 645-18, 4층 우측</span>
                        <span>대표 : 최성만</span>
                        <span>사업자등록번호 : 165-86-00709</span>
                    </div>
                    <div>대표번호 : 070-5176-7766 (10:00~19:00 / 점심시간 13:00~14:00)</div>
                </div>
                <div class="copyright">© 2020. Vanillabridge. All rights reserved.</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LoginPage',
    data: () => ({
        email: null,
        password: null,
        counter: 0,
        counterInterv: null,
        loginCounter: 0,
    }),
    watch: {
        counter(newVal) {
            if (newVal !== 5) return

            this.$modal.custom({
                component: 'ModalSelectEnv',
                options: {
                    preventCloseOnMousedownMask: true,
                },
            })
        },
        loginCounter(newVal) {
            if (newVal !== 5) return

            this.$router.push({ name: 'VerificationPage' })
        },
    },
    mounted() {
        this.watchCounter()
    },
    beforeDestroy() {
        if (this.counterInterv) {
            clearInterval(this.counterInterv)
            this.counterInterv = null
        }
    },
    computed: {
        shouldDisableLogin() {
            return !this.email || !this.password ? 'disabled' : null
        },
    },
    methods: {
        watchCounter() {
            this.counterInterv = setInterval(() => {
                if (this.counter > 0) this.counter -= 1
                if (this.loginCounter > 0) this.loginCounter -= 1
            }, 1000)
        },
        increaseCounter() {
            if (this.counter < 5) this.counter++
        },
        increaseLoginCounter() {
            if (this.loginCounter < 5) this.loginCounter++
        },
        async login() {
            const loginButton = this.$refs.loginButton

            if (this.shouldDisableLogin) {
                this.$animate.shake(loginButton)
                return
            }

            try {
                await this.$store.dispatch('signIn', {
                    email: this.email,
                    password: this.password,
                })
            } catch (e) {
                this.$animate.shake(loginButton)
                const errMsg = typeof e === 'string' ? e : e.data.msg
                if (!errMsg) return

                this.$toast.error(errMsg)
            }
        },
        signup() {
            // 가입하기 버튼 클릭 시 동작
            this.$modal
                .basic({
                    title: '꼭 확인해주세요',
                    body: '1. 주선자로 활동하려면 카카오톡 플러스친구 바닐라브릿지_주선자로 문의해주세요!<br>2. 회원으로 가입하려면 아래 버튼을 눌러주세요!',
                    buttons: [
                        {
                            label: '회원으로 가입하기',
                            class: 'btn-primary',
                        },
                    ],
                })
                .then(async idx => {
                    if (idx === 0) {
                        this.$nativeBridge.postMessage({
                            action: 'openInAppBrowser',
                            value: 'https://abr.ge/h5p8s',
                        })
                    }
                })
            // this.$router.push({ name: 'VerificationPage' })
            // this.$router.push({name: 'Consent'})
        },
    },
}
</script>
