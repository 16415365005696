<template>
    <div class="textarea-with-auto-texts">
        <div class="textarea-wrapper flex" :class="{ focus: textareaFocused }">
            <textarea
                class="textarea"
                ref="message"
                @focus="textareaFocused = true"
                @blur="textareaFocused = false"
                :placeholder="$translate(placeholder || 'RECOMMEND_PLACEHOLDER')"
                @keydown="onKeydown"
                @keypress.exact.enter.prevent="continueEnter ? $emit('onEnter', message) : null"
                v-model="message"
            />
            <i v-show="message" @click="message = null" class="zmdi zmdi-close-circle" />
            <div class="bottom-functions" v-show="!hideAutotexts">
                <img
                    @click="onClickIcon('auto-texts')"
                    class="chat-input-function-icon cursor-pointer"
                    @mouseover="overAutoTexts = true"
                    @mouseleave="overAutoTexts = false"
                    :src="autoTextSrc"
                />
                <img
                    @click="onClickIcon('emoji')"
                    class="chat-input-function-icon cursor-pointer m-l-8"
                    @mouseover="overEmoji = true"
                    @mouseleave="overEmoji = false"
                    :src="emojiSrc"
                />
            </div>
        </div>
        <transition name="slide-down">
            <AutoTexts
                v-if="showAutoTexts"
                :shortcut="shortcut"
                @close="showAutoTexts = false"
                @onAutoText="onAutoText"
            />
        </transition>
        <transition name="slide-down">
            <Emoji v-if="showEmoji" @onClickEmoji="onClickEmoji" @close="showEmoji = false" />
        </transition>
    </div>
</template>

<script>
export default {
    name: 'TextareaWithAutoTexts',
    // continueEnter : enter 입력시 입력한 텍스트 상위로 넘기기 여부 판단하는 param
    props: ['placeholder', 'value', 'hideAutotexts', 'continueEnter'],
    data: () => ({
        showAutoTexts: false,
        overAutoTexts: false,
        textareaFocused: false,
        shortcut: null,
        isInit: true,
        message: null,
        showEmoji: false,
        overEmoji: false,
    }),
    computed: {
        emojiSrc() {
            if (this.showEmoji) {
                return require('@/assets/images/emoji_active.svg')
            } else if (this.overEmoji) {
                return require('@/assets/images/emoji_hover.svg')
            } else {
                return require('@/assets/images/emoji_inactive.svg')
            }
        },
        autoTextSrc() {
            if (this.showAutoTexts) {
                return require('@/assets/images/auto_texts_active.svg')
            } else if (this.overAutoTexts) {
                return require('@/assets/images/auto_texts_hover.svg')
            } else {
                return require('@/assets/images/auto_texts_inactive.svg')
            }
        },
    },
    watch: {
        value(newVal) {
            this.message = newVal
        },
        message(newVal) {
            if (!this.isInit) {
                this.$emit('changedText')
            } else {
                this.isInit = false
            }

            this.$emit('input', newVal)

            if (!newVal) {
                this.showAutoTexts = false
                this.shortcut = null
                return
            }

            const splitted = (newVal || '').split('/')
            if (splitted.length > 1) {
                this.shortcut = splitted[1]
                this.showAutoTexts = true
            } else {
                setTimeout(_ => {
                    this.showAutoTexts = false
                }, 200)
            }
        },
    },
    methods: {
        onAutoText(autoText) {
            if (this.message) {
                if (this.message.includes('/')) {
                    this.message = this.message.replace(`/${this.shortcut}`, autoText.content)
                } else {
                    this.message += autoText.content
                }
                return
            }
            this.message = autoText.content

            this.$nextTick(_ => {
                const dom = this.$refs.message
                if (dom) dom.focus()
            })
        },
        onClickEmoji(value) {
            this.showEmoji = false
            if (!this.message) this.message = ''
            this.message += value

            const dom = this.$refs.message
            if (dom) dom.focus()
        },
        onKeydown() {
            setTimeout(() => {
                const dom = this.$refs.message
                if (!dom) return

                this.message = dom.value
            })
        },
        onClickIcon(foo) {
            if (foo === 'auto-texts') {
                this.shortcut = null
                this.showAutoTexts = !this.showAutoTexts
                this.showEmoji = false
            }
            if (foo === 'emoji') {
                this.showAutoTexts = false
                this.showEmoji = !this.showEmoji
            }
        },
    },
}
</script>
