import * as $http from 'axios'

export default {
    detail: userId => $http.get(`v2/users/${userId}`),
    me: () => $http.get('v2/users/me'),
    chunkedNotifications: offset => $http.get(`users/chunked_notifications?offset=${offset}`),
    banNoOnsCampaign: userId => $http.put(`v2/users/${userId}/no_ons_campaign`),
    registerDevice: payload => $http.post('users/register_device', payload),
    badges: userId => $http.get(`v2/users/${userId}/badge`),
    deleteToken: registrationId => $http.post('users/logout_delete_token', { registration_id: registrationId }),
    findChat: userId => $http.get(`v2/users/${userId}/find_chats`),
    rating: userId => $http.get(`v2/users/${userId}/user_ratings`),
    // 주선자가 유저 appearance_rate 평가 시, request_type = 1을 준다.
    updateAppearanceRating: (userId, requestType, lookScoreAdmin) =>
        $http.post(`v2/users/${userId}/user_ratings`, { request_type: requestType, appearance_grade: lookScoreAdmin }),
    checkNickname: nickname => $http.get(`v2/users/check_nickname?nickname=${nickname}`),
    checkEmail: email => $http.get(`v2/users/check_email?email=${email}`),
    create: payload => $http.post('v2/users', payload),
    reportUser: {
        create: (userId, payload) => $http.post(`v2/users/${userId}/report_users`, payload),
        update: (userId, reportUserId, payload) =>
            $http.put(`v2/users/${userId}/report_users/${reportUserId}`, payload),
    },
    acceptedHistory: userId => $http.get(`v2/users/${userId}/accepted_history`),
    profileUpdateRequest: (id, params) => $http.put(`v2/users/${id}/profile_update_request`, params),
    getProfileUpdateRequest: userId => $http.get(`v2/users/me/profile_update_requests/get_pur?chat_user_id=${userId}`),
    updateIntroducingDay: (id, params) => $http.put(`v2/users/${id}/update_introducing_day`, params),
    dropoutUser: userId => $http.put(`v2/users/${userId}/dropout_user`),
    dormantUser: userId => $http.put(`v2/users/${userId}/dormant_user`),
    profileConfirm: (userId, status, managerId) =>
        $http.put(`v2/users/me/profiles/pass_profile_judging`, {
            chat_user_id: Number(userId),
            status: status,
            my_manager: Number(managerId),
        }),
    passFailJudgment: payload => $http.put(`v2/users/me/profiles/pass_fail_judgment`, payload),
    updateCompanyShowMethod: payload => $http.put('v2/users/update_company_show_method', payload),
    universities: ({ schoolTypeId, name }) => $http.get(`v2/universities?school_type_id=${schoolTypeId}&name=${name}`),
    updateOnboardStatus: payload => $http.put('v2/users/update_onboard_status', payload),

    // verification_badge
    getVerificationBadge: userId => {
        if (!userId) return Promise.reject()

        return $http.get(`v2/user_verification_badges?user_id=${userId}`)
    },
}
