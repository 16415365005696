<template>
    <div class="chats-mobile p-relative" :class="{ disconnected: !connected }">
        <!-- <Disconnected v-if="!loading" /> -->
        <Loading :loading="loading" />
        <Scroller :direction="'up'" :dom="verticalList" :hideAtEdge="true" />

        <div class="flex layout">
            <div class="top flex-wrap">
                <div class="flex-row items-center flex-between">
                    <div class="title flex-fill">
                        <span v-html="$translate('CHATTING')" />
                    </div>
                    <!--                    <BadgeActionPoint />-->
                </div>
            </div>

            <div class="requirement flex-wrap">
                <div class="require-item">
                    <div>
                        <span class="content-user">담당 회원</span
                        ><span class="content-user-count m-l-4" v-html="`${testStat.my_user || 0}명`" />
                    </div>
                    <div class="m-t-4">
                        <span class="content-dating">주선률</span
                        ><span class="content-dating-rate m-l-4" v-html="`${testStat.my_user_rate || 0}%`" />
                    </div>
                </div>
                <div class="line"></div>
                <div class="require-item">
                    <div>
                        <span class="content-user">신규 회원</span
                        ><span class="content-user-count m-l-4" v-html="`${testStat.new_user || 0}명`" />
                    </div>
                    <div class="m-t-4">
                        <span class="content-dating">주선률</span
                        ><span class="content-dating-rate m-l-4" v-html="`${testStat.new_user_rate || 0}%`" />
                    </div>
                </div>
            </div>

            <div class="agent-status-btns flex-row flex-wrap">
                <li class="status-btn" v-for="key in Object.keys(agentStatus)" :key="key" @click="toggle(key)">
                    <label v-html="$translate(key.toUpperCase())" />
                    <Toggler v-model="agentStatus[key]" class="no-touch" />
                </li>
            </div>

            <Tabs class="flex-wrap" :tabs="tabs" @onClickTab="onClickTab" />

            <div class="search-and-matching-chance flex-row items-center flex-wrap p-relative">
                <SearchBox class="flex-fill" :initialValue="chatSearchValue" @onKeydown="onSearch" />
                <span class="divider flex-wrap m-l-8" v-html="'|'" />
                <FilterIcon
                    class="flex-wrap"
                    @click="showChatsFilter = !showChatsFilter"
                    :applyingFilter="applyingChatFilters"
                    :showFilter="showChatsFilter"
                />
            </div>

            <transition name="slide-up">
                <ChatsFilter
                    v-show="showChatsFilter"
                    :applyingChatFilters="applyingChatFilters"
                    @close="showChatsFilter = false"
                />
            </transition>

            <div
                @scroll="onScrollChatList"
                ref="ver-scroll"
                v-show="(chats || []).length > 0"
                class="ver-scroll flex-fill"
            >
                <ChatItemMobile
                    :class="{ selected: chat.$$selected }"
                    :chat="chat"
                    :key="idx"
                    v-for="(chat, idx) in chats"
                />
            </div>

            <div class="empty" v-if="!chats && !loading">
                <div class="empty-title" v-html="$translate('CHAT_EMPTY')" />
                <div class="empty-todo" v-html="$translate('EMPTY_TODO')" />
            </div>

            <div class="empty" v-if="chats && chats.length === 0">
                <div class="search-empty" v-html="$translate('SEARCH_EMPTY')" />
            </div>
        </div>
    </div>
</template>

<script>
import ChatsFilter from '@/routes/chat/chats/ChatsFilter'
import ChatItemMobile from '@/routes/mobile/chat/chats/ChatItemMobile'
// import Disconnected from '@/components/common/Disconnected'
import agentService from '@/services/agent'

export default {
    components: {
        ChatsFilter,
        ChatItemMobile,
        // Disconnected,
    },
    data: () => ({
        verticalList: null,
        showSbFilter: false,
        showChatsFilter: false,
        tabs: [],
        agentStatus: {
            signup_friends: false,
            vacation: false,
        },
    }),
    computed: {
        loading() {
            return this.$store.getters.loading.chats
        },
        currentChat() {
            return this.$store.getters.chat || {}
        },
        chatSearchValue() {
            return this.$store.getters.chatSearchValue
        },
        chatFilters() {
            return this.$store.getters.chatFilters
        },
        applyingChatFilters() {
            if (this.chatSearchValue) return true

            const selectedFilterKeys = Object.keys(this.chatFilters).filter(key => this.chatFilters[key].selected)

            return (
                !selectedFilterKeys.includes('male') ||
                !selectedFilterKeys.includes('female') ||
                selectedFilterKeys.length !== 2
            )
        },
        chats() {
            return this.$store.getters.chats
        },
        connected() {
            return this.$store.getters.connected
        },
        agent() {
            return this.$store.getters.agent || {}
        },
        testStat() {
            return this.$store.getters.testStat || {}
        },
    },
    mounted() {
        this.init()
        this.setCurrentChatNullWhensIsMobile()
    },
    methods: {
        async init() {
            this.$store.dispatch('loadTestStat')
            this.tabs = [
                {
                    title: 'ALL',
                },
                {
                    title: 'SEARCH_DETAIL',
                },
                {
                    title: 'MALE',
                },
                {
                    title: 'FEMALE',
                },
            ].map(tab => ({
                title: tab.title,
                selected: tab.title === this.$store.getters.chatFilters.type,
            }))

            try {
                await this.$store.dispatch('loadChats')
                this.verticalList = this.$refs['ver-scroll']
                this.verticalList.scrollTop = this.$store.getters.scrollTopChats

                this.agentStatus = {
                    signup_friends: this.agent.signup_friends || false,
                    vacation: this.agent.vacation || false,
                }
            } catch (e) {}
        },
        onSearch(value) {
            const trimmed = (value || '').trim()
            this.$store.commit('setChatSearchValue', trimmed)
            this.$store.dispatch('loadChats', { force: true })
        },
        onScrollChatList(event) {
            const scrollTop = event.target.scrollTop
            this.$store.commit('setScrollTopChats', scrollTop)
            if (this.$store.getters.loading.chats) return

            const diff = event.target.scrollHeight - scrollTop - event.target.clientHeight
            const isBottom = diff <= 10
            if (!isBottom) return

            const asyncGroup = async () => {
                await this.$store.dispatch('loadChats', {
                    force: true,
                    loadMore: true,
                })
                this.verticalList = this.$refs['ver-scroll']
            }
            asyncGroup()
        },
        onClickTab({ tab }) {
            const chatFilters = this.$store.getters.chatFilters
            if (tab.title === chatFilters.type) return

            chatFilters.type = tab.title
            this.$store.commit('setChatFilters', chatFilters)

            if (tab.title !== 'SEARCH_DETAIL') {
                this.showChatsFilter = false
                this.$store.dispatch('loadChats', { force: true })
                this.verticalList.scrollTop = 0
            } else {
                this.showChatsFilter = true
            }
        },
        setCurrentChatNullWhensIsMobile() {
            this.$store.commit('setChat', null)
        },
        async updateAgent(key) {
            try {
                await agentService.update(this.agentStatus)
                this.agent.vacation = this.agentStatus.vacation
                this.agent.signup_friends = this.agentStatus.signup_friends

                if (key === 'signup_friends') {
                    if (this.agentStatus[key]) {
                        this.$toast.success('지금부터 신규회원을 배정받습니다.')
                    } else {
                        this.$toast.success('지금부터 신규회원을 받지 않습니다.')
                    }
                    return
                }
                this.$toast.success('UPDATED')
            } catch (e) {
                this.$toast.error(e.data)
            }
        },
        async toggle(key) {
            if (key === 'signup_friends') {
                const idx = await this.$modal.basic({
                    body:
                        '앞으로 최소 이틀 간 휴가 일정이 없고, 신규 회원 관리가 가능할 때에 on 버튼을 눌러주세요. 5분 이상 활동이 없으면 자동으로 off 됩니다.',
                    buttons: [
                        {
                            label: 'CANCEL',
                            class: 'btn-default',
                        },
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
                if (idx !== 1) return
            }

            this.agentStatus[key] = !this.agentStatus[key]
            await this.updateAgent(key)
        },
    },
}
</script>
<style scoped lang="scss">
.chats-mobile {
    .btn-default {
        width: 140px;
        height: 32px;
        margin: 12px 4px 8px 4px;
        opacity: 0.9;
        border-radius: 8px;
    }

    .agent-status-btns {
        padding: 0 12px;

        .status-btn {
            border: 1px solid $grey-03;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 8px 12px;
            font-size: 13px;
            color: black;
            @extend .btn-default;
            @include f-regular;
        }
    }
}
</style>
