<template>
    <div
        class="profile"
        :class="{ 'before-confirm': from === 'chatUser' && confirmedStatus !== 'confirmed' }"
        v-if="chatUser && profile"
    >
        <Career :profile="profile" />
        <Educational :profile="profile" />
        <PersonalStory :user="chatUser" />
        <Personal :profile="profile" />
        <Income :profile="profile" />
        <Family :profile="profile" />
        <Marriage :profile="profile" />
        <!-- <UserIntro :profile="profile" /> -->
        <!-- <LikeTags :user="chatUser" /> -->
        <JudgingBtns :profile="profile" :chatUser="chatUser" />
    </div>
</template>

<script>
import Personal from '@/routes/chat/chatroom/components/Personal'
import PersonalStory from '@/routes/chat/chatroom/components/PersonalStory'
import Educational from '@/routes/chat/chatroom/components/Educational'
import Career from '@/routes/chat/chatroom/components/Career'
import Marriage from '@/routes/chat/chatroom/components/Marriage'
import Income from '@/routes/chat/chatroom/components/Income'
// import LikeTags from '@/components/common/LikeTags'
// import UserIntro from '@/components/common/UserIntro'
import Family from '@/routes/chat/chatroom/components/Family'
import JudgingBtns from '@/routes/chat/chatroom/components/JudgingBtns'

export default {
    name: 'Profiles',
    components: {
        Personal,
        PersonalStory,
        Educational,
        Career,
        Income,
        Marriage,
        // LikeTags,
        // UserIntro,
        Family,
        JudgingBtns,
    },
    props: ['chatUser', 'profile', 'from'],
    computed: {
        confirmedStatus() {
            return (this.profile || {}).status
        },
    },
    methods: {
        onFailClick() {
            this.$modal.custom({
                component: 'ModalChooseReason',
            })
        },
    },
}
</script>

<style lang="scss">
.profile {
    position: relative;
    margin-top: 16px;
    &.before-confirm {
        i {
            color: white !important;
        }
        color: white !important;

        .title {
            color: white;
        }

        .item {
            .key {
                color: $grey-03;
            }
            .value {
                color: white !important;
            }
        }
        .like-tags {
            .tag-container {
                .title {
                    color: white;
                }
                .tag-content {
                    background: #111111 !important;
                    color: white !important;
                }
            }
        }
    }
    .detail {
        border-top: 1px solid #f1f2f4;
        padding: 16px;
        .item {
            display: flex;
            margin-bottom: 8px;
            .key {
                width: 20%;
            }
            .value {
                color: black;
                width: 80%;
            }
        }
    }
}
.judging-btns {
    .title {
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 10px;
        margin-top: 10px;
    }
}
.profile-confirm {
    .profile-success-btn {
        width: 210px;
        height: 40px;
        background-color: #55efc4;
        color: black;
        font-size: 12px;
        border-radius: 8px;
        cursor: pointer;
    }
    .profile-failed-btn {
        width: 210px;
        height: 40px;
        background-color: #ffeaa7;
        color: black;
        font-size: 12px;
        border-radius: 8px;
        cursor: pointer;
    }
    .user-detail-btn {
        width: 210px;
        height: 40px;
        background-color: #8a74ff;
        color: white;
        font-size: 12px;
        border-radius: 8px;
        cursor: pointer;
    }
    .user-failed-btn {
        width: 210px;
        height: 40px;
        background-color: palevioletred;
        color: white;
        font-size: 12px;
        border-radius: 8px;
        cursor: pointer;
    }
}
</style>
