<template>
    <div class="notices card flex">
        <Tabs
            class="flex-wrap"
            v-if="ntype === 'notices'"
            :tabs="tabs"
            :shortUnderbar="true"
            @onClickTab="o => setTab(o.tab.title.toLowerCase())"/>
        <div
            v-else
            class="top flex-wrap">
            <div class="title" v-html="$translate($case.toConst(ntype))"/>
        </div>
        <Banner/>
        <div class="notice-list flex">
            <div
                @click="onClickNotice(notice)"
                class="notice flex-row items-center flex-between flex-wrap"
                :key="notice.id"
                v-for="notice in list">
                <div class="flex-fill">
                    <div class="flex-row items-center">
                        <div class="timestamp flex-wrap m-r-8">{{ notice.created_at | formatDate('YY.MM.DD') }}</div>
                        <div class="badges flex-row">
                            <div
                                v-if="notice.is_required"
                                class="badge badge-brd red flex-wrap"
                                v-html="$translate('NOTICE_REQUIRED')"
                            />
                            <div
                                v-if="notice.is_new"
                                class="badge badge-brd primary flex-wrap"
                                v-html="'NEW'"
                            />
                            <div
                                v-if="(notice.quizzes.filter(quiz => !quiz.is_solved) || []).length > 0"
                                class="badge badge-brd orange flex-wrap"
                                v-html="$translate('QUIZ')"
                            />
                        </div>
                    </div>
                    <div class="title" v-html="notice.title"/>
                </div>
                <i class="zmdi zmdi-chevron-right" />
            </div>
        </div>
    </div>
</template>

<script>
import Banner from './Banner'

export default {
    name: 'Notices',
    props: ['passedNtype'],
    components: { Banner },
    data: () => ({
        selectedTab: null,
    }),
    computed: {
        tabs() {
            return [{
                title: 'NOTICES',
                selected: this.selectedTab === 'notices',
            }, {
                title: 'PINNED',
                selected: this.selectedTab === 'pinned',
            }]
        },
        ntype() {
            return this.passedNtype || 'notices'
        },
        list() {
            if (!this.selectedTab) return

            return this.$store.getters[this.selectedTab]
        },
    },
    mounted() {
        this.selectedTab = this.ntype
        this.$bus.$on('openPinnedNotice', this.onOpenPinnedNotice)
    },
    beforeDestroy() {
        this.$bus.$off('openPinnedNotice', this.onOpenPinnedNotice)
    },
    methods: {
        onClickNotice(notice) {
            this.$modal.custom({
                component: 'ModalNotice',
                options: {
                    notice,
                },
            }).then(() => {
                if (this.$store.getters.agent.$$solvedQuiz || (notice.quizzes || []).length === 0) return

                this.$modal.custom({
                    component: 'ModalQuiz',
                    options: {
                        quizzes: notice.quizzes,
                    },
                })
            })
        },
        setTab(ntype) {
            this.selectedTab = ntype
        },
        onOpenPinnedNotice() {
            this.setTab('pinned')
        },
    },
}
</script>