<template>
    <transition name="slide-down">
        <div v-if="showNotifications" class="notifications" @click="closeOnClickOverlay">
            <div class="notifications-wrapper">
                <div class="top">
                    <div class="title flex-row flex-between">
                        <div>{{ 'NOTIFICATIONS' | translate }}</div>
                        <i @click.stop="showNotifications = false" class="zmdi zmdi-close" />
                    </div>
                    <SearchBox :placeholder="'PLACEHOLDER_SEARCH_NOTIFICATION'" @onKeydown="onSearch" />
                </div>
                <div @scroll="onScrollNotifications" class="notifications-list">
                    <Notification
                        @close="showNotifications = false"
                        :notification="notification"
                        :key="notification.id"
                        v-for="notification in notifications"
                    />
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
import Notification from './Notification'

export default {
    name: 'Notifications',
    components: { Notification },
    computed: {
        showNotifications: {
            get() {
                return this.$store.getters.showNotifications
            },
            set(newVal) {
                this.$store.commit('setShowNotifications', newVal)
            },
        },
        notifications() {
            const nList = this.$store.getters.notifications
            if (!this.searchValue) return nList

            return nList.filter(n => n.content.includes(this.searchValue))
        },
    },
    data: () => ({
        searchValue: null,
    }),
    methods: {
        closeOnClickOverlay(event) {
            this.showNotifications = !event.target.classList.contains('notifications')
        },
        async onScrollNotifications(event) {
            const t = event.target
            const isBottom = t.scrollHeight - t.scrollTop <= t.clientHeight
            if (isBottom) {
                await this.$store.dispatch('loadNotifications')
            }
        },
        onSearch(searchValue) {
            this.searchValue = searchValue
        },
        toggleNotifications() {
            this.$store.dispatch('loadNotifications', 0)
            this.showNotifications = !this.showNotifications
        },
    },
    mounted() {
        this.$bus.$on('toggleNotifications', this.toggleNotifications)
    },
    beforeDestroy() {
        this.$bus.$off('toggleNotifications', this.toggleNotifications)
    },
}
</script>
