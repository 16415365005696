<template>
    <div class="control-panel bg-primary">
        <div class="menu-items" v-if="!$store.getters.isMobile">
            <div>
                <MenuButton
                    @click="button.action"
                    :icon="button.icon"
                    :iconPng="button.iconPng"
                    :snackbarText="button.snackbarText"
                    :badgeCount="button.badgeCount"
                    :selected="button.selected"
                    :shortcut="button.shortcut"
                    :key="idx"
                    v-for="(button, idx) in upperButtons"
                />
            </div>
            <div class="hr" />
            <div>
                <MenuButton
                    @click="button.action"
                    :icon="button.icon"
                    :iconPng="button.iconPng"
                    :snackbarText="button.snackbarText"
                    :badgeCount="button.badgeCount"
                    :selected="button.selected"
                    :shortcut="button.shortcut"
                    :key="idx"
                    v-for="(button, idx) in bottomButtons"
                />
            </div>
            <!--            <div class="site-toggler">-->
            <!--                <MenuButton @click="onClickToggleSite" :label="toggleSiteLabel" :snackbarText="sbToggleSite" />-->
            <!--            </div>-->
        </div>
        <div v-else class="menu-items flex-row flex-between items-center">
            <MenuButton
                class="flex-fill"
                @click="button.action"
                :icon="button.selected ? button.icon : button.iconInactive"
                :iconPng="button.iconPng"
                :snackbarText="button.snackbarText"
                :badgeCount="button.badgeCount"
                :selected="button.selected"
                :shortcut="button.shortcut"
                :key="idx"
                v-for="(button, idx) in mobileButtons"
            />
        </div>
    </div>
</template>

<script>
import MenuButton from './MenuButton'

export default {
    components: {
        MenuButton,
    },
    data: () => ({}),
    computed: {
        dest() {
            return this.toggleSiteLabel === '구' ? 'https://agent.vanillabridge.com' : 'https://pro.vanillabridge.com'
        },
        toggleSiteLabel() {
            return window.location.href.includes('pro.vanillabridge.com') ? '구' : '신'
        },
        sbToggleSite() {
            return window.location.href.includes('pro.vanillabridge.com') ? '구버전으로 가기' : '신버전으로 가기'
        },
        currentRouteName() {
            return this.$route.name
        },
        badges() {
            return this.$store.getters.badges || {}
        },
        mobileButtons() {
            return [
                // {
                //     action: () => this.navigate('HomePage'),
                //     icon: 'home',
                //     iconInactive: 'home',
                //     snackbarText: 'HOME',
                //     badgeCount: 0,
                //     selected: this.$route.name === 'HomePage',
                // },
                {
                    action: () => this.navigate('ChatPage'),
                    icon: 'chat',
                    iconInactive: 'chat',
                    snackbarText: 'CHAT',
                    badgeCount: this.badges.chats,
                    selected: this.$route.name === 'ChatPage',
                },
                // {
                //     action: () => this.navigate('MyPage'),
                //     icon: 'favorite',
                //     iconInactive: 'favorite',
                //     snackbarText: 'MY_ACTIVITY',
                //     badgeCount: this.badges.friends + (this.$store.getters.refunds || []).length,
                //     selected: this.$route.name === 'MyPage',
                // },
                {
                    action: () => this.navigate('SettingsPageMobile'),
                    iconPng: this.icon('profile'),
                    snackbarText: 'SETTINGS',
                    selected: ['SettingsPageMobile'].indexOf(this.$route.name) !== -1,
                },
            ]
        },
        upperButtons() {
            const upper = [
                // {
                //     action: () => this.navigate('HomePage'),
                //     icon: 'home',
                //     iconInactive: 'home',
                //     snackbarText: 'HOME',
                //     badgeCount: 0,
                //     selected: this.$route.name === 'HomePage',
                // },
                {
                    action: () => this.navigate('ChatPage'),
                    icon: 'chat',
                    iconInactive: 'chat',
                    snackbarText: 'CHAT',
                    badgeCount: this.badges.chats,
                    selected: this.$route.name === 'ChatPage',
                },
                // {
                //     action: () => this.navigate('MyPage'),
                //     icon: 'favorite',
                //     iconInactive: 'favorite',
                //     snackbarText: 'MY_ACTIVITY',
                //     badgeCount: this.badges.friends + (this.$store.getters.refunds || []).length,
                //     selected: this.$route.name === 'MyPage',
                // },
                // {
                //     action: () => this.navigate('GiftPage'),
                //     icon: 'card_giftcard',
                //     snackbarText: 'GIFT',
                //     badgeCount: ((this.$store.getters.gifts || {}).received_gift || []).length,
                //     selected: this.$route.name === 'GiftPage',
                // },
                {
                    action: () => this.navigate('SettingsPage'),
                    iconPng: this.icon('profile'),
                    snackbarText: 'SETTINGS',
                    selected: ['SettingsPage'].indexOf(this.$route.name) !== -1,
                },
            ]
            return upper
        },
        bottomButtons() {
            // const agent = this.$store.getters.agent || {}

            const bottom = [
                {
                    action: this.toggleNotifications,
                    icon: 'notifications',
                    snackbarText: 'NOTIFICATIONS',
                    badgeCount: this.badges.notifications,
                },
            ]

            return bottom
        },
    },
    created() {
        this.$bus.$on('openTab', this.handleOpenTab)
        this.$bus.$on('onShortcut', this.toggleShowShortcuts)
    },
    beforeDestroy() {
        this.$bus.$off('openTab', this.handleOpenTab)
        this.$bus.$off('onShortcut', this.toggleShowShortcuts)
    },
    methods: {
        onClickToggleSite() {
            window.location.href = `https://${this.toggleSiteLabel === '신' ? 'pro' : 'agent'}.vanillabridge.com`
        },
        navigate(name) {
            this.$router.push({ name }).catch(e => {})
        },
        handleOpenTab(tab) {
            if (!tab) return

            if (['ChatPage', 'MyPage', 'HomePage', 'SettingsPage', 'SettingsPageMobile', 'Gift'].indexOf(tab) === -1)
                return

            this.$router.push({ name: tab })
        },
        toggleNotifications() {
            this.$bus.$emit('toggleNotifications')
        },
        toggleShowShortcuts(event) {
            const shouldShow = event.code === 'ControlRight' && event.type === 'keydown'
            this.upperButtons.forEach((b, idx) => {
                b.shortcut = shouldShow ? `Ctrl + ${idx + 1}` : null
            })
            this.$forceUpdate()
        },
        icon(icon) {
            const postFix = this.$store.getters.isMobile ? 'mob' : 'pc'
            return {
                active: require(`@/assets/images/tab/icon_${icon}_active_${postFix}.svg`),
                inactive: require(`@/assets/images/tab/icon_${icon}_inactive_${postFix}.svg`),
            }
        },
    },
}
</script>
