<template>
    <div class="settings bg-white flex-row">
        <div v-if="!$store.getters.isMobile" class="my-profile-container-pc">
            <div class="my-profile flex-row items-center flex-wrap">
                <PhotoAndLastSeen
                    class="flex-wrap"
                    :profile="agent.photo_url"
                    :gender="agent.gender"
                    :userId="agent.user_id"
                />
                <div>
                    <div class="names">
                        <span class="name">{{ agent.name }}</span>
                        <span v-if="agent.nickname" class="nickname">({{ agent.nickname }})</span>
                    </div>
                    <div class="buttons flex-row">
                        <button
                            @click="updateProfile"
                            class="btn btn-brd flex-fill"
                            v-html="$translate('EDIT_PROFILE')"
                        />
                        <button
                            @click="updatePhoto"
                            class="btn btn-brd flex-fill m-l-8"
                            v-html="$translate('EDIT_PHOTO')"
                        />
                    </div>
                </div>
            </div>
            <ul class="flex-fill">
                <div class="section">
                    <div class="section-title" v-html="$translate('NOTIFICATIONS')" />
                    <li @click="toggle(key)" :key="key" v-for="key in ['notified', 'message_accepted']">
                        <label v-html="$translate(key.toUpperCase())" />
                        <Toggler v-model="agent[key]" class="no-touch" />
                    </li>
                </div>
                <div class="section">
                    <div class="section-title" v-html="$translate('FUNCTION')" />
                    <li @click="settingFunc(key)" :key="key" v-for="key in ['default_filter']">
                        <label v-html="$translate(key.toUpperCase())" />
                        <!-- <i v-else class="zmdi zmdi-chevron-right flex-wrap"/> -->
                    </li>
                </div>
                <div class="section">
                    <div class="section-title" v-html="$translate('MESSAGE')" />
                    <li
                        @click="key != 'auto_message_management' ? toggle(key) : settingFunc(key)"
                        :key="key"
                        v-for="key in [
                            'notify_recommend_request',
                            'notify_recommend_reject',
                            'notify_recommend_accept',
                            'notify_user_pass',
                            'auto_message_management',
                        ]"
                    >
                        <label v-html="$translate(key.toUpperCase())" />
                        <Toggler v-if="key != 'auto_message_management'" v-model="agent[key]" class="no-touch" />
                        <i
                            v-else
                            class="zmdi flex-wrap"
                            :class="showAutoMessageChildren ? 'zmdi-chevron-up' : 'zmdi-chevron-down'"
                        />
                    </li>
                    <div v-if="showAutoMessageChildren" class="section-children">
                        <li
                            class="section-child"
                            :class="{ active: keyObj.show }"
                            @click="settingFunc(keyObj.key)"
                            :key="keyObj.key"
                            v-for="keyObj in autoMessageChildren()"
                        >
                            <label v-html="$translate(keyObj.key.toUpperCase())" />
                        </li>
                    </div>
                </div>
                <div class="section">
                    <div class="section-title" v-html="$translate('ACCOUNT')" />
                    <li @click="toggleLocale">
                        <label v-html="$translate('LOCALE')" />
                        <span v-html="$store.getters.translation.locale" />
                    </li>
                    <li @click="askLogout">
                        <label v-html="$translate('LOGOUT')" />
                        <!-- <i class="zmdi zmdi-chevron-right"/> -->
                    </li>
                </div>
            </ul>
        </div>

        <EditProfilePc v-if="showProfile" />
        <DefaultFilterPc v-if="showFilter" />
        <RejectMessagePc v-if="showRejectMessage" />
        <SpreadMessagePc v-if="showSpreadMessage" />
        <HelloMessagePc v-if="showHelloMessage" />
    </div>
</template>

<script>
import agentService from '@/services/agent'
import EditProfilePc from './EditProfilePc'
import DefaultFilterPc from './DefaultFilterPc'
import RejectMessagePc from './RejectMessagePc'
import SpreadMessagePc from './SpreadMessagePc'
import HelloMessagePc from './HelloMessagePc'

export default {
    name: 'Settings',
    components: {
        EditProfilePc,
        DefaultFilterPc,
        RejectMessagePc,
        SpreadMessagePc,
        HelloMessagePc,
    },

    data: () => ({
        showProfile: false,
        showFilter: false,
        showRejectMessage: false,
        showSpreadMessage: false,
        showAutoMessageChildren: false,
        showHelloMessage: false,

        pages: [],
        showPages: [],
    }),
    mounted() {
        this.pages = ['profile', 'friendsFilter', 'rejectMessage', 'spreadMessage', 'helloMessage']
        this.showPages = [false, false, false, false, false]
        this.setDefaultShow()
    },
    beforeDestroy() {},

    computed: {
        agent() {
            return this.$store.getters.agent || {}
        },
    },
    methods: {
        askLogout() {
            this.$modal
                .basic({
                    title: 'LOGOUT',
                    body: this.$translate('LOGOUT_BODY'),
                    buttons: [
                        {
                            label: 'CANCEL',
                            class: 'btn-default',
                        },
                        {
                            label: 'LOGOUT',
                            class: 'btn-primary',
                        },
                    ],
                })
                .then(selectedBtnIdx => {
                    if (selectedBtnIdx === 1) {
                        this.$toast.success('BYEBYE!')
                        this.$store.dispatch('signOut')
                    }
                })
        },
        toggleLocale() {
            this.$store.dispatch('toggleLocale')
        },
        async updateProfile() {
            this.setShow('profile')
        },
        updatePhoto() {
            this.$toast.error(this.$translate('DO_IT_ON_MOBILE'))
        },

        async updateAgent() {
            try {
                await agentService.update(this.agent)
                this.$toast.success('UPDATED')
            } catch (e) {
                this.$toast.error(e.data)
            }
        },
        toggle(key) {
            this.agent[key] = !this.agent[key]
            this.updateAgent()
        },
        settingFunc(key) {
            if (key === 'auto_message_management') {
                this.showAutoMessageChildren = !this.showAutoMessageChildren
            } else if (key === 'default_filter') {
                this.setShow('friendsFilter')
            } else if (key === 'like_reject_message') {
                this.setShow('rejectMessage')
            } else if (key === 'spread_suggestion_message') {
                this.setShow('spreadMessage')
            } else if (key === 'say_hello_message') {
                this.setShow('helloMessage')
            }
        },
        autoMessageChildren() {
            // return this.testAgent
            // ? [
            return [
                { key: 'say_hello_message', show: this.showHelloMessage },
                { key: 'spread_suggestion_message', show: this.showSpreadMessage },
                // { key: 'like_reject_message', show: this.showRejectMessage },
            ]
            // : [{ key: 'like_reject_message', show: this.showRejectMessage }]
        },
        setDefaultShow() {
            if (this.$route.params) {
                this.setShow(this.$route.params.defaultShow)
            }
        },
        setShow(type) {
            const idx = this.pages.findIndex(c => c === type)
            this.showPages = []
            this.showAutoMessageChildren = false

            this.pages.map(p => this.showPages.push(false))
            this.showPages[idx] = true

            this.showProfile = this.showPages[0]
            this.showFilter = this.showPages[1]
            this.showRejectMessage = this.showPages[2]
            this.showSpreadMessage = this.showPages[3]
            this.showHelloMessage = this.showPages[4]
            this.showAutoMessageChildren = this.showRejectMessage || this.showSpreadMessage || this.showHelloMessage
        },
    },
}
</script>
