<template>
    <div class="chat-body-mobile" :class="{ 'chat-user-on': user }">
        <Loading :loading="loading" />
        <div ref="ver-scroll" class="messages" @scroll="onScroll">
            <div
                class="message-row"
                :class="[shouldAddMoreMargin(idx), me(message) ? 'right' : 'left']"
                :key="message.id"
                v-for="(message, idx) in messages"
            >
                <UnreadSeparator ref="unreadSeparator" v-if="showUnreadSepartor(message, idx)" />
                <DailySeparator v-if="showSeparator(idx)" :message="message" />
                <div v-if="$store.getters.isMobile && !me(message)" class="profile-and-name flex-row">
                    <img
                        @click="onClickProfileImage(vb(message) || user)"
                        class="img-profile flex-wrap"
                        :src="(vb(message) || user).photo_url"
                    />
                    <div v-if="vb(message)" class="name flex-wrap" v-html="vb(message).name" />
                    <div v-else class="name flex-wrap" v-html="$nameOrNick(user)" />
                </div>
                <Message class="flex-wrap" :message="message" :me="me(message)" />
            </div>
        </div>
        <div v-if="!user" class="chat-unset" v-html="$translate('CHAT_UNSET')" />
    </div>
</template>
<script>
import DailySeparator from '@/routes/chat/chatroom/DailySeparator'
import UnreadSeparator from '@/routes/chat/chatroom/UnreadSeparator'
import Message from '@/routes/chat/chatroom/message/Message'

export default {
    name: 'ChatBodyMobile',
    components: { DailySeparator, UnreadSeparator, Message },
    computed: {
        messages() {
            return (this.chat.$$messages || []).slice().reverse()
        },
        user() {
            return this.chat.user
        },
        chat() {
            return this.$store.getters.chat || {}
        },
    },
    watch: {
        chat(newVal, oldVal) {
            if (newVal && oldVal && newVal.id === oldVal.id) return

            if (newVal) {
                this.$nextTick(() => {
                    this.initScrollPosition()
                    this.firstUnreadMessage = null
                    this.unreadCount = newVal.$$unread
                })
            }
        },
    },
    data: () => ({
        loading: false,
        firstUnreadMessage: null,
        unreadCount: 0,
        firstUpdatedHookCalled: false,
    }),
    mounted() {
        this.$nextTick(() => {
            this.initScrollPosition()
        })

        // 자기가 뒤로 간 경우는 스크롤 위치 삭제
        this.$registerBackHandler(() => {
            delete this.chat.$$scrollTop
            return true
        })
    },
    updated() {
        if (this.firstUpdatedHookCalled) return

        this.$nextTick(() => {
            this.initScrollPosition()
            setTimeout(() => {
                this.firstUpdatedHookCalled = true
            }, 1000)
        })
    },
    methods: {
        isInstantChat() {
            return this.$store.getters.chat && this.$store.getters.chat.expire_at
        },

        async initScrollPosition() {
            // 스크롤 위치 기억은 모바일에서만 하면 될듯
            if (this.unreadCount > this.messages.length) {
                const loadMessages = () => {
                    this.chat.$$firstMessageId = this.messages[0].id
                    return this.$store.dispatch('loadMessages', {
                        firstId: this.chat.$$firstMessageId,
                        chatId: this.chat.id,
                    })
                }

                const loopCount = parseInt(this.unreadCount / this.messages.length)

                for (let i = 0; i < loopCount; i += 1) {
                    await loadMessages()
                }
            }

            const dom = this.$refs['ver-scroll']
            const unreadElem = document.querySelector('.unread-separator')
            if (unreadElem) {
                unreadElem.scrollIntoView(true)
                if (dom.scrollTop + dom.clientHeight < dom.scrollHeight) {
                    dom.scrollTop -= dom.clientHeight / 3
                }
            } else {
                this.$scroll.down(dom, false)
            }
        },
        async loadMessages() {
            if (!this.chat.$$loadMore || this.loading) return

            let dom
            let scrollHeightBefore

            try {
                this.loading = true
                dom = this.$refs['ver-scroll']
                scrollHeightBefore = dom.scrollHeight

                await this.$store.dispatch('loadMessages', {
                    firstId: this.chat.$$firstMessageId,
                })
            } catch (e) {
                this.$toast.error(e.data)
            } finally {
                this.loading = false
                this.$nextTick(() => {
                    const scrollHeightAfter = dom.scrollHeight
                    dom.scrollTop = scrollHeightAfter - scrollHeightBefore
                })
            }
        },
        me(message) {
            const content = this.$mustParse(message.content)

            if ((content || {}).is_vb) return false
            return message.user_id === this.$store.getters.agent.user_id
        },
        vb(message) {
            const content = this.$mustParse(message.content) || {}
            if (!content.is_vb) return

            return {
                photo_url: require('@/assets/images/logo_symbol.svg'),
                name: this.$translate('VANILLABRIDGE'),
            }
        },
        onScroll(event) {
            const scrollTop = event.target.scrollTop
            this.$set(this.chat, '$$scrollTop', scrollTop)
            if (scrollTop !== 0 || !this.messages || this.messages.length === 0) {
                return
            }

            this.chat.$$firstMessageId = this.messages[0].id
            this.loadMessages()
        },
        onClickProfileImage(user) {
            if (!user.id) return

            this.$router.push({
                name: 'UserDetailPage',
                params: {
                    userId: this.user.id,
                },
            })
        },
        showSeparator(idx) {
            if (idx === 0) {
                return true
            }

            const curMessage = this.messages[idx]
            if (!curMessage || !curMessage.created_at) return false

            const prevMessage = this.messages[idx - 1]
            return (
                this.$moment(prevMessage.created_at).format('YYYY-MM-DD') !==
                this.$moment(curMessage.created_at).format('YYYY-MM-DD')
            )
        },
        showUnreadSepartor(message, idx) {
            if (this.unreadCount === 0) return false

            if (
                this.firstUnreadMessage &&
                this.firstUnreadMessage.id === message.id &&
                this.firstUnreadMessage.mtype !== 'open-chat-pro'
            )
                return true

            if (this.messages.length - this.unreadCount === idx) {
                if (!this.firstUnreadMessage) {
                    this.firstUnreadMessage = message

                    return true
                }
            }

            return false
        },
        shouldAddMoreMargin(msgIdx) {
            const curMessage = this.messages[msgIdx]
            const nextMessage = this.messages[msgIdx + 1]

            if (!curMessage || !nextMessage) return

            if (curMessage.user_id !== nextMessage.user_id) {
                return 'm-b-20'
            }

            if (['text', 'photo'].indexOf(curMessage.mtype) === -1) {
                return 'm-b-16'
            }
        },
    },
}
</script>
