<template>
    <div class="floating-components">
        <div v-if="$store.getters.isMobile">
            <!-- <MobileFloatings v-if="user && !chat.expire_at" /> -->
            <AcceptedUser v-if="!isAlimi && chat.expire_at" />
            <PremiumRestriction v-if="notMyPremiumUser" :valid-until="premium.valid_until" />
            <MobileFloatings :user="user" :expireAt="chat.expire_at" />
        </div>
        <div class="chat-user-box" v-else>
            <ChatUser class="flex-wrap" v-if="!$store.getters.isMobile && user.id" />
            <!-- <ChatStat /> -->
            <PremiumRestriction v-if="notMyPremiumUser" :valid-until="premium.valid_until" />
        </div>
    </div>
</template>

<script>
import MobileFloatings from './MobileFloatings'
import AcceptedUser from './../common/AcceptedUser'
import ChatUser from '@/routes/chat/chatroom/ChatUser'
// import ChatStat from '@/routes/chat/chatroom/ChatStat'
import PremiumRestriction from './PremiumRestriction'

export default {
    name: 'FloatingComponents',
    components: {
        MobileFloatings,
        AcceptedUser,
        ChatUser,
        // ChatStat,
        PremiumRestriction,
    },
    computed: {
        user() {
            return (this.$store.getters.chat || {}).user || {}
        },
        chat() {
            return this.$store.getters.chat || {}
        },
        isAlimi() {
            return (this.chat.user || {}).id === 0
        },
        premium() {
            return this.user.$$premium
        },
        notMyPremiumUser() {
            const isMyPremiumUser = (this.premium || {}).agent_id === (this.$store.getters.agent || {}).id
            return this.premium && !isMyPremiumUser
        },
    },
}
</script>
