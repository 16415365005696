<template>
    <div class="hello-message">
        <div class="hello-message-container">
            <div class="section">
                <div class="hello-message-title" v-html="$translate(`SAY_HELLO_MESSAGE`)" />
                <div class="hello-message-body" v-html="$translate(`SAY_HELLO_MESSAGE_BODY`)" />
            </div>
            <div class="content">
                <div class="hello-message-container">
                    <div class="container-top">
                        <div class="container-message">
                            <span class="container-title" v-html="$translate(`SAY_HELLO_MESSAGE_MALE_TITLE`)"> </span>
                            <span class="container-ex" v-html="$translate(`SAY_HELLO_MESSAGE_MALE_BODY`)"> </span>
                        </div>
                        <button
                            v-if="!isExistTextM"
                            @click="helloMessageChangedM ? updateHelloMessage('male') : null"
                            class="btn"
                            :class="helloMessageChangedM ? 'save' : 'btn-block save-off'"
                            v-html="$translate('DO_SAVE')"
                        />
                        <button
                            v-else
                            @click="modifyHelloMessage('male')"
                            class="btn save"
                            v-html="$translate('DO_MODIFY')"
                        />
                    </div>
                    <div class="textarea-container">
                        <div v-if="messagesM[0] != ''" class="preview-container" :class="{ modify: isExistTextM }">
                            <div class="preview-message" :key="idx" v-for="(message, idx) in messagesM">
                                <span v-html="message" />
                            </div>
                        </div>
                        <textareaWithAutoTexts
                            v-if="!isExistTextM"
                            :continueEnter="true"
                            :placeholder="$translate('PLACEHOLDER_MESSAGE')"
                            @onEnter="onEnterM"
                            v-model="helloMessageTempM"
                        />
                    </div>
                </div>
                <div class="hello-message-container">
                    <div class="container-top">
                        <div class="container-message">
                            <span class="container-title" v-html="$translate(`SAY_HELLO_MESSAGE_FEMALE_TITLE`)"> </span>
                            <span class="container-ex" v-html="$translate(`SAY_HELLO_MESSAGE_FEMALE_BODY`)"> </span>
                        </div>
                        <button
                            v-if="!isExistTextF"
                            @click="helloMessageChangedF ? updateHelloMessage('female') : null"
                            class="btn"
                            :class="helloMessageChangedF ? 'save' : 'btn-block save-off'"
                            v-html="$translate('DO_SAVE')"
                        />
                        <button
                            v-else
                            @click="modifyHelloMessage('female')"
                            class="btn save"
                            v-html="$translate('DO_MODIFY')"
                        />
                    </div>
                    <div class="textarea-container">
                        <div v-if="messagesF[0] != ''" class="preview-container" :class="{ modify: isExistTextF }">
                            <div class="preview-message" :key="idx" v-for="(message, idx) in messagesF">
                                <span v-html="message" />
                            </div>
                        </div>
                        <textareaWithAutoTexts
                            v-if="!isExistTextF"
                            :continueEnter="true"
                            :placeholder="$translate('PLACEHOLDER_MESSAGE')"
                            @onEnter="onEnterF"
                            v-model="helloMessageTempF"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HelloMessagePc',
    data: () => ({
        helloMessageChangedM: false,
        helloMessageChangedF: false,
        helloMessageTextM: [],
        helloMessageTextF: [],
        helloMessageTempM: '',
        helloMessageTempF: '',
        isExistTextM: false,
        isExistTextF: false,
    }),
    mounted() {
        this.initHelloMessage()
    },
    computed: {
        helloMessageM() {
            const autoTexts = this.$store.getters.autoTextsAllCategory
            if (autoTexts && autoTexts.intro_new_user_male !== undefined) {
                return autoTexts.intro_new_user_male
            } else {
                return []
            }
        },
        helloMessageF() {
            const autoTexts = this.$store.getters.autoTextsAllCategory
            if (autoTexts && autoTexts.intro_new_user_female !== undefined) {
                return autoTexts.intro_new_user_female
            } else {
                return []
            }
        },
        messagesM() {
            return this.helloMessageTextM
        },
        messagesF() {
            return this.helloMessageTextF
        },
    },
    methods: {
        async initHelloMessage() {
            await this.$store.dispatch('loadAutoTextsAllCategory')
            this.helloMessageM.map(message => {
                this.helloMessageTextM.push(message.content)
            })

            this.helloMessageF.map(message => {
                this.helloMessageTextF.push(message.content)
            })

            this.isExistTextM = this.helloMessageTextM.length > 0 ? true : false
            this.isExistTextF = this.helloMessageTextF.length > 0 ? true : false
        },

        async updateHelloMessage(gender) {
            this.$store.dispatch('updateHelloMessage', {
                helloMessage: gender === 'male' ? this.helloMessageTextM : this.helloMessageTextF,
                gender,
            })

            if (gender === 'male') {
                this.isExistTextM = true
            } else {
                this.isExistTextF = true
            }
        },

        async modifyHelloMessage(gender) {
            this.$modal
                .basic({
                    title: '',
                    body: this.$translate('ALERT_MESSAGE_RESET'),
                    buttons: [
                        {
                            label: 'CANCEL',
                            class: 'btn-default',
                        },
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
                .then(selectedBtnIdx => {
                    if (selectedBtnIdx === 1) {
                        this.$store.dispatch('updateHelloMessage', {
                            helloMessage: '',
                            gender,
                        })

                        if (gender === 'male') {
                            this.helloMessageTextM = []
                            this.helloMessageTempM = ''
                            this.helloMessageChangedM = false
                            this.isExistTextM = false
                        } else {
                            this.helloMessageTextF = []
                            this.helloMessageTempF = ''
                            this.helloMessageChangedF = false
                            this.isExistTextF = false
                        }
                    }
                })
        },

        onEnterM(text) {
            if (!text) return

            this.helloMessageTempM = text
            this.helloMessageTextM.push(text)

            if (this.helloMessageTextM.length > 0) this.helloMessageChangedM = true

            this.helloMessageTempM = ''
            const dom = document.getElementsByClassName('preview-container')[0]
            if (dom) {
                this.$scroll.down(dom, true)
            }
        },

        onEnterF(text) {
            if (!text) return

            this.helloMessageTempF = text
            this.helloMessageTextF.push(text)

            if (this.helloMessageTextF.length > 0) this.helloMessageChangedF = true

            this.helloMessageTempF = ''
            const dom = document.getElementsByClassName('preview-container')[1]
            if (dom) {
                this.$scroll.down(dom, true)
            }
        },
    },
}
</script>
