<template>
    <div class="not-found">
        <div class="f-700 f-28 m-b-30">
            {{ 'NOT_FOUND' | translate }}
        </div>
        <div class="instruction" v-html="instruction" />
        <button @click="onClickOpenChat" class="btn btn-primary m-a m-t-32">{{ 'OPEN_CHAT' | translate }}</button>
    </div>
</template>

<script>
export default {
    computed: {
        instruction() {
            return this.$translate('NOT_FOUND_INSTRUCTION')
        },
    },
    methods: {
        onClickOpenChat() {
            this.$bus.$emit('openTab', 'ChatPage')
        },
    },
}
</script>

<style lang="scss" scoped>
.not-found {
    width: 100%;
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translateX(-50%);
    text-align: center;
    padding: 16px;

    .instruction {
        font-size: 20px;
        line-height: 24px;
    }
}
</style>
