<template>
    <div class="rate-plan-container">
        <div class="rate-plan" v-if="userRatePlan.pid">
            <div class="rate-plan-info" :class="{ hideExpire }">
                <div class="info-desc">
                    <i @mouseenter="onMouseEnter" @mouseleave="onMouseLeave" class="material-icons">help_outline</i>
                </div>
                <div class="info" :class="userRatePlan.status">
                    <div class="product-name" v-html="userRatePlan.name" />
                    <div class="add-info">
                        <div v-html="`시작 ${userRatePlan.start}`" />
                    </div>
                    <div class="period-info">
                        <div v-html="`주기 ${userRatePlan.cycle}주`" />
                    </div>
                </div>
            </div>
            <div class="valid-date" v-if="!hideExpire">
                <div
                    class="badge"
                    :class="
                        userRatePlan.status !== 'expired' && userRatePlan.expire > 14
                            ? `bg-success`
                            : userRatePlan.status !== 'expired' && userRatePlan.expire >= 0
                            ? `bg-danger`
                            : `bg-lighter`
                    "
                    @mouseenter="onMouseEnterExpireDay"
                    @mouseleave="onMouseLeaveExpireDay"
                    v-html="userRatePlan.status !== 'expired' ? `D-${userRatePlan.expire}` : `만료`"
                />
            </div>
        </div>
        <div class="no-plan" v-else>
            <div class="product-name c-black" v-html="userRatePlan.name" />
        </div>
        <div class="description" v-if="mouseOver">
            <div class="c-black" v-for="desc in userRatePlan.description" v-html="desc" :key="desc" />
        </div>
        <div class="description" v-if="mouseOverExpireDay">
            <div class="c-black" v-html="$moment(userRatePlan.expireTypeOrigin).format('만료일: YYYY. MM. DD')" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'RatePlan',
    data: () => ({
        mouseOver: false,
        mouseOverExpireDay: false,
    }),
    methods: {
        onMouseEnter() {
            this.mouseOver = true
        },
        onMouseLeave() {
            this.mouseOver = false
        },
        onMouseEnterExpireDay() {
            this.mouseOverExpireDay = true
        },
        onMouseLeaveExpireDay() {
            this.mouseOverExpireDay = false
        },
    },
    props: {
        userRatePlan: Object,
        hideExpire: Boolean,
    },
}
</script>

<style scoped lang="scss">
.no-plan {
    height: 1.25rem;
    margin: auto 0;
}
.rate-plan-container {
    position: relative;

    .description {
        position: absolute;
        top: -4.375rem;
        z-index: 10;
        width: 100%;
        background: $grey-03;
        padding: 0.5rem;
        border-radius: 0.5rem;
        text-align: center;
        font-size: 0.9375rem;
        line-height: 1.25rem;
    }
}
.rate-plan {
    @include flex-between;
    padding: 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid $grey-05;
    background: white;

    .rate-plan-info {
        display: flex;
        max-width: 80%;

        &.hideExpire {
            width: 100%;
            max-width: 100%;
        }
    }

    .info-desc {
        display: flex;
        align-items: center;
        margin-right: 0.25rem;
        i {
            color: black;
            font-size: 1.25rem;
        }
    }
    .info {
        width: 100%;

        &.pending {
            .product-name,
            .add-info {
                color: $brand-red;
            }
        }
        &.expired {
            .product-name,
            .add-info {
                color: $grey-06;
            }
        }

        .product-name {
            @include f-bold;
            color: black;
            font-size: 1rem;
            margin-bottom: 0.25rem;
            line-height: 1.25rem;
        }
        .add-info {
            color: $grey-07;
            font-size: 0.8rem;
        }
        .period-info {
            color: $grey-07;
            font-size: 0.8rem;
            margin: 0.125rem 0;
        }
    }
    .valid-date {
        @include center;
        .badge {
            cursor: pointer;
            padding: 0.4rem 0.2rem;
            font-size: 1rem !important;
            min-width: 3rem !important;
        }
    }
}
</style>
