<template>
    <div class="judging-btns detail">
        <div v-if="isPass === 'judgment_required' || isPass === 'before_judgment'">
            <div class="title">합불 여부</div>
            <div class="flex-row profile-confirm">
                <div class="bottom-button">
                    <div class="btn profile-failed-btn" @click="onFailClick" v-html="'불합격'" />
                </div>
                <div class="bottom-button">
                    <div class="btn profile-success-btn" @click="onSuccessClick" v-html="'합격'" />
                </div>
            </div>
        </div>
        <div>
            <div class="title" v-if="confirmedStatus === 'judging' || confirmedStatus === 'judged'">심사 여부</div>
            <div class="flex-row profile-confirm">
                <div @click="clickProfileConfirm('pass')" class="bottom-button" v-if="confirmedStatus === 'judging'">
                    <div class="btn user-detail-btn" v-html="'심사 승인'" />
                </div>
                <div @click="clickProfileConfirm('start')" class="bottom-button" v-if="confirmedStatus === 'judged'">
                    <div class="btn user-detail-btn" v-html="'퍼스널 매니저 배정'" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import userService from '@/services/user'

export default {
    name: 'JudgingBtns',
    props: ['chatUser', 'profile', 'from'],
    computed: {
        confirmedStatus() {
            return (this.profile || {}).status
        },
        isPass() {
            return this.$store.getters.chats.filter(el => el.user.id === this.chatUser.id)[0].user.profile.is_pass
        },
    },
    methods: {
        async clickProfileConfirm(status) {
            try {
                if (status === 'pass') {
                    const idx = await this.$modal.basic({
                        body: '심사 승인하시겠습니까?',
                        buttons: [
                            {
                                label: 'CANCEL',
                                class: 'btn-default',
                            },
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    if (idx) {
                        this.$loading(true)
                        const res = await userService.profileConfirm(this.chatUser.id, 'success')
                        this.$set(this.chatUser.profile, 'status', 'judged')
                        this.$toast.success(res.msg)
                        this.$loading(false)
                    }
                } else if (status === 'start') {
                    const managerId = await this.$modal.custom({
                        component: 'ModalChooseManager',
                        options: { name: this.chatUser.name },
                    })
                    if (managerId && managerId !== '취소') {
                        this.$loading(true)
                        const res = await userService.profileConfirm(this.chatUser.id, 'start', managerId)
                        this.$set(this.chatUser.profile, 'status', 'confirmed')
                        this.$toast.success(res.msg)
                        this.$loading(false)
                    }
                }
            } catch (e) {
                this.$loading(false)
                this.$toast.error(e.data)
            }
        },
        onFailClick() {
            this.$modal
                .custom({
                    component: 'ModalChooseReason',
                    options: null,
                })
                .then(async result => {
                    if (result !== '취소' && result !== undefined) {
                        const payload = {
                            chat_user_id: this.chatUser.id,
                            status: 'fail',
                            fail_reason: result,
                        }
                        const res = await userService.passFailJudgment(payload)
                        const chats = this.$store.getters.chats
                        const findChat = chats.filter(el => el.user.id === this.chatUser.id)[0]
                        findChat.user.profile.is_pass = 'fail'
                        const newChats = [...chats, findChat]
                        this.$store.commit('setChats', newChats)
                        this.$toast.success(res.msg)
                    }
                })
        },
        async onSuccessClick() {
            const idx = await this.$modal.basic({
                body: '프로필을 합격시키시겠습니까?',
                buttons: [
                    {
                        label: 'CANCEL',
                        class: 'btn-default',
                    },
                    {
                        label: 'CONFIRM',
                        class: 'btn-primary',
                    },
                ],
            })
            if (idx) {
                const payload = {
                    chat_user_id: this.chatUser.id,
                    status: 'success',
                }
                const res = await userService.passFailJudgment(payload)
                const chats = this.$store.getters.chats
                const findChat = chats.filter(el => el.user.id === this.chatUser.id)[0]
                findChat.user.profile.is_pass = 'pass'
                const newChats = [...chats, findChat]
                this.$store.commit('setChats', newChats)
                this.$toast.success(res.msg)
            }
        },
    },
}
</script>

<style lang="scss">
.judging-btns {
    .title {
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 10px;
        margin-top: 10px;
    }
    .profile-confirm {
        .profile-success-btn {
            width: 210px;
            height: 40px;
            background-color: #55efc4;
            color: black;
            font-size: 12px;
            border-radius: 8px;
            cursor: pointer;
        }
        .profile-failed-btn {
            width: 210px;
            height: 40px;
            background-color: #ffeaa7;
            color: black;
            font-size: 12px;
            border-radius: 8px;
            cursor: pointer;
        }
        .user-detail-btn {
            width: 210px;
            height: 40px;
            background-color: #8a74ff;
            color: white;
            font-size: 12px;
            border-radius: 8px;
            cursor: pointer;
        }
        .user-failed-btn {
            width: 210px;
            height: 40px;
            background-color: palevioletred;
            color: white;
            font-size: 12px;
            border-radius: 8px;
            cursor: pointer;
        }
    }
}
</style>
