import LoginPage from '@/routes/LoginPage'
import ChatPage from '@/routes/chat/ChatPage'
import MyPage from '@/routes/manage-friends/MyPage'
// import HomePage from '@/routes/home/HomePage'
import SettingsPage from '@/routes/settings/SettingsPage'
import NotFound from '@/routes/NotFound'
import EditProfilePage from '@/routes/settings/EditProfilePage'
import GiftPage from '@/routes/gift/GiftPage'

export default [
    {
        path: '/not-found',
        name: 'NotFound',
        component: NotFound,
    },
    {
        path: '/login',
        name: 'LoginPage',
        component: LoginPage,
    },
    {
        path: '/',
        name: 'ChatPage',
        component: ChatPage,
    },
    {
        path: '/chat',
        name: 'ChatPage',
        component: ChatPage,
    },
    {
        path: '/my-page',
        name: 'MyPage',
        component: MyPage,
    },
    {
        path: '/settings',
        name: 'SettingsPage',
        component: SettingsPage,
    },
    {
        path: '/edit-profile',
        name: 'EditProfilePage',
        component: EditProfilePage,
    },
    {
        path: '/gift-page',
        name: 'GiftPage',
        component: GiftPage,
    },
]
